





































import { Component, Vue } from 'vue-property-decorator'
// Types
import { GUIComponent, GUIEventCode, GuisProps, FormsProps, GUIWindowState } from './../../types/enums'
import { GuisServiceType } from './../../types/services/guis'

@Component({
    name: 'SidebarComponent'
})

export default class Sidebar extends Vue {

/** ******************************** Vue Computed **********************************/

    get guiService (): GuisServiceType { return this.$store.getters['guiGuis/getGuisService'] }

    get guisComponents () {
        const root = this.$store.getters['guiGuis/getGuisRoot'];
        return root.children; 
    }

/** ******************************** Vue Method **********************************/

    closeHandler () {
    }

    activate(component: GUIComponent): void {
        if(component.props.gpWindowState === GUIWindowState.gwsMinimized) {
            this.$store.dispatch('guiGuis/updateProperty', {id: component.props.id, property: 'gpWindowState', value: GUIWindowState.gwsNormal }) 
        }
        this.guiService.methodService.activateComponentTree(component)
    }

    listVisibleAndEnabled(components: Array<GUIComponent>) {
        return components.filter(component => this.guiService.methodService.activatable(component)); // remove any forms that aren't visible nor enabled
    }


}
