







































import { Component, Prop, Vue } from 'vue-property-decorator';
// Types
import { TreeItem } from '../../../types/components'
import { UtilsType } from '../../../types/utils'

// Utilities 
import Utils from '../../../utils/index';

// Components
import Icon from '../../layout/Icon.vue'

@Component({
  name: 'Branch',
  components: {Icon}
})

export default class Branch extends Vue {
  /** ******************************** Vue Props **********************************/
    @Prop() public id: any;
    @Prop() public item!: TreeItem;

    @Prop() public updateItem: any;
    @Prop() public leftClick: any;
    @Prop() public rightClick: any;
    @Prop() public status: any;
    @Prop() public treeIndex: any;
    @Prop() public selectionType: any;
    @Prop() public level!: number;

  /** ******************************** Vue Data **********************************/

    public utils: UtilsType = new Utils();

    public localItem: TreeItem = { ...this.item };
    public isOpen: boolean = true;

    // Single click and double click events
    public clickCount: number = 0;
    public clickTimer: any = null

  /** ******************************** Vue Computed **********************************/

    get incrLevel() {
        return this.level + 1
    }

    get tabindex() {
        // Only visual selections are tabbed
        if(this.localItem.visualSelection || this.item.visualSelection) {
            
        }
        return this.item.visualSelection? "0" : "-1"
    }

    get isMultiSelect() { return this.item.selectionType === 'multi' || this.item.selectionType === 'hierarchical' }

    get hasChildren () { return this.item.children && this.item.children.length }

    get getBranchChildren () { return this.item.children }

    get openState() { return this.item.state }
    set openState(val) { 
        this.localItem.state = val

        this.updateItem(this.localItem)
        this.status(this.localItem, val)
    }

    get checked() { return this.localItem.selected }
    set checked(val) {
        this.localItem.selected = val
    }

    get gpStyle () { 
        // 0 = text only 
        // 1 = text & sign 
        // 2 = text & small icon 
        // 3 = text & small icon & sign 
        // 4 = text & large icon 
        // 5 = text & large icon & sign
        return this.item.style 
    }

    get visualSelection () { return { selected: this.item.visualSelection } }

    get showSign () { return (this.gpStyle == 1 || this.gpStyle == 3 || this.gpStyle == 5) }
    get showSmallIcon () { return (this.gpStyle == 2 || this.gpStyle == 3) }

    get iconStyle() {
        let style: Partial<CSSStyleDeclaration>[] = []
        let styleProps: Partial<CSSStyleDeclaration> = {
            height: this.showSmallIcon ? '16px' : '32px'
        }
        style.push(styleProps)
        return style
    }
    get caption () { return this.item.caption }

    get tip () { return this.item.tip }


    get style() {
        let style: Partial<CSSStyleDeclaration>[] = []
        let styleProps: Partial<CSSStyleDeclaration> = {
            padding: "1px 3px",
            whiteSpace: "nowrap",
        }
        if(this.item.visualSelection) {
            style.push({background: "#ccc"})
        } else {
            style.push({background: "none"})
        }
        style.push(styleProps)
        return style
    }

    get focused() { return this.$store.getters['guiGuis/getFocusedControl']; }

  /** ******************************** Vue Methods **********************************/

    focusout() {
        this.localItem.visualSelection = false;
    }

    focus() {
        let id = `${this.id}-${this.localItem.id}-tooltip`;
        this.localItem.visualSelection = true;
        (this.$refs[id] as HTMLSpanElement).focus();
        (this.$refs[id] as HTMLSpanElement).scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
    }

    keyHandler(e: KeyboardEvent) {
        switch (e.code) {
            case ("ArrowUp"): 
                this.handleArrowUp();
            break;
            case ("ArrowDown"):
                this.handleArrowDown();
            break;
            case ("ArrowRight"):
                if(this.openState) {
                    if(this.hasChildren) {
                        let id = this.item.children[0].id;
                        let ref = `${id}-tree-branch-${id}`;
                        (this.$refs[ref] as Branch[])[0].focus()
                    } 
                } else {
                    this.openState = true;
                }
            break;
            case ("ArrowLeft"): 
                if(this.openState) {
                    this.openState = false;
                } else if(this.level !== 0) {
                    (this.$parent as Branch).focus();
                }
            break;
            case ("Home"):
                if(this.level != 0) {
                    let root = this.$parent as Branch;

                    while(root.level != 0) {
                        root = root.$parent as Branch;
                    }
                    root.focus();
                }
            break;
            case ("End"):
                // TODO
            break;
            case ("PageDown"):
                // TODO
            break;
            case ("PageUp"): 
                // TODO
            break;
            case ("Enter"): 
                this.toggleOpenState()
                if (this.hasChildren) {
                    this.openState = !this.openState;
                } else {
                    this.leftClick(this.localItem, 'double')
                }
            break;
            case("Space"):
                e.preventDefault();
                if(this.isMultiSelect) {
                    this.clickHandler();
                }
            break;
        }

    }

    handleArrowUp() {
        // Have to parent to get previous sibling 
        let parent = (this.$parent as Branch)
        let siblings = parent.getBranchChildren;
        
        // Check if a previous sibling is valid else focus on parent (move up a level)
        if(this.treeIndex - 1 >= 0) {                    
            let nextSibling = siblings[this.treeIndex-1] as TreeItem;
            let ref = `${nextSibling.id}-tree-branch-${nextSibling.id}`;
            (parent.$refs[ref] as Branch[])[0].findPrevSiblingChild();
        } else if(this.level !== 0) {
            parent.focus();
        }
    }

    handleArrowDown() {
        if(this.openState) {
            // Checking if open state to go to first child branch
            let id = this.item.children[0].id;
            let ref = `${id}-tree-branch-${id}`;
            (this.$refs[ref] as Branch[])[0].focus();
        } else {
            // Have to use parent to find next sibling
            let parent = (this.$parent as Branch);
            let siblings = parent.getBranchChildren;
            let nextSibling = siblings[this.treeIndex+1] as TreeItem

            // If there is a next sibling go to it else find the next sibling
            if(this.treeIndex + 1 < siblings.length) {
                let ref = `${nextSibling.id}-tree-branch-${nextSibling.id}`;
                (parent.$refs[ref] as Branch[])[0].focus()
            } else {
                this.nextSiblingDown();
            }
        }
    }

    toggleOpenState (e?: MouseEvent) {
        if (e) { e.stopPropagation() }

        if (this.hasChildren) {
            this.openState = !this.openState;
        }
    }

    clickHandler() {
        this.localItem.selected = !this.localItem.selected
        
        this.leftClick(this.item, 'single')

        // Checking for hierarchical to handle checking boxes
        if(this.selectionType === "hierarchical") {
            if(this.localItem.selected) {
                this.selectChildren();
                this.selectParent()
            } else {
                this.unselectChildren();
            }
        }

        this.focus();
    }

    singleClickHandler(e: MouseEvent) {
        e.stopPropagation()

        this.clickCount++;
        
        if (this.clickCount === 1) {
            this.clickTimer = setTimeout(() => { this.clickCount = 0 }, 250)  
            this.localItem.visualSelection = true

            this.leftClick(this.localItem, 'single')
        } else {
            this.clickCount = 0
        }

        //this.focus();
    }

    doubleClickHandler(e: MouseEvent) {
        e.stopPropagation()

        this.clickCount = 0

        this.leftClick(this.localItem, 'double')

        if (this.hasChildren) {
            this.toggleOpenState()
        }
    }

    rightClickHandler(e: MouseEvent) {
        e.stopPropagation()

        this.rightClick(e, this.localItem)
    }

    select() {
        this.localItem.selected = true;
    }

    unselect() {
        this.localItem.selected = false;
    }

    selectChildren() {
        // Selecting children and checking each child again
        let children = this.getBranchChildren
        children.forEach( (child) => {
            let ref = `${child.id}-tree-branch-${child.id}`;
            ((this.$refs[ref] as Branch[])[0] as Branch).select();
            ((this.$refs[ref] as Branch[])[0] as Branch).selectChildren();
        })
    }

    unselectChildren() {
        // Unselecting children and un-checking each child again
        let children = this.getBranchChildren
        children.forEach( (child) => {
            let ref = `${child.id}-tree-branch-${child.id}`;
            ((this.$refs[ref] as Branch[])[0] as Branch).unselect();
            ((this.$refs[ref] as Branch[])[0] as Branch).unselectChildren();
        })
    }

    selectParent() {
        if(this.level !== 0) {
            let parent = this.$parent as Branch;
            while(parent.level != 0) {
                parent.select();
                parent = parent.$parent as Branch;
            }
            parent.select();
        }
    }

    nextSiblingDown() {
        // Checking if root since we need to find a parent/grandparent
        if(this.level != 0) {
            // getting parent/grandparent to find a valid sibling on the bottom
            let parent = this.$parent as Branch;
            let grandParent = parent.$parent as Branch;
            // Sibling is last available child of grandparent (last branch in parent)
            let sibling = parent.treeIndex + 1 < grandParent.getBranchChildren.length? grandParent.getBranchChildren[parent.treeIndex + 1] as TreeItem : null;

            // Need to travese up next available sibling
            // Exits when sibling is found or no parents/grandparents exist
            while(!sibling && grandParent.level != 0 && parent.level != 0) {
                parent = parent.$parent as Branch;
                grandParent = parent.$parent as Branch;
                // Sibling is last available child of grandparent (last branch in parent)
                sibling = parent.treeIndex + 1 < grandParent.getBranchChildren.length? grandParent.getBranchChildren[parent.treeIndex + 1] as TreeItem : null;
            }

            // Once a sibling is found focus
            if(sibling) {
                let ref = `${sibling.id}-tree-branch-${sibling.id}`;
                (grandParent.$refs[ref] as Branch[])[0].focus()
            }
        }
    }

    findPrevSiblingChild() {
        // Checking if children branches are not available.
        if(!this.openState) {
            this.focus();
            return
        }

        // Getting the last child in the list
        let children = this.getBranchChildren
        let lastChild = children.length > 0? children[children.length -1] as TreeItem : null;
        let ref =""
        let branches: Branch[] = [];
        let branch: Branch = new Branch();

        // If a child exists need to check if another branch exists to go down a level
        // Else no more level to go down and set focus to current branch
        if(lastChild) {
            ref = `${lastChild.id}-tree-branch-${lastChild.id}`;
            branches = this.$refs[ref] as Branch[];
            branch = (branches[0] as Branch);
        } else {
            this.focus();
            return;
        }

        branch.findPrevSiblingChild();
    }
}
