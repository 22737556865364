












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'ErrorAlertComponent'
})

export default class ErrorAlert extends Vue {
/** ******************************** Vue Props **********************************/

@Prop(Number) readonly errorKey: number | undefined
@Prop(String) readonly message: string | undefined
@Prop(String) readonly type: string | undefined

/** ******************************** Vue Methods **********************************/

    dismissError () {
        this.$store.dispatch('errors/dismissError', { key: this.errorKey })
    }
}
