// Store
import store from '../../store'
// Types
import { IGUICmdControl, IGUIRspError, IGUIRspSuccess } from '../../types'
import { GUIErrorCode } from '../../types/enums'
import { GuisServiceControlType } from '../../types/services/guis'

export default class GuisServiceControl implements GuisServiceControlType {

    control(command: IGUICmdControl) {

        let response: IGUIRspSuccess = { command: command.command, error: GUIErrorCode.grSuccess }
        if (command.func === 1 || command.func === 2) {
            // this will trigger the watcher in App.vue (that redirects to the terminal)
            store.dispatch('guiGuis/toggleSuspended', command.func === 1);
        } else {
            // ignore other functions
        }
        store.dispatch('guiResponse/setResponse', response);
    }

}
