
import ProfilesServiceType from '@/types/services/profiles'

// payload is generally TermApp instance
export default class ProfilesService implements ProfilesServiceType {

    public endpoint: string

    constructor (endpoint: string) {
        this.endpoint = endpoint
    }

    async getProfiles (authToken?: string) {

        const endpoint = this.endpoint + '/profiles'

        let headers: {[key: string]: string} = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }

        if (authToken) {
            headers.Authorization = `Bearer ${authToken}`
        }

        const response = await fetch(endpoint, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: headers
        })

        return response.json()
    }

    async getProfile(id: string, authToken?: string) {

        const endpoint = this.endpoint + '/profiles/' + id;

        let headers: {[key: string]: string} = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }

        if (authToken) {
            headers.Authorization = `Bearer ${authToken}`
        }

        const response = await fetch(endpoint, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: headers
        })

        return response.json()
    }
}
