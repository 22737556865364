import { TermSettings } from "./termsettings.js";
import { TermColors } from "./termcolors.js";
import { TermUtil } from './termutil.js';
import { isEqualNoCase } from './termapp.js';

function makeAccuTermProxy(target, _constants, _common) {
    
    var timer = null;
    var constants = _constants;
    var common = _common;
    var accutermProxy;
    var sessionProxy;

    const accutermHandler = {
        get: function(target, name) {
            var propname;
            try {
                propname = name.toLowerCase();
            } catch(e) {
                console.log('tdproxy getter called with invalid name argument (type=' + typeof name + ')');
                console.log(e);
                return undefined;
            }
            switch(propname)
            {
                // properties
                case 'activesession':
                    return sessionProxy;
                case 'initsession':
                    return sessionProxy;
                case 'sessions':
                    return this._sessions;
                case 'visible':
                    return true;
                case 'windowstate':
                    return 0; // pretend the AccuTerm window is normalized (not maximized or minimized)
                // intermediate objects
                case 'common':
                    return this._common;
                // functions
                default:
                    throw 'unknown property: ' + name;
            }
        },
        set: function(target, name, value) {
            var propname;
            try {
                propname = name.toLowerCase();
            } catch(e) {
                console.log('tdproxy setter called with invalid name argument (type=' + typeof name + ')');
                console.log(e);
                return undefined;
            }
            switch(propname)
            {
                case 'visible':
                case 'windowstate':
                    break; // ignore
                default:
                    throw 'unknown property: ' + name;
            }
            return true;
        },
        _common: function(index) {
            return new __common(index);
        },
        _sessions: function(index) {
            return new __sessions(index);
        },
    }
    accutermHandler._sessions["count"] = 1; // the 'sessions' collection has a count property, always returns 1 for web app

    const sessionHandler = {
        get: function(target, name) {
            switch(name.toLowerCase())
            {
                // properties
                case 'capturemode':
                case 'capturesource':
                    return 0; // TODO
                case 'capturefilename':
                    return ''; // capture to file not supported
                case 'changed':
                    return 0; // settings not changed
                case 'charset':
                    return target.settings.charSet;                
                case 'col':
                    return target.x;
                case 'cols':
                    return target.cols;
                case 'cursortype':
                    return isEqualNoCase(target.settings.cursorStyle, "UNDERLINE") ? 0 : 1; 
                case 'extcols':
                    return target.settings.extCols;
                case 'extrows':
                    return target.settings.extRows;
                case 'inputmode':
                    return target.filterMode;
                case 'noclosewarning':
                    return 0; // TODO
                case 'normcols':
                    return target.settings.normCols;
                case 'normrows':
                    return target.settings.normRows;
                case 'row':
                    return target.y;
                case 'rows':
                    return target.rows;
                case 'scrmode':
                    return target.scrMode;
                case 'settings':
                    return this; // not exactly, but close enough
                case 'termtype':
                    switch(target.termName) {
                        case 'VT100':
                            return constants.attermvt100;
                        case 'VT220':
                            return constants.attermvt220;
                        case 'VT320':
                            return constants.attermvt320;
                        case 'VT420':
                            return constants.attermvt420;
                        case 'LINUX':                            
                        case 'XTERM':
                            return constants.attermlinux;
                        case 'ADDSVP':
                            return constants.atterma2;
                        case 'VPA2E':
                            return constants.atterma2e;
                        case 'WYSE50':
                            return constants.attermwy50;
                        case 'WYSE60':
                            return constants.attermwy60;
                        case 'PCMON':
                            return constants.attermpickmon;
                        default:
                            return constants.attermtty;
                    }
                case 'windowstate':
                    return 2; // session window is maximized in the browser tab

                // functions
                case 'apply':
                    return; // settings.apply is NOP here
                case 'capture':
                    return this._capture;
                case 'captureend':
                    return target._pvt_capture_stop.bind(target);
                case 'emulate':
                    return this._emulate;
                case 'extmode':
                    return target._pvt_screen_mode.bind(target, 1);
                case 'input': case 'readtext':
                    return target._input.bind(target);
                case 'normmode':
                    return target._pvt_screen_mode.bind(target, 0);
                case 'output': case 'writetext':
                    return target._output.bind(target);
                case 'paste':
                    return this._paste;
                case 'printerclose':
                    return target._pvt_printer_mode.bind(target, 'X');
                case 'printeroff':
                    return target._pvt_printer_mode.bind(target, '0');
                case 'printeron':
                    return this._printeron;
                case 'waitfor':
                    return target._waitfor.bind(target);

                // intermediate objects
                case 'colors':
                    return this._colors;
                case 'fkeys':
                    return this._fkeys;
                        
                // unimplemented functions / properties
                case 'download': case 'printscreen': case 'upload':
                    throw 'unimplemented method: ' + name;
                default:
                    throw 'unknown property: ' + name;
            }
        },
        set: function(target, name, value) {
            var newSettings;
            switch(name.toLowerCase())
            {
                case 'charset':
                    newSettings = new TermSettings(target.settings);
                    newSettings.charSet = value;
                    target.updateSettings(newSettings); // this will sanitize settings before using
                    break;
                case 'col':
                    value = +value || 0;
                    if(value >= 0 && value < target.cols) {
                        target.updateRange(target.y);
                    } else
                        throw new RangeError('invalid property value for: ' + name);
                    break;
                case 'cursortype':
                    newSettings = new TermSettings(target.settings);
                    newSettings.cursorStyle = value ? "BLOCK" : "UNDERLINE";
                    target.updateSettings(newSettings);
                    break;
                case 'extcols':
                    newSettings = new TermSettings(target.settings);
                    newSettings.extCols = value;
                    target.updateSettings(newSettings);
                    break;
                case 'extrows':
                    newSettings = new TermSettings(target.settings);
                    newSettings.extRows = value;
                    target.updateSettings(newSettings);
                    break;
                case 'inputmode':
                    target._setInputMode(value);
                    break;
                case 'noclosewarning':
                    break; // TODO
                case 'normcols':
                    newSettings = new TermSettings(target.settings);
                    newSettings.normCols = value;
                    target.updateSettings(newSettings);
                    break;
                case 'normrows':
                    newSettings = new TermSettings(target.settings);
                    newSettings.normRows = value;
                    target.updateSettings(newSettings);
                    break;
                case 'row':
                    value = +value || 0;
                    if(value >= 0 && value < target.rows) {
                        target.updateRange(target.y);
                    } else
                        throw 'invalid property value for: ' + name;
                    break;
                case 'scrmode':
                    value = +value || 0;
                    if (value >= 0 && value <= 1)
                        target._pvt_screen_mode(value);
                    else
                        throw 'invalid property value for: ' + name;
                    break;
                case 'termtype':
                    newSettings = new TermSettings(target.settings);
                    switch(value) {
                        case constants.attermvt100:
                            newSettings.termtype = 'VT100'; break;
                        case constants.attermvt220:
                            newSettings.termtype = 'VT220'; break;
                        case constants.attermvt320:
                            newSettings.termtype = 'VT320'; break;
                        case constants.attermvt420:
                            newSettings.termtype = 'VT420'; break;
                        case constants.attermlinux:
                            newSettings.termtype = 'LINUX'; break;
                        case constants.atterma2:
                            newSettings.termtype = 'ADDSVP'; break;
                        case constants.atterma2e:
                            newSettings.termtype = 'VPA2E'; break;
                        case constants.attermwy50:
                            newSettings.termtype = 'WYSE50'; break;
                        case constants.attermwy60:
                            newSettings.termtype = 'WYSE60'; break;
                        case constants.attermpickmon:
                            newSettings.termtype = 'PCMON'; break;
                        default:
                            newSettings.termtype = 'TTY'; break;
                    }
                    target.updateSettings(newSettings);
                    break;
                case 'changed':
                    break; // ignore 
                case 'capturemode': case 'capturesource': case 'capturefilename':
                case 'cols': case 'rows':
                    throw 'cannot set read-only property: ' + name;
                default:
                    throw 'unknown property: ' + name;
            }
            return true;
        },
        _capture: function(filename, source, mode) {
            filename = filename || '';
            if (!filename)
                throw 'invalid argument: filename must be null';
            source = +source || 0;
            mode = +mode || 0;
            // TODO: create capture mode string and call _pvt_capture_start
        },
        _colors: function(index) {
            return new __colors(index);
        },
        _emulate: function(text) {
            target._emulate.call(target, text);
        },
        _fkeys: function(index) {
            return new __fkeys(index);
        },
        _paste: function() {
            // TODO: get clipboard contents and send to host
        },
        _printeron: function(transparent) {
            target._pvt_printer_mode.call(target, transparent ? '2' : '1');
        },
    };

    accutermProxy = new Proxy(target, accutermHandler);
    sessionProxy = new Proxy(target, sessionHandler);
    return accutermProxy;

    // Intermediate objects implement array-like properties

    // Note: the 'defval' property is a kludge necessary because Javascript
    // does not have a "default property" for assignment. The 'valueOf' and
    // 'toString' functions handle the default property for access. For
    // assignment you must set the 'defval' property. The IndexVariable()
    // function in wwwbasic.js appends .defval to an object expression if
    // it is the target of an assignment, and the expression ends in a
    // close-parenthesis.

    function __colors(index) {
        this.index = index;
        Object.defineProperty(this, 'defval', {
          get: function() {
              var ac = 0;
              try {
                var xa = target.attrColor[TermColors.XlateAttr(this.index)];
                var bg = xa[0] || 0;
                var fg = xa[1] || 0;
                ac = TermColors.XlateColor(fg) | (TermColors.XlateColor(bg) << 4);
              } catch(e) {}            
            return ac;
          },
          set: function(value) {
            try {
                var ac = (parseInt(value) || 0) & 255; // ignore borders & effects (TODO: implement effects)
                var fg = TermColors.XlateColor(ac & 15);
                var bg = TermColors.XlateColor(ac >> 4);
                var newSettings = new TermSettings(target.settings);
                if (newSettings.themeStyle !== 'custom') {
                    newSettings.themeStyle = 'custom';
                    newSettings.customPalette = target.colors;
                    newSettings.customColors = target.attrColor;
                }
                newSettings.customColors[TermColors.XlateAttr(this.index)] = [bg, fg]; // TODO: border effects
                target.updateSettings(newSettings);
            } catch(e) {}
          }
        });
        this.valueOf = function() {return this.defval;};
        this.toString = function() {return this.defval.toString();};
    }

    function __common(index) {
        this.index = index;
        Object.defineProperty(this, 'defval', {
          get: function() {              
              try {
                return common[index.toString().toLowerCase()];
              } catch(e) {}            
          },
          set: function(value) {
            try {
                common[index.toString().toLowerCase()] = value;
            } catch(e) {}
          }
        });
        this.valueOf = function() {
            return this.defval;
        };
        this.toString = function() {
            return this.defval.toString();
        };
    }

    function __fkeys(index) {
        this.index = +index || 0;
        Object.defineProperty(this, 'defval', {
          get: function() {
              try {
                var vkey = index % 1000;
                var shift = Math.floor(index/1000);
                return target._getFKey(1, vkey, shift);
              } catch(e) {}            
          },
          set: function(value) {
            try {
                var vkey = index % 1000;
                var shift = Math.floor(index/1000);
                target._setFKey(1, vkey, shift, value);
            } catch(e) {}
          }
        });
        this.valueOf = function() {
            return +this.defval || 0;
        };
        this.toString = function() {
            return this.defval.toString();
        };
    }

    function __sessions(index) {
        if (index == 0)
            return sessionProxy;
    }
}

////////////////////////////////////////////////////////////
// Exports!
////////////////////////////////////////////////////////////

export { makeAccuTermProxy };
