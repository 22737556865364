































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { BTable } from 'bootstrap-vue';
// Types
import { GUIEventCode, GridItem } from './../../../types/enums';
import { IGUIEvent } from './../../../types'
import { UtilsType } from './../../../types/utils';

// Utilities 
import Utils from './../../../utils/index';


@Component({
  name: 'GridGeneralDropDownComponent'
})

export default class GridGeneralDropDown extends Vue {
/** ******************************** Vue Props **********************************/

  @Prop({ default: () => (new GridItem()) }) public item!: GridItem;
  @Prop({ default: 0 }) public offset!: number;
  @Prop({ default: 0 }) public height!: number;
  @Prop({ default: false }) public isCombo!: boolean;

/** ******************************** Vue Data **********************************/ 
  public utils: UtilsType = new Utils();

  public activated: boolean = false;
/** ******************************** Vue Computed **********************************/

  get col (): number { return this.item.col }
  get row (): number { return this.item.row }

  get id (): string { return this.item.id; }
  get tableId (): string { return this.id +'-drop-down-table'; }

  get isComboOrList() {
    if(this.isCombo) {
      return this.value
    } else {
      return ""
    }
  }

  get style(): Array<Partial<CSSStyleDeclaration>> { 
    let style: Array<Partial<CSSStyleDeclaration>> = [{position: 'fixed'}]

    const dropDown: HTMLElement = document.getElementById(this.id)!;
    const dropDownParent: HTMLElement = dropDown.parentElement!;
    const dropDownParentCoords: DOMRect = dropDownParent.getBoundingClientRect();

    const position = {top: dropDownParentCoords.top + Math.round(dropDownParentCoords.height) + "px", left: dropDownParentCoords.left + "px"} // { top: dropDownY +'px', left: dropDownX +'px'}

    let maxDropToPixels = 144;
    if (this.height >= 1) {
      maxDropToPixels = 18 * this.height;
    }
    
    let height = maxDropToPixels + 'px';

    style.push(position, {minWidth: dropDown.offsetWidth + "px", zIndex: "200", height})

    return style;        
  }

  get value () { return this.item.value }
  set value (val)  { this.$emit('updateValue', val, this.col, this.row); }

  get items(): Array<any> {
    const options: Array<Array<string>> = this.item.columnInfo.items.map(option => option.split('|') )

    let items: any = options.map((option, row) => {

      let newRow: any = {}  

      // loop the inner array
      option.forEach((opt, column) => {
        let acCol = column + 1; // Accuterm numbering starts at 1,1 not 0,0
        let acRow = row + 1;
        let id = this.id +'-col-'+ acCol +'-row-'+ acRow;
        newRow[column] = {
          id: id,
          value: opt || '',
        }; 
      })

      return newRow;
    })

    return items;
  }

  get dataColumnNo(): number { return (this.item.columnInfo.dataCol === 0) ? 0 : (this.item.columnInfo.dataCol - 1); }

  get selectedRow(): number { 
    const dataColumnNo = this.dataColumnNo
    const value = this.value.toString().toLowerCase()
    const rowIndex: number = this.items.findIndex((item: any) => {
      const comp = (item[dataColumnNo].value as string | number).toString().toLowerCase()
      return comp === value
    })
    return rowIndex !== -1 ? rowIndex : 0;
  }

/** ******************************** Methods **********************************/ 

  dataColumnNumber(value: string | number): number {
    const rowIndex: number = this.items.findIndex((item: any) => item[this.dataColumnNo].value === value)
    return rowIndex
  }

  rowClickHandler (record: any, index: number, e: KeyboardEvent | MouseEvent) {
    // Ignore enter key since it will get called later.
    if((e as KeyboardEvent).key !== "Enter") {
      // Clear the drop down
      this.value = record[this.dataColumnNo].value || '';
      this.toggleDownAndFocus()
    }
  }

  toggleDownAndFocus() {
    const activateEvent: IGUIEvent = { event: GUIEventCode.geActivateCell, id: this.id, args: [this.row, this.col] }
    this.$store.dispatch('guiGuis/addEvent', activateEvent);
    this.$emit("focusInner", this.id);
    this.$emit('toggleDropDown', null, null)
  }

  keyHandler(e: KeyboardEvent) {
    e.stopPropagation();
    const index = (e.target as HTMLTableRowElement).sectionRowIndex;
    switch (e.key) {
      case ("Enter"):
        this.value = this.items[index][this.dataColumnNo].value || '';
        this.toggleDownAndFocus();
      break;
      case ("ArrowUp"):
      case ("ArrowDown"):
        (this.$refs[this.tableId] as BTable).selectRow(index);
      break;
      default:
    }
  }

  hover(record: any, index: number, e: MouseEvent) {
    let value = record[this.dataColumnNo].value || '';
    this.selectRowByValue(value);
    (e.target as HTMLTableRowElement).focus();
  }

  selectRowByValue(value: string | number) {
    let columnNum = this.dataColumnNumber(value);
    if(columnNum >= 0) {
      (this.$refs[this.tableId] as BTable).selectRow(columnNum);
    }
  }

  focusDropDown() {
    let elem: BTable = (this.$refs[this.tableId] as BTable);
    let elemChild: HTMLElement = elem.$el.children[1] as HTMLElement;

    // Find the focused element in drop down table or pick first
    if(elemChild && elemChild.children.length >= 0) {
      let index = this.dataColumnNumber(this.value);
      index = Math.max(index, 0);
      index = Math.min(elemChild.children.length - 1, index);
      (elemChild.children[index] as HTMLTableRowElement).focus();
    }
  }

  setValueFromDropDown() {
    let table: BTable = (this.$refs[this.tableId] as BTable);
    let rows = table.$el.children[1].children;

    // Need to find active index to set value for some reason value does not always get set
    for(let i = 0; i < rows.length; i++) {
      if(rows[i].className.includes("table-row-selected")) {
        let row: HTMLTableRowElement = table.$el.children[1].children[i] as HTMLTableRowElement;
        let value: string | null = row.firstChild!.textContent;
        value = value? value.trim() : ""; // Getting textContext adds whitespace
        this.$emit('updateValue', value, this.col, this.row);
        this.$emit('toggleDropDown', null, null);
        break;
      }
    }
  }

/** ******************************** Vue Life Cycle **********************************/

  mounted() {
    (this.$refs[this.tableId] as BTable).selectRow(this.selectedRow);
  }

  updated() {
    this.selectRowByValue(this.value);
  }
}
