/* javascript-obfuscator:disable */

/* eslint key-spacing: 0, block-spacing: 0 */

var BasicExtension =
{
    constants: 
    {
        win32:0,
        attermfirst:0,
        attermtty:0,
        attermvpa2:1,
        attermvp60:2,
        attermp60:3,
        atterma2e:4,
        attermwy50:5,
        attermwy60:6,
        attermvt52:7,
        attermvt220:8,
        attermansi:9,
        attermpickmon:10,
        attermtek:11,
        attermvt100:12,
        attermvt320:13,
        attermvt420:14,
        attermsco:15,
        attermlinux:16,
        attermtek4100:17,
        attermp30:18,
        attermp8:19,
        attermlast:19,
        atcustomtermfirst:76,
        atcustomtermc330:76,
        atcustomtermlanpar:77,
        atcustomtermlast:77,
        atdevfirst:1,
        atdevnone:0,
        atdevserial:1,
        atdevpiclan:2,
        atdevtelnet:3,
        atdevmodem:4,
        atdevssh:5,
        atdevwebsock:6,
        atdevssl:7,
        atdevlast:7,
        atbaudfirst:0,
        atbaud300:0,
        atbaud1200:1,
        atbaud2400:2,
        atbaud4800:3,
        atbaud9600:4,
        atbaud14400:5,
        atbaud19200:6,
        atbaud38400:7,
        atbaud57600:8,
        atbaud115200:9,
        atbaudlast:9,
        atparityfirst:0,
        atparitynone:0,
        atparityodd:1,
        atparityeven:2,
        atparitymark:3,
        atparityspace:4,
        atparitylast:4,
        atduplexfirst:0,
        atduplexfull:0,
        atduplexhalf:1,
        atduplexlocal:2,
        atduplexlast:2,
        athandshakefirst:0,
        athandshakenone:0,
        athandshakexon:1,
        athandshakerts:2,
        athandshakedtr:3,
        athandshakexio:4,
        athandshakelast:4,
        atsshcipherfirst:0,
        atsshcipherdefault:0,
        atsshcipher3des:3,
        atsshcipherblowfish:6,
        atsshcipheraes128:7,
        atsshcipheraes256:8,
        atsshcipherlast:8,
        atsshauthfirst:0,
        atsshauthdefault:0,
        atsshauthauto:1,
        atsshauthpubkey:2,
        atsshauthpassword:3,
        atsshauthinteractive:7,
        atsshauthlast:7,
        atprotocolfirst:0,
        atprotocolascii:0,
        atprotocolkermit:1,
        atprotocolxmodem:2,
        atprotocolymodem:3,
        atprotocolzmodem:4,
        atprotocollast:4,
        atxfrovrfirst:0,
        atprotect:0,
        atoverwrite:1,
        atappend:2,
        atnewer:3,
        atupdate:4,
        atresume:5,
        atxfrovrlast:5,
        atprinterfirst:0,
        atprinteroff:0,
        atprinteron:1,
        atprintertransparent:2,
        atprinterlast:2,
        atresetfirst:0,
        atresetall:0,
        atresetterminal:1,
        atresetcomm:2,
        atclosecomm:3,
        atresetlast:3,
        atstatusfirst:0,
        atstatuslabel:0,
        atstatusshift:1,
        atstatusmessage:2,
        atstatusnone:3,
        atstatuslast:3,
        atmaxcols:240,
        atmaxrows:240,
        atmaxpages:25,
        atmaxhistory:10000,
        atscrlhistlineup:0,
        atscrlhistlinedown:1,
        atscrlhistpageup:2,
        atscrlhistpagedown:3,
        atscrlhisttop:6,
        atscrlhistbottom:7,
        atencdnone:0,
        atencdansi:1,
        atencdunicode:2,
        atencdutf8:3,
        vbokonly:0,
        vbokcancel:1,
        vbabortretryignore:2,
        vbyesnocancel:3,
        vbyesno:4,
        vbretrycancel:5,
        vbcritical:16,
        vbquestion:32,
        vbexclamation:48,
        vbinformation:64,
        vbdefaultbutton1:0,
        vbdefaultbutton2:256,
        vbdefaultbutton3:512,
        vbok:1,
        vbcancel:2,
        vbabort:3,
        vbretry:4,
        vbinore:5,
        vbyes:6,
        vbno:7
    },
    types:
    { 
        session: 'object'
    },
    functions:
    {
        // dummy VBA functions throw runtime error, not compilation error
        appactivate: function(name) {throw 'Unsupported function: AppActivate.';},
        appclose: function(name) {throw 'Unsupported function: AppClose.';},
        appfind: function(name) {throw 'Unsupported function: AppFind.';},
        apphide: function(name) {throw 'Unsupported function: AppHide.';},
        appgetactive: function(name) {throw 'Unsupported function: AppGetActive.';},
        appgetposition: function(name) {throw 'Unsupported function: AppGetPosition.';},
        appgetstate: function(name) {throw 'Unsupported function: AppGetState.';},
        applist: function(name) {throw 'Unsupported function: AppList.';},
        appmaximize: function(name) {throw 'Unsupported function: AppMaximize.';},
        appminimize: function(name) {throw 'Unsupported function: AppMinimize.';},
        appmove: function(name) {throw 'Unsupported function: AppMove.';},
        apprestore: function(name) {throw 'Unsupported function: AppRestore.';},
        appsetstate: function(name) {throw 'Unsupported function: AppSetState.';},
        appshow: function(name) {throw 'Unsupported function: AppShow.';},
        appsize: function(name) {throw 'Unsupported function: AppSize.';},
        command: function() {throw 'Unsupported function: Command.';},
        deletesetting: function(name) {throw 'Unsupported function: SeleteSetting.';},
        dir: function(path, mode) {throw 'Unsupported function: Dir.';},
        environ: function(name) {throw 'Unsupported function: Environ.';},
        fileexists: function(name) {throw 'Unsupported function: FileExists.';},
        getattr: function(name) {throw 'Unsupported function: GetAttr.';},
        getsetting: function(name) {throw 'Unsupported function: GetSetting.';},
        mkdir: function(name) {throw 'Unsupported function: MkDir.';},
        openfilename: function(name) {throw 'Unsupported function: OpenFileName.';},
        savefilename: function(name) {throw 'Unsupported function: SaveFileName.';},
        savesetting: function(name) {throw 'Unsupported function: SaveSetting.';},
        sendkeys: function(name) {throw 'Unsupported function: SendKeys.';},
    }
};

BasicExtension.functions.dir.minLength = 0;

////////////////////////////////////////////////////////////
// Exports!
////////////////////////////////////////////////////////////

export { BasicExtension };
