



































import { Component, Vue } from 'vue-property-decorator'
// Types
import Profile from '@/types'
// Components
import ProfileCard from '@/components/profiles/ProfileCard.vue'

@Component({
    name: 'ProfilesComponent',
    components: {
        ProfileCard
    }
})

export default class Profiles extends Vue {
/** ******************************** Vue Computed **********************************/

    get profiles (): Array<Profile> { return this.$store.getters['profiles/getProfiles'] }
    get hasProfile (): boolean { return this.$store.getters['profiles/getHasProfile'] }
    get activeProfile (): Profile { return this.$store.getters['profiles/getActiveProfile'] }
    get activeIndex (): number { return this.$store.getters['profiles/getActiveIndex'] }
    get hasActiveProfile (): boolean { return this.$store.getters['profiles/getHasActiveProfile'] }

    get authToken () { return this.$store.getters['user/getAuthToken'] }

/** ******************************** Vue Methods **********************************/

    refreshProfiles () {
        this.$store.dispatch('toggleLoading', { loading: true })
        this.$store.dispatch('profiles/setProfiles', { authToken: this.authToken })
    }
}
