














import { Component, Vue } from 'vue-property-decorator'
// Libraries
import Keyboard from 'simple-keyboard'
import 'simple-keyboard/build/css/index.css'

@Component({
    name: 'TermKeyboardComponent'
})

export default class TerminalKeyboard extends Vue {
/** ******************************** Vue Data! **********************************/
    // Keyboards
    private keyboard!: Keyboard;
    private keyboardControlPad !: Keyboard;
    private keyboardArrows !: Keyboard;

    private extend: boolean = true;

    // Modifiers
    private mods: Array<any> = [
        { key: 'ctrl', sKey: '{controlleft}', on: false, code: 1000 },
        { key: 'shift', sKey: '{shift}', on: false, code: 2000 }
    ]

    // Special Keys
    private specialKeys = [
        { key: 'F1', sKey: '{f1}', code: 112 },
        { key: 'F2', sKey: '{f2}', code: 113 },
        { key: 'F3', sKey: '{f3}', code: 114 },
        { key: 'F4', sKey: '{f4}', code: 115 },
        { key: 'F5', sKey: '{f5}', code: 116 },
        { key: 'F6', sKey: '{f6}', code: 117 },
        { key: 'F7', sKey: '{f7}', code: 118 },
        { key: 'F8', sKey: '{f8}', code: 119 },
        { key: 'F9', sKey: '{f9}', code: 120 },
        { key: 'F10', sKey: '{f10}', code: 121 },
        { key: 'F11', sKey: '{f11}', code: 122 },
        { key: 'F12', sKey: '{f12}', code: 123 },
        { key: 'tab', sKey: '{tab}', code: 9 },
        { key: 'esc', sKey: '{escape}', code: 27 },
        { key: 'home', sKey: '{home}', code: 36 },
        { key: 'end', sKey: '{end}', code: 35 },
        { key: 'space', sKey: '{space}', code: 32 },
        { key: 'page up', sKey: '{pageup}', code: 33 },
        { key: 'page down', sKey: '{pagedown}', code: 34 },
        { key: '&larr;', sKey: '{arrowleft}', code: 37 },
        { key: '&uarr;', sKey: '{arrowup}', code: 38 },
        { key: '&rarr;', sKey: '{arrowright}', code: 39 },
        { key: '&darr;', sKey: '{arrowdown}', code: 40 },
        { key: 'insert', sKey: '{insert}', code: 45 },
        { key: 'delete', sKey: '{delete}', code: 46 },
        { key: 'bksp', sKey: '{backspace}', code: 46 },
        { key: 'enter', sKey: '{enter}', code: 13 },
        { key: 'break', sKey: '{pause}', code: 3 },
        { key: 'scroll lock', sKey: '{scrolllock}', code: 145 }
    ];

/** ******************************** Vue Methods **********************************/

    onKeyPress (button: string) {
        let modifierKey = (button === '{shift}' || button === '{controlleft}')

        // If button is a Modifier key
        if (modifierKey) {
            this.handleModifier(button)
        } else {
            let specialKey = this.specialKeys.find(specialKey => button === specialKey.sKey)

            // TODO: Inline copy/paste instead of requiring modal; don't output {copy} and {paste} on click
            // Paste functionality (modal)
            if (button === '{paste}') {
                this.$emit('showPasteModal')
            // Toggle the control/arrow keyboard
            //} else if (button === '{extend}' || button === '{close}') {
            //    this.extend = !this.extend
            // If the pause/break' button is pushed
            } else if (button === '{pause}') {
                this.$store.dispatch('terminal/breakKey')
            // Special Keys
            } else if (specialKey) {
                let keyCode = specialKey.code

                // Loop through the modifiers array
                this.mods.forEach(mod => {
                    // Apply modifiers if they're on (+1000 or +2000)
                    if (mod.on) {
                        keyCode = keyCode + mod.code
                    }
                })

                this.$store.dispatch('terminal/sendFunctionKey', { key: keyCode })

            // All other keys
            } else {
                let text = button
                // Space
                //MW if (text === '{space}') { text = ' ' }

                let ascii
                // Get the keycode and check if the control key is on (per Pete S)
                if ((text.length === 1) && (ascii = text.charCodeAt(0)) && ((ascii >= 64 && ascii <= 95) || (ascii >= 97 && ascii <= 122)) && this.mods[0].on) {
                    // control character
                    ascii &= 0x1F
                    this.$store.dispatch('terminal/sendFunctionKey', { key: (ascii + 2000) })
                } else {
                   this.$store.dispatch('terminal/sendKeys', { key: text })
                }
            }
        }
        // Keep Simple Keyboard's input cleared out (so it doesn't stack up all the input)
        this.keyboard.clearInput()
        this.keyboardControlPad.clearInput()
        this.keyboardArrows.clearInput()
        // Focus back on the terminal
        this.$store.dispatch('terminal/focus')
    }

    handleModifier (modifier: string) {
        let modIndex = this.mods.findIndex(mod => modifier === mod.sKey)
        let layout = this.mods[modIndex].key
        // Toggle the on/off state
        this.mods[modIndex].on = !this.mods[modIndex].on

        if (this.mods[modIndex].on) {
            this.keyboard.addButtonTheme(this.mods[modIndex].sKey, 'button-highlight')
        } else {
            this.keyboard.removeButtonTheme(this.mods[modIndex].sKey, 'button-highlight')
            layout = 'default'
        }

        // Check if all (ctrl & shift) modifiers are on
        let all = this.mods.filter(mod => mod.on)

        if (all.length === 2) {
            layout = 'ctrlShift'
        }

        this.keyboard.setOptions({ layoutName: layout })
        this.keyboardControlPad.setOptions({ layoutName: layout })
        this.keyboardArrows.setOptions({ layoutName: layout })
    }

/** ******************************** Vue Mounted! **********************************/

    mounted () {
        let display:{ [index: string]: string } = {
            '{controlleft}': 'ctrl',
            '{copy}': 'copy',
            '{paste}': 'paste',
            '{extend}': 'extend',
            '{close}': 'close'
        }
        // loop through all the special keys
        this.specialKeys.forEach(specialKey => { display[specialKey.sKey] = specialKey.key })

        let commonKeyboardOptions = {
            // debug: true,
            theme: 'accuterm-keyboard',
            onKeyPress: this.onKeyPress,
            mergeDisplay: true,
            display: display,
            autoUseTouchEvents: true,
            physicalKeyboardHighlight: true,
            physicalKeyboardHighlightBgColor: 'rgba(51, 51, 51, 0.7)',
            buttonTheme: [
                {
                    class: 'special-button',
                    buttons: '{copy} {paste} {close}'
                }
            ]
        }

        this.keyboard = new Keyboard('.simple-keyboard', {
            ...commonKeyboardOptions,
            layout: {
                default: [
                    '{escape} {f1} {f2} {f3} {f4} {f5} {f6} {f7} {f8} {f9} {f10} {f11} {f12}',
                    '` 1 2 3 4 5 6 7 8 9 0 - = {backspace}',
                    '{tab} q w e r t y u i o p [ ] \\',
                    "{shift} a s d f g h j k l ; ' {enter}",
                    '{controlleft} z x c v b n m , . / {shift}',
                    '{copy} {paste} {space}'
                ],
                shift: [
                    '{escape} {f1} {f2} {f3} {f4} {f5} {f6} {f7} {f8} {f9} {f10} {f11} {f12}',
                    '~ ! @ # $ % ^ & * ( ) _ + {backspace}',
                    '{tab} Q W E R T Y U I O P { } |',
                    '{shift} A S D F G H J K L : " {enter}',
                    '{controlleft} Z X C V B N M < > ? {shift}',
                    '{copy} {paste} {space}'
                ],
                ctrl: [
                    '{escape} {f1} {f2} {f3} {f4} {f5} {f6} {f7} {f8} {f9} {f10} {f11} {f12}',
                    '` 1 2 3 4 5 6 7 8 9 0 - = {backspace}',
                    '{tab} q w e r t y u i o p [ ] \\',
                    "{shift} a s d f g h j k l ; ' {enter}",
                    '{controlleft} z x c v b n m , . / {shift}',
                    '{copy} {paste} {space}'
                ],
                ctrlShift: [
                    '{escape} {f1} {f2} {f3} {f4} {f5} {f6} {f7} {f8} {f9} {f10} {f11} {f12}',
                    '` 1 2 3 4 5 6 7 8 9 0 - = {backspace}',
                    '{tab} q w e r t y u i o p [ ] \\',
                    "{shift} a s d f g h j k l ; ' {enter}",
                    '{controlleft} z x c v b n m , . / {shift}',
                    '{copy} {paste} {space}'
                ]
            }
        })

        this.keyboardControlPad = new Keyboard('.simple-keyboard-control', {
            ...commonKeyboardOptions,
            layout: {
                default: [
                    '{scrolllock} {pause}',
                    '{insert} {home} {pageup}',
                    '{delete} {end} {pagedown}'
                ],
                shift: [
                    '{scrolllock} {pause}',
                    '{insert} {home} {pageup}',
                    '{delete} {end} {pagedown}'
                ],
                ctrl: [
                    '{scrolllock} {pause}',
                    '{insert} {home} {pageup}',
                    '{delete} {end} {pagedown}'
                ],
                ctrlShift: [
                    '{scrolllock} {pause}',
                    '{insert} {home} {pageup}',
                    '{delete} {end} {pagedown}'
                ]
            }
        })

        this.keyboardArrows = new Keyboard('.simple-keyboard-arrows', {
            ...commonKeyboardOptions,
            layout: {
                default: ['{arrowup}', '{arrowleft} {arrowdown} {arrowright}'],
                shift: ['{arrowup}', '{arrowleft} {arrowdown} {arrowright}'],
                ctrl: ['{arrowup}', '{arrowleft} {arrowdown} {arrowright}'],
                ctrlShift: ['{arrowup}', '{arrowleft} {arrowdown} {arrowright}']
            }
        })
    }
}
