




















import { Component, Vue } from 'vue-property-decorator'
// Types
import { errorObject } from '@/types/store/errors'
// Components
import ErrorAlert from '@/components/layout/ErrorAlert.vue'

@Component({
    name: 'ErrorsComponent',
    components: {
        ErrorAlert
    }
})

export default class Errors extends Vue {
/** ******************************** Vue Computed **********************************/
    get activeErrors (): Array<errorObject> { return this.$store.getters['errors/getErrors'].filter((error: errorObject) => (error.active && error.show)) }
}
