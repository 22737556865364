import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
// Types
import { RootState } from '../../types/store/'
import { GuisState, deferredDeactivateType } from '../../types/store/guis'
import { IGUIEvent } from '../../types'
import { GUIComponent, GUIConstants, GUIEventCode, RootProps, AllProps, GuisProps, FormsProps, HotKey, HotKeyBase, ControlsProps, GUIObjectType } from '../../types/enums'
// Services
import GuisService from '../../services/guis/'
// Utilities 
import Utils from '../../utils/index'
import Vue from 'vue'

// State
export const state: GuisState = {
    // Commands
    context: { defaultVersion: GUIConstants.guiVersion, version: null, defaultScale: GUIConstants.guiScale, scale: null },
    dialogBox: {
        type: null,
        visible: false,
        title: null,
        message: null,
        buttons: 0,
        defBtn: 0,
        answer: null,
        icon: 0,
        extFilter: null,
        fileStyle: null,
        font: null,
        color: null
    },
    shutdown: false,
    suspended: false,

    // GUI Components
    guisService: new GuisService(),
    guisMap: new Map<string, GUIComponent>(),
    guisRoot: { props: new RootProps(), children: [] },

    // Events
    events: [],

    // UX
    loading: false,
    focused: '',    
    mousePosition: { x: 0, y: 0 },
    nextFormZIndex: 1,
    utils: new Utils(),
    menuItemId: '',

    // Deferred event handlers
    deferredFrmDeactivate: null,
    deferredCtlDeactivate: null,
}

// Namespace
const namespaced: boolean = true

// Getters
export const getters: GetterTree<GuisState, RootState> = {
    // Commands
    getVersion(state) { return state.context.version || state.context.defaultVersion },
    getScale(state) { return state.context.scale || state.context.defaultScale },
    getDialogBox() { return state.dialogBox },
    getShutdown(state) { return state.shutdown },
    getSuspended(state) { return state.suspended },

    // GUI Components
    getGuisService(state) { return state.guisService },
    getGuisRoot(state) { return state.guisRoot },
    getComponent: (state: GuisState) => (id: string): GUIComponent | undefined => {
        return state.guisMap.get(id.toUpperCase());
    },
    getProps: (state: GuisState) => (id: string): AllProps | undefined => { 
        const gui = state.guisMap.get(id.toUpperCase());
        if (gui) {
            return gui.props;
        }
    },
    hasGuis(state) {
        // Check if there are any enabled and visible forms
        const root: GUIComponent = state.guisRoot;
        if (root.children.some(gui => {
            // Adjust this to allow MDI viewing 
            // if (gui.props.gpVisible && gui.props.gpEnabled) {
            if (gui.props.gpVisible && gui.props.gpEnabled) {
                return gui.children.some(form => form.props.gpVisible && form.props.gpEnabled);
            } else {
                return false;
            }
        })) {
            return true;
        }
        return false;
    },

    // GUI Events
    getEvents(state) { return state.events },

    // UX
    getLoading(state) { return state.loading },

    getFocusedApp(state) {
        let k = state.focused.indexOf('*', 0);
        return k > -1 ? state.focused.substr(0, k) : state.focused; },
    getFocusedForm(state) {
        let k = state.focused.indexOf('*', 0);
        if (k > -1) k = state.focused.indexOf('*', k + 1);
        return k > -1 ? state.focused.substr(0, k) : state.focused; },
    getFocusedControl(state) { return state.focused; },

    getMousePosition(state) { return state.mousePosition },

    getNextFormZIndex(state) { return state.nextFormZIndex },

    getHotKeys: (state, getters) => (id: string): Array<HotKey> => { 
        const props: GuisProps | FormsProps = getters.getProps(id.toUpperCase());
        
        if (props) {
            return props.hotKeys.map((key: HotKey) => ({ ...key }));
        } else {
            return [];
        }
    },
    getUtils(state) { return state.utils },
    getMenuItemId(state) { return state.menuItemId },

    // Deferred event handlers
    getDeferredFrmDeactivate(state) { return state.deferredFrmDeactivate },
    getDeferredCtlDeactivate(state) { return state.deferredCtlDeactivate },

}

// Mutations
export const mutations: MutationTree<GuisState> = {
    // Commands
    SET_VERSION (state, version) { state.context.version = version },
    SET_SCALE (state, scale) { state.context.scale = scale },
    SET_DIALOG_BOX (state, msgBox) { state.dialogBox = { ...msgBox } },
    CLEAR_DIALOG_BOX (state) { state.dialogBox.visible = false; }, // just hide it, the details can live in there till a new one opens
    SET_SHUTDOWN (state, toggle) { state.shutdown = toggle },
    SET_SUSPENDED (state, toggle) { state.suspended = toggle },
    
    // GUI Components
    SET_GUIS (state, guisRoot) { state.guisRoot = guisRoot },
    CLEAR_GUIS (state) { state.guisRoot.children = []; state.guisMap.clear() },

    // GUI Events
    SET_EVENTS (state, events) { state.events = events },
    CLEAR_EVENTS (state) { state.events = [] },

    // UX
    SET_FOCUSED (state, focused) { state.focused = focused },
    CLEAR_FOCUSED (state) { state.focused = '' },   
    SET_MOUSE_POSITION (state, position) { state.mousePosition = { ...position } },
    SET_NEXT_FORM_ZINDEX (state, index) { state.nextFormZIndex = index },
    SET_MENU_ITEM_ID (state, id) { state.menuItemId = id },

    // Deferred event handlers
    SET_DEFERRED_FRM_DEACTIVATE (state, func) { state.deferredFrmDeactivate = func },
    SET_DEFERRED_CTL_DEACTIVATE (state, func) { state.deferredCtlDeactivate = func },
    
}

// Actions
export const actions: ActionTree<GuisState, RootState> = {
/*
* Actions for commands and command helpers
*/
    commandHandler({ state }, command) {
        state.guisService.commandHandler(command);
    }, // used by request service/store

    setVersion ({ commit }, version) { commit('SET_SCALE', version) }, // used by context command service
    setScale ({ commit }, scale) { commit('SET_SCALE', scale) }, // used by context command service

    setDialogBox({ commit }, msgBox) { commit('SET_DIALOG_BOX', msgBox) }, // used by msgBox command service
    clearDialogBox({ commit }) { commit('CLEAR_DIALOG_BOX',) }, // used by shutdown command service

    toggleShutdown ({ commit }, toggle) { commit('SET_SHUTDOWN', toggle) }, // used by shutdown command service
    toggleSuspended ({ commit }, toggle) { commit('SET_SUSPENDED', toggle) }, // used by control command service

/*
* Actions for GUIComponents
*/
    createRoot({ state, commit }) {
        const root = state.guisRoot;
        state.guisMap.set('*', root);
        //commit('SET_GUIS', state.guisRoot);
    },
    setComponent ({ state, commit }, payload: { id: string, component: GUIComponent }) {
        state.guisMap.set(payload.id.toUpperCase(), payload.component);
        //commit('SET_GUIS', state.guisRoot);
    },
    deleteComponent ({ state, commit }, id: string) {
        state.guisMap.delete(id.toUpperCase());
        //commit('SET_GUIS', state.guisRoot);
    },
    updateProperty({ state, commit }, payload: { id: string, property: string, value: any }) {     
        const propKey = payload.property;
        let value = (Array.isArray(payload.value)) ? payload.value.slice(0) : payload.value;
        const gui = state.guisMap.get(payload.id.toUpperCase());
        if (gui) {
            value = (Array.isArray(value)) ? value.slice(0) : value;
            if(gui.props[propKey] !== value) {
                gui.props[propKey] = value;
            }
            //commit('SET_GUIS', state.guisRoot);
        }
    },
    setProps({ state, commit }, payload: { id: string, props: AllProps }) {
        const gui = state.guisMap.get(payload.id.toUpperCase());
        if (gui) {
            // TODO: why does Object.assign maintain the focused property, but spread operator sometimes clears focused property?
            //gui.props = { ...payload.props };
            if(gui.props !== payload.props) {
                Object.assign(gui.props, payload.props);
            }
            //commit('SET_GUIS', state.guisRoot);
        }
    },
    clearGuis ({ commit }) { commit('CLEAR_GUIS') },
/*
* Actions to handle gui/app events
*/
    addEvent ({ state, commit }, guiEvent: IGUIEvent) { 

        let newEvents = state.events.map(newEvent => ({ ...newEvent })) 
        newEvents.push(guiEvent)

        commit('SET_EVENTS', newEvents) 
        // send the events back
        state.guisService.checkWaitEvent();
        
    },

    removeEventType ({ state, commit }, eventType: GUIEventCode) { 

        let newEvents = state.events.filter(event => event.event !== eventType);
        
        commit('SET_EVENTS', newEvents) 
    },

    clearEvents ({ commit }) { commit('CLEAR_EVENTS') },

    setDeferredFrmDeactivate ( { commit }, f: deferredDeactivateType | null ) { commit('SET_DEFERRED_FRM_DEACTIVATE', f) },
    setDeferredCtlDeactivate ( { commit }, f: deferredDeactivateType | null ) { commit('SET_DEFERRED_CTL_DEACTIVATE', f) },

/*
* Actions to handle UX user interactions and information
*/
    setFocused({ getters, commit }, payload: { id: string }) { 
        let id: string = payload.id;
        const focusProps: AllProps = getters.getProps(payload.id);
        if (focusProps) {
            switch (focusProps.typeFamily) {
                case ('app'):
                    if (focusProps.focused) {
                        const formProps: AllProps = getters.getProps(focusProps.focused);
                        if (formProps) {
                            id = formProps.id;
                            if (formProps.focused) {
                                const controlProps = getters.getProps(formProps.focused);
                                if (controlProps) {
                                    id = controlProps.id;
                                }
                            }
                        }
                    }
                    commit('SET_FOCUSED', id);
                break;
                case ('form'):
                    if (focusProps.focused) {
                        const controlProps = getters.getProps(focusProps.focused);
                        if (controlProps) {
                            id = controlProps.id;
                        }
                    }
                    commit('SET_FOCUSED', id);
                break;
                case ('control'):
                    commit('SET_FOCUSED', id);
                break;
            }    
        } else {
            commit('CLEAR_FOCUSED');
        }
    },

    clearFocused ({ commit }) { commit('CLEAR_FOCUSED') },

    updateMousePosition({ commit }, position) { commit('SET_MOUSE_POSITION', position ) },

    setNextFormZIndex( { commit }, index) { commit('SET_NEXT_FORM_ZINDEX', index) },

    updateHotKey({ getters, dispatch }, payload: { index: number, hotKey: HotKey }) { 
        // update parent
        const parentId = payload.hotKey.form ? payload.hotKey.form : payload.hotKey.app; // if the form id is null it means we have gui app controls (e.g. menu, toolbar, etc)
        const parentProps: GuisProps | FormsProps = getters.getProps(parentId);
        
        let parentHotKeys: Array<HotKey> = parentProps.hotKeys.map(hotKey => ({ ...hotKey }));
        parentHotKeys.splice(payload.index, 1, payload.hotKey);
        
        dispatch('updateProperty', { id: parentId, property: 'hotKeys', value: parentHotKeys });

        // update control
        const props: ControlsProps = getters.getProps(payload.hotKey.control);
        const hotKeyIndex: number = props.hotKeys.findIndex(hotKey => hotKey.key === payload.hotKey.key); // this always going to be 0 because the only controls using this store action are Label and Frame (both of which only have one hot key)

        if (hotKeyIndex !== -1) {
            let hotKeys: Array<HotKeyBase> = props.hotKeys.map(hotKey => ({ ...hotKey }));
        
            hotKeys[hotKeyIndex].trigger = payload.hotKey.trigger; // update just the trigger property

            dispatch('updateProperty', { id: payload.hotKey.control, property: 'hotKeys', value: hotKeys });
        }
    },

    setMenuItemId( { commit }, id) { commit('SET_MENU_ITEM_ID', id) }
}

export const guiGuis: Module<GuisState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}
