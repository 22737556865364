








































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
// Types
import { GUICommand } from './types'
import Profile from '../../../types'
import { GUIComponent, GUIObjectType } from './types/enums'
// Components
import Sidebar from './components/layout/Sidebar.vue'
import Notifications from './components/layout/Notifications.vue'
import DialogBox from './components/layout/dialog/Dialog.vue'
// Demo form commands
import demoRequest from './demo/request'

@Component({    
  components: {
    Notifications, Sidebar, DialogBox
  }
})

export default class App extends Vue {
  /** ******************************** Vue Props **********************************/

  @Prop({ default: () => ([]) }) public request!: Array<GUICommand>

  @Prop({ default: () => ({}) }) public profile!: Profile

  public start = performance.now()
  public waiting: boolean = false;
  
  private idleTimerID = 0 // timeout for implicit GUI suspend

  /** ******************************** Vue Computed **********************************/

  get performance () { return this.$store.getters['guiGeneral/performanceCheck'] }
  
  get loading () { return this.$store.getters['guiGuis/getLoading'] || this.waiting }
  get hasRequests() { return this.$store.getters["guiRequest/getRequestLength"] }
  get guisComponents (): Array<GUIComponent> {
    const root = this.$store.getters['guiGuis/getGuisRoot'];
    return root.children; 
  }
  get shutdown () { return this.$store.getters['guiGuis/getShutdown'] }
  get suspended () { return this.$store.getters['guiGuis/getSuspended'] }
  get response () { return this.$store.getters['guiResponse/getResponse'] }
  get respond () { return this.$store.getters['guiResponse/getRespond'] }

  get shown() {
    return this.guisComponents.some(gui => {
          if (gui.props.type === GUIObjectType.gxMDI) {
            return gui.props.gpVisible;
          } else {
            if (gui.props.gpVisible) {
              return gui.children.some(form => form.props.gpVisible);
            } else {
              return false;
            }
          }
      });
  }

  get hasNotifications () { return this.$store.getters['guiNotifications/getHasNotifications'] }

/** ******************************** Vue Methods **********************************/

  // When the idle timeout expires, we have no visible GUI forms, and the server has
  // not sent a GUI request for a while. So we assume GUI is suspended and revert back
  // to the terminal.
  handleIdleTimeout() {
    this.idleTimerID = 0;
    this.$emit('toTerminal');
  }
  
/** ******************************** Vue Life Cycle **********************************/

  created() {
    this.$store.dispatch('guiGuis/createRoot')
    this.$store.dispatch('guiGeneral/setProfile', this.profile)
    // process the initial request
    this.$store.dispatch('guiRequest/request', this.request)
  }

/** ******************************** Vue Watch and Emit Events! **********************************/

    @Watch('request') passRequest () {   
      // To test performance
      if (this.performance) {
        let seconds = ((performance.now() - this.start) / 1000).toFixed(7)
        console.log('Request in '+ seconds +' seconds:', this.request) 
        this.start = performance.now();
      }
      // reset the idle timer
      if (this.idleTimerID) {
        clearTimeout(this.idleTimerID);
        this.idleTimerID = 0;
      }
      // process any secondary requests
      this.$store.dispatch('guiRequest/request', this.request)
    }

    @Watch('respond') passResponse () { 
      
      if (this.respond) {
        // To test performance
        if (this.performance) {
          let seconds = ((performance.now() - this.start) / 1000).toFixed(7)
          console.log('Response in '+  seconds +' seconds: ', this.response)
          this.start = performance.now(); 
        }

        this.$emit('passResponse', this.response)
        // once we pass the response back we clear the request and response store
        this.$store.dispatch('guiRequest/clear')
        this.$store.dispatch('guiResponse/clear')

        // set the idle timer if no GUI forms are visible
        if (this.idleTimerID) {
          clearTimeout(this.idleTimerID);
          this.idleTimerID = 0;
        }
        if (!this.shown) {
          this.idleTimerID = setTimeout(this.handleIdleTimeout, 200);
        }

      }
    }

    @Watch('shutdown') backToTerminal () { 

      if (this.shutdown) {
        this.$emit('toTerminal')
        this.$store.dispatch('guiGuis/toggleShutdown', false)
      }  
    }

    @Watch('suspended') suspendToTerminal () { 
      if (this.suspended) {
        this.$emit('toTerminal')
      }  
    }

    @Watch('hasRequests') watchRequest (curr: any, prev: any) { 
      // console.log(curr, prev);
      // This works well but breaks focus
      // if(prev === 1) {
      //   this.waiting = true;
      // } else {
      //   this.waiting = false;
      // }
    }
}
