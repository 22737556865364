








import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'HomeComponent'
})

export default class Home extends Vue {
    // This used to have functions to supply private label
    // logo URL, but there is really no point in just
    // displaying a big logo image when clicking the logo
    // button. We can make the "Home URL" redirect to the
    // customer's web site instead, in the router.
}
