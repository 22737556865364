



































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { BDropdown } from 'bootstrap-vue';

// Types
import { MenuItem } from './../../../types/components'
// Components
import CharacterUnderline from './../../layout/CharacterUnderline.vue';
import Icon from './../../layout/Icon.vue';

@Component({
  name: 'MenuDropDownComponent',
  components: { CharacterUnderline, Icon }
})

export default class MenuDropDown extends Vue {
  /** ******************************** Vue Props **********************************/

  @Prop() public item!: MenuItem;

  @Prop() public showMenu!: string; // menu id to show or empty string

  @Prop() public leftClick: any;
  @Prop() public toggle: any;
  @Prop() public hide: any;
  @Prop() public menuid: any;

  /** ******************************** Vue Data **********************************/

  public show: boolean = false;

  /** ******************************** Vue Computed **********************************/

  get children(): Array<MenuItem> { return this.item.children; }

  get id(): string { return this.menuid +  "-" +this.item.id; }

  /** ******************************** Vue Methods **********************************/

  clickHandler(e: MouseEvent, id: string, level: number) {   
    this.leftClick(e, id, level);
  }

  toggleHandler(e: PointerEvent) {
    this.show = !this.show;   
    this.toggle(e, this.id, this.item.level, this.show);
  }

  hideHandler() {
    this.hide(this.item.level)
  }

/** ******************************** Vue Watch and Emit Events! **********************************/

  @Watch('showMenu') passRequest () { 
    
    if (this.showMenu !== '' && this.showMenu === this.id && !this.show) {
      (this.$refs[this.id] as BDropdown).show();
    }
  }

}
