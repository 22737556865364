// Store
import store from '../../store'
// Types
import { IGUICmdMacro, IGUIRspError, IGUIRspMacro } from '../../types'
import { GUIErrorCode } from '../../types/enums'
import { GuisServiceMacroType } from '../../types/services/guis'

export default class GuisServiceMacro implements GuisServiceMacroType {

    macro(command: IGUICmdMacro) {

        const response: IGUIRspMacro = { command: command.command, error: GUIErrorCode.grSuccess, result: 1 }

        store.dispatch('guiResponse/setResponse', response)
    }

}
