// Store
import store from '../../store'
// Types
import { IGUICmdCtx, IGUIRspError, IGUIRspSuccess } from '../../types'
import { GUIErrorCode } from '../../types/enums'
import { GuisServiceContextType } from '../../types/services/guis'

export default class GuisServiceContext implements GuisServiceContextType {

    setContext(command: IGUICmdCtx) {

        const response: IGUIRspSuccess = { command: command.command, error: GUIErrorCode.grSuccess }

        const newVersion = (command.newVersion !== -1) ? command.newVersion : null;
        const newScale = (command.newVersion !== -1) ? +command.newScale : null;

        store.dispatch('guiGuis/setVersion', newVersion)
        store.dispatch('guiGuis/setScale', newScale)

        store.dispatch('guiResponse/setResponse', response)

    }


}
