import * as Msal from '@azure/msal-browser'
import IAuthService from '@/types/services/b2c-auth'

/**
 * Service for user authentication against an Azure B2C tenant
 */
export default class B2CAuthService implements IAuthService {

    private app: Msal.PublicClientApplication
    private authorityDomain = new URL(process.env.VUE_APP_B2C_AUTHORITY_URL).origin
    private msalConfig = {
        auth: {
            clientId: process.env.VUE_APP_B2C_CLIENT_ID,
            authority: process.env.VUE_APP_B2C_AUTHORITY_URL,
            knownAuthorities: [this.authorityDomain],
            redirectUri: process.env.VUE_APP_B2C_REDIRECT_URI, // after microsoft login
            scopes: [process.env.VUE_APP_B2C_SCOPE]
        },
        // share the session between multiple tabs
        cache: {
            cacheLocation: 'localStorage'
        },
        system: {
            allowRedirectInIframe: true
        }        
    }

    private loginRequest = { scopes: [process.env.VUE_APP_B2C_SCOPE] };
    private passwordReset = { authority: process.env.VUE_APP_B2C_RESET_PASSWORD_URL };

    /**
     * Creates a new instance of the service with the given B2C config.
     */
    constructor() {
        this.app = new Msal.PublicClientApplication(this.msalConfig);
    }

    async login() {
        let accountInfo: Msal.AccountInfo | null = null;
        //console.log('B2C login awaiting redirect promise');
        try {
            await this.app.handleRedirectPromise();
            const accounts = this.app.getAllAccounts();
            if (accounts.length > 0) {
                this.app.setActiveAccount(accounts[0]);
                //console.log('B2C account ' + accounts[0].homeAccountId);
            } else {
                //console.log('B2C no accounts, awaiting loginRedirect');
                await this.app.loginRedirect(this.loginRequest);
                //console.log('B2C awaited loginRedirect');
            }
        } catch (error: any) {
            if (error.errorMessage) {
                //console.log('B2C login error', error);
                if (error.errorMessage.indexOf('AADB2C90118') > -1) {
                    //console.log('B2C password reset detected, awaiting loginRedirect');
                    const response = await this.app.loginRedirect(this.passwordReset as Msal.RedirectRequest);
                    //console.log('B2C awaited password reset', response);
                    this.logout(null);
                }
            } else {
                console.log('B2C login error (no errorMessage property)', error);
            }
        }
    }

    logout(user: Msal.AccountInfo | null) {
        if (user) {
            this.app.logoutRedirect({ account: user })
        }
    }

    getUser(): Msal.AccountInfo | null {
        return this.app.getActiveAccount();
    }

    async getToken(): Promise<Msal.AuthenticationResult | void> {
        const user = this.app.getActiveAccount();
        if (user) {
            const silentRequest = { account: user, scopes: this.loginRequest.scopes };
            return await this.app.acquireTokenSilent(silentRequest).catch(async () => {
                return this.app.acquireTokenRedirect(silentRequest);
            });
        }
    }

}
