




































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// Types
import { IGUIEvent } from './../../../types'
import { DrpDwnCboProps, GUIEventCode } from './../../../types/enums'
import { UtilsType } from './../../../types/utils'
import { BFormInput, BTable } from 'bootstrap-vue';
// Utilities 
import Utils from './../../../utils/index';
import GridLabelIcon from './labelIcon.vue'
import Icon from './../../layout/Icon.vue';

@Component({
  name: 'gxDrpDwnCboComponent',
  components: { Icon, GridLabelIcon }
})

export default class gxDrpDwnCbo extends Vue {
  /** ******************************** Vue Props **********************************/

  @Prop({ default: () => (new DrpDwnCboProps()) }) public props!: DrpDwnCboProps;

  @Prop() public fields: any;
  @Prop() public items: any;
  @Prop() public hasIcons: any;
  @Prop() public iconStyle: any;
  @Prop() public tableStyle: any;
  @Prop() public buttonStyle: any;

  /** ******************************** Vue Data **********************************/
  
  public utils: UtilsType = new Utils();
  public showTable: boolean = false
  public activated: boolean = false
  public fieldtype = 'GridLabelIcon'

  public dropDownId: string = this.props.id +"-drop-down";   
  public listGridId: string = this.props.id +"-list-grid";

  public inputID: string = this.props.id +'-input'

  /** ******************************** Vue Computed **********************************/

  get focused() { return this.$store.getters['guiGuis/getFocusedControl']; }

  get inputStyle (): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = []
    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight);
    const font = this.utils.controlFont(this.props)
    let height: Partial<CSSStyleDeclaration> = { 
      height: size.height,
      padding: '0px'
    }
    style.push(font, height);
    return style;
  }

  colStyle(index: number): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = [];
    if(this.props.gpColWidth[index]) {
      style.push({width: this.props.gpColWidth[0] + "px"})
    } else {
      style.push({width: "20px"})
    }
    return style;
  }

  get style (): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = []
    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight);
    const font = this.utils.controlFont(this.props)
    //const positioning = this.utils.controlPositionCSS(this.props.gpTop, this.props.gpLeft)
    style.push(size, font/*, positioning*/);
    return style;
  }

  get enabled() { return this.props.gpEnabled }

  get visible() { return this.props.gpVisible }

  get value () { return this.props.gpValue; }

  get maxlength (): string { return (this.props.gpMaxLen !== 0) ? this.props.gpMaxLen.toString() : '' }

  set value (val) { 
    // Check if the change event is included in the prop events
    if (this.props.gpEventMask & GUIEventCode.geChange) {
      // send this event to the server
      const guiEvent: IGUIEvent = { event: GUIEventCode.geChange, id: this.props.id, args: [val] }
      this.$store.dispatch('guiGuis/addEvent', guiEvent);
    }
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'changed', value: true })
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpChanged', value: true })
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpValue', value: val }) 
  }

  get showHeaders () {
    const found = this.props.columnInfo.find(element => element.heading !== '' || element.heading === null);
    let result = '';
    if (found === undefined) {
        result = 'd-none';
    } 
    return result;
  }

  get HorizontalVerticalLines (): boolean { return !!(this.props.gpGridLines & 1) }

  get NoVerticalHorizontalLines (): boolean { return (this.props.gpGridLines === 0) }

  get required (): boolean { return this.props.gpRequired }

  get selectionStart(): number { return +this.props.gpSelStart }
  set selectionStart(selection: number) { 
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpSelStart', value: selection }) 
  }

  get selectionLength(): number { return +this.props.gpSelLength }
  set selectionLength(selection: number) {
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpSelLength', value: selection }) 
  }

  /** ******************************** Vue Methods **********************************/  
  handleBlur(e: FocusEvent) {
    let target: HTMLElement | null = e.relatedTarget as HTMLElement;
    let found = false;
    while(target) {
      if(target.id === this.props.id) {
        target = null;
        found = true;
      } else {
        target = target.parentElement
      }
    }
    if(!found) {
      this.showTable = false;
    }
  }
  
  handleButtonClick() {
    this.showTable = !this.showTable;
    (this.$refs[this.inputID] as HTMLInputElement).focus();
  }

  handleKeys(e: KeyboardEvent) {
    switch(e.key) {
      case "ArrowDown":
          this.handleArrow()
          break;
      case "ArrowUp":
        break;
      case "Enter":
          this.showTable = !this.showTable;
          if(!this.showTable) {
            (this.$refs[this.inputID] as HTMLInputElement).focus()
          }
        break;
    }
  }

  handleArrow() {
      if(!this.showTable) {
        this.showTable = true;
      }
      let elem: BTable = (this.$refs[this.listGridId] as BTable);
      let elemChild: HTMLElement = elem.$el.children[1] as HTMLElement;

      (elemChild.children[0] as HTMLTableElement).focus();

  }

  clickHandler (data: any) {
    let dataColumnNo: number = this.props.gpDataCol - 1; // DataCol is one-based, item is zero-based
    if (dataColumnNo < 0) dataColumnNo = 0;
    let newValue = (data.item[dataColumnNo] && data.item[dataColumnNo].value) || '';

    this.value = newValue
    let args = [newValue];
    this.showTable = !this.showTable;
    
    // Check if the change event is included in the prop events
    if (this.props.gpEventMask & GUIEventCode.geClick) {
      // send this event to the server
      const guiEvent: IGUIEvent = { event: GUIEventCode.geClick, id: this.props.id, args: args }
      this.$store.dispatch('guiGuis/addEvent', guiEvent);
    }
  }

  headClickHandler (value: any) {
      let args = [value + 1];
      if (this.props.gpEventMask & GUIEventCode.geColClick) {
      // send this event to the server
      const columnHeadlClickEvent: IGUIEvent = { event: GUIEventCode.geColClick, id: this.props.id, args: args  }
      this.$store.dispatch('guiGuis/addEvent', columnHeadlClickEvent);
    }
  }

  rightClickHandler (e: MouseEvent) {
    if (this.props.gpEventMask & GUIEventCode.geContext) {
      // send this event to the server
      const guiEvent: IGUIEvent = { event: GUIEventCode.geContext, id: this.props.id }
      this.$store.dispatch('guiGuis/addEvent', guiEvent);
    }

    this.$store.dispatch('guiGuis/updateMousePosition', this.utils.getMousePositionRelativeToParent(e, this.props.form!.id))
  }

  focus() {
    if (this.focused === this.props.id && this.enabled && this.visible) {
      try {
        const elem =this.$refs[this.inputID];
        (elem as BFormInput).focus();
console.log('Combo Focus Me '+ this.props.id);
      } catch(e) {
console.log('Combo Focus Me error: ' + e);
      }
    }
  }

  onEnter () {
    if ( this.showTable === true) {
        this.showTable = false;
    } 
  }

  selectionHandler (e: any) {
    e.stopPropagation()

    const ct = e.currentTarget

    this.selectionStart = ct.selectionStart
    this.selectionLength = ct.selectionEnd - ct.selectionStart
    (this.$refs[this.inputID] as HTMLInputElement).focus()
  }

  rowClickHandler (data: any, e: KeyboardEvent | MouseEvent) {
    let dataColumnNo: number = this.props.gpDataCol - 1; // DataCol is one-based, item is zero-based
    if (dataColumnNo < 0) dataColumnNo = 0;
    let newValue = (data[dataColumnNo] && data[dataColumnNo].value) || '';

    this.value = newValue
    let args = [newValue];
    this.showTable = false;
    
    // Check if the change event is included in the prop events
    if (this.props.gpEventMask & GUIEventCode.geClick) {
      // send this event to the server
      const guiEvent: IGUIEvent = { event: GUIEventCode.geClick, id: this.props.id, args: args }
      this.$store.dispatch('guiGuis/addEvent', guiEvent);
    }
    (this.$refs[this.inputID] as HTMLInputElement).focus()
  }
  
/** ******************************** Vue Life Cycle **********************************/

  mounted() {
    this.$nextTick(() => { this.focus(); });
  }

/** ******************************** Vue Watch and Emit Events! **********************************/

  @Watch('focused') passRequest () { 
    this.focus();
  }

  @Watch('value') selectRow () { 
    // const index = this.items.findIndex((item: any) => item[this.props.gpDataCol - 1].value.toLowerCase().includes(this.value.toLowerCase()))
    // if(index >= 0) {
    //   const table = (this.$refs[this.listGridId] as BTable);
    //   if(table) {
    //     table.selectRow(0);
    //   }
    // }
  }
}
