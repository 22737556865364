// Store
import store from '../../store'
// Types
import { IGUICmdGetProp, IGUIRspGetProp, IGUIRspError, simpleNotification } from '../../types'
import { GUIObjectType, GUIComponent, GUICSpecialObjectID, GUIProperty, GUIScaleMode, GUIErrorCode, GUIErrorLevel, GUIRspErrorType, AllProps, gxProps } from '../../types/enums'
import { GuisServiceGetPropType } from '../../types/services/guis'
import { ListValue, TableValue, PropsValue, OptionProps, OptionItemProps, ListColumnProps, GridProps, GridColumnProps, GridRowProps, TreeItemProps, MenuItemProps } from '../../types/enums'
// Utilities 
import Utils from '../../utils';

export default class GuisServiceGetProp implements GuisServiceGetPropType {

    public utils = new Utils();

    getProp(command: IGUICmdGetProp) {
      let response: IGUIRspGetProp | IGUIRspError;

      try {

        if (GUICSpecialObjectID.includes(command.id)) {

          response = { command: command.command, error: GUIErrorCode.grSuccess, property: command.property, value: this.getSpecialProp(command) }

        } else {

          let props = store.getters['guiGuis/getProps'](command.id || '*')

          if (props) {  
            response = { command: command.command, error: GUIErrorCode.grSuccess, property: command.property, value: this.getPropValue(command, props) }
          } else {
              // error repsonse
              response = new GUIRspErrorType(command.command, GUIErrorCode.grNoExist, GUIErrorLevel.glvlFail, 'An object of the specified ID does not exist', [command.id, command.property]);
          }
        }

      } catch (e) {
        if (e instanceof GUIRspErrorType) {
          response = e
        } else if (e instanceof RangeError) {
          response = new GUIRspErrorType(command.command, GUIErrorCode.grInvArg, GUIErrorLevel.glvlFail, e.message, [command.id, command.property]);
        } else {
          response = new GUIRspErrorType(command.command, GUIErrorCode.grFailure, GUIErrorLevel.glvlFail, (e && (e as any).message ? (e as any).message : 'An unknown error has occurred'), [command.id, command.property]);
        }
      }

      if (response.error !== GUIErrorCode.grSuccess) {
        // Notify
        const notification: simpleNotification = { type: 'warning', show: false, message: response.message || 'GUI error', friendlyMessage: 'Error setting property ' + command.property + ' on object ' + command.id, script: 'services/guis/getProp.ts', error: { errorCode: response.error, errorLevel: response.level } }
        store.dispatch('guiNotifications/addNotification', notification)
      }
      
      store.dispatch('guiResponse/setResponse', response)

    }

    getSpecialProp(command: IGUICmdGetProp) {

      switch(command.id) {
        case '&SCREEN':    
          //  &SCREEN = screen object
          //      Screen object properties: row argument specifies scale mode.
          //          gpWidth - returns the screen width in specified scale.
          //          gpHeight - returns the screen height in specified scale.
          //          Note: desktop version returns full screen size if col
          //          argument is 0, or useable screen size if col is 1.
          return ''; // TODO: implement screen properties
          break;
        case '&PRINTER':
          //  &PRINTER = printer object
          //      Printer object properties: see gpPrinter... properties.
          //      Also gpWidth & gpHeight.      
          // TODO: implement printer properties
          throw new GUIRspErrorType(command.command, GUIErrorCode.grInvProp, GUIErrorLevel.glvlFail, 'Invalid property code', [command.id, command.property])
          break;
      }
    }

    getStatus (command: IGUICmdGetProp, props: AllProps) : any {

      let value; // undefined
      switch (command.col) {
        case -1:
          // TODO: get status message displayed on terminal window while GUI waiting for server
          value = '';
        case 0:          
          // number of visible forms (and subsequently gui apps)
          let elems: Array<GUIComponent>;
          if (props.typeFamily === 'root') {
            elems = store.getters['guiGuis/getComponent']('*')!.children; // get root children
          } else if (props.typeFamily === 'app') {
            elems = [store.getters['guiGuis/getComponent'](props.id)!];
          } else {
            throw new RangeError('column or row argument is not valid');
          }
          let visibleGuis = 0;
          let visibleForms = 0;
          if (props.gpVisible) {
            elems.forEach(gui => {
              if (gui.props.gpVisible) {
                // TODO: when MDI parent form is implemented, count it as a visible form
                //if (gui.props.type === GUIObjectType.gxMDI) visibleGuis++; 
                gui.children.forEach(frm => {
                  if (frm.props.typeFamily === 'form' && frm.props.gpVisible) visibleForms++;
                });
              };                
            });
          }                                    
          value = visibleGuis + visibleForms;
          break;
        case 1:
          value = store.getters['guiGuis/getFocusedControl'];
          break;
        case 2:
          // list of child objects
          value = [];
          const comp: GUIComponent = store.getters['guiGuis/getComponent'](props.id)!;
          if (comp) {
            comp.children.forEach(item => {
              switch(command.row) {
                case 0:
                  value.push(item.props.id);
                  break;
                case 1:
                  if (item.props.typeFamily === 'form' || item.props.type === GUIObjectType.gxMDI) {
                    value.push(item.props.id);                        
                  }
                  break;
                case 2:
                  if (item.props.type === GUIObjectType.gxSDI) {
                    value.push(item.props.id);                        
                  }
                break;
              }
            });
          }
          break;
      }

      return value;

    }

  getPropValue(command: IGUICmdGetProp, props: AllProps) : any {
    let propType: string;
    let value; // undefined
        
    const gpScale = this.utils.getScale(props);

        switch (command.property) {
  
          case GUIProperty.gpDefVal:
            propType = 'gpDefVal'
            if ('gpDefVal' in props) { value = props.gpDefVal }
          break;
          case GUIProperty.gpValue:
            propType = 'gpValue'
            if ('gpValue' in props) {
              if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
                value = this.GetTableValue(props.gpValue, command.col, command.row);
              } else {
                value = props.gpValue;
              }
            }
          break;
          case GUIProperty.gpAlign:
            propType = 'gpAlign'
            if ('gpAlign' in props) {
              if (props.type === GUIObjectType.gxStatusbar) {
                if (command.col === 0 && command.row === 0) {
                  value = this.GetItemProp(props.gpItems as ListValue<MenuItemProps>, command.item || '', 'id', 'panelAlign');
                } else {
                  throw new RangeError('column or row argument is not valid')
                }
              } else {
                value = props.gpAlign;
              }
            }
          break;
          case GUIProperty.gpAltColor:
            propType = 'gpAltColor'
            if ('gpAltColor' in props) { value = props.gpAltColor }
          break;
          case GUIProperty.gpArrange:
            propType = 'gpArrange'
            if ('gpArrange' in props) { value = props.gpArrange }
          break;
          case GUIProperty.gpAuthor:
            propType = 'gpAuthor'
            if ('gpAuthor' in props) { value = props.gpAuthor }
          break;
          case GUIProperty.gpAutoSelect:
            propType = 'gpAutoSelect'
            if ('gpAutoSelect' in props) { value = props.gpAutoSelect }
          break;
          case GUIProperty.gpBackColor:
            propType = 'gpBackColor'
            if ('gpBackColor' in props) {
              if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
                let gridRow: GridRowProps;
                value = props.gpBackColor;
                if (command.col !== 0 && command.row !== 0) {
                  // cell
                  if (command.col <= props.gpColumns && command.row <= props.rowInfo.length) {
                    gridRow = props.rowInfo[command.row - 1];
                    value = (gridRow && command.col <= gridRow.cellBackColor.length && gridRow.cellBackColor[command.row - 1]) || value;
                  }
                } else if (command.col !== 0 && command.row === 0) {
                  // column
                  if (command.col <= props.columnInfo.length) {
                    value = props.columnInfo[command.col - 1].backColor || value;
                  }
                } else if (command.col !== 0 && command.row === 0) {
                  // row
                  if (command.row <= props.rowInfo.length) {
                    gridRow = props.rowInfo[command.row - 1];
                    value = (gridRow && gridRow.rowBackColor) || value;
                  }
                }
              } else {
                value = props.gpBackColor;
              }
            }
          break;
          case GUIProperty.gpBorder:
            propType = 'gpBorder'
            if ('gpBorder' in props) { value = props.gpBorder }
          break;
          case GUIProperty.gpCaption:
            propType = 'gpCaption'
            if ('gpCaption' in props) {
              // caption may be string or Array<string>
              if (props.type === GUIObjectType.gxOption) {
                if (command.col === 0 && command.row === 0) {
                  // control
                  value = props.gpCaption;
                } else if (command.col === 0 && command.row >= 1 && command.row <= props.gpItems.length) {
                  // specifc option button
                  value = (props as OptionProps).gpItems[command.row - 1].caption;
                } else {
                  throw new RangeError('column or row argument is not valid')
                }
              } else if (props.type === GUIObjectType.gxTree) {
                if (command.col === 0 && command.row === 0) {
                  value = this.GetItemProp(props.gpItems as ListValue<TreeItemProps>, command.item || '', 'path', 'caption');
                } else {
                  throw new RangeError('column or row argument is not valid')
                }
              } else if (props.type === GUIObjectType.gxMenu || props.type === GUIObjectType.gxPopup || props.type === GUIObjectType.gxToolbar || props.type === GUIObjectType.gxStatusbar) {
                if (command.col === 0 && command.row === 0) {
                  value = this.GetItemProp(props.gpItems as ListValue<MenuItemProps>, command.item || '', 'id', 'caption');
                } else {
                  throw new RangeError('column or row argument is not valid')
                }    
              } else {
               value = props.gpCaption;
              }
            }
          break;
          case GUIProperty.gpChanged:
            propType = 'gpChanged'
            if ('gpChanged' in props) { 
              value = this.GetChanged(props);
            }
          break;
          case GUIProperty.gpColAlign:
            propType = 'gpColAlign'
            if ('gpColAlign' in props) {
              if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
                // Grid
                value = this.GetListValue(props.columnInfo.align, 0, command.col)
              } else if (props.type === GUIObjectType.gxList || props.type === GUIObjectType.gxListMultisel || props.type === GUIObjectType.gxCheckedList || props.type === GUIObjectType.gxDrpDwnCbo || props.type === GUIObjectType.gxDrpDwnList) {
                // List, Combo
                value = this.GetListValue(props.columnInfo.align, 0, command.col)
              }          
            }
          break;
          case GUIProperty.gpColDataType:
            propType = 'gpColDataType'
            if ('gpColDataType' in props) {
              if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
                // Grid
                value = this.GetListValue(props.columnInfo.dataType, 0, command.col)
              } else if (props.type === GUIObjectType.gxList || props.type === GUIObjectType.gxListMultisel || props.type === GUIObjectType.gxCheckedList || props.type === GUIObjectType.gxDrpDwnCbo || props.type === GUIObjectType.gxDrpDwnList) {
                value = this.GetListValue(props.columnInfo.dataType, 0, command.col)
              }
            }
          break;
          case GUIProperty.gpColFieldType:
            propType = 'gpColFieldType'
            if ('gpColFieldType' in props) {
              // Grids only
              if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
                value = this.GetListValue(props.columnInfo.fieldType, 0, command.col);
              }
            }
          break;
          case GUIProperty.gpColHeading:
            propType = 'gpColHeading'
            if ('gpColHeading' in props) {
              if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
                // Grid
                value = this.GetListValue(props.columnInfo.heading, '', command.col);
              } else if (props.type === GUIObjectType.gxList || props.type === GUIObjectType.gxListMultisel || props.type === GUIObjectType.gxCheckedList || props.type === GUIObjectType.gxDrpDwnCbo || props.type === GUIObjectType.gxDrpDwnList) {
                // List, Combo
                value = this.GetListValue(props.columnInfo.heading, '', command.col);
              }                  
            }
          break;
          case GUIProperty.gpColHint:
            propType = 'gpColHint'
            if ('gpColHint' in props) {
              if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
                // Grid only
                value = this.GetListValue(props.columnInfo.tip, '', command.col);
              }    
            }
          break;
          case GUIProperty.gpColItems:
            propType = 'gpColItems'
            if ('gpColItems' in props) {
              if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
                // Grids only
                // [ [ dropdown_row1_col1 | dropdown_row1_col2 | ... , dropdown_row2_col1 | dropdown_row2_col2 | ... ] ... ]
                value = this.GetListValue(props.columnInfo.items, [], command.col);
              }                  
            }
          break;
          case GUIProperty.gpColSizable:
            propType = 'gpColSizable'
            if ('gpColSizable' in props) {
              if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
                // Grid only
                value = this.GetListValue(props.columnInfo.sizable, false, command.col);
              }
            }
          break;
          case GUIProperty.gpColTabStop:
            propType = 'gpColTabStop'
            if ('gpColTabStop' in props) {
              if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
                // Grid only
                value = this.GetListValue(props.columnInfo.tabStop, true, command.col);
              }
            }
          break;
          case GUIProperty.gpColumn:
            propType = 'gpColumn'
            if ('gpColumn' in props) {
              if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
                // Grids only
                value = props.gpColumn;
              }
            }
          break;
          case GUIProperty.gpColumns:
            propType = 'gpColumns'
            if ('gpColumns' in props) { value = props.gpColumns } // List, Combo, Grids, Option
          break;
          case GUIProperty.gpColWidth:
            propType = 'gpColWidth'
            if ('gpColWidth' in props) {
              if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
                // Grid
                value = this.GetListValue(props.columnInfo.width, 120, command.col);
              } else if (props.type === GUIObjectType.gxList || props.type === GUIObjectType.gxListMultisel || props.type === GUIObjectType.gxCheckedList || props.type === GUIObjectType.gxDrpDwnCbo || props.type === GUIObjectType.gxDrpDwnList) {
                // List, Combo
                value = this.GetListValue(props.columnInfo.width, 120, command.col);
              }
              if (Array.isArray(value)) {
                value = value.map(v1 => this.utils.scaleConversion(v1, 'h', gpScale));
              } else if (value) {
                value = this.utils.scaleConversion(value, 'h', gpScale);
              }
            }
          break;
          case GUIProperty.gpContent:
            propType = 'gpContent'
            if ('gpContent' in props) { value = props.gpContent }
          break;
          case GUIProperty.gpCopyright:
            propType = 'gpCopyright'
            if ('gpCopyright' in props) { value = props.gpCopyright }
          break;
          case GUIProperty.gpCustom:
            propType = 'gpCustom'
            if ('gpCustom' in props) { value = props.gpCustom }
          break;
          case GUIProperty.gpDataCol:
            propType = 'gpDataCol'
            if ('gpDataCol' in props) {
              if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
                // Grids
                value = this.GetListValue(props.columnInfo.dataCol, 0, command.col);
              } else if (props.type === GUIObjectType.gxDrpDwnList || props.type === GUIObjectType.gxDrpDwnCbo) {
                // Combo
                value = props.gpDataCol;
              }           
            }
            break;
          case GUIProperty.gpDataType:
            propType = 'gpDataType'
            if ('gpDataType' in props) { value = props.gpDataType }
          break;
          case GUIProperty.gpDescription:
            propType = 'gpDescription'
            if ('gpDescription' in props) { value = props.gpDescription }
          break;
          case GUIProperty.gpDragID:
            propType = 'gpDragID'
            if ('gpDragID' in props) { value = props.gpDragID }
          break;
          case GUIProperty.gpDragMode:
            propType = 'gpDragMode'
            if ('gpDragMode' in props) { value = props.gpDragMode }
          break;
          case GUIProperty.gpDropIDs:
            propType = 'gpDropIDs'
            if ('gpDropIDs' in props) { value = props.gpDropIDs }
          break;
          case GUIProperty.gpEnabled:
            propType = 'gpEnabled'
            if ('gpEnabled' in props) {
              if (props.type === GUIObjectType.gxOption) {
                if (command.col === 0 && command.row === 0) {
                  // control
                  value = props.gpEnabled;
                } else if (command.col === 0 && command.row >= 1 && command.row <= props.gpItems.length) {
                  // specifc option button
                  value = (props as OptionProps).gpItems[command.row - 1].enabled;
                } else {
                  throw new RangeError('column or row argument is not valid')
                }
              } else if (props.type === GUIObjectType.gxMenu || props.type === GUIObjectType.gxPopup || props.type === GUIObjectType.gxToolbar || props.type === GUIObjectType.gxStatusbar) {
                if (command.col === 0 && command.row === 0) {
                  value = this.GetItemProp(props.gpItems as ListValue<MenuItemProps>, command.item || '', 'id', 'enabled');
                } else {
                  throw new RangeError('column or row argument is not valid')
                }    
              } else {
                value = props.gpEnabled;
              }
                  
            }
          break;
          case GUIProperty.gpEventMask:
            propType = 'gpEventMask'
            if ('gpEventMask' in props) { value = props.gpEventMask }
          break;
          case GUIProperty.gpExtension:
            propType = 'gpExtension'
            if ('gpExtension' in props) { value = props.gpExtension }
          break;        
          case GUIProperty.gpFocusStyle:
            propType = 'gpFocusStyle'
            if ('gpFocusStyle' in props) { value = props.gpFocusStyle }
          break;
          case GUIProperty.gpFixedCols:
            propType = 'gpFixedCols'
            if ('gpFixedCols' in props) { value = props.gpFixedCols }
          break;
          case GUIProperty.gpFontBold:
            propType = 'gpFontBold'
            if ('gpFontBold' in props) { value = props.gpFontBold }
          break;
          case GUIProperty.gpFontItalic:
            propType = 'gpFontItalic'
            if ('gpFontItalic' in props) { value = props.gpFontItalic }
          break;
          case GUIProperty.gpFontName:
            propType = 'gpFontName'
            if ('gpFontName' in props) { value = props.gpFontName }
          break;
          case GUIProperty.gpFontSize:
            propType = 'gpFontSize'
            if ('gpFontSize' in props) { value = props.gpFontSize }
          break;
          case GUIProperty.gpFontUnderline:
            propType = 'gpFontUnderline'
            if ('gpFontUnderline' in props) { value = props.gpFontUnderline }
          break;
          case GUIProperty.gpForeColor:
            propType = 'gpForeColor'
            if ('gpForeColor' in props) {
              if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {          
                let gridRow: GridRowProps;
                value = props.gpForeColor;
                if (command.col !== 0 && command.row !== 0) {
                  // cell
                  if (command.col <= props.gpColumns && command.row <= props.rowInfo.length) {
                    gridRow = props.rowInfo[command.row - 1];
                    value = (gridRow && command.col <= gridRow.cellForeColor.length && gridRow.cellForeColor[command.row - 1]) || value;
                  }
                } else if (command.col !== 0 && command.row === 0) {
                  // column
                  if (command.col <= props.columnInfo.length) {
                    value = props.columnInfo[command.col - 1].foreColor || value;
                  }
                } else if (command.col !== 0 && command.row === 0) {
                  // row
                  if (command.row <= props.rowInfo.length) {
                    gridRow = props.rowInfo[command.row - 1];
                    value = (gridRow && gridRow.rowForeColor) || value;
                  }
                }
              } else {
                value = props.gpForeColor;
              }
            }
          break;
          case GUIProperty.gpGridLines:
            propType = 'gpGridLines'
            if ('gpGridLines' in props) { value = props.gpGridLines }
          break;
          case GUIProperty.gpHeight:
            propType = 'gpHeight'
            if ('gpHeight' in props) {
              value = this.utils.scaleConversion(props.gpHeight, 'v', gpScale);
            }
          break;
          case GUIProperty.gpHelpFile:
            propType = 'gpHelpFile'
            if ('gpHelpFile' in props) { value = props.gpHelpFile }
          break;
          case GUIProperty.gpHelpID:
            propType = 'gpHelpID'
            if ('gpHelpID' in props) { value = props.gpHelpID }
          break;
          case GUIProperty.gpHelpType:
            propType = 'gpHelpType'
            if ('gpHelpType' in props) { value = props.gpHelpType }
          break;
          case GUIProperty.gpHint:
            propType = 'gpHint'
            if ('gpHint' in props) {
              if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
                let gridRow: GridRowProps;
                if (command.col === 0 && command.row === 0) {
                  // all
                  value = props.gpHint;
                } else if (command.row === 0 && command.col <= props.columnInfo.length) {
                  // column
                  value = props.columnInfo[command.col - 1].tip;
                } else if (command.col === 0 && command.row <= props.rowInfo.length) {
                  // row
                  gridRow = props.rowInfo[command.row - 1];
                  value = (gridRow && gridRow.rowTip) || '';
                } else if (command.col <= props.columnInfo.length && command.row <= props.rowInfo.length) {
                  // cell
                  gridRow = props.rowInfo[command.row - 1];
                    value = (gridRow && command.col <= gridRow.cellTip.length && gridRow.cellTip[command.col - 1]) || '';
                } else {
                  value = '';
                }
              } else if (props.type === GUIObjectType.gxOption) {
                if (command.col === 0 && command.row === 0) {
                  // control
                  value = props.gpHint;
                } else if (command.col === 0 && command.row >= 1 && command.row <= props.gpItems.length) {
                  // specifc option button
                  value = (props as OptionProps).gpItems[command.row - 1].tip || '';
                } else {
                  throw new RangeError('column or row argument is not valid')
                }
              } else if (props.type === GUIObjectType.gxTree) {
                if (command.col === 0 && command.row === 0) {
                  value = this.GetItemProp(props.gpItems as ListValue<TreeItemProps>, command.item || '', 'path', 'tip');
                } else {
                  throw new RangeError('column or row argument is not valid')
                }
              } else if (props.type === GUIObjectType.gxToolbar || props.type === GUIObjectType.gxStatusbar) {
                if (command.col === 0 && command.row === 0) {
                  value = this.GetItemProp(props.gpItems as ListValue<MenuItemProps>, command.item || '', 'id', 'tip');
                } else {
                  throw new RangeError('column or row argument is not valid')
                }
              } else {
                value = props.gpHint;
              }
            }
          break;
          case GUIProperty.gpIcon:
            propType = 'gpIcon'
            if ('gpIcon' in props) {
              if (props.type === GUIObjectType.gxList || props.type === GUIObjectType.gxListMultisel || props.type === GUIObjectType.gxCheckedList || props.type === GUIObjectType.gxDrpDwnCbo || props.type === GUIObjectType.gxDrpDwnList) {
                if (command.col === 0 || command.col === 1) {
                  value = this.GetListValue(props.gpIcon, '', command.row);
                } else {
                  throw new RangeError('column or row argument is not valid')
                }
              } else if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
                const tbl: TableValue<string> = [];
                let gridRow: GridRowProps;
                let ir: number, ic: number;
                let sr = 0;
                let er = props.rowInfo.length;
                let sc = 0;
                let ec = props.columnInfo.length;
                if (command.col !== 0) {
                  // column only
                  sc = command.col - 1;
                  ec = sc + 1;
                }
                if (command.row !== 0) {
                  // row only
                  sr = command.row - 1;
                  er = sr + 1;
                }
                for (ir = sr; ir < er; ir++) {
                  if (ir < props.rowInfo.length) {
                    gridRow = props.rowInfo[ir];
                    tbl[ir - sr] = [];
                    for (ic = sc; ic < ec; ic++) {
                      if (ic < props.columnInfo.length) {
                        tbl[ir - sr][ic - sc] = (gridRow && ic < gridRow.cellIcon.length && gridRow.cellIcon[ic]) || '';
                      }
                    }
                  }
                }
                value = tbl;
              } else if (props.type === GUIObjectType.gxTree) {
                if (command.col === 0 && command.row === 0) {
                  value = this.GetItemProp(props.gpItems as ListValue<TreeItemProps>, command.item || '', 'path', 'icon');
                } else {
                  throw new RangeError('column or row argument is not valid')
                }
              } else if (props.type === GUIObjectType.gxMenu || props.type === GUIObjectType.gxPopup || props.type === GUIObjectType.gxToolbar || props.type === GUIObjectType.gxStatusbar) {
                if (command.col === 0 && command.row === 0) {
                  value = this.GetItemProp(props.gpItems as ListValue<MenuItemProps>, command.item || '', 'id', 'icon');
                } else {
                  throw new RangeError('column or row argument is not valid')
                }    
              } else {
                value = props.gpIcon;
              }
            }
          break;
          case GUIProperty.gpIconAlign:
            propType = 'gpIconAlign'
            if ('gpIconAlign' in props) { value = props.gpIconAlign }
          break;
          case GUIProperty.gpIconSize:
            propType = 'gpIconSize'
            if ('gpIconSize' in props) { value = props.gpIconSize }
          break;
          case GUIProperty.gpItems:
            propType = 'gpItems'
            if ('gpItems' in props) {
              if (props.type === GUIObjectType.gxList || props.type === GUIObjectType.gxListMultisel || props.type === GUIObjectType.gxCheckedList || props.type === GUIObjectType.gxDrpDwnCbo || props.type === GUIObjectType.gxDrpDwnList) {
                value = this.GetTableValue(props.gpItems, command.col, command.row);
              } else if (props.type === GUIObjectType.gxOption) {
                // With just captions
                // ['option 1 caption', 'option 2 caption', ...]
                // With more features
                // [ ['option 1 caption', (1 (or null) if option is enabled or 0 if disabled), 'help hint'], ...]
                let itms: OptionItemProps[];
                if (command.row === 0 && command.col === 0) {
                  itms = props.gpItems;
                } else if (command.row <= props.gpItems.length && command.col === 0) {
                  itms = [props.gpItems[command.row - 1]];
                } else {
                  throw new RangeError('column or row argument is not valid')
                }
                value = itms.map(v1 => [
                  v1.caption,
                  v1.enabled,
                  v1.tip]);
              } else if (props.type === GUIObjectType.gxTree) {
                let itms: TreeItemProps[];
                if (command.row === 0 && command.col === 0) {
                  itms = props.gpItems;
                } else if (command.row <= props.gpItems.length && command.col === 0) {
                  itms = [props.gpItems[command.row - 1]];
                } else {
                  throw new RangeError('column or row argument is not valid')
                }
                value = itms.map(v1 => [
                  v1.id,
                  v1.level,
                  v1.caption,
                  v1.icon,
                  v1.state,
                  v1.tip]);
                } else if (props.type === GUIObjectType.gxMenu || props.type === GUIObjectType.gxPopup || props.type === GUIObjectType.gxToolbar || props.type === GUIObjectType.gxStatusbar) {
                let itms: MenuItemProps[];
                if (command.row === 0 && command.col === 0) {
                  itms = props.gpItems;
                } else if (command.row <= props.gpItems.length && command.col === 0) {
                  itms = [props.gpItems[command.row - 1]];
                } else {
                  throw new RangeError('column or row argument is not valid')
                }
                value = itms.map(v1 => [
                  v1.id,
                  v1.level,
                  v1.caption,
                  v1.icon,
                  v1.enabled,
                  v1.visible,
                  v1.shortcut,
                  v1.causesValidation,
                  v1.tip,
                  v1.panelWidth > 0 ? this.utils.scaleConversion(v1.panelWidth, 'h', gpScale) : v1.panelWidth,
                  v1.panelAlign]);
              } else {
                value = props.gpItems;
              }
            }
          break;
          case GUIProperty.gpLeft:
            propType = 'gpLeft'
            if ('gpLeft' in props) {
              if (props.gpLeft !== 'auto') {
                value = this.utils.scaleConversion(props.gpLeft as number, 'h', gpScale);
              } else {
                value = props.gpLeft;
              }
            }
          break;
          case GUIProperty.gpLogo:
            propType = 'gpLogo'
            if ('gpLogo' in props) { value = props.gpLogo }
          break;
          case GUIProperty.gpMaxDrop:
            propType = 'gpMaxDrop'
            if ('gpMaxDrop' in props) { value = props.gpMaxDrop }
          break;
          case GUIProperty.gpMaxLen:
            propType = 'gpMaxLen'
            if ('gpMaxLen' in props) {
              if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
                value = this.GetListValue(props.gpMaxLen, 0, command.col);
              } else {
                value = props.gpMaxLen;
              }
            }
          break;
          case GUIProperty.gpMaxLines:
            propType = 'gpMaxLines'
            if ('gpMaxLines' in props) { value = props.gpMaxLines }
          break;
          case GUIProperty.gpMsgText:
            propType = 'gpMsgText'
            if ('gpMsgText' in props) { value = props.gpMsgText }
          break;
          case GUIProperty.gpNoAutoTips:
            propType = 'gpNoAutoTips'
            if ('gpNoAutoTips' in props) { value = props.gpNoAutoTips }
          break;
          case GUIProperty.gpPasteMode:
            propType = 'gpPasteMode'
            if ('gpPasteMode' in props) { value = props.gpPasteMode }
          break;
          case GUIProperty.gpPicture:
            propType = 'gpPicture'
            if ('gpPicture' in props) { value = props.gpPicture }
          break;
          case GUIProperty.gpReadOnly:
            propType = 'gpReadOnly'
            if ('gpReadOnly' in props) {
              if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
                value = this.GetListValue(props.gpReadOnly, false, command.col);
              } else {
                value = props.gpReadOnly;
              }
            }
          break;
          case GUIProperty.gpRequired:
            propType = 'gpRequired'
            if ('gpRequired' in props) {
              if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
                value = this.GetListValue(props.gpRequired, false, command.col);
              } else {
                value = props.gpRequired;
              }
            }
          break;
          case GUIProperty.gpRow:
            propType = 'gpRow'
            if ('gpRow' in props) { value = props.gpRow }
          break;
          case GUIProperty.gpRows:
            propType = 'gpRows'
            if ('gpRows' in props) { value = props.gpRows } // Grids, Option
          break;
          case GUIProperty.gpRtnEqTab:
            propType = 'gpRtnEqTab'
            if ('gpRtnEqTab' in props) { value = props.gpRtnEqTab }
          break;
          case GUIProperty.gpScale:
            propType = 'gpScale'
            if ('gpScale' in props) { value = props.gpScale }
          break;
          case GUIProperty.gpSelLength:
            propType = 'gpSelLength'
            if ('gpSelLength' in props) { value = props.gpSelLength }
          break;
          case GUIProperty.gpSelRange:
            propType = 'gpSelRange'
            if ('gpSelRange' in props && 'gpSelStart' in props && 'gpSelLength' in props) {
              value = [props.gpSelStart + 1, props.gpSelLength];
            }
          break;
          case GUIProperty.gpSelStart:
            propType = 'gpSelStart'
            if ('gpSelStart' in props) {
              value = props.gpSelStart + 1;
            }
          break;
          case GUIProperty.gpState:
            propType = 'gpState'
            if ('gpState' in props) {             
              if (props.type === GUIObjectType.gxTree) {
                if (command.col === 0 && command.row === 0) {
                  value = this.GetItemProp(props.gpItems as ListValue<TreeItemProps>, command.item || '', 'path', 'state');
                } else {
                  throw RangeError('column or row argument is not valid')
                }            
              } else {
                value = props.gpState;
              }              
            }
          break;
          case GUIProperty.gpStatus:
            propType = 'gpStatus'
            if ('gpStatus' in props) {
              value = this.getStatus(command, props);
            }
          break;
          case GUIProperty.gpStyle:
            propType = 'gpStyle'
            if ('gpStyle' in props) { value = props.gpStyle }
          break;
          case GUIProperty.gpTabStop:
            propType = 'gpTabStop'
            if ('gpTabStop' in props) { value = props.gpTabStop }
          break;
          case GUIProperty.gpTimeout:
            propType = 'gpTimeout'
            if ('gpTimeout' in props) { value = props.gpTimeout }
          break;
          case GUIProperty.gpTop:
            propType = 'gpTop'
            if ('gpTop' in props) {
              if (props.gpTop !== 'auto') {
                value = this.utils.scaleConversion(props.gpTop as number, 'v', gpScale);
              } else {
                value = props.gpTop;
              }
            }
          break;
          case GUIProperty.gpTransparent:
            propType = 'gpTransparent'
            if ('gpTransparent' in props) { value = props.gpTransparent }
          break;
          case GUIProperty.gpVersion:
            propType = 'gpVersion'
            if ('gpVersion' in props) { value = props.gpVersion }
          break;
          case GUIProperty.gpVisible:
            propType = 'gpVisible'
            if ('gpVisible' in props) {
              if (props.type === GUIObjectType.gxMenu || props.type === GUIObjectType.gxPopup || props.type === GUIObjectType.gxToolbar || props.type === GUIObjectType.gxStatusbar) {
                if (command.col === 0 && command.row === 0) {
                  value = this.GetItemProp(props.gpItems as ListValue<MenuItemProps>, command.item || '', 'id', 'visible');
                } else {
                  throw new RangeError('column or row argument is not valid')
                }
              } else {
                value = props.gpVisible;
              }
            }
          break;
          case GUIProperty.gpWidth:
            propType = 'gpWidth'
            if ('gpWidth' in props) {
              if (props.type === GUIObjectType.gxStatusbar) {
                if (command.col === 0 && command.row === 0) {
                  value = this.utils.scaleConversion(this.GetItemProp(props.gpItems as ListValue<MenuItemProps>, command.item || '', 'id', 'panelWidth'), 'h', gpScale);
                } else {
                  throw new RangeError('column or row argument is not valid')
                }
              } else {
                value = this.utils.scaleConversion(props.gpWidth, 'h', gpScale);
              }
            }
          break;
          case GUIProperty.gpWindowState:
            propType = 'gpWindowState'
            if ('gpWindowState' in props) { value = props.gpWindowState }
          break;
          case GUIProperty.gpWordWrap:
            propType = 'gpWordWrap'
            if ('gpWordWrap' in props) { value = props.gpWordWrap }
          break;
        }
  
        if (value === undefined) {
            throw new GUIRspErrorType(command.command, GUIErrorCode.grInvProp, GUIErrorLevel.glvlFail, 'Invalid property code', [props.id, command.property])
        }

        return value;

    }

  // Get gpChanged for object, including any descendents
  GetChanged(props: AllProps): boolean {
    function childRecurs(children: Array<GUIComponent>): boolean {
      return children.some(v1 => {
        return v1.props.gpChanged || childRecurs(v1.children);
      });
    }
    let rc = props.gpChanged;
    if (!rc) {
      // recursively check if any descendents have gpChanged set
      const component = store.getters['guiGuis/getComponent'](props.id);
      if (component) {
        rc = childRecurs(component.children);
      }
    }
    return rc;
  }

  /*
   * Get an item property, searching array of items for matching key
   */
  GetItemProp<T, K extends keyof T>(props: ListValue<T>, key: string, keyname: K, propname: K) : any {
    const index = props.findIndex((item: T) => {
      return this.utils.compareIDs([key, item[keyname] as unknown as string])
    })
    if (index !== -1) {
      return props[index][propname];
    } else {
      throw RangeError('item not found')
    }
  }

  GetListValue<T>(value: ListValue<T>, defval: T, index: number) : any {
      if (index === 0) {
        return value;
      } else if (index <= value.length) {
        return value[index - 1];
      } else {
        return defval;
      }
  }

  GetTableValue<T>(value: ListValue<T>, col: number, row: number) : any {
    if (col === 0 && row === 0) {
      return value;
    } else if (col === 0) {
      return (row <= value.length) ? [value[row - 1]] : []
    } else if (row === 0) {
      return value.map( (v1: T | any) => {
        return [(col <= Object.keys(v1).length) ? v1[col - 1] : []]
      })
    } else {
      if (row <= value.length) {
        return [(col <= Object.keys(value[row - 1]).length) ? [(value[row - 1] as any)[col - 1]] : []];
      } else {
        return [];
      }
    }
  }

}
