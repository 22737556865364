// Store
import store from '../../store'
// Types
import { GUICommand, IGUICmdInit, IGUICmdCtx, IGUICmdCreate, IGUICmdDelete, IGUICmdSetProp, IGUICmdGetProp, IGUICmdGetUpds, IGUICmdMethod, IGUICmdWaitEvent, IGUICmdCheckEvent, IGUICmdMsgBox, IGUICmdInputBox, IGUICmdMacro, IGUICmdFileDialog, IGUICmdFontDialog, IGUICmdColorDialog, IGUICmdControl, IGUICmdShutdown } from '../../types'
import { GUICommandCode, GUIErrorCode } from '../../types/enums'
import { GuisServiceType } from '../../types/services/guis/'
// Services
import GuisServiceContext from './context'
import GuisServiceInitialize from './initialize'
import GuisServiceCreate from './create'
import GuisServiceDelete from './delete'
import GuisServiceSetProp from './setProp'
import GuisServiceGetProp from './getProp'
import GuisServiceMethod from './method'
import GuisServiceWaitEvent from './waitEvent'
import GuisServiceControl from './control'
import GuisServiceMacro from './macro'
import GuisServiceGetUpds from './getUpds'
import GuisServiceDialogBox from './dialog'
import GuisServiceShutdown from './shutdown'

export default class GuisService implements GuisServiceType {
  // Command Services
  public contextService = new GuisServiceContext();
  public initializeService = new GuisServiceInitialize();
  public createService = new GuisServiceCreate();
  public delteService = new GuisServiceDelete();
  public setPropService = new GuisServiceSetProp();
  public getPropService = new GuisServiceGetProp();  
  public methodService = new GuisServiceMethod();
  public waitEventService = new GuisServiceWaitEvent();
  public controlService = new GuisServiceControl();
  public dialogService = new GuisServiceDialogBox();
  public macroService = new GuisServiceMacro();
  public getUpdsService = new GuisServiceGetUpds();
  public shutdownService = new GuisServiceShutdown();

  commandHandler (command: GUICommand) {
      
      switch (command.command) {
        // Context command = 0
        case GUICommandCode.gcContext:
          this.gcContext(<IGUICmdCtx>command)
        break;
        // Initialize command = 1
        case GUICommandCode.gcInitialize:
          this.gcInitialize(<IGUICmdInit>command)
        break;   
        // Create command = 2
        case GUICommandCode.gcCreate:
          this.gcCreate(<IGUICmdCreate>command)
        break;
        // Delete command = 3
        case GUICommandCode.gcDelete:
          this.gcDelete(<IGUICmdDelete>command)
        break;
        // SetProp command = 4
        case GUICommandCode.gcSetProp:
          this.gcSetProp(<IGUICmdSetProp>command)
        break;
        // GetProp command = 5
        case GUICommandCode.gcGetProp:
          this.gcGetProp(<IGUICmdGetProp>command)
        break;
        // Method command = 6
        case GUICommandCode.gcMethod:
          this.gcMethod(<IGUICmdMethod>command)
        break;
        // WaitEvent command = 7
        case GUICommandCode.gcWaitEvent:
          this.gcWaitEvent(<IGUICmdWaitEvent>command)
        break;
        // Control command = 8
        case GUICommandCode.gcControl:
          this.gcControl(<IGUICmdControl>command)
        break;
        // MsgBox command = 9
        case GUICommandCode.gcMsgBox:
          this.gcMsgBox(<IGUICmdMsgBox>command)
        break;
        // InputBox command = 10
        case GUICommandCode.gcInputBox:
          this.gcInputBox(<IGUICmdInputBox>command)
        break;
        // Macro command = 11
        case GUICommandCode.gcMacro:
          this.gcMacro(<IGUICmdMacro>command)
        break;
        // GetUpds command = 12
        case GUICommandCode.gcGetUpds:
          this.gcGetUpds(<IGUICmdGetUpds>command)
        break;
        // CheckEvent command = 13
        case GUICommandCode.gcCheckEvent:
          this.gcWaitEvent(<IGUICmdCheckEvent>command)
        break;
        // FileDialog command = 16
        case GUICommandCode.gcFileDialog:
          this.gcFileDialog(<IGUICmdFileDialog>command)
        break;    
        // FontDialog command = 17
        case GUICommandCode.gcFontDialog:
          this.gcFontDialog(<IGUICmdFontDialog>command)
        break;
        // ColorDialog command = 18
        case GUICommandCode.gcColorDialog:
          this.gcColorDialog(<IGUICmdColorDialog>command)
        break;
        // Shutdown command = 99
        case GUICommandCode.gcShutdown:
          this.gcShutdown(<IGUICmdShutdown>command)
        break;
    }   

  }

  gcContext (command: IGUICmdCtx) { this.contextService.setContext(command) }

  gcInitialize (command: IGUICmdInit) { this.initializeService.initiate(command) }

  gcCreate (command: IGUICmdCreate) { this.createService.create(command) }

  gcDelete (command: IGUICmdDelete) { this.delteService.delete(command) }

  gcSetProp (command: IGUICmdSetProp) { this.setPropService.setProp(command) }

  gcGetProp (command: IGUICmdGetProp) { this.getPropService.getProp(command) }
   
  gcMethod (command: IGUICmdMethod) { this.methodService.method(command) }
 
  gcWaitEvent (command: IGUICmdWaitEvent | IGUICmdCheckEvent) { this.waitEventService.waitEvent(command) }
   
  gcControl (command: IGUICmdControl) { this.controlService.control(command) }

  gcMsgBox (command: IGUICmdMsgBox) { this.dialogService.msgBox(command) }

  gcInputBox (command: IGUICmdInputBox) { this.dialogService.inputBox(command) }

  gcMacro (command: IGUICmdMacro) { this.macroService.macro(command) }

  gcGetUpds (command: IGUICmdGetUpds) { this.getUpdsService.getUpds(command) }

  gcFileDialog (command: IGUICmdFileDialog) { this.dialogService.fileDialog(command) }

  gcFontDialog (command: IGUICmdFontDialog) { this.dialogService.fontDialog(command) }

  gcColorDialog (command: IGUICmdColorDialog) { this.dialogService.colorDialog(command) }
  
  gcShutdown (command: IGUICmdShutdown) { this.shutdownService.shutdown(command) }

  
/**
 * Alias Functions (for directly accessing sub services)
 */

  checkWaitEvent () { this.waitEventService.checkWaitEvent(); }


}

