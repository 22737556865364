/*
 * AccuTerm Mobile - terminal driver for ANSI emulations
 *
 * Copyright 2015 Zumasys, Inc.
 */

////////////////////////////////////////////////////////////
// Import Dependencies!
////////////////////////////////////////////////////////////

import { TerminalExport as Terminal } from './term.js';
import { TermUtil } from './termutil.js';
import { TermColors } from './termcolors.js';
import { Charmap } from './charmap.js';
import { DEBUG } from './globals.js';

/* global Terminal: false, TermColors: false, Charmap: false, DEBUG: false, TermUtil: false */

DEBUG&&console.log('top of tdansi.js');

function AnsiEmulator(term, termtype) {
	
	this.term = term;
 
	// TODO: NRCS
	// redefine the character sets
	Terminal.charsets.SCLD = 'DEC_GRAPH';
	Terminal.charsets.UK = 'ASCII'; // (A
	Terminal.charsets.US = 'ASCII'; // (B (USASCII)
	Terminal.charsets.Dutch = 'ASCII'; // (4
	Terminal.charsets.Finnish = 'ASCII'; // (C or (5
	Terminal.charsets.French = 'ASCII'; // (R
	Terminal.charsets.FrenchCanadian = 'ASCII'; // (Q
	Terminal.charsets.German = 'ASCII'; // (K
	Terminal.charsets.Italian = 'ASCII'; // (Y
	Terminal.charsets.NorwegianDanish = 'ASCII'; // (E or (6
	Terminal.charsets.Spanish = 'ASCII'; // (Z
	Terminal.charsets.Swedish = 'ASCII'; // (H or (7
	Terminal.charsets.Swiss = 'ASCII'; // (=
	Terminal.charsets.ISOLatin = 'LATIN1'; // /A
	Terminal.charsets.MULTINATIONAL = 'DEC'; // %5
	Terminal.charsets.UPSS = 'UPSS'; // <  (PJS)
	Terminal.charsets.DEFAULT = 'DEFAULT'; // ESC % @ (PJS)
	Terminal.charsets.UTF8 = 'UTF8'; // ESC % G (PJS)
	
	this.init(termtype);
	this.term.charAttributes = AnsiEmulator.prototype.charAttributes.bind(this);
	this.term.setgLevel = AnsiEmulator.prototype.setgLevel.bind(this);
	this.term.setgCharset = AnsiEmulator.prototype.setgCharset.bind(this);
	this.term.advanceCursor = Terminal.prototype.advanceCursor;
	
}

AnsiEmulator.prototype.init = function(termtype) {
	this.kbdinit();
	// call superclass softReset() method
	Terminal.prototype.softReset.call(this.term);
	this.term.grlevel = 2; // default for VT220/320/420
	this.term.charsets[0] = Terminal.charsets.US;
	this.term.charsets[1] = Terminal.charsets.US;
	this.term.charsets[2] = Terminal.charsets.UPSS;
	this.term.charsets[3] = Terminal.charsets.UPSS;
	this.updateCharset();
};

AnsiEmulator.prototype.kbdinit = function(appCursor, appKeypad, backspaceDel, eightBitControls) {

/*	
NORMAL              0
SHIFT               1000
CTRL                2000
CTRL+SHIFT          3000
ALT                 4000
CTRL+SHIFT          3000
ALT+SHIFT           5000
CTRL+ALT            6000
CTRL+ALT+SHIFT      7000

VK_BACK             8
VK_TAB              9
VK_INSERT           45
VK_DELETE           46
VK_HOME             36
VK_END              35
VK_PRIOR (PgUp)     33
VK_NEXT (PgDn)      34
VK_LEFT             37
VK_RIGHT            39
VK_UP               38
VK_DOWN             40

VK_ESCAPE           27
VK_RETURN           13
VK_KEYPAD_ENTER     253

VK_F1               112
VK_F2               113
VK_F3               114
VK_F4               115
VK_F5               116
VK_F6               117
VK_F7               118
VK_F8               119
VK_F9               120
VK_F10              121
VK_F11              122
VK_F12              123

VK_NUMPAD0          \x60
VK_NUMPAD1          \x61
VK_NUMPAD2          \x62
VK_NUMPAD3          \x63
VK_NUMPAD4          \x64
VK_NUMPAD5          \x65
VK_NUMPAD6          \x66
VK_NUMPAD7          \x67
VK_NUMPAD8          \x68
VK_NUMPAD9          \x69
VK_MULTIPLY         \x6A
VK_ADD              \x6B
VK_SEPARATOR        \x6C
VK_SUBTRACT         \x6D
VK_DECIMAL          \x6E
VK_DIVIDE           \x6F
	 
*/	
	var key
	  , def
	  , dfltkbd = [];
		
	if (typeof(appCursor) === 'undefined') {
		appCursor = !!(this.term.settings && this.term.settings.terminalCursorCodes);
	}
	if (typeof(appKeypad) === 'undefined') {
		appKeypad = !!(this.term.settings && this.term.settings.terminalKeypadCodes);
	}
	if (typeof(backspaceDel) === 'undefined') {
		backspaceDel = !!(this.term.settings && this.term.settings.bkspSendsDel);
	}
	if (typeof(eightBitControls) === 'undefined') {
		eightBitControls = !!(this.term.settings && this.term.settings.terminal8Bit);
	}
	
	// constant
	dfltkbd[9] = '\x09'; // TAB
	dfltkbd[13] = '\x0d'; // RETURN
	dfltkbd[27] = '\x1b'; // ESCAPE
	dfltkbd[33] = '\x9b\x35\x7e'; // PRIOR
	dfltkbd[34] = '\x9b\x36\x7e'; // NEXT
	dfltkbd[35] = '\x9b\x31\x7e'; // END
	dfltkbd[36] = '\x9b\x48'; // HOME
	dfltkbd[45] = '\x9b\x32\x7e'; // INSERT
	dfltkbd[253] = '\x0d'; // ENTER
	dfltkbd[1009] = '\x9b\x5a'; // SHIFT+TAB
	dfltkbd[1013] = '\x0d'; // SHIFT+RETURN
	dfltkbd[1027] = '\x1b'; // SHIFT+ESCAPE
	dfltkbd[1033] = '\x9b\x35\x7e'; // SHIFT+PRIOR
	dfltkbd[1034] = '\x9b\x36\x7e'; // SHIFT+NEXT
	dfltkbd[1035] = '\x9b\x31\x7e'; // SHIFT+END
	dfltkbd[1036] = '\x9b\x48'; // SHIFT+HOME
	dfltkbd[1045] = '\x9b\x32\x7e'; // SHIFT+INSERT
	dfltkbd[1046] = '\x9b\x33\x7e'; // SHIFT+DELETE
	dfltkbd[1253] = '\x0d'; // SHIFT+ENTER
	dfltkbd[112] = '\x8f\x50'; // F1
	dfltkbd[113] = '\x8f\x51'; // F2
	dfltkbd[114] = '\x8f\x52'; // F3
	dfltkbd[115] = '\x8f\x53'; // F4
	dfltkbd[116] = '\x9b\x4d'; // F5
	dfltkbd[117] = '\x9b\x31\x37\x7e'; // F6
	dfltkbd[118] = '\x9b\x31\x38\x7e'; // F7
	dfltkbd[119] = '\x9b\x31\x39\x7e'; // F8
	dfltkbd[120] = '\x9b\x32\x30\x7e'; // F9
	dfltkbd[121] = '\x9b\x32\x31\x7e'; // F10
	dfltkbd[122] = '\x9b\x32\x33\x7e'; // F11
	dfltkbd[123] = '\x9b\x32\x34\x7e'; // F12
	dfltkbd[2112] = '\x9b\x32\x33\x7e'; // CTRL+F1
	dfltkbd[2113] = '\x9b\x32\x34\x7e'; // CTRL+F2
	dfltkbd[2114] = '\x9b\x32\x35\x7e'; // CTRL+F3
	dfltkbd[2115] = '\x9b\x32\x36\x7e'; // CTRL+F4
	dfltkbd[2116] = '\x9b\x32\x38\x7e'; // CTRL+F5
	dfltkbd[2117] = '\x9b\x32\x39\x7e'; // CTRL+F6
	dfltkbd[2118] = '\x9b\x33\x31\x7e'; // CTRL+F7
	dfltkbd[2119] = '\x9b\x33\x32\x7e'; // CTRL+F8
	dfltkbd[2120] = '\x9b\x33\x33\x7e'; // CTRL+F9
	dfltkbd[2121] = '\x9b\x33\x34\x7e'; // CTRL+F10
	// backspace
	if (backspaceDel) {
		dfltkbd[8] = '\x7f'; // BACKSPACE
		dfltkbd[46] = '\x9b\x33\x7e'; // DELETE
		dfltkbd[1008] = '\x08'; // SHIFT+BACKSPACE
	} else {
		dfltkbd[8] = '\x08'; // BACKSPACE
		dfltkbd[46] = '\x7f'; // DELETE
		dfltkbd[1008] = '\x7f'; // SHIFT+BACKSPACE
	}
	// cursor
	if (appCursor) {
		dfltkbd[37] = '\x8f\x44'; // LEFT
		dfltkbd[39] = '\x8f\x43'; // RIGHT
		dfltkbd[38] = '\x8f\x41'; // UP
		dfltkbd[40] = '\x8f\x42'; // DOWN
		dfltkbd[1037] = '\x8f\x44'; // SHIFT+LEFT
		dfltkbd[1039] = '\x8f\x43'; // SHIFT+RIGHT
		dfltkbd[1038] = '\x8f\x41'; // SHIFT+UP
		dfltkbd[1040] = '\x8f\x42'; // SHIFT+DOWN
	} else {
		dfltkbd[37] = '\x9b\x44'; // LEFT
		dfltkbd[39] = '\x9b\x43'; // RIGHT
		dfltkbd[38] = '\x9b\x41'; // UP
		dfltkbd[40] = '\x9b\x42'; // DOWN
		dfltkbd[1037] = '\x9b\x44'; // SHIFT+LEFT
		dfltkbd[1039] = '\x9b\x43'; // SHIFT+RIGHT
		dfltkbd[1038] = '\x9b\x41'; // SHIFT+UP
		dfltkbd[1040] = '\x9b\x42'; // SHIFT+DOWN
	}
	// keypad
	if (appKeypad) {
		dfltkbd[96] = '\x8f\x70'; // NUMPAD0
		dfltkbd[97] = '\x8f\x71'; // NUMPAD1
		dfltkbd[98] = '\x8f\x72'; // NUMPAD2
		dfltkbd[99] = '\x8f\x73'; // NUMPAD3
		dfltkbd[100] = '\x8f\x74'; // NUMPAD4
		dfltkbd[101] = '\x8f\x75'; // NUMPAD5
		dfltkbd[102] = '\x8f\x76'; // NUMPAD6
		dfltkbd[103] = '\x8f\x77'; // NUMPAD7
		dfltkbd[104] = '\x8f\x78'; // NUMPAD8
		dfltkbd[105] = '\x8f\x79'; // NUMPAD9
		dfltkbd[106] = '\x8f\x6b'; // ADD
		dfltkbd[107] = '\x8f\x6d'; // SUBTRACT
		dfltkbd[108] = '\x8f\x6a'; // MULTIPLY
		dfltkbd[109] = '\x8f\x6f'; // DIVIDE
		dfltkbd[110] = '\x8f\x6e'; // DECIMAL
		dfltkbd[253] = '\x8f\x3d'; // ENTER
	} else {
		dfltkbd[96] = '0'; // NUMPAD0
		dfltkbd[97] = '1'; // NUMPAD1
		dfltkbd[98] = '2'; // NUMPAD2
		dfltkbd[99] = '3'; // NUMPAD3
		dfltkbd[100] = '4'; // NUMPAD4
		dfltkbd[101] = '5'; // NUMPAD5
		dfltkbd[102] = '6'; // NUMPAD6
		dfltkbd[103] = '7'; // NUMPAD7
		dfltkbd[104] = '8'; // NUMPAD8
		dfltkbd[105] = '9'; // NUMPAD9
		dfltkbd[106] = '+'; // ADD
		dfltkbd[107] = '-'; // SUBTRACT
		dfltkbd[108] = '*'; // MULTIPLY
		dfltkbd[109] = '/'; // DIVIDE
		dfltkbd[110] = '.'; // DECIMAL
		dfltkbd[253] = '\x0d'; // ENTER
	}
	// add default key definitions to kbdmap (replace 8-bit lead byte with 7-bit equivalent if not using 8-bit controls)
    for(key in dfltkbd) {
        if (dfltkbd.hasOwnProperty(key)) {
			def = this.term.kbdmap['key' + key] || {};
			def.dflt = eightBitControls ? dfltkbd[key] : dfltkbd[key].replace('\x9b', '\x1b\x5b').replace('\x8f', '\x1b\x4f');
			this.term.kbdmap['key' + key] = def;
        }
    }	

};

AnsiEmulator.prototype.reset = function() {
	// already did a hard reset
};

AnsiEmulator.prototype.write = function(data) {
	DEBUG&&console.log('AnsiEmulator.write: len=' + data.length);
	// call superclass method
	return Terminal.prototype.write.call(this.term, data);
};

AnsiEmulator.prototype.setgLevel = function(g, lr) {
	if (lr) {
		this.term.grlevel = g; // GR
	} else {
		this.term.glevel = g; // GL
	}
	this.updateCharset();
};

AnsiEmulator.prototype.setgCharset = function(g, charset) {
	this.term.charsets[g] = charset;
	if (this.term.glevel === g || this.term.grlevel === g) {
		this.updateCharset();
	}
};

// update the host character set to Unicode map
AnsiEmulator.prototype.updateCharset = function() {
	/* jshint curly: false */
	var gl = this.term.charsets[this.term.glevel] || 'ASCII',
		gr = this.term.charsets[this.term.grlevel] || 'ASCII';
	if (gl === 'DEFAULT') {
		if (this.term.charmap.utf8) {
			this.term.charmap = new Charmap('LATIN1', this.term.settings.euroChar); // create a new map without UTF-8 enabled
		}
		gl = 'UPSS';
	} else if (gl === 'UTF8') {
		if (!this.term.charmap.utf8) {
			this.term.charmap = new Charmap('UTF8', this.term.settings.euroChar); // create a new map with UTF-8 enabled
		}		
		gl = 'UPSS';
	}
	if (gl === 'UPSS') {
		gl = TermUtil.XlateCharSet(this.term.settings.charSet, this.term.getTermtype());
		if (gl === 'UTF8') {
			gl = 'LATIN1';
		} else if (gl === 'NATIVE') {
			gl = 'DEC';
		}
	}
	if (gr === 'UPSS') {
		gr = TermUtil.XlateCharSet(this.term.settings.charSet, this.term.getTermtype());
		if (gr === 'UTF8') {
			gr = 'LATIN1';
		} else if (gr === 'NATIVE') {
			gr = 'DEC';
		}
	}
	this.term.charset = this.term.charmap.updateCharset(gl, gr);
};
	
AnsiEmulator.prototype.charAttributes = function(params) {
	// Optimize a single SGR0.
	if (params.length === 1 && params[0] === 0) {
		this.term.curAttr = this.term.defAttr;		
		//TODO: if linux, set the screen attribute to curAttr
		return;
	}

	var term = this.term
	  , l = params.length
	  , i = 0
	  , flags = term.curAttr >> 18
	  , fg = (term.curAttr >> 9) & 0x1ff
	  , bg = term.curAttr & 0x1ff
	  , xg
	  , p
	  , fgac
	  , bgac;

	/* jshint curly: false */
	  
	//PJS: when the current attribute is the default attribute, then we must
	//     have done a "reset attribute to default" in a previous SGR sequence
	//     so we can set the fgac & bgac flags to lookup colors from the
	//     attrColor map.
	fgac = bgac = (this.term.curAttr === this.term.defAttr);
	
	//PJS: if inverse attribute is set, swap colors first
	//PJS 1.0.10: AccuTerm does not swap colors for DEC VT emulations
	if (term.getTermtype() === 'LINUX' || term.getTermtype() === 'XTERM') {
		if (flags & 8) {
			xg = fg; fg = bg; bg = xg;
		}
	}

	for (; i < l; i++) {
	  p = params[i];
	  if (p >= 30 && p <= 37) {
		// fg color 8
		fg = p - 30;
		if (flags & 1) {
			fg += 8; //PJS
		}
		fgac = false;
	  } else if (p >= 40 && p <= 47) {
		// bg color 8
		bg = p - 40;
		bgac = false;
	  } else if (p >= 90 && p <= 97) {
		// fg color 16
		p += 8;
		fg = p - 90;
		fgac = false;
	  } else if (p >= 100 && p <= 107) {
		// bg color 16
		p += 8;
		bg = p - 100;
		bgac = false;
	  } else if (p === 0) {
		// default
		flags = term.defAttr >> 18;
		fg = (term.defAttr >> 9) & 0x1ff;
		bg = term.defAttr & 0x1ff;
		fgac = bgac = true;
		// flags = 0;
		// fg = 0x1ff;
		// bg = 0x1ff;
	  } else if (p === 1) {
		// bold text
		flags |= 1;
		if (fg >= 0 && fg <= 7) {
			fg += 8; //PJS
		}
	  } else if (p === 2) {
		// dim
		flags |= 32;
		if (fg >= 8 && fg <= 15) {
			fg -= 8; //PJS
		}
	  } else if (p === 4) {
		// underlined
		flags |= 2;
	  } else if (p === 5) {
		// blink
		flags |= 4;
	  } else if (p === 7) {
		// inverse
		flags |= 8;
	  } else if (p === 8) {
		// invisible
		flags |= 16;
	  } else if (p === 22) {
		// not bold or dim
		flags &= ~(1 | 32);
		if (fg >= 8 && fg <= 15) {
			fg -= 8; //PJS
		}
	  } else if (p === 24) {
		// not underlined
		flags &= ~2;
	  } else if (p === 25) {
		// not blink
		flags &= ~4;
	  } else if (p === 27) {
		// not inverse
		flags &= ~8;
	  } else if (p === 28) {
		// not invisible
		flags &= ~16;
	  } else if (p === 39) {
		// reset fg
		fg = (term.defAttr >> 9) & 0x1ff;
		fgac = false;
	  } else if (p === 49) {
		// reset bg
		bg = term.defAttr & 0x1ff;
		bgac = false;
	  } else if (p === 38) {
		// fg color 256
		if (params[i + 1] === 2) {
		  i += 2;
		  fg = TermColors.matchColor(
			term.colors,
			params[i] & 0xff,
			params[i + 1] & 0xff,
			params[i + 2] & 0xff);
		  if (fg === -1) fg = 0x1ff;
		  i += 2;
		} else if (params[i + 1] === 5) {
		  i += 2;
		  p = params[i] & 0xff;
		  fg = p;
		}
		fgac = false;
	  } else if (p === 48) {
		// bg color 256
		if (params[i + 1] === 2) {
		  i += 2;
		  bg = TermColors.matchColor(
			term.colors,
			params[i] & 0xff,
			params[i + 1] & 0xff,
			params[i + 2] & 0xff);
		  if (bg === -1) bg = 0x1ff;
		  i += 2;
		} else if (params[i + 1] === 5) {
		  i += 2;
		  p = params[i] & 0xff;
		  bg = p;
		}
		bgac = false;
	  } else if (p === 100) {
		// reset fg/bg
		fg = (term.defAttr >> 9) & 0x1ff;
		bg = term.defAttr & 0x1ff;
		fgac = bgac = false;
	  } else {
		term.error('Unknown SGR attribute: %d.', p);
	  }
	}

	//PJS: if inverse attribute is set, swap colors
	//PJS 1.0.10: AccuTerm does not swap colors for DEC VT emulations
	if (term.getTermtype() === 'LINUX' || term.getTermtype() === 'XTERM') {
		if (flags & 8) {
			xg = fg; fg = bg; bg = xg;
		}
	}

	// if specific colors are not used, look up fg/bg in attrColor map
	if (fgac) {
		try {
			fg = flags ? term.attrColor[flags][1] : 257; // use the default foreground color if attr is zero
			DEBUG&&console.log('set fg using attrColor=' + fg);
		} catch (e) {}
	}
	if (bgac) {
		try {
			bg = flags ? term.attrColor[flags][0] : 256; // use the default background color if attr is zero
			DEBUG&&console.log('set bg using attrColor=' + bg);
		} catch (e) {}
	}
	
	term.curAttr = (flags << 18) | (fg << 9) | bg;
	
};

// set background color
AnsiEmulator.prototype.setBackground = function(color) {
	var flags = this.term.curAttr >> 18;
	var fg = (this.term.curAttr >> 9) & 0x1ff;
	this.term.curAttr = (flags << 18) | (fg << 9) | color;
};

// set foreground color
AnsiEmulator.prototype.setForeground = function(color) {
	var flags = this.term.curAttr >> 18;
	var bg = this.term.curAttr & 0x1ff;
	this.term.curAttr = (flags << 18) | (color << 9) | bg;
};


// save emulation-specific state variables
AnsiEmulator.prototype.saveState = function(screen_state) {
	screen_state.defAttr = this.term.defAttr;
	screen_state.curAttr = this.term.curAttr;
	screen_state.protMode = this.term.protMode;
	screen_state.originMode = this.term.originMode;
	screen_state.delayWrap = this.term.delayWrap;
	screen_state.glevel = this.term.glevel;
	screen_state.grlevel = this.term.grlevel;
	screen_state.singleShift = this.term.singleShift;
	screen_state.charsets = this.term.charsets.slice(0);
};

// restore emulation-specific state variables
AnsiEmulator.prototype.restoreState = function(screen_state) {	
	this.term.defAttr = screen_state.defAttr !== undefined ? screen_state.defAttr : this.term.defAttr;
	this.term.curAttr = screen_state.curAttr !== undefined ? screen_state.curAttr : this.term.curAttr;
	this.term.protMode = screen_state.protMode !== undefined ? screen_state.protMode : this.term.protMode;
	this.term.originMode = screen_state.originMode !== undefined ? screen_state.originMode : this.term.originMode;
	this.term.delayWrap = screen_state.delayWrap !== undefined ? screen_state.delayWrap : this.term.delayWrap;
	this.term.glevel = screen_state.glevel !== undefined ? screen_state.glevel : this.term.glevel;
	this.term.grlevel = screen_state.grlevel !== undefined ? screen_state.grlevel : this.term.grlevel;
	this.term.singleShift = screen_state.singleShift !== undefined ? screen_state.singleShift : this.term.singleShift;
	this.term.charsets = screen_state.charsets || this.term.charsets;
	this.updateCharset();
};


////////////////////////////////////////////////////////////
// Exports!
////////////////////////////////////////////////////////////

export { AnsiEmulator };
