// AccuTerm Utilities
import { TermUtil } from '@/vendor/accuterm/client/js/termutil'

/**
 * Utility class with static methods for common functionality
 */
export default class Utils {
    /**
     * Returns the Font Family from submodule TermUtil
     * payload is { font }
     */
    getFontFamily (payload: any) {
        return TermUtil.GetFontFamily(payload.font)
    }

    /**
     * Returns the Font Family from submodule TermUtil
     * payload is { style }
     */
    getThemeStyle (payload: any) {
        return TermUtil.GetThemeStyleTitle(payload.style)
    }

    /**
     * Returns true if version of IE shows up. Returns true if is not Internet Explorer or Microsoft Edge
     * (//codepen.io/gapcode/pen/vEJNZN)
     */
    static detectIE (): boolean {
        const ua = window.navigator.userAgent
        let version: boolean | number = false

        const msie = ua.indexOf('MSIE ')
        if (msie > 0) {
            // IE 10 or older => return version number
            version = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
        }

        const trident = ua.indexOf('Trident/')
        if (trident > 0) {
            // IE 11 => return version number
            let rv = ua.indexOf('rv:')
            version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
        }

        const edge = ua.indexOf('Edge/')
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            version = parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
        }

        // if it's not IE or Edge || if it's edge
        if (!version || version >= 12) {
            return false
        } else {
            return true
        }
    }
}
