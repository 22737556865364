






































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {BTable} from 'bootstrap-vue';
// Types
import { IGUIEvent } from './../../../types'
import { ListMultiselProps, GUIEventCode } from './../../../types/enums'
import { UtilsType } from './../../../types/utils'
// Utilities 
import Utils from './../../../utils/index';
import GridLabelIcon from './labelIcon.vue';
import Icon from './../../layout/Icon.vue';

@Component({
  name: 'gxListMultiselComponent',
  components: { GridLabelIcon, Icon }
})

export default class gxListMultisel extends Vue {
/** ******************************** Vue Props **********************************/
  @Prop({ default: () => (new ListMultiselProps()) }) public props!: ListMultiselProps;
  @Prop() private getMultipleRowIndexes!: any;

  @Prop() public fields: any;
  @Prop() public items: any;
  @Prop() public hasIcons: any;
  @Prop() public iconStyle: any;

/** ******************************** Vue Data **********************************/
  public utils: UtilsType = new Utils();
  public clickCount: number = 0;
  public clickTimer: any = null;
  public fieldtype = 'GridLabelIcon'
  public activated: boolean = false

  public listGridId: string = this.props.id +"-list-grid";   

/** ******************************** Vue Computed **********************************/
  get focused() { return this.$store.getters['guiGuis/getFocusedControl']; }
  get visible (): boolean { return this.props.gpVisible }

  get showHeaders () {
    const found = this.props.columnInfo.find(element => element.heading !== '' || element.heading === null);
    let result = '';
    if (found === undefined) {
        result = 'd-none';
    } 
    return result;
  }

  get value(): Array<number> { return this.props.gpValue }

  set value(newValue: Array<number>) { 
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'changed', value: true })
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpChanged', value: true })
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpValue', value: newValue }) 
  }

  get style(): Array<Partial<CSSStyleDeclaration>> { 
    let style: Array<Partial<CSSStyleDeclaration>> = []
    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight)
    const font = this.utils.controlFont(this.props)
    const border = this.utils.controlBorder(this.props)
    const colors = { 
      backgroundColor: this.props.gpBackColor || 'white',
      color: this.props.gpForeColor || 'black',
      overflow: 'auto'
    }
    style.push(size, border, colors, font)
    return style
  }

  get enabled() { return this.props.gpEnabled }
  
  get HorizontalVerticalLines (): boolean { return !!(this.props.gpGridLines & 1) }

  get NoVerticalHorizontalLines (): boolean { return (this.props.gpGridLines === 0) }

/** ******************************** Vue Methods **********************************/

  singleClickHandler (selectedRows: any) {
    if (selectedRows.length >= 1 ) {
      let newValues = this.getMultipleRowIndexes(this.items, selectedRows);
      this.value = newValues;        
      // Check if the change event is included in the prop events
      if (this.props.gpEventMask & GUIEventCode.geClick) {
          // send this event to the server
          const guiEvent: IGUIEvent = { event: GUIEventCode.geClick, id: this.props.id, args: [newValues] }
          this.$store.dispatch('guiGuis/addEvent', guiEvent);
      }
    } else {
      this.value = [];
    } 
  }

  doubleClickHandler(e: MouseEvent) {
    e.stopPropagation();
    
    let args = [this.value];
    if (this.props.gpEventMask & GUIEventCode.geDblClick) {
      // send this event to the server
      const guiEvent: IGUIEvent = { event: GUIEventCode.geDblClick, id: this.props.id, args: args }
      this.$store.dispatch('guiGuis/addEvent', guiEvent);
    }
  }

  rightClickHandler(e: MouseEvent) {
    e.stopPropagation();

    if (this.props.gpEventMask & GUIEventCode.geContext) {
      // send this event to the server
      const contextEvent: IGUIEvent = { event: GUIEventCode.geContext, id: this.props.id }
      this.$store.dispatch('guiGuis/addEvent', contextEvent);
    }

    this.$store.dispatch('guiGuis/updateMousePosition', this.utils.getMousePositionRelativeToParent(e, this.props.form!.id))
  }

  headClickHandler (value: any) {
    let args = [value + 1];
    if (this.props.gpEventMask & GUIEventCode.geColClick) {
      // send this event to the server
      const columnHeadlClickEvent: IGUIEvent = { event: GUIEventCode.geColClick, id: this.props.id, args: args  }
      this.$store.dispatch('guiGuis/addEvent', columnHeadlClickEvent);
    }
  }

  focus() {
    if (this.focused === this.props.id && this.enabled && this.visible) {
      try {
      let elem: BTable = (this.$refs[this.listGridId] as BTable);
      let elemChild: HTMLElement = elem.$el.children[1] as HTMLElement;
      if(elemChild && elemChild.children.length > 0) {
        (elemChild.children[0] as HTMLTableRowElement).focus()
      }
console.log('List Focus Me ' + this.props.id);
      } catch(e) {
console.log('List Focus Me error: ' + e);
      }
    }
  }

/** ******************************** Life Cycle Hooks **********************************/

  mounted () {
    this.value.forEach(select => {
      (this.$refs[this.listGridId] as any).selectRow(select - 1)
    })
    this.$nextTick(() => { this.focus(); });
  }

/** ******************************** Vue Watch and Emit Events! **********************************/

  @Watch('focused') passRequest () { 
    this.focus();
  }

  @Watch('value') updateSelection () {
    const table: BTable = this.$refs[this.listGridId] as BTable;
    const items: Array<any> = table.items as Array<any>;
    const value = this.value;
    items.forEach((item: any, index: number) => {
      if (value.includes(index + 1 )) {
        table.selectRow(index);
      } else {
        table.unselectRow(index);
      }
    });
  }  

}
