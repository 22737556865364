import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
// Types
import { RootState } from '../../types/store/'
import { RequestState } from '../../types/store/request'
import { GUICommand } from '../../types'
// Services
import RequestService from '../../services/request/'

// State
export const state: RequestState = {
    requestService: new RequestService(),
    request: [],
    requestLength: 0
}

// Namespace
const namespaced: boolean = true

// Getters
export const getters: GetterTree<RequestState, RootState> = {
    getRequestService (state) { return state.requestService },
    getRequest (state) { return state.request },
    getRequestLength (state) { return state.requestLength }
}

// Mutations
export const mutations: MutationTree<RequestState> = {
    CLEAR_REQUEST (state) { state.request = [] },
    CLEAR_REQUEST_LENGTH (state) { state.requestLength = 0 },
    SET_REQUEST (state, request) { state.request = [...request] },
    SET_REQUEST_LENGTH (state, length) { state.requestLength = length }
}

// Actions
export const actions: ActionTree<RequestState, RootState> = {
    clear({ commit }) {
        commit('CLEAR_REQUEST')
        commit('CLEAR_REQUEST_LENGTH')
    },
    request ({ commit, state }, request: Array<GUICommand>) {
        state.requestService.requestHandler(request)
    },
    addRequestCommand ({ commit, state }, command: GUICommand) {
        let newRequest = [...state.request]
        
        newRequest.push({ ...command })

        commit('SET_REQUEST', newRequest)
    },
    requestLength ({ commit }, length: number) {
        commit('SET_REQUEST_LENGTH', length)
    }
}

export const guiRequest: Module<RequestState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}
