





import { Component, Prop, Vue } from 'vue-property-decorator'
// Types
import { GUIComponent, GUIObjectType, HotKey } from '../../types/enums';
import { CharacterUnderlineProps } from './../../types/components'

@Component({
    name: 'CharacterUnderlineComponent'
})

export default class CharacterUnderline extends Vue {
/** ******************************** Vue Props **********************************/

    @Prop() public props!: CharacterUnderlineProps;

    @Prop({ default: ''}) public text!: string;
    @Prop({ default: false}) public pointer!: boolean;

/** ******************************** Vue Data **********************************/

    public classes: Array<any> = []

/** ******************************** Vue Computed **********************************/

    get parentID(): string { return (this.props.form) ? this.props.form : this.props.app; } // if the form is null, then it's parent is the gui app
    get parentComponent(): GUIComponent { return this.$store.getters['guiGuis/getComponent'](this.parentID) }
    get component(): GUIComponent { return this.$store.getters['guiGuis/getComponent'](this.props.control) }
    get hotKeys(): Array<HotKey> { return this.$store.getters['guiGuis/getHotKeys'](this.parentID); }

    get hasAmpersand() { return this.text.search(/&[A-Za-z]/) !== -1; }

    get pieces(): Array<string> {
        try {
            const rtn = this.text.match(/(.*)(&[A-Za-z])(.*)/);
            if (rtn) return rtn.slice(1);
        } catch(e) {}
        return [this.text, '', ''];
    }

    get before() { return this.unescapeAmpersand(this.pieces[0]); }
    get underlineCharacter() { return this.pieces[1].charAt(1); }
    get after() { return this.unescapeAmpersand(this.pieces[2]); }



    get style(): Partial<CSSStyleDeclaration>[] {
        let style: Partial<CSSStyleDeclaration>[] = []
        if(this.props.type === GUIObjectType.gxCommand || this.props.type === GUIObjectType.gxOption) {
            style.push({whiteSpace: "break-spaces"})
        }

        return style;
    }

/** ******************************** Vue Method **********************************/
    unescapeAmpersand(text: string): string {
        return text.replace(/&&/g, '&'); // change all && to &
    }

/** ******************************** Vue Life Cycle **********************************/

    created() {

        if (this.hasAmpersand) {
            // These controls need a trigger added
            // The trigger wasn't initially added because the "next" control didn't exist when hot keys/triggers get set (in the set prop service)
            const controlTriggers: Array<GUIObjectType> = [GUIObjectType.gxLabel, GUIObjectType.gxFrame]

            if (controlTriggers.includes(this.props.type)) {

                const index: number = this.hotKeys.findIndex((hotKey: HotKey) => hotKey.control === this.props.control && hotKey.key === this.underlineCharacter.toLowerCase())

                if (index !== -1) {

                    let hotKey: HotKey = { ...this.hotKeys[index] };

                    switch(this.props.type) {
                        case GUIObjectType.gxLabel:

                            let index = this.parentComponent.children.findIndex(child => child.props.id === this.props.control);
                            let nextControlIndex = index + 1;

                            if (index !== -1 && nextControlIndex < this.parentComponent .children.length) {
                                hotKey.trigger = this.parentComponent.children[nextControlIndex].props.id;
                            }

                        break;
                        case GUIObjectType.gxFrame:
                            // if the frame has children
                            if (this.component.children.length > 0) {
                                // TODO: confirm this first child control is a control that can receive focus 
                                if (this.component.children[0].props.focusable) {
                                    hotKey.trigger = this.component.children[0].props.id;
                                }
                            }
                        break;
                    }

                    this.$store.dispatch('guiGuis/updateHotKey', { index: index, hotKey: hotKey })

                }
            }

        }
    }

}
