// Types
import { Component } from 'vue'
import { GUIObjectType, OptionItemProps, TreeItemProps, MenuItemProps, GridColumnProps } from '../enums'
// Components
import GUIApp from './../../components/GUIApp.vue'
import formComponents from './../../components/forms'
import controlsComponents from './../../components/controls'
import menusComponents from './../../components/menus'

const GUIComponents: Array<Component> = []

// application
GUIComponents[GUIObjectType.gxSDI] = GUIApp;
GUIComponents[GUIObjectType.gxMDI] = GUIApp;
// form
GUIComponents[GUIObjectType.gxFormSizable] = formComponents.Form;
GUIComponents[GUIObjectType.gxFormFixed] = formComponents.Form;
GUIComponents[GUIObjectType.gxDialog] = formComponents.gxDialog;
GUIComponents[GUIObjectType.gxSDISizable] = formComponents.Form;
GUIComponents[GUIObjectType.gxSDIFixed] = formComponents.Form;
// controls
GUIComponents[GUIObjectType.gxLabel] = controlsComponents.gxLabel;
GUIComponents[GUIObjectType.gxCommand] = controlsComponents.gxCommand;
GUIComponents[GUIObjectType.gxOption] = controlsComponents.gxOption;
GUIComponents[GUIObjectType.gxCheck] = controlsComponents.gxCheck;
GUIComponents[GUIObjectType.gxPicture] = controlsComponents.gxPicture;
GUIComponents[GUIObjectType.gxFrame] = controlsComponents.gxFrame;
GUIComponents[GUIObjectType.gxTabgrp] = controlsComponents.gxTabgrp;
GUIComponents[GUIObjectType.gxTab] = controlsComponents.gxTab;
GUIComponents[GUIObjectType.gxGauge] = controlsComponents.gxGauge;
GUIComponents[GUIObjectType.gxTree] = controlsComponents.gxTree;
GUIComponents[GUIObjectType.gxTimer] = controlsComponents.gxTimer;
GUIComponents[GUIObjectType.gxBrowser] = controlsComponents.gxBrowser;
// controls - Edit
GUIComponents[GUIObjectType.gxEdit] = controlsComponents.Edit;
GUIComponents[GUIObjectType.gxEditPassword] = controlsComponents.Edit;
GUIComponents[GUIObjectType.gxEditMultiline] = controlsComponents.Edit;
// controls - Grid
GUIComponents[GUIObjectType.gxGrid] = controlsComponents.Grid;
GUIComponents[GUIObjectType.gxGridEditable] = controlsComponents.Grid
// controls - List
GUIComponents[GUIObjectType.gxList] = controlsComponents.List;
GUIComponents[GUIObjectType.gxListMultisel] = controlsComponents.List;
GUIComponents[GUIObjectType.gxDrpDwnList] = controlsComponents.List;
GUIComponents[GUIObjectType.gxCheckedList] = controlsComponents.List;
GUIComponents[GUIObjectType.gxDrpDwnCbo] = controlsComponents.List

// menus
GUIComponents[GUIObjectType.gxMenu] = menusComponents.gxMenu;
GUIComponents[GUIObjectType.gxPopup] = menusComponents.gxPopup;
GUIComponents[GUIObjectType.gxToolbar] = menusComponents.gxToolbar;
GUIComponents[GUIObjectType.gxStatusbar] = menusComponents.gxStatusbar;

export { GUIComponents }



/**
 * Controls
 */

export interface TreeItem extends TreeItemProps {  
  indicies: Array<number>;
  visualSelection: boolean;
  selected: boolean;
  selectionType: string;
  style: number;
  children: Array<TreeItem>;
}

export interface OptionItem extends OptionItemProps {
  value: number
}

/**
 * Menus
 */

export interface MenuItem extends MenuItemProps {  
  indicies: Array<number>;
  cuProps: CharacterUnderlineProps;
  children: Array<MenuItem>;
}

/**
 * Other
 */

 export interface CharacterUnderlineProps {  
  app: string; // parent gui app id
  form: string | null; // parent form id
  control: string; // control id
  type: GUIObjectType; // control type
 }