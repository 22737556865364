// Import Components
import gxBrowser from './../../components/controls/gxBrowser.vue'
import gxCheck from './../../components/controls/gxCheck.vue'
import gxCommand from './../../components/controls/gxCommand.vue'
//import gxDrpDwnCbo from './../../components/controls/gxDrpDwnCbo.vue'
import gxFrame from './../../components/controls/gxFrame.vue'
import gxGauge from './../../components/controls/gxGauge.vue'
import gxLabel from './../../components/controls/gxLabel.vue'
import gxOption from './../../components/controls/gxOption.vue'
import gxPicture from './../../components/controls/gxPicture.vue'
import gxTabgrp from './../../components/controls/gxTabgrp.vue'
import gxTab from './../../components/controls/gxTab.vue'
import gxTimer from './../../components/controls/gxTimer.vue'
import gxTree from './../../components/controls/tree/Tree.vue'
// Edit Controls
// Handles gxEdit, gxEditPassword and gxEditMultiline
import Edit from './../../components/controls/edit/Edit.vue'
// Grid(**Table**) Controls
// Handles gxGrid, gxGridEditable
import Grid from './../../components/controls/grid/Grid.vue'
// List Controls
// Handles gxList, gxListMultisel, gxCheckedList and gxDrpDwnList
import List from './../../components/controls/list/List.vue'

export default {
  gxBrowser, gxCheck, gxCommand, Edit, Grid, List, gxFrame, gxGauge, gxLabel, gxOption, gxPicture, gxTab, gxTabgrp, gxTimer, gxTree
}
