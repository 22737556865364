import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
// Types
import { RootState } from '@/types/store/'
import { GUIState } from '@/types/store/gui'
import { actionErrorObject } from '@/types/store/errors'

// State
export const state: GUIState = {
    request: [],
    response: [],
    callback: undefined
}

// Namespace
const namespaced: boolean = true

// Getters
export const getters: GetterTree<GUIState, RootState> = {
    getRequest (state) { return state.request },
    getResponse (state) { return state.response }
    // moduleGettersExample (state, getters, rootState, rootGetters) { console.log(rootGetters['terminal/hasAccuTerm']) }
}

// Mutations
export const mutations: MutationTree<GUIState> = {
    SET_REQUEST (state, request) { state.request = request.map((r: object) => { return { ...r } }) },
    SET_RESPONSE (state, response) { state.response = response.map((r: object) => { return { ...r } }) },
    SET_CALLBACK (state, callback) { state.callback = callback }
}

// Actions
export const actions: ActionTree<GUIState, RootState> = {
    // We're going to need the terminal soon
    // moduleGettersExample  ({ rootGetters }) { console.log(rootGetters['terminal/hasAccuTerm']) }
    setRequest ({ commit }, request) {
        commit('SET_REQUEST', request)
    },
    setResponse ({ commit }, response) {
        commit('SET_RESPONSE', response)
    },
    setCallback ({ commit }, callback) {
        commit('SET_CALLBACK', callback)
    },
    callback ({ state }, response) {
        if (state.callback) {
            state.callback(response)
        }
    }
}

export const gui: Module<GUIState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}
