







































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// Types
import { GUIComponent, GUIObjectType, TabgrpProps, GUIEventCode } from './../../types/enums'
import { UtilsType } from './../../types/utils'
// Utilities 
import { GuisServiceType } from './../../types/services/guis'
import Utils from './../../utils/index';
import { IGUIEvent } from './../../types'

@Component({
  name: 'gxTabgrpComponent'
})

export default class gxTabgrp extends Vue {
  /** ******************************** Vue Props **********************************/

  @Prop({ default: () => (new TabgrpProps()) }) private props!: TabgrpProps;
  @Prop({ default: () => ([]) }) private children!: Array<GUIComponent>

  /** ******************************** Vue Data **********************************/
  public utils: UtilsType = new Utils();

  public activated: boolean = false;

  /** ******************************** Vue Computed **********************************/
//  get focused() { return this.$store.getters['guiGuis/getFocusedControl']; }
  get focused() { const x = this.$store.getters['guiGuis/getFocusedControl']; console.log('      tabgrp get focused: ' + x); return x; }
  get visible (): boolean { return this.props.gpVisible }

  get tabChildren () { return this.children.filter(child => child.props.type == GUIObjectType.gxTab) }
  get otherchildren () { return this.children.filter(child => child.props.type !== GUIObjectType.gxTab) }

  get style(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = []
    const positioning = this.utils.controlPositionCSS(this.props.gpTop, this.props.gpLeft);
    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight)
    const containerCSS: Partial<CSSStyleDeclaration> = { 
      backgroundColor: this.props.gpBackColor,
      color: this.props.gpForeColor
      }
       
    style.push(size)
    style.push(positioning)
    style.push(containerCSS)

    return style
  }

  get cssProperties(): Partial<CSSStyleDeclaration>[] { 
      let style: Partial<CSSStyleDeclaration>[] = []
      const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight)
      const font = this.utils.controlFont(this.props)
      const border = this.utils.controlBorder(this.props)
      const alignment = this.utils.controlAlign(this.props)
      const containerCSS: Partial<CSSStyleDeclaration> = { 
        backgroundColor: this.props.gpBackColor,
        color: this.props.gpForeColor
      }
       
    style.push(size)
    style.push(font)
    style.push(border)
    style.push(alignment)
    style.push(containerCSS)

    return style
  }

  get tabindex(): number { return 0 /*this.props.tabindex*/ }

  get enabled(): boolean { return this.props.gpEnabled }

  get stackVertical (): boolean { return this.props.gpStyle === 1}

  get value () { return this.props.gpValue > 0 ? this.props.gpValue - 1 : 0 }
  set value (value: number) { this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpValue', value: value + 1 }) }

  /** ******************************** Vue Methods **********************************/
  
  leftClickHandler (index: number) {
      // Check if the change event is included in the prop events
      if (this.props.gpEventMask & GUIEventCode.geClick) {
          // send this event to the server
          const guiEvent: IGUIEvent = { event: GUIEventCode.geClick, id: this.props.id, value: this.value }
          this.$store.dispatch('guiGuis/addEvent', guiEvent);
      }
    }

  rightClickHandler(e: MouseEvent) {
    e.stopPropagation()

      //right Click Handler
    if (this.props.gpEventMask & GUIEventCode.geContext) {
      // send this event to the server
      const contextEvent: IGUIEvent = { event: GUIEventCode.geContext, id: this.props.id, value: this.value  }
      this.$store.dispatch('guiGuis/addEvent', contextEvent);
    }
  }

  focus() {
    if (this.focused === this.props.id && this.enabled && this.visible) {
      try {
        const btabs: any = this.$refs[this.props.id];
        const tabs = btabs.getTabs();
        const tab = tabs[this.value];
        btabs.focusButton(tab);
console.log('Tabgrp Focus Me '+ this.value +' in '+ this.props.id + ' (form focused=' + this.props.form!.focused + ')');
      } catch(e) {
console.log('Tabgrp Focus Me error: ' + e);
      }
    }
  }

  activateHandler(e: any) {
    // Because we are in capturing phase, we catch the focus event, but the target
    // may be a child control of a tab panel, not the tab button. So check that
    // the target is actually this tab group control.
    const targetControlID = (e.target && this.utils.getRelatedTargetID(e.target, this.props.id, 'controlID')) || null;
    if (targetControlID === this.props.id) {
      if (!this.activated) {
        this.activated = true;
        let prevControlID = (e.relatedTarget && this.utils.getRelatedTargetID(e.relatedTarget, '', 'controlID')) || null;
        if (!prevControlID) {
          // handle the deferred deactivate event
          prevControlID = this.utils.handleDeferredCtlDeactivate(this.props.id);
        }
        if (prevControlID !== this.props.id) {
console.log('Tabgrp activate ' + this.props.id + ' from ' + prevControlID);
          if (this.props.gpEventMask & GUIEventCode.geActivate) {
            const activateEvent: IGUIEvent = { event: GUIEventCode.geActivate, id: this.props.id, args: [prevControlID] }
            this.$store.dispatch('guiGuis/addEvent', activateEvent);
          }
        } else console.log('   skipping Tabgrp activate ' + this.props.id + ' - self');
        // Set focused in store and in parent form
        this.$store.dispatch('guiGuis/setFocused', { id: this.props.id })
        this.$store.dispatch('guiGuis/updateProperty', {id: this.props.form!.id, property: 'focused', value: this.props.id }) 
      } else console.log('   skipping Tabgrp activate ' + this.props.id + ' - already active');
    } else console.log('   skipping Tabgrp activate ' + this.props.id + ' <> target ' + targetControlID + ' - other');
  }

  deactivateHandler(e: any) {
    // Because we are in capturing phase, we catch the blur event, but the target
    // may be a child control of a tab panel, not the tab button. So check that
    // the target is actually this tab group control.
    const targetControlID = (e.target && this.utils.getRelatedTargetID(e.target, this.props.id, 'controlID')) || null;
    if (targetControlID === this.props.id) {
      if (this.activated) {
        this.activated = false;
      }
      const nextControlID = (e.relatedTarget && this.utils.getRelatedTargetID(e.relatedTarget, '', 'controlID')) || null;
      if (nextControlID) {
        this.handleDeactivateEvents(nextControlID);
      } else {
        this.$store.dispatch('guiGuis/setDeferredCtlDeactivate', this.handleDeactivateEvents.bind(this));
console.log('   deferring Tabgrp deactivate ' + this.props.id + ' related = null');
      }
    } else console.log('   skipping Tabgrp deactivate ' + this.props.id + ' <> target ' + targetControlID + ' - other');
  }

  handleDeactivateEvents(nextControlID: string): string {
    if (nextControlID !== this.props.id) {
console.log('Tabgrp deactivate ' + this.props.id + ' to ' + nextControlID);
      if (this.props.gpEventMask & GUIEventCode.geDeactivate) {
        const deactivateEvent: IGUIEvent = { event: GUIEventCode.geDeactivate, id: this.props.id, args: [nextControlID] }
        this.$store.dispatch('guiGuis/addEvent', deactivateEvent);
      }
    } else console.log('   skipping Tabgrp deactivate ' + this.props.id + ' - self');
    return this.props.id;
  }

  mounted() {
    this.focus()
  }

/** ******************************** Vue Watch and Emit Events! **********************************/

  @Watch('focused') passRequest () { 
    if (this.focused === this.props.id) {
console.log('      Tabgrp focused watcher ' + this.props.id);
    }
    this.focus()
  }  

}
