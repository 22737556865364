// Store
import store from '../../store'
// Types
import { GUICommand, IGUICmdWaitEvent, IGUICmdCheckEvent, IGUIRspError, IGUIEvent, IGUIRspEvent } from '../../types'
import { GUICommandCode, GUIComponent, GUIErrorCode, GUIErrorLevel, GUIObjectType } from '../../types/enums'
import { GuisServiceWaitEventType } from '../../types/services/guis'

let  timeoutID: number = 0;
export default class GuisServiceWaitEvent implements GuisServiceWaitEventType {

    waitEvent(command: IGUICmdWaitEvent | IGUICmdCheckEvent) {

        const guiEvents = store.getters['guiGuis/getEvents'].map((guiEvent: IGUIEvent) => ({ ...guiEvent }));
        const response: IGUIRspEvent = { command: command.command, error: GUIErrorCode.grSuccess, events: guiEvents }
        
        if (guiEvents.length === 0) {
            // Check if there are any enabled and visible forms (TODO: verify MDI)
            if (store.getters['guiGuis/hasGuis']) {
                // at least one form is visible & enabled, and can produce an event
                if (command.command ===  GUICommandCode.gcCheckEvent) {

                    // if handling gcCheckEvent, set a timeout
                    const milliseconds = command.timeout * 1000;
                    timeoutID = setTimeout(() => {
                        timeoutID = 0;
                        const timeoutEvent: IGUIEvent = { event: 0, id: '' }; // return event 0 on timeout
                        store.dispatch('guiGuis/addEvent', timeoutEvent);
                    }, milliseconds);                  
                }

            } else {
                // if there are no (enabled and visible) form(s)
                store.dispatch('guiResponse/setResponse', { command: command.command, error: GUIErrorCode.grNoEvent, level: GUIErrorLevel.glvlFail })
            }
        } else {
            store.dispatch('guiResponse/setResponse', response)
            store.dispatch('guiGuis/clearEvents')
        }
    }

    checkWaitEvent () {

        const guiRequest: Array<GUICommand> = store.getters['guiRequest/getRequest'];
        const guiEvents: Array<IGUIEvent> = store.getters['guiGuis/getEvents'].map((guiEvent: IGUIEvent) => ({ ...guiEvent }));

        if (timeoutID) {
            clearTimeout(timeoutID);
        }

        // If there is only 1 current request and there are events to send
        if (guiRequest.length === 1 && guiEvents.length !== 0) {
            // If the only current request is gcWaitEvent or gcCheckEvent
            if (guiRequest[0].command === GUICommandCode.gcWaitEvent || guiRequest[0].command === GUICommandCode.gcCheckEvent ) {
                const response: IGUIRspEvent = { command: guiRequest[0].command, error: GUIErrorCode.grSuccess, events: guiEvents }
                store.dispatch('guiResponse/setResponse', response)
                store.dispatch('guiGuis/clearEvents')
            }
        }
    }

}
