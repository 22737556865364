/*
 * AccuTerm Web Globals
 *
 * Copyright 2018 Zumasys, Inc.
 */

const app = {
    version: '8.0.101',
    product: 'Web'
}

const DEBUG = false;

////////////////////////////////////////////////////////////
// Exports!
////////////////////////////////////////////////////////////

export { app, DEBUG };
