import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/types/store/'
import { AccuTermUser, UserState } from '@/types/store/user'
import { actionErrorObject } from '@/types/store/errors'
// Auth Serivce
import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';
import B2CAuthService from '@/services/b2c-auth'
import IAuthService from '@/types/services/b2c-auth'
import EnterpriseAuthService from '@/services/enterprise/auth'
import { EnterpriseAuthServiceType } from '@/types/services/enterprise'

// State
export const state: UserState = {
    auth: false,
    authService: null,
    authToken: null,
    customer: null,
    enterprise: null,
    user: null,
    loading: {
        service: false,
        user: false,
        token: false
    }
}

// Namespace (https://vuex.vuejs.org/guide/modules.html#namespacing)
const namespaced: boolean = true

// Getters
export const getters: GetterTree<UserState, RootState> = {
    getAuthToken (state) { return state.authToken },
    getCustomerID (state) {
        if (state.customer) {
            return state.customer.id
        } else {
            return false
        }
    },
    getEnterprise (state) { return state.enterprise },
    getUserAuth (state) { return state.auth },
    getUserEmail (state) {
        if (state.user && state.user.idTokenClaims.emails) {
            return state.user.idTokenClaims.emails[0]
        } else {
            return false
        }
    },
    getUserName (state) {
        if (state.user) {
            return state.user.name
        } else {
            return false
        }
    },
    userLoading (state) { return state.loading }
}

// Mutations
export const mutations: MutationTree<UserState> = {
    CLEAR_USER (state, val) { state.user = val },
    SET_CUSTOMER (state, customer) { state.customer = { ...customer } },
    SET_AUTH_SERVICE (state, service) { state.authService = service },
    SET_AUTH_TOKEN (state, token) { state.authToken = token },
    SET_ENTERPRISE (state, enterprise) { state.enterprise = enterprise },
    SET_LOADING (state, { process, toggle }) {
        let loading = { ...state.loading }
        loading[process] = toggle
        state.loading = loading
    },
    SET_USER (state, user) { state.user = user },
    TOGGLE_USER_AUTH (state, auth) { state.auth = auth }
}

// Actions
export const actions: ActionTree<UserState, RootState> = {
    addUser ({ commit }, { newUser }) { commit('SET_USER', newUser) },
    clearUser ({ commit }) { commit('CLEAR_USER') },
    async checkB2CUser ({ commit, dispatch, state }) {
        let logError: actionErrorObject = { type: 'user', show: true, message: '', friendly: 'Please login to continue', script: 'store/user/index.ts' }

        if (state.authService) {
            const user = state.authService.getUser()
            commit('SET_USER', user)
            // If there is a user
            if (user) {
                // If the user has an AccuTerm claim
                if (user.idTokenClaims && user.idTokenClaims.hasOwnProperty('extension_AccuTermCustomerId')) {
                    commit('SET_LOADING', { process: 'user', toggle: true })
                    commit('TOGGLE_USER_AUTH', true)
                    await dispatch('setCustomer', { customerID: (user as AccuTermUser).idTokenClaims.extension_AccuTermCustomerId })
                } else {
                    logError.message = 'This microsoft account does not have access to AccuTerm.io'
                    logError.friendly = 'The current microsoft account does not have access to AccuTerm.io'
                    dispatch('errors/addError', { ...logError }, { root: true })
                }
            } else {
                // The user from msal is null.
                logError.show = false
                logError.message = 'No authorized user'
                dispatch('errors/addError', { ...logError }, { root: true })
            }
        } else {
            logError.message = 'Auth service is not working while fetching user'
            dispatch('errors/addError', { ...logError }, { root: true })
        }
    },
    checkEnterpriseUser ({ commit, dispatch, state }) {
        let logError: actionErrorObject = { type: 'user', show: true, message: '', friendly: 'We are unable to log you in', script: 'store/user/index.ts' }
        
        if (state.authService) {
            (state.authService as EnterpriseAuthServiceType).auth(state.enterprise!).then((data: any) => {
                commit('SET_LOADING', { process: 'user', toggle: true })
                commit('SET_LOADING', { process: 'token', toggle: true })
                if (data.valid) {
                    commit('TOGGLE_USER_AUTH', true)
                    fetch(data.server).then(() => {
                        commit('TOGGLE_USER_AUTH', true)
                        dispatch('profiles/setProfileService', data.server, { root: true })
                        dispatch('profiles/setProfiles', { authToken: state.authToken, profileID: data.profile }, { root: true })
                        dispatch('settings/setSettingsService', data.server, { root: true })
                        dispatch('settings/setSettings', null, { root: true })
                    }).catch(() => {
                        logError.message = 'Enterprise server not available'
                        dispatch('errors/addError', { ...logError }, { root: true })
                        commit('SET_ENTERPRISE', null)
                    })
                } else {
                    logError.message = 'Licensing API return not valid'
                    dispatch('errors/addError', { ...logError }, { root: true })
                }
            }).catch((error: any) => {
                console.log(error)
                logError.message = error
                dispatch('errors/addError', { ...logError }, { root: true })
                commit('SET_ENTERPRISE', null)
            })
        }
    },
    async login ({ dispatch, commit, state }) {
        let logError: actionErrorObject = { type: 'user', show: true, message: '', friendly: '', script: 'store/user/index.ts' }

        if (state.authService) {
            console.log('user login');                    
            if (state.authService.login) {
                let accountInfo: AccountInfo | null = null;
                await state.authService.login();
                // Kick off the other actions
                await dispatch('checkB2CUser');          
                await dispatch('setB2CAuthToken');
            }
        } else {
            logError.message = 'Auth service not working while logging in'
            logError.friendly = 'We are having trouble authenticating your account'
            dispatch('errors/addError', { ...logError }, { root: true })
        };
    },
    logout ({ dispatch, commit, state }) {
        let logError: actionErrorObject = { type: 'user', show: true, message: '', friendly: '', script: 'store/user/index.ts' }

        if (state.authService) {
            if (state.authService.logout) {
                state.authService.logout(state.user);
            }
            commit('SET_USER', null)
        } else {
            logError.message = 'Auth service not working while logging out'
            logError.friendly = 'We are having trouble authenticating your account'
            dispatch('errors/addError', { ...logError }, { root: true })
        };
    },
    setB2CAuthService ({ commit, dispatch }) {
        let authService: IAuthService = new B2CAuthService()
        commit('SET_AUTH_SERVICE', authService)        
        commit('SET_LOADING', { process: 'service', toggle: true })
    },
    setEnterpriseAuthService ({ commit, dispatch }, enterprise) {
        let logError: actionErrorObject = { type: 'user', show: true, message: '', friendly: '', script: 'store/user/index.ts' }

        let authService: EnterpriseAuthServiceType = new EnterpriseAuthService()
        commit('SET_ENTERPRISE', enterprise)
        commit('SET_AUTH_SERVICE', authService)
        commit('SET_LOADING', { process: 'service', toggle: true })
        dispatch('checkEnterpriseUser')
    },
    setCustomer ({ commit }, { customerID }) {
        let customer = { id: customerID }
        commit('SET_CUSTOMER', customer)
    },
    async setB2CAuthToken ({ commit, dispatch, state }) {
        let logError: actionErrorObject = { type: 'user', show: true, message: '', friendly: '', script: 'store/user/index.ts' }

        if (state.authService) {            
            try {
                const result = await state.authService.getToken();
                //console.log('setB2CAuthToken ' + (result as AuthenticationResult).accessToken);
                commit('SET_LOADING', { process: 'token', toggle: true });
                commit('SET_AUTH_TOKEN', (result as AuthenticationResult).accessToken);
                // Kick off the setProfiles action in profiles store
                dispatch('profiles/setProfiles', { authToken: (result as AuthenticationResult).accessToken }, { root: true });
            } catch (error) {
                logError.message = 'Unable to fetch auth token - ' + error
                logError.friendly = 'Your login session has expired. Please log in again.'
                dispatch('errors/addError', { ...logError }, { root: true })
            }
        } else {
            logError.message = 'Auth service not working while fetching auth token'
            logError.friendly = 'We are having trouble authenticating your account.'
            dispatch('errors/addError', { ...logError }, { root: true })
        }
    },
    toggleUserAuth ({ commit }, { auth }) { commit('TOGGLE_USER_AUTH', auth) }
}

export const user: Module<UserState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}
