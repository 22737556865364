



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// Types
import { GridItem } from '../../../types/enums'
import { UtilsType } from '../../../types/utils'

import Utils from '../../../utils';
@Component({
  name: 'GridEditComponent'
})

export default class GridEdit extends Vue {
  /** ******************************** Vue Props **********************************/

  @Prop({ default: () => (new GridItem()) }) public item!: GridItem;

  public utils: UtilsType = new Utils();
  public focusOnInput: boolean = false;
  /** ******************************** Vue Computed **********************************/

  get col (): number { return this.item.col }
  get row (): number { return this.item.row }

  // computed value for thr text input
  get value () { return this.item.value; }
  set value (val)  { this.item.value = val }

  get readonly (): boolean { return !this.item.editable }

  get id(): string { return this.item.id; }

  get maxLength(): string { return (this.item.columnInfo.maxLen !== 0) ? this.item.columnInfo.maxLen.toString() : ''; }

  get dataType(): string { return this.utils.getDataType(this.item.columnInfo.dataType) }

  get style (): Partial<CSSStyleDeclaration> {
    let css: { [k: string]: string } = {};
    let color: string;
    css.width = "inherit";
    css.height = "inherit";
    
    color = this.item.foreColor;

    css.backgroundColor = 'transparent';
    css.border = 'transparent';

    css.display = "flex"
    css.alignItems = "center"

    if (color) css.color = color;
    return css;
  }

  get bgColor(): Partial<CSSStyleDeclaration> {
    let css: { [k: string]: string } = {};
    let color: string;
    color = this.item.backColor;
    if (color) css.backgroundColor = color; 

    return css

  }

  /** ******************************** Vue Methods **********************************/
  focusInner(val: string) {
    if(!this.readonly) {
      switch(val) {
        case "Enter":
          if(this.focusOnInput){
            (this.$refs[this.id + "-input"] as HTMLInputElement).focus();
            this.focusOnInput = false;
          } else {
            (this.$refs[this.id + "-inner"] as HTMLDivElement).focus();
            this.focusOnInput = true;
          }
         break;
        case "Backspace":
          (this.$refs[this.id + "-input"] as HTMLInputElement).focus();
          this.focusOnInput = false;
          break;
        default:
          (this.$refs[this.id + "-inner"] as HTMLDivElement).focus();
          this.focusOnInput = true;
          break;
      }
    } else {
      (this.$refs[this.id + "-inner"] as HTMLDivElement).focus();
    }
  }

  keyHandler(e: KeyboardEvent) {
    e.stopPropagation();
    switch(e.key) {
      case "Enter": 
        if(this.focusOnInput){
          (this.$refs[this.id + "-input"] as HTMLInputElement).focus();
        } else {
          (this.$refs[this.id + "-inner"] as HTMLDivElement).focus();
        }
        this.focusOnInput = false; // Focus loops back twice. Need to stop focusing loop by setting one side to false
        
        this.$emit('updateValue', this.value, this.col, this.row);
        break;
      case "ArrowRight":
        if((this.$refs[this.id + "-input"] as HTMLInputElement).selectionEnd === this.value.toString().length) {
          (this.$refs[this.id + "-inner"] as HTMLDivElement).focus();
          this.focusOnInput = true;
          this.$emit('updateValue', this.value, this.col, this.row);
        }
        break;
      case "ArrowLeft":
        if((this.$refs[this.id + "-input"] as HTMLInputElement).selectionEnd === 0) {
          (this.$refs[this.id + "-inner"] as HTMLDivElement).focus();
          this.focusOnInput = true;
          this.$emit('updateValue', this.value, this.col, this.row);
        } 
        break;
      case "ArrowUp":
      case "ArrowDown":
        (this.$refs[this.id + "-inner"] as HTMLDivElement).focus();
        this.focusOnInput = true;
        this.$emit('updateValue', this.value, this.col, this.row);
        break;
    }
  }

  handlePaste(e: any) {
    this.$emit('handlePaste', this.col, this.row);
  }

  @Watch("focusOnInput") watchFocus (){
    //console.log(this.focusOnInput)
  }

}
