import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
// Types
import { RootState } from '@/types/store/'
import { ErrorsState, actionErrorObject } from '@/types/store/errors'

// State
export const state: ErrorsState = {
    errors: [],
    hasErrors: false,
    logErrors: true
}

// Namespace
const namespaced: boolean = true

// Getters
export const getters: GetterTree<ErrorsState, RootState> = {
    getErrors (state) { return state.errors },
    getHasErrors (state) { return state.hasErrors }
}

// Mutations
export const mutations: MutationTree<ErrorsState> = {
    ADD_ERROR (state, error) {
        let errors = [...state.errors]
        let newError = { ...error }
        errors.push(newError)
        state.errors = errors
    },
    DISMISS_ERROR (state, key) {
        let errors = [...state.errors]
        let newError = { ...errors[key] }
        newError.active = false
        errors[key] = newError
        state.errors = errors
    },
    SET_HAS_ERRORS (state) {
        let activeErrors = state.errors.filter((error) => error.active)
        state.hasErrors = !!(activeErrors && activeErrors.length)
    }
}

// Actions
export const actions: ActionTree<ErrorsState, RootState> = {
    addError ({ commit, dispatch, state }, actionError: actionErrorObject) {
        let error = {
            id: state.errors.length,
            type: actionError.type,
            active: true,
            show: actionError.show,
            friendlyMessage: actionError.friendly,
            message: actionError.message,
            script: actionError.script
        }

        if (state.logErrors) {
            console.log(actionError.type + ' error - ' + actionError.message + ' (' + actionError.script + ')')
        }

        commit('ADD_ERROR', error)
        commit('SET_HAS_ERRORS')
        // Take care of turning off the loading screen in the store
        // because the App.Vue is created and mounted afterwards (so it doesn't handle early errors)
        dispatch('toggleLoading', { loading: false }, { root: true })
    },
    dismissError ({ commit }, { key }) {
        commit('DISMISS_ERROR', key)
        commit('SET_HAS_ERRORS')
    }
}

export const errors: Module<ErrorsState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}
