


















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// Types
import { CommandProps, GUIEventCode } from './../../types/enums'
import { IGUIEvent } from './../../types'
import { CharacterUnderlineProps } from './../../types/components'
import { UtilsType } from './../../types/utils'
// Utilities 
import Utils from './../../utils/index';
// Components 
import Icon from './../layout/Icon.vue';
import CharacterUnderline from './../layout/CharacterUnderline.vue';

@Component({
  name: 'gxCommand',
  components: { CharacterUnderline, Icon }
})

export default class gxCommand extends Vue {
  /** ******************************** Vue Props **********************************/

    @Prop({ default: () => (new CommandProps()) }) private props!: CommandProps;

  /** ******************************** Vue Data **********************************/

  public utils: UtilsType = new Utils();
  public clickCount: number = 0
  public clickTimer: any = null

  /** ******************************** Vue Computed **********************************/
  get focused() { return this.$store.getters['guiGuis/getFocusedControl']; }
  get visible (): boolean { return this.props.gpVisible }
  
  get text() { return Array.isArray(this.props.gpCaption) ? this.props.gpCaption.join('\r\n') : this.props.gpCaption; }

  get showPictureButton () { return this.props.gpPicture !== '' }

  get pictureIsIcon () { return this.utils.getIcon(this.props.gpPicture) !== 'false' }

  get picture () { return this.utils.getImage(this.props.gpPicture) }

  get iconName () {
    if (this.showPictureButton) {
      return this.utils.getImage(this.props.gpPicture);
    } else {
      return this.props.gpIcon;
    }
  };

  get iconStyle(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = []
    let styleProps = {
      float: '',
      verticalAlign: '',
      width: '',
      height: '',
      cursor: 'pointer'
    };
    //icon size is small
    if (this.props.gpIconSize === 0) {  
        styleProps.width = '16px';
        styleProps.height = '16px';
    }
    //icon size is large
    if (this.props.gpIconSize === 1) {
        styleProps.width = '32px';
        styleProps.height = '32px';
    }
    if (this.props.gpIconAlign === 0) {
        styleProps.float = 'left';
    } if (this.props.gpIconAlign === 1) {
        styleProps.float = 'right';
    } if (this.props.gpIconAlign === 2) {
        styleProps.verticalAlign = 'middle' 
    } if (this.props.gpIconAlign === 3) {
        styleProps.verticalAlign = 'top' 
    } if (this.props.gpIconAlign === 4) {
        styleProps.verticalAlign = 'bottom'
    }
    style.push(styleProps);
    return style;
  }

  get pictureStyle(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = []
    let styleProps = {
      float: 'left',
      verticalAlign: '',
      width: '',
      height: '',
      cursor: 'pointer',
      zIndex: "",

    };
    if(this.props.gpBorder === 0) {
      styleProps.width = (this.props.gpWidth) + 'px';
      styleProps.height = (this.props.gpHeight) + 'px';
    } else {
    styleProps.width = (this.props.gpWidth - 6) + 'px';
    styleProps.height = (this.props.gpHeight - 6) + 'px';
    }

    style.push(styleProps);
    return style;
  }

  get style(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = []
    const positioning = this.utils.controlPositionCSS(this.props.gpTop, this.props.gpLeft)
    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight)
    const containerCSS = { 
      backgroundColor: this.props.gpBackColor,
      color: this.props.gpForeColor
    }
       
    style.push(size)
    style.push(positioning)
    style.push(containerCSS)

    return style
  }

  get cssProperties(): Partial<CSSStyleDeclaration>[] { 
      let style: Partial<CSSStyleDeclaration>[] = []
      
      const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight)
      const font = this.utils.controlFont(this.props)
      const border = this.utils.controlBorder(this.props)
      const alignment = this.utils.controlAlign(this.props)
      const colors: Partial<CSSStyleDeclaration> = {}

      if (this.props.gpStyle === 3){
        colors.backgroundColor = 'transparent';
        colors.borderWidth = '0';
        colors.borderStyle = 'transparent';
      } else {
          colors.backgroundColor = this.utils.getHexColor('3dlight'),
          colors.color = this.utils.getHexColor('MENUTEXT')
      }
        colors.color = this.props.gpForeColor
  
    style.push(size)
    style.push(font)
    style.push(border)
    style.push(alignment)
    style.push(colors)
    style.push({"display": "inline-block"})

    return style
  }

  get enabled() { return this.props.gpEnabled }

  get tabindex(): number { return this.props.tabindex }

  get buttonTable(): any {
    // Need to translate AT button key to browser button key 
    return {
      0: 1, // left click
      1: 3, // right click
      2: 2, // middle click
    }
  }

  get textPosition (): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = []
    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight)
    const alignment = this.utils.controlAlign(this.props)
    const font = this.utils.controlFont(this.props)
    let position: any = {
      position: 'absolute',
      zIndex: 20,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
    style.push(position, size, alignment, font);
    return style
  }

  get cuProps(): CharacterUnderlineProps {
    return { app: this.props.app!.id, form: this.props.form!.id, control: this.props.id, type: this.props.type }
  }

  get buttonHint() { return Array.isArray(this.props.gpHint) ? this.props.gpHint.join('\r\n') : this.props.gpHint; };

  /** ******************************** Vue Methods **********************************/

  leftClickHandler(e: MouseEvent) {
      e.stopPropagation()
      if (this.props.gpEventMask & GUIEventCode.geClick) {
        // send this event to the server
        const guiEvent: IGUIEvent = { event: GUIEventCode.geClick, id: this.props.id }
        this.$store.dispatch('guiGuis/addEvent', guiEvent);
      }
  }

  rightClickHandler(e: MouseEvent) {
    e.stopPropagation();

    if (this.props.gpEventMask & GUIEventCode.geContext) {
      // send this event to the server
      const guiEvent: IGUIEvent = { event: GUIEventCode.geContext, id: this.props.id }
      this.$store.dispatch('guiGuis/addEvent', guiEvent);
    }

    this.$store.dispatch('guiGuis/updateMousePosition', this.utils.getMousePositionRelativeToParent(e, this.props.form!.id))
  }

  focus() {
    if (this.focused === this.props.id && this.enabled && this.visible) {
      try {
        const elem: any = this.$refs[this.props.id];
        (elem as HTMLInputElement).focus();
console.log('Command Focus Me ' + this.props.id);
      } catch(e) {
console.log('Command Focus Me error: ' + e);
      }
    }
  }

  activateHandler(e: any) {
    let prevControlID = (e.relatedTarget && this.utils.getRelatedTargetID(e.relatedTarget, '', 'controlID')) || null;
    if (!prevControlID) {
      // handle the deferred deactivate event
      prevControlID = this.utils.handleDeferredCtlDeactivate(this.props.id);
    }
    if (prevControlID !== this.props.id) {
console.log('Command activate ' + this.props.id + ' from ' + prevControlID);
      if (this.props.gpEventMask & GUIEventCode.geActivate) {
        const activateEvent: IGUIEvent = { event: GUIEventCode.geActivate, id: this.props.id, args: [prevControlID] }
        this.$store.dispatch('guiGuis/addEvent', activateEvent);
      }
    } else console.log('   skipping Command activate ' + this.props.id + ' - self')
    // Set focused in store and in parent form
    this.$store.dispatch('guiGuis/setFocused', { id: this.props.id })
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.form!.id, property: 'focused', value: this.props.id })
  }

  deactivateHandler(e: any) {
    const nextControlID = (e.relatedTarget && this.utils.getRelatedTargetID(e.relatedTarget, '', 'controlID')) || null;
    if (nextControlID) {
      this.handleDeactivateEvents(nextControlID);
    } else {
      this.$store.dispatch('guiGuis/setDeferredCtlDeactivate', this.handleDeactivateEvents.bind(this));
console.log('   deferring Command deactivate ' + this.props.id + ' related = null');
    }
  }

  handleDeactivateEvents(nextControlID: string): string {
    if (nextControlID !== this.props.id) {
console.log('Command deactivate ' + this.props.id + ' to ' + nextControlID);
      if (this.props.gpEventMask & GUIEventCode.geDeactivate) {
        const deactivateEvent: IGUIEvent = { event: GUIEventCode.geDeactivate, id: this.props.id, args: [nextControlID] }
        this.$store.dispatch('guiGuis/addEvent', deactivateEvent);
      }
    } else console.log('   skipping Command deactivate ' + this.props.id + ' - self')
    return this.props.id;
  }

  mounted() {
    this.$nextTick(() => { this.focus(); });
  }

  statusHandler(e: MouseEvent) {
    if(this.props.gpEventMask & GUIEventCode.geStatus) {
        const buttonType = this.buttonTable[e.button];
        if(buttonType === 3 && (this.props.gpEventMask & GUIEventCode.geContext)) {
          this.rightClickHandler(e);
        } else {
          const shiftState = e.ctrlKey && e.shiftKey? 3: e.shiftKey? 2: e.ctrlKey? 1: 0;
          const buttonState = e.type === "mousedown"? 1 : 0;
          const statusEvent: IGUIEvent = { event: GUIEventCode.geStatus, id: this.props.id, args: [[buttonType, shiftState, buttonState, e.offsetX, e.offsetY]] }
          this.$store.dispatch('guiGuis/addEvent', statusEvent);
        }
    }
  }

/** ******************************** Vue Watch and Emit Events! **********************************/

  @Watch('focused') passRequest () { 
    this.focus()
  }  

}
