/*
 * AccuTerm Mobile - terminal colors utility class
 *
 * Copyright 2015 Zumasys, Inc.
 */

 ////////////////////////////////////////////////////////////
// Import Dependencies!
////////////////////////////////////////////////////////////

import { DEBUG } from './globals.js';
 
/* jshint expr: true, laxcomma: true, laxbreak: true */
/* global DEBUG: false */
/* exported TermColors */

DEBUG&&console.log('top of termcolors.js');

var TermColors = (function() {

	var matchColorCache = {};

	// The following xxxxColors arrays are indexed by ANSI color number.
	
	// Colors 0-15
	function tangoColors() {
		return [
		// dark:
		'#2e3436',
		'#cc0000',
		'#4e9a06',
		'#c4a000',
		'#3465a4',
		'#75507b',
		'#06989a',
		'#d3d7cf',
		// bright:
		'#555753',
		'#ef2929',
		'#8ae234',
		'#fce94f',
		'#729fcf',
		'#ad7fa8',
		'#34e2e2',
		'#eeeeec'
		];		
	}

	function xtermColors() {
		return [
		// dark:
		'#000000', // black
		'#cd0000', // red3
		'#00cd00', // green3
		'#cdcd00', // yellow3
		'#0000ee', // blue2
		'#cd00cd', // magenta3
		'#00cdcd', // cyan3
		'#e5e5e5', // gray90
		// bright:
		'#7f7f7f', // gray50
		'#ff0000', // red
		'#00ff00', // green
		'#ffff00', // yellow
		'#5c5cff', // rgb:5c/5c/ff
		'#ff00ff', // magenta
		'#00ffff', // cyan
		'#ffffff'  // white
		];
	}

	function accutermColors() {
		return [
		// dark:
		'#000000', // black
		'#620f0f', // red
		'#0f620f', // green
		'#62620f', // olive
		'#0f0f62', // blue
		'#620f62', // magenta
		'#0f6262', // cyan
		'#e6e6e6', // gray
		// bright:
		'#686868', // gray
		'#e86c6c', // red
		'#6ce86c', // green
		'#e8e86c', // yellow
		'#6c6ce8', // blue
		'#e86ce8', // magenta
		'#6ce8e8', // cyan
		'#ffffff'  // white
		];
	}

	function legacyColors() {
		return [
		// dark:
		'#000000', // black
		'#800000', // red
		'#008000', // green
		'#808000', // olive
		'#000080', // blue
		'#800080', // magenta
		'#008080', // cyan
		'#c0c0c0', // gray
		// bright:
		'#9f9f9f', // gray
		'#ff0000', // red
		'#00ff00', // green
		'#ffff00', // yellow
		'#0000ff', // blue
		'#ff00ff', // magenta
		'#00ffff', // cyan
		'#ffffff'  // white
		];
	}
	
	function systemColors() {
		return [
		// dark:
		'#000000', // black
		'#620f0f', // red
		'#0f620f', // green
		'#ffffff', // white (WIN7 HIGHLIGHTTEXT)
		'#3399ff', // blue (WIN7 HIGHLIGHT)
		'#620f62', // magenta
		'#0f6262', // cyan3
		'#f0f0f0', // gray (WIN7 3DFACE)
		// bright:
		'#a0a0a0', // gray (WIN7 3DSHADOW)
		'#e86c6c', // red
		'#6ce86c', // green
		'#e8e86c', // yellow
		'#6c6ce8', // blue
		'#e86ce8', // magenta
		'#6ce8e8', // cyan
		'#ffffff'  // white
		];
	}

	function vsdarkColors() {
		return [
		// dark:
		'#1c1c1c', // black
		'#620f0f', // red
		'#0f620f', // green
		'#62620f', // olive
		'#0f0f62', // blue
		'#620f62', // magenta
		'#0f6262', // cyan
		'#999999', // gray
		// bright:
		'#333333', // gray
		'#eb986c', // red
		'#35a155', // green
		'#e8e86c', // yellow
		'#6c6ce8', // blue
		'#e86ce8', // magenta
		'#6ce8e8', // cyan
		'#f0f0f0'  // white
		];
	}

    function windowsColors() {
        return [
                "#C8C8C8",
                "#000000",
                "#99B4D1",
                "#BFCDDB",
                "#F0F0F0",
                "#FFFFFF",
                "#646464",
                "#000000",
                "#000000",
                "#000000",
                "#B4B4B4",
                "#F4F7FC",
                "#ABABAB",
                "#3399FF",
                "#FFFFFF",
                "#F0F0F0",
                "#A0A0A0",
                "#6D6D6D",
                "#000000",
                "#434E54",
                "#FFFFFF",
                "#696969",
                "#E3E3E3",
                "#000000",
                "#FFFFE1",
                "#000000",
                "#0066CC",
                "#B9D1EA",
                "#D7E4F2",
                "#3399FF",
                "#F0F0F0",
                "#000000"
        ];
    }

	// The color values in the following arrays refer to the ANSI colors, not AccuTerm colors.
	// The xxxxAttrColors arrays are indexed by term.js attribute number, not AccuTerm attribute
	// (see xlateColor table). The color array format is [bg,fg].
	
	function accutermAttrColors() {
		return [
			[7,0], [7,4], [11,0], [10,15], [7,1], [7,5], [11,1], [10,9], [15,0], [0,15], [4,11], [15,2], [15,1], [1,15], [4,9], [15,5], 
			[7,7], [7,7], [11,11], [10,10], [7,7], [7,7], [11,11], [10,10], [15,15], [0,0], [4,4], [15,15], [15,15], [1,1], [4,4], [15,15], 
			[7,8], [7,0], [11,8], [11,0], [7,9], [7,1], [11,9], [11,1], [15,8], [15,0], [12,11], [4,11], [9,8], [15,1], [12,1], [4,9], 
			[7,7], [7,7], [11,11], [11,11], [7,7], [7,7], [11,11], [11,11], [15,15], [15,15], [12,12], [4,4], [9,9], [15,15], [12,12], [4,4]
		];		
	}
	
	function legacyAttrColors() {
		return [
			[4,11], [12,14], [4,9], [12,9], [4,13], [12,13], [4,14], [12,13], [8,11], [7,15], [8,1], [7,1], [8,13], [7,11], [8,5], [7,5],
			[4,4], [12,12], [4,4], [12,12], [4,4], [12,12], [4,4], [12,12], [8,8], [7,7], [8,8], [7,7], [8,8], [7,7], [8,8], [7,7],
			[4,14], [4,11], [4,9], [4,9], [4,10], [4,13], [4,7], [4,14], [8,14], [8,11], [8,14], [8,1], [8,1], [8,13], [8,4], [8,5],
			[4,4], [4,4], [4,4], [4,4], [4,4], [4,4], [4,4], [4,4], [8,8], [8,8], [8,8], [8,8], [8,8], [8,8], [8,8], [8,8]
		];
	}
	
	function systemAttrColors() {
		return [
			[7,0], [7,8], [7,0], [8,0], [7,0], [7,8], [7,0], [8,0], [15,0], [7,0], [4,3], [7,8], [15,0], [7,0], [4,3], [7,8],
			[7,7], [7,7], [7,7], [8,8], [7,7], [7,7], [7,7], [8,8], [15,15], [7,7], [4,4], [7,7], [15,15], [7,7], [4,4], [7,7],
			[7,8], [7,8], [7,8], [8,0], [7,8], [7,8], [7,8], [8,0], [7,0], [7,0], [7,8], [7,8], [7,0], [7,0], [7,8], [7,8],
			[7,7], [7,7], [7,7], [8,8], [7,7], [7,7], [7,7], [8,8], [7,7], [7,7], [7,7], [7,7], [7,7], [7,7], [7,7], [7,7]
		];
	}
	
	function vsdarkAttrColors() {
		return [
			[0,15], [0,10], [0,15], [0,10], [0,15], [0,10], [0,15], [0,10], [8,7], [8,12], [8,7], [8,12], [8,7], [8,12], [8,7], [8,12], 
			[0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [0,0], [8,8], [8,8], [8,8], [8,8], [8,8], [8,8], [8,8], [8,8], 
			[0,10], [7,0], [0,10], [11,0], [0,10], [7,1], [0,10], [11,1], [8,12], [15,0], [8,12], [4,11], [8,12], [15,1], [8,12], [4,9], 
			[0,0], [7,7], [0,0], [11,11], [0,0], [7,7], [0,0], [11,11], [8,8], [0,0], [8,8], [4,4], [8,8], [15,15], [8,8], [4,4]
		];
	}
	// The xlateColor array translate AccuTerm colors (QBCOLOR) to ANSI colors & vice versa (translation is symetric).
	function xlateColor() {
		return [0, 4, 2, 6, 1, 5, 3, 7, 8, 12, 10, 14, 9, 13, 11, 15];
	}
	
	// The xlateAttr array maps an AccuTerm attribute number to a term.js attribute number as defined in term.js rendering engine.
//  +----------+---------+---------+---------+---------+---------+---------+---------+---------+
//  | attr bit |    1    |    2    |    4    |    8    |    16   |   32    |   64    |  128    |
//  +----------+---------+---------+---------+---------+---------+---------+---------+---------+
//  | AccuTerm |  blank  |  blink  | reverse |  under  |   dim   |   bold  |         |         |
//  +----------+---------+---------+---------+---------+---------+---------+---------+---------+
//  |  Term.js |  bold   |  under  |  blink  | reverse |  blank  |   dim   |         |         |
//  +----------+---------+---------+---------+---------+---------+---------+---------+---------+
	function xlateAttr() { // translate AccuTerm attribute number to term.js attribute number
		return [0, 16, 4, 20, 8, 24, 12, 28, 2, 18, 6, 22, 10, 26, 14, 30, 32, 48, 36, 52, 40, 56, 44, 60, 34, 50, 38, 54, 42, 58, 46, 62, 
				1, 17, 5, 21, 9, 25, 13, 29, 3, 19, 7, 23, 11, 27, 15, 31, 33, 49, 37, 53, 41, 57, 45, 61, 35, 51, 39, 55, 43, 59, 47, 63];
	}
	
	function xlateAttrRev() { // translate term.js attribute number to AccuTerm attribute number
		return [0, 32, 8, 40, 2, 34, 10, 42, 4, 36, 12, 44, 6, 38, 14, 46, 1, 33, 9, 41, 3, 35, 11, 43, 5, 37, 13, 45, 7, 39, 15, 47, 16,
                48, 24, 56, 18, 50, 26, 58, 20, 52, 28, 60, 22, 54, 30, 62, 17, 49, 25, 57, 19, 51, 27, 59, 21, 53, 29, 61, 23, 55, 31, 63];
	}

	function extendColors(colors16) {
		
		// input: colors 0-15
		// output: colors 0-15 + 16-255
		// Much thanks to TooTallNate for writing this.
		var colors = colors16.slice()
			, r = [0x00, 0x5f, 0x87, 0xaf, 0xd7, 0xff]
			, i;

		// 16-231
		i = 0;
		for (; i < 216; i++) {
			out(r[(i / 36) % 6 | 0], r[(i / 6) % 6 | 0], r[i % 6]);
		}

		// 232-255 (grey)
		i = 0;
		for (; i < 24; i++) {
			r = 8 + i * 10;
			out(r, r, r);
		}

		function out(r, g, b) {
			colors.push('#' + hex(r) + hex(g) + hex(b));
		}

		function hex(c) {
			c = c.toString(16);
			return c.length < 2 ? '0' + c : c;
		}

		return colors;
	}

	// synthesize monochrome attributes color mapping
	function synthesizeMonoAttrs(colors, darktext, iblack, iwhite, idkgray, iltgray) {
   
		var i
		  , attr
		  , base_fg
		  , base_bg
		  , new_fg
		  , new_bg
		  , color
		  , ix = []
		  , out = [];
         

		// Map actual color indicies into black & white scale. This way
		// we can use the same logic to synthesize the green screen
		// attributes, using green color indicies.
   
		ix[0] = iblack;
		ix[1] = iltgray;
		ix[2] = idkgray;
		ix[3] = iwhite;
   
		if (darktext) {
		   base_bg = 1; // background = light gray
		   base_fg = 0; // text = black
		} else {
		   base_bg = 0; // background = black
		   base_fg = 1; // text = light gray
		}
         
		for (attr = 0; attr < 64; attr++) {
			switch (attr & 41) { // mask: dim, reverse, bold (ignore underline, blink & invisible)
				case 8: // reverse
				   new_fg = 1 & base_bg;
				   new_bg = 1 & base_fg;
				   break;
				case 32: // dim
				   if (darktext) {
					  new_fg = 2 | base_fg;
					  new_bg = 1 & base_bg;
				   } else {
					  new_fg = 2 | base_bg;
					  new_bg = 1 & base_bg;
				   }
				   break;
				case 1: // bold
				   if (darktext) {
					  new_fg = 1 & base_fg;
					  new_bg = 2 | base_bg;
				   } else {
					  new_fg = 2 | base_fg;
					  new_bg = 1 & base_bg;
				   }
				   break;
				case 40: // dim+reverse
				   if (darktext) {
					  new_fg = 2 | base_fg;
					  new_bg = 1 & base_fg;
				   } else {
					  new_fg = 1 & base_bg;
					  new_bg = 2 | base_bg;
				   }
				   break;
				case 9: // bold+reverse
				   if (darktext) {
					  new_fg = 2 | base_bg;
					  new_bg = 1 & base_fg;
				   } else {
					  new_fg = 1 & base_bg;
					  new_bg = 2 | base_fg;
				   }
				   break;
				case 33: // dim+bold
				   if (darktext) {
					  new_fg = 1 & base_fg;
					  new_bg = 2 | base_bg;
				   } else {
					  new_fg = 2 | base_fg;
					  new_bg = 1 & base_bg;
				   }
				   break;
				case 41: // dim+bold+reverse
				   if (darktext) {
					  new_fg = 2 | base_bg;
					  new_bg = 1 & base_fg;
				   } else {
					  new_fg = 1 & base_bg;
					  new_bg = 2 | base_fg;
				   }
				   break;
			   default: // normal
				   new_fg = 1 & base_fg;
				   new_bg = 1 & base_bg;
			}
			if (attr & 16) {
				new_fg = new_bg;
			}
			out.push([ ix[new_bg], ix[new_fg] ]);
		}
		return out;		
	}
	
	// http://stackoverflow.com/questions/1633828
	function matchColorDistance(r1, g1, b1, r2, g2, b2) {
		return Math.pow(30 * (r1 - r2), 2)
		    + Math.pow(59 * (g1 - g2), 2)
		    + Math.pow(11 * (b1 - b2), 2);
	}
	
	
	return {
		
		ColorPalette: function(theTheme, useBold, bg, fg) {
			var rtn;
			matchColorCache = {};
			if (typeof(theTheme) === 'string') {
				switch (theTheme) {
					case 'legacy':
						rtn = legacyColors();
						break;
					case 'system':
					case 'modern':
					case 'classic':
						rtn = systemColors();
						break;
					case 'wob':
						rtn = accutermColors();
						rtn[7] = useBold ? '#c0c0c0' : '#f0f0f0';
						rtn[8] = useBold ? '#686868' : '#a0a0a0';
						break;
					case 'bow':
						rtn = accutermColors();
						rtn[7] = useBold ? '#d8d8d8' : '#f8f8f8';
						rtn[8] = useBold ? '#686868' : '#808080';
						break;
					case 'green':
					case 'green-reverse':
						rtn = accutermColors();
						if (useBold) {
							rtn[3] = '#00b000';
							rtn[10] = '#00ff00';
						} else {
							rtn[2] = '#009c00';
							rtn[3] = '#00ff00';
						}
						break;
					case 'vscode':
						rtn = vsdarkColors();
						break;
					default:
						rtn = accutermColors();
				}
			} else if (Array.isArray(theTheme)) {
				rtn = theTheme.slice(0);
			}
			rtn = extendColors(rtn); // synthesize colors 16-255
			fg = (typeof(fg) === 'undefined') ? rtn[0] : rtn[fg];
			bg = (typeof(bg) === 'undefined') ? rtn[7] : rtn[bg];
			rtn[256] = bg; // screen background
			rtn[257] = fg; // screen foreground
			return rtn;
		},
		
		AttrColors: function(theTheme) {
			var rtn;
			if (typeof(theTheme) === 'string') {
				switch (theTheme) {
					case 'legacy':
						rtn = legacyAttrColors();
						break;
					case 'system':
					case 'modern':
					case 'classic':
						rtn = systemAttrColors();
						break;
					case 'wob':
						rtn = synthesizeMonoAttrs(accutermAttrColors(), false, 0, 15, 8, 7);
						break;
					case 'bow':
						rtn = synthesizeMonoAttrs(accutermAttrColors(), true, 0, 15, 8, 7);
						break;
					case 'green':
						rtn = synthesizeMonoAttrs(accutermAttrColors(), false, 0, 10, 2, 3);
						break;
					case 'green-reverse':
						rtn = synthesizeMonoAttrs(accutermAttrColors(), true, 0, 10, 2, 3);
						break;
					case 'vscode':
						rtn = vsdarkAttrColors();
						break;
					default:
						rtn = accutermAttrColors();
				}
			} else if (Array.isArray(theTheme)) {
				rtn = theTheme.slice(0);
			}
			return rtn;
		},
				
		matchColor: function(colors, r1, g1, b1) {
			var hash = (r1 << 16) | (g1 << 8) | b1;
			/* jshint eqnull:true */
			if (matchColorCache[hash] != null) {
				return matchColorCache[hash];
			}

			var ldiff = Infinity
			  , li = -1
			  , i = 0
			  , r2
			  , g2
			  , b2
			  , diff;

			for (; i < colors.length; i++) {
				r2 = parseInt(colors[i].substring(1, 3), 16);
				g2 = parseInt(colors[i].substring(3, 5), 16);
			    b2 = parseInt(colors[i].substring(5, 7), 16);
			    diff = matchColorDistance(r1, g1, b1, r2, g2, b2);
			    if (diff === 0) {
					li = i;
					break;
			    }
			    if (diff < ldiff) {
					ldiff = diff;
					li = i;
				}
			}
			return (matchColorCache[hash] = li);
		},
		
		// translate AccuTerm color index to term.jS / ANSI color index
		XlateColor: function(xc) {
			return xlateColor()[xc];
		},
		
		// translate AccuTerm attribute number to term.js attribute number
		XlateAttr: function(xa) {
			return xlateAttr()[xa];
		},

		// translate Windows RGB color integer to CSS RGB hex color
		XlateRGB: function(bgr) {
            if (bgr & 0x80000000)
                return windowsColors()[bgr & 0x1f]; // TODO: leave windows colors alone until use (preserve windows color# in profile)
			var r = bgr & 0xff;
			var g = (bgr >> 8) & 0xff;
			var b = (bgr >> 16) & 0xff;
			return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
		},

		// translate CSS RGB hex color to Windows RGB color integer
		XlateBGR: function(rgb) {
			var i = windowsColors().findIndex(function(xc) {return (xc === rgb)});
			if (i !== -1)
				return 0x80000000 + i; // return the Windows color
			rgb = parseInt(rgb.slice(1), 16);
			return ((rgb & 0xff) << 16) | (rgb & 0x00ff00) | ((rgb >> 16) & 0xff);
		}
		
	};
	
})();


////////////////////////////////////////////////////////////
// Exports!
////////////////////////////////////////////////////////////

export { TermColors };
