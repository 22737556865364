// Javascript
import Vue from 'vue'
import App from './App.vue'
// Router
import index from '@/router/index'
// Store
import store from '@/store'
// Style
// Bootstrap
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircle as fasCircle, faRedo as fasRedo, faServer as fasServer, faTerminal as fasTerminal, faUser as fasUser } from '@fortawesome/pro-solid-svg-icons'
import { faCheck as farCheck } from '@fortawesome/pro-regular-svg-icons'
import { faExternalLink as falExternalLink, faPlus as falPlus, faTimes as falTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Import AccuTerm GUI
import AccuTermGUI from '@/vendor/accuterm-gui/src/install'
// Import AccuTerm js that is 'exlcuded' from build
import BasicObject from '@/vendor/accuterm/client/js-exclude/wwwbasic'
import { BasicExtension } from '@/vendor/accuterm/client/js-exclude/basicext'

// Add AccuTerm js to the global window object
(<any>window).Basic = BasicObject.Basic;
(<any>window).BasicExtension = BasicExtension

// Add font awesome icons to library
library.add(farCheck, fasCircle, falExternalLink, falPlus, fasRedo, fasServer, fasTerminal, falTimes, fasUser)
// Inject icons into compontents
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Inject Styles
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
// Injecting AccuTerm GUI
Vue.use(AccuTermGUI, { store })

Vue.config.productionTip = false

// Add B2C auth bypass for enterprise
const query: string = window.location.search
let enterprise: string | null = store.getters['user/getEnterprise']

if (query !== '') {
    // https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
    const params = new URLSearchParams(query)

    if (params.has('enterprise')) {
        enterprise = params.get('enterprise')!
    }
}

// User Auth Service & Store
// Profile Service & Store
if (enterprise) {
    store.dispatch('user/setEnterpriseAuthService', enterprise)
} else {
    store.dispatch('user/setB2CAuthService')
    store.dispatch('profiles/setProfileService')
}
// Terminal Service & Store
store.dispatch('terminal/setTerminalService')
// Router Const
const router = () => { return index }

new Vue({
    store: store,
    router: router(),
    render: h => h(App)
}).$mount('#accuterm-io')
