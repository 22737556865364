






















import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'ThankYouComponent'
})

export default class ThankYou extends Vue {
/** ******************************** Vue Computed **********************************/

    get userAuth () { return this.$store.getters['user/getUserAuth'] }
}
