/*
 * Terminal utility functions
 *
 * Copyright 2019 Zumasys, Inc.
 */

////////////////////////////////////////////////////////////
// Import Dependencies!
////////////////////////////////////////////////////////////

import { DEBUG } from './globals.js';

/* global DEBUG: false */
/* exported TermUtil */
/* jshint expr: true */

DEBUG&&console.log('top of termutil.js');

var TermUtil = (function() {
	
	var term_type_name = {
		display: {
			TTY: 'TTY',
			WYSE50: 'Wyse 50',
			WYSE60: 'Wyse 60',
			ADDSVP: 'ADDS Viewpoint',
			VPA2E: 'Viewpoint Enhanced',
			VT100: 'VT100',
			VT220: 'VT220',
			VT320: 'VT320',
			VT420: 'VT420',
			LINUX: 'Linux Console',
			XTERM: 'Xterm',
			PCMON: 'Pick PC Monitor'
			},
		default: {
			TTY: 'dumb',
			WYSE50: 'wyse50',
			WYSE60: 'wyse60',
			ADDSVP: 'viewpoint',
			VPA2E: 'viewpoint',
			VT100: 'vt100',
			VT220: 'vt220',
			VT320: 'vt320',
			VT420: 'vt420',
			LINUX: 'linux',
			XTERM: 'xterm',
			PCMON: 'pcmon'
			},
		QM: {
			TTY: 'dumb',
			WYSE50: 'wyse50-at',
			WYSE60: 'wyse60-at',
			ADDSVP: 'viewpoint-at',
			VPA2E: 'adds4000-at',
			VT100: 'vt100-at',
			VT220: 'vt220-at',
			VT320: 'vt320-at',
			VT420: 'vt420-at',
			LINUX: 'linux',
			XTERM: 'xterm',
			PCMON: 'pcmon-at'
		}
	};
	
	var host_type_name = {
		display: {			
			DEFAULT: 'default',
			LINUX: 'Linux',
			UNIX: 'Unix',
			AIX: 'IBM AIX',
			D3NT: 'D3/Windows',
			QM: 'QM/Windows',			
			UVNT: 'UniVerse/Windows',
			MVBASE: 'mvBase',
			OASYS: 'OASYS',
			CTO: 'OpenInsight CTO',
			SCO: 'SCO Unix',
			SOLARIS: 'Solaris'
		},
		options: {
			D3NT: 'B',
			MVBASE: 'B',
			OASYS: 'B',
			CTO: 'BA',
			QM: 'B',			
			SCO: 'B',
			UVNT: 'BA'
		},
		TELNET: {
			DEFAULT: '23',
			QM: '4242'
		},
		SSH: {
			DEFAULT: '22'
		},
		WEBSOCKET: {
			DEFAULT: '443'
		}
	};
	
	var charset_name = {
		ansi: {
			DEC: "DEC Multinational",
			LATIN1: "Latin-1",
			IBMPC: "IBM-PC",
			UTF8: "UTF-8"
		},
		wyse: {
			NATIVE: "Wyse Native",
			LATIN1: "Latin-1",
			IBMPC: "IBM-PC",
			UTF8: "UTF-8"
		},
		default: {
			NATIVE: "Terminal Native",
			LATIN1: "Latin-1",
			IBMPC: "IBM-PC",
			UTF8: "UTF-8"
		}
	};
	
	var style = {
		themes: [
			{ value: 'default', title: 'Default Colors' },
			{ value: 'legacy', title: 'Legacy Colors' },
			{ value: 'wob', title: 'White on Black' },
			{ value: 'system', title: 'System Colors' },
			{ value: 'bow', title: 'Black on White' },
			{ value: 'classic', title: 'Classic Windows' },
			{ value: 'green', title: 'Green Screen' },
			{ value: 'modern', title: 'Modern Windows' },
			{ value: 'green-reverse', title: 'Reverse Green Screen' },
			{ value: 'vscode', title: 'Modern Code' }
		]
	}

	return {
		
		TermTypes: function() {
			DEBUG&&console.log('term types object');
			DEBUG&&console.log(term_type_name.display);
			return term_type_name.display;
		},
		
		HostTypes: function() {
			return host_type_name.display;
		},
		
		// translate term type ID to host term type
		HostTermType: function(termType, hostType, hostTermType) {
			if(hostTermType) {
				return hostTermType;
			}
			var names = term_type_name[hostType] || term_type_name['default'];
			return names[termType] || termType || 'tty';
		},
		
		IsDefaultHostTermType: function(termType, hostType, hostTermType) {
			return (!hostTermType || TermUtil.HostTermType(termType, hostType, '') === hostTermType);
		},
		
		// translate term type ID to host term type
		DisplayTermType: function(termType) {
			var names = term_type_name['display'];
			return names[termType] || termType || 'tty';
		},
		
		// translate host type ID to host port (pass 'TELNET' or 'SSH' for connType)
		/* jshint eqeqeq: false */
		HostPort: function(hostType, connType, hostPort, product) {
			if(hostPort && hostPort != 22 && hostPort != 23 && hostPort != 4242 && hostPort != 80 && hostPort != 443) {
				return hostPort;
			}
			var defaultConnType = (product && product.toUpperCase() === 'WEB') ? 'WEBSOCKET' : 'TELNET';
			var defaultHostPort = (product && product.toUpperCase() === 'WEB') ? '443' : '23';
			var ports = host_type_name[connType] || host_type_name[defaultConnType];
			return ports[hostType] || ports['DEFAULT'] || defaultHostPort;
		},
		/* jshint eqeqeq: true */
		
		// translate host type ID to default connection options
		HostOptions: function(hostType, connectionOptions) {
			if(connectionOptions) {
				return connectionOptions;
			}
			var opts = host_type_name['options'];
			return opts[hostType] || '';			
		},
		
		// return character sets appropriate for specific terminal type
		CharacterSets: function(termtype) {
			var cst = TermUtil.isAnsi(termtype) ? 'ansi' : 'wyse'; // TODO: add PC Console character sets
			return charset_name[cst] || charset_name['default'];
		},
		
		// return duplex options
		DuplexOptions: function() {
			return {
				FULL: 'Remote echo (full duplex)',
				HALF: 'Local echo (half duplex)',
				LOCAL: 'Loopback (local mode)'
			};
		},
		
		// return clipboard options
		PasteLineOptions: function() {
			return {
				NONE: 'None',
				CR: 'CR',
				LF: 'LF',
				CRLF: 'CR+LF',
				TAB: 'Tab',
				USER: 'Custom'				
			};
		},
		
		PasteTextOptions: function() {
			return {
				NONE: 'None',
				EOF: 'EOF (Ctrl+Z)',
				USER: 'Custom'
			};
		},
		
		// extract user name from host (username@server.com)
		GetHostName: function(host) {
			var i;
			if(host && ((i = host.indexOf('@')) > 0)) {
				return host.substring(i + 1);
			}
			return host;
		},
		
		// extract user name from host (username@server.com)
		GetUserName: function(host) {
			var i;
			if(host && ((i = host.indexOf('@')) > 0)) {
				return host.substring(0, i);
			}
			return '';
		},
		
		// return font-familiy style
		GetFontFamily: function(fontName) {
			var ret;
			if (fontName.toLowerCase() === 'dejavu sans mono') {
				ret = '"DejaVu Sans Mono", "DejaVu LGC Sans Mono"';
			} else if (fontName.toLowerCase() === 'dejavu lgc sans mono') {
				ret = '"DejaVu LGC Sans Mono", "DejaVu Sans Mono"';
			} else if (fontName.length > 0) {
				ret = '"' + fontName + '"';
			}
			if (ret) ret += ', ';
			ret += '"Liberation Mono", monospace';
			return ret;
		},
		
		GetThemeStyleTitle: function(themeStyle) {
			var theme = style.themes.find(theme => theme.value === themeStyle);

			return theme.title || 'Unknown Theme';
		},

		ThemeStyles: function() {
			return style.themes;
		},

		isAnsi: function(termtype) {
			return (termtype === 'VT100' || termtype === 'VT220' || termtype === 'VT320' || termtype === 'VT420' || termtype === 'LINUX' || termtype === 'linux' || termtype === 'XTERM' || termtype === 'xterm');
		},
		
		XlateCharSet: function(charSet, termtype) {
			switch(charSet.toUpperCase()) {
				case 'TERMINAL': return TermUtil.isAnsi(termtype) ? 'DEC' : 'NATIVE'; break;
				case 'ALTERNATE': return 'LATIN1'; break;
				case 'WINDOWS':
				case 'CP1252':
				case 'CP28591':
					return 'LATIN1';
					break;
				case 'CP437':
				case 'CP850':
				case 'CP858':
					return 'IBMPC';
					break;
			}
			return ''; // punt - Charmap() will assume ASCII
		}
		
	};
})();

////////////////////////////////////////////////////////////
// Exports!
////////////////////////////////////////////////////////////

export { TermUtil };
