






import { Component, Prop, Vue } from 'vue-property-decorator';
import { GridProps } from '../../../types/enums'
// Utilities 
import { UtilsType } from '.././../../types/utils';
import Utils from '.././../../utils/index';


@Component({
  name: 'GridLabelIconComponent'
})

export default class GridLabelIcon extends Vue {
  /** ******************************** Vue Props **********************************/
  @Prop({ default: () => ({}) }) private item: any;
  @Prop({ default: () => (new GridProps()) }) public props!: GridProps;

  /** ******************************** Vue Computed **********************************/
    public utils: UtilsType = new Utils();

    get tabindex() {
      // return this.props.tabindex
      // -1 tabindex breaks focus on dropdowns and grids 
      return '0';
    }

    get labelName () { return this.item.value }

    get id (): string { return this.props.id +'-row-'+ this.item.row +'-col-'+ this.item.col; }
    
}
