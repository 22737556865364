/*
 * AccuTerm Mobile - terminal driver for slave printer
 *
 * Copyright 2017 Zumasys, Inc.
 */

////////////////////////////////////////////////////////////
// Import Dependencies!
////////////////////////////////////////////////////////////

import { DEBUG } from './globals.js';

/* global DEBUG: false */

DEBUG&&console.log('top of tdprint.js');

function PtrEmulator(term, exit_strings) {
	this.term = term;
	this.exit_strings = exit_strings;
	this.state = this.term.NORMAL;
	this.init();
}

PtrEmulator.prototype.init = function() {
	this.reset();
};

PtrEmulator.prototype.reset = function() {
	this.matched_exit_str = '';
};

// returns number of characters remaining to be processed (in case of private sequence or changing termtype)
PtrEmulator.prototype.write = function(data) {	
	DEBUG&&console.log('Ptr.write ' + data.length);
	var len = data.length
	  , i = 0
	  , j
	  , k
	  , m
	  , ch
	  , xit
	  , test_str
	  , test_part
	  , nx = this.exit_strings.length;	  		

	for (; i < len; i++) {
		xit = false;
		ch = data[i];
		test_str = this.matched_exit_str + ch;
		// check if we match one of the exit strings
		for (j = 0; j < nx; j++) {
			if (test_str === this.exit_strings[j]) {
				this.term.printer_off(test_str === "\x1b\x02\pX\r"); // close print job if ESC STX "pX" CR
				return (len - i - 1); // return number of characters remaining to process
			}			
		}
		// test for partial match
		m = test_str.length;
		for (k = 0; k < m; k++) {
			test_part = test_str.substr(k);
			for (j = 0; j < nx; j++) {
				if (test_part === this.exit_strings[j].substr(0, m - k)) {
					this.matched_exit_str = test_part;
					test_str = test_str.substr(0, k);
					xit = true;
					break;
				}
			}
			if (xit) break;
		}
		if (!xit) this.matched_exit_str = '';
		// print any data that is not partial match for an exit string
		if(test_str.length)
			this.term.write_printer(test_str);
	}
	return 0;					
};

////////////////////////////////////////////////////////////
// Exports!
////////////////////////////////////////////////////////////

export { PtrEmulator };
