/*
 * AccuTerm Mobile Terminal Settings Class
 *
 * Copyright 2019 Zumasys, Inc.
 */

////////////////////////////////////////////////////////////
// Import Dependencies!
////////////////////////////////////////////////////////////

import { TermUtil } from './termutil.js';
import { isEqualNoCase } from './termapp.js';
import { DEBUG } from './globals.js';

/* global TermUtil: false */

// TODO: following settings are available in Standard version, but not in mobile or web:
//
//	AnsiAutoPrint
//	DeviceLicenseMode (how to support device licensing over websocket?)
//	Duplex
//	HostCertificateValidation
//	MessageRows (status line not implemented)
//	AttributeMask (enables character cell border effects)
//	Printer (slave printer & screen print)
//	CapsLock (start with caps-lock set - probably not possible)
//	CopyPasteShortcut
//	MouseTable
//	FuncBarVisible
//	FuncBarStyle
//	FuncBarPos
//	ToolBarVisible


function TermSettings(params) {
	// constructor
	
	var paramsVersion = (params && params.profileVersion) || 0;

	this.profileVersion = 1;

	// Secure (encrypted) connection
	this.SECURE = (params && params.hasOwnProperty("SECURE")) ? !!params.SECURE : true; // default to secure connection
	
	// UI settings
	this.profileName = (params && params.profileName) || "";
	this.jumboXtras = ((params && params.jumboXtras) || false); // mobile only
	this.autoXtras = ((params && params.autoXtras) || false); // mobile only
	this.sessionTitle = ((params && params.sessionTitle) || '');
	
	// connection settings
	this.host = (params && params.host) || "";
	this.port = (params && params.port) || "443"; // TODO: mobile = 23
	this.connection = (params && params.connection) || "WEBSOCKET"; // TODO: mobile = TELNET
	this.timeout = (params && params.hasOwnProperty("timeout")) ? params.timeout : 10;
	this.hosttype = (params && params.hosttype) || "DEFAULT";
	this.hosttermtype = (params && params.hosttermtype) || "";
	//this.breaktype = (params && params.breaktype) || 0; // 0=default, 1=none, 2=break, 3=interrupt, 256+control character (0-31 or 127)
	// keepalive not applicable for socket.io connections
	this.keepaliveSec = ((params && params.keepaliveSec) || 0);
	this.breakKey = ((params && params.breakKey) || "CONTROL"); // choices: disabled, interrupt, break, control
	this.breakKeyCode = ((params && params.breakKeyCode) || 3);
	this.disconnectWarn = (params && params.disconnectWarn) || false;
	
	// protocol settings
	this.telnetBinary = (params && params.telnetBinary) || false;
	
	// terminal settings
	this.termtype = (params && params.termtype) || "VT100";
	this.normCols = (params && params.normCols) || 80;
	this.normRows = (params && params.normRows) || 24;
	this.extCols = (params && params.extCols) || 132;
	this.extRows = (params && params.extRows) || 24;
	this.terminalAnswerBack = (params && params.terminalAnswerBack) || '';
	this.terminal8Bit = (params && params.terminal8Bit) || false;
	this.terminalAppMode = (params && params.terminalAppMode) || false;
	this.terminalKeypadCodes = (params && params.terminalKeypadCodes) || false;
	this.terminalCursorCodes = (params && params.terminalCursorCodes) || false;
	this.bkspSendsDel = (params && params.bkspSendsDel) || false;
	this.screenSize = (params && params.screenSize) || 'NORMAL';
	this.screenPages = (params && params.screenPages) || 1;
	this.historyRows = (params && params.historyRows) || 0;
	this.autoWrap = (params && params.hasOwnProperty("autoWrap")) ? params.autoWrap : true;
	this.cursorStyle = (params && params.cursorStyle) || 'UNDERLINE';
	this.protectAttribute = (params && params.hasOwnProperty("protectAttribute")) ? (paramsVersion < 1 ? TermColors.XlateAttr(params.protectAttribute) : params.protectAttribute) : 32; // was AccuTerm attribute, now term.js attribute
	this.themeStyle = (params && params.themeStyle) || 'default';
	if (this.themeStyle === 'custom' && params && Array.isArray(params.customPalette) && Array.isArray(params.customColors)) {
		this.customPalette = params.customPalette;
		this.customColors = params.customColors;
	}
	this.fontName = (params && params.fontName) || 'DejaVu Sans Mono';
	this.fontSize = ((params && params.fontSize) || 'auto');
	this.charSet = (params && params.charSet) || 'TERMINAL';
	this.euroChar = (params && params.euroChar) || '';
	this.poundChar = (params && params.poundChar) || ''; // TODO: not implemented
	this.terminalBell = (params && params.hasOwnProperty("terminalBell")) ? params.terminalBell : true;
	this.mouseInput = ((params && params.mouseInput) || false);
	this.allowUnderline = ((params && params.hasOwnProperty("allowUnderline")) ? params.allowUnderline : true);
	this.allowBlinking = ((params && params.hasOwnProperty("allowBlinking")) ? params.allowBlinking : true);
	this.statusLine = ((params && params.statusLine) || false); // TODO: not implemented
	this.backgroundPicturePath = ((params && params.backgroundPicturePath) || '');
	this.backgroundPictureMode = ((params && params.backgroundPictureMode) || 0);
	this.backgroundPictureAlpha = ((params && params.backgroundPictureAlpha) || 0);
	
	// clipboard settings
	this.clearAfterCopy = ((params && params.clearAfterCopy) || false);
	this.pasteLine = ((params && params.pasteLine) || 'CR');
	this.pasteLineUser = ((params && params.pasteLineUser) || '');
	this.pasteLineEnd = ((params && params.pasteLineEnd) || false); // pasteSuppressFinalEOL
	this.pasteText = ((params && params.pasteText) || 'NONE');
	this.pasteTextUser = ((params && params.pasteTextUser) || '');
	this.pasteAllowCurlyQuotes = ((params && params.pasteAllowCurlyQuotes) || false); // TODO: not implemented
	 
	// keyboard settings
	this.lockFKeys = (params && params.lockFKeys) || false;
	this.keyboard = {};
	if (params && params.keyboard) {
		var keys = params.keyboard;
	    for(var key in keys) {
			if (keys.hasOwnProperty(key)) {
				if (/key\d+/.test(key)) {
					var keyid = parseInt(key.substr(3));
					var bank = Math.floor(keyid / 1000);
					var vkey = keyid % 1000;
					if ((vkey >= 0 && vkey <= 255) && 
						((bank >= 0 && bank <= 7) || (bank >= 10 && bank <= 17))) {
						this.keyboard[key] = keys[key];							
					}
				}
			}
        }
    }
	
}

TermSettings.prototype.sanitize = function() {
	var tt = String(this.termtype?this.termtype:'').toUpperCase();
	this.termtype = (TermUtil.TermTypes().hasOwnProperty(tt) && tt) || 'TTY';
	this.normCols = this._validate_range(this.normCols, 10, 240, 80);
	this.normRows = this._validate_range(this.normRows, 2, 240, 24);
	this.screenSize  = isEqualNoCase(this.screenSize, 'EXTENDED') ? 'EXTENDED' : 'NORMAL';
	this.extCols = this._validate_range(this.extCols, 10, 240, 80);
	this.extRows = this._validate_range(this.extRows, 2, 240, 24);
	this.terminalAppMode = !!(+this.terminalAppMode || false);
	this.terminalKeypadCodes = !!(+this.terminalKeypadCodes || false);
	this.terminalCursorCodes = !!(+this.terminalCursorCodes || false);
	this.bkspSendsDel = !!(+this.bkspSendsDel || false);
	this.terminal8Bit = !!(+this.terminal8Bit || false);
	this.allowUnderline = this.hasOwnProperty('allowUnderline') ? !!(+this.allowUnderline || false) : true;
	this.allowBlinking = this.hasOwnProperty('allowBlinking') ? !!(+this.allowBlinking || false): true;
	this.autoWrap = !!(+this.autoWrap || false);
	this.terminalBell = !!(+this.terminalBell || false);
	this.screenPages = this._validate_range(this.screenPages, 1, 10, 1);
	this.historyRows = this._validate_range(this.historyRows, 0, 1000, 0);
	this.protectAttribute = this._validate_range(this.protectAttribute, 0, 63, 32); // default is 'dim'
	var xcharSet = TermUtil.XlateCharSet(this.charSet, this.termtype); // can we translate to internal charset name?
	this.charSet = (xcharSet && TermUtil.CharacterSets(this.termtype).hasOwnProperty(xcharSet) && this.charSet) || 'TERMINAL';
	this.euroChar = this._validate_range(this.euroChar, 0, 255, 0) || null;
	this.fontSize = this.fontSize === 'auto' ? 'auto' : this._validate_range(this.fontSize, 5, 20, 10);
	this.pasteLineUser = this._validate_range(this.pasteLineUser, 0, 255, 0) || null;
	// validate some connection settings
	this.keepaliveSec = this._validate_range(this.keepaliveSec, 0, 3600, 0);
	this.backgroundPictureMode = this._validate_range(this.backgroundPictureMode, 0, 2, 0);
	this.backgroundPictureAlpha = this._validate_range(this.backgroundPictureAlpha, 0, 100, 0);
	this.breakKey = this._validate_list(this.breakKey, ['INTERRUPT', 'BREAK', 'CONTROL', 'DISABLED'], 'DISABLED');
	this.breakKeyCode = this._validate_range(this.breakKeyCode, 0, 255, 3);
	return this;
};

TermSettings.prototype._validate_range = function(val, min, max, defval) {
	if (/^(0|[1-9]\d*)$/.test(val)) {
		if (val < min) {
			return min;
		} else if (val > max) {
			return max;
		}
		return +val;
	}
	return +defval;
};

TermSettings.prototype._validate_list = function(val, list, defval) {
	var ret = list.find(function(item) {return isEqualNoCase(item, val);});
	if (ret === undefined)
		return defval;
	return ret;
};

////////////////////////////////////////////////////////////
// Exports!
////////////////////////////////////////////////////////////

export { TermSettings };


