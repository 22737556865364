























import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'WelcomeComponent'
})

export default class Welcome extends Vue {
/** ******************************** Vue Computed **********************************/

    get userAuth () { return this.$store.getters['user/getUserAuth'] }

/** ******************************** Vue Methods **********************************/

    login () {
        this.$store.dispatch('user/login')
    }
}
