






























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// Types
import { IGUIEvent } from './../../../types'
import { GUIEventCode, ListProps, GUIObjectType, ListColumnProps, GUIAlignment } from './../../../types/enums';
import { UtilsType } from './../../../types/utils';
// Utilities 
import Utils from './../../../utils/index';
// Components
import gxList from './gxList.vue';
import gxListMultisel from './gxListMultisel.vue';
import gxCheckedList from './gxCheckedList.vue';
import gxDrpDwnList from './gxDrpDwnList.vue';
import gxDrpDwnCbo from './gxDrpDwnCbo.vue';

@Component({
  name: 'ListComponent', 
  components: { gxList, gxListMultisel, gxCheckedList, gxDrpDwnList, gxDrpDwnCbo }
})

export default class List extends Vue {
/** ******************************** Vue Props **********************************/
  @Prop({ default: () => (new ListProps()) }) private props!: ListProps;

/** ******************************** Vue Data **********************************/
  public utils: UtilsType = new Utils();

  public activated: boolean = false

/** ******************************** Vue Computed **********************************/
  get focused() { return this.$store.getters['guiGuis/getFocusedControl']; }
  get enabled (): boolean { return this.props.gpEnabled }
  get visible (): boolean { return this.props.gpVisible }

  get singleSelect (): boolean { return this.props.type === GUIObjectType.gxList }

  get multiSelect (): boolean { return this.props.type === GUIObjectType.gxListMultisel }

  get dropDownList (): boolean { return this.props.type === GUIObjectType.gxDrpDwnList }

  get checkedList (): boolean { return this.props.type === GUIObjectType.gxCheckedList }

  get dropDownCombo (): boolean { return this.props.type === GUIObjectType.gxDrpDwnCbo }

  get position(): Array<any> { return [this.utils.controlPositionCSS(this.props.gpTop, this.props.gpLeft)] }

  get tabindex(): number { return this.props.tabindex }

  get icons () {
    let icons: Array<string> = []
    for (const i in this.props.gpIcon) {
      let s = this.props.gpIcon[i]
      if (s !== '') {
        this.utils.getIcon(s) !== 'false' ? icons.push(s) : icons.push(this.utils.getImage(s))
      } else
        icons.push('')
    }
    return icons
  }

  get hasIcons() {
    return Array.isArray(this.icons) && this.icons.length
  }

  get iconStyle (): Array<Partial<CSSStyleDeclaration>> {
    let style: Array<Partial<CSSStyleDeclaration>> = []
    let icnPx = 8 * (this.props.gpIconSize + 1)
    let styleProps = {
      float: 'left',
      width: icnPx + 'px',
      height: icnPx + 'px',
      cursor: 'pointer'
    }
    style.push(styleProps)
    return style
  }

  get tableStyle (): Array<Partial<CSSStyleDeclaration>> { 
    let style: Array<Partial<CSSStyleDeclaration>> = []
    let columnWidths: Array<number> = [];
    let icnPx = 8 * (this.props.gpIconSize + 1)

    this.props.columnInfo.map((field: ListColumnProps) => {
      columnWidths.push(field.width)
    });
    if (this.hasIcons && this.props.gpIconSize) { columnWidths[0] = columnWidths[0] + icnPx }
  
    let combinedWidth: number = columnWidths.reduce((a: number, b: number) => a + b, 0);
    
    const font = this.utils.controlFont(this.props)
    const border = this.utils.controlBorder(this.props)
    const colors: Partial<CSSStyleDeclaration> = { 
      backgroundColor: this.props.gpBackColor || 'white',
      color: this.props.gpForeColor || 'black',
      overflow: 'auto'
    }
    const zIndex: Partial<CSSStyleDeclaration> = { 
      zIndex: (this.props.zIndex + 3).toString(),
      position: 'fixed',
      width: combinedWidth + 'px'
    }

    let maxDropToPixels = 144;
    if (this.props.gpMaxDrop >= 1) {
      maxDropToPixels = 18 * this.props.gpMaxDrop;
    }
    
    colors.height = maxDropToPixels + 'px';
    style.push(border, colors, font, zIndex)
    return style
  }

  get buttonStyle (): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = []
    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight);
    const buttonSize: any = {}
    buttonSize.height = size.height;
    buttonSize.width = '25px';
    buttonSize.padding = '0px';
    style.push(buttonSize);
    return style;
  }

  get fields () { 
    // gpColWidth, gpColFieldType, gpColHeading, gpReadOnly, gpColDataType, gpColItems and gpDataCol, gpColAlign, gpColHint, gpColSizable, gpColTabStop
    // maxLen, required
  
    let fields: Array<any> = this.props.columnInfo.map((field: ListColumnProps, index: number) => {
      let cssClass = []    
      cssClass.push(this.getTextAlignmentClass(field.align))
      let rtn: any = {
        label: field.heading,
        key: index.toString(),
        type: field.fieldType,
        thStyle: { width: field.width +'px' },
        thClass: cssClass,
        tdClass: cssClass
      }
      if (index < this.props.gpFixedCols) {
        rtn.stickyColumn = true
      }
      return rtn
    })
    
    if (this.checkedList) {
      let forSelected: any = {
        label: '',
        key: 'selected',
      }
      fields.unshift(forSelected)
    }

    return fields 
  }

  get items () {
    /* Transform each sub array into a object row
      so bootstrap-vue's table/grid can injest it
      [ // Table
        [a, b, c ...], // Row 1  
        [e, f, g ...], // Row 2
        ... // n number of rows
      ] // End table
      Converts to
      [ {0: a, 1: b, 2: c ...}, {0: e, 1: g, 2: f ...}, ...]
    */
    let items = this.props.gpItems.map((item: Array<string>, j: number) => {    
        let row: any = {}
        // Place the nested array in an object (to conform to bootstrap vue)
        this.props.columnInfo.forEach((info: ListColumnProps, i: number) => {
          row[i] = {
            value: item[i] || '',
            col: i,
            row: j,
            icon: (i === 0) ? this.icons[j] : null
          }
        })
        return row
    }); 
    return items
  }

/** ******************************** Vue Methods **********************************/

  getMultipleRowIndexes (items: any, selectedRows: any) {
      let indexes: Array<number>  = [];
      selectedRows.forEach((selectedRow: any) => {
          let rowIndex: number = items.findIndex( (row:any) => {
          let cellValueEquality: Array<boolean> = []
          for (const column in row) {
            let isCellValueEqual = row[column].value === selectedRow[column].value
            cellValueEquality.push(isCellValueEqual)
          }              
            return cellValueEquality.every(value => value === true)
          })
      indexes.push(rowIndex + 1)
    });
    return indexes;
  }

  getTextAlignmentClass(align: GUIAlignment) {
    let alignmentClass = '';

    switch (align) {
      case GUIAlignment.galLeft:
        alignmentClass = 'text-left'
      break;
      case GUIAlignment.galRight:
        alignmentClass = 'text-right'
      break;
      case GUIAlignment.galCenter:
        alignmentClass = 'text-center'
      break;
    }

    return alignmentClass
  }

/*
* GUI Event handlers
*/

  focus() {
    if (this.focused === this.props.id && this.enabled && this.visible) {
      try {
        let elem: any = this.$children[0].$refs[this.props.id + "-list-grid"]; // get the VUE b-table
        elem = elem.$el; // get the HTML element for the table (must have tabindex = 0)
        (elem as HTMLElement).focus();
console.log('List Focus Me ' + this.props.id);
      } catch(e) {
console.log('List Focus Me error: ' + e);
      }
    }
  }

  activateHandler(e: any) {
    if (!this.activated) {
      this.activated = true;
      let prevControlID = (e.relatedTarget && this.utils.getRelatedTargetID(e.relatedTarget, '', 'controlID')) || null;
      if (!prevControlID) {
        // handle the deferred deactivate event
        prevControlID = this.utils.handleDeferredCtlDeactivate(this.props.id);
      }
      if (prevControlID !== this.props.id) {
console.log('List activate ' + this.props.id + ' from ' + prevControlID);
        if (this.props.gpEventMask & GUIEventCode.geActivate) {
          const activateEvent: IGUIEvent = { event: GUIEventCode.geActivate, id: this.props.id, args: [prevControlID] }
          this.$store.dispatch('guiGuis/addEvent', activateEvent);
        }
        // Clear the changed property
        this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'changed', value: false })
      } else console.log('   skipping List activate ' + this.props.id + ' - self');
      // Set focused in store and in parent form
      this.$store.dispatch('guiGuis/setFocused', { id: this.props.id })
      this.$store.dispatch('guiGuis/updateProperty', {id: this.props.form!.id, property: 'focused', value: this.props.id })
    } 
  }

  deactivateHandler(e: any) {
    if (this.activated) {
      // if the current target doesn't contain the related
      if (!e.currentTarget.contains(e.relatedTarget)) {
        this.activated = false
        const nextControlID = (e.relatedTarget && this.utils.getRelatedTargetID(e.relatedTarget, '', 'controlID')) || null;
        if (nextControlID) {
          this.handleDeactivateEvents(nextControlID);
        } else {
          this.$store.dispatch('guiGuis/setDeferredCtlDeactivate', this.handleDeactivateEvents.bind(this));
console.log('   deferring List deactivate ' + this.props.id + ' related = null');
        }
      }
    }
  }

  handleDeactivateEvents(nextControlID: string): string {
    if (nextControlID !== this.props.id) {
console.log('List deactivate ' + this.props.id + ' to ' + nextControlID);
      if (this.props.gpEventMask & GUIEventCode.geValidate && this.props.changed) {
        const validateEvent: IGUIEvent = { event: GUIEventCode.geValidate, id: this.props.id, args: [nextControlID], value: this.props.gpValue }
        this.$store.dispatch('guiGuis/addEvent', validateEvent);
      }
      if (this.props.gpEventMask & GUIEventCode.geDeactivate) {
        const deactivateEvent: IGUIEvent = { event: GUIEventCode.geDeactivate, id: this.props.id, args: [nextControlID] }
        this.$store.dispatch('guiGuis/addEvent', deactivateEvent);
      }
    } else console.log('   skipping List deactivate ' + this.props.id + ' - self')
    return this.props.id;
  }

}
