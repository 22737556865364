import Vue from 'vue'
import Router from 'vue-router'
// Store
import store from '@/store'
// Components
import AppError from '@/components/AppError.vue'
import Error404 from '@/components/404.vue'
import Home from '@/components/Home.vue'
import Login from '@/components/Login.vue'
import Welcome from '@/components/Welcome.vue'
import Profiles from '@/components/profiles/Profiles.vue'
import Terminal from '@/components/terminal/Terminal.vue'
import GUI from '@/components/gui/GUI.vue'
import ThankYou from '@/components/ThankYou.vue'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/404',
            name: '404',
            component: Error404,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/error',
            name: 'Error',
            component: AppError,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/',
            name: 'Home',
            component: Home,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: {
                requiresAuth: false
            },
            beforeEnter: async (to, from, next) => {
                let userAuth = store.getters['user/getUserAuth']
                // If there's B2C hash parameter, then call login again to complete the authentication
                if (!to.hash) {
                    if (userAuth) {
                        next({ name: 'Profiles' })
                    } else {
                        await store.dispatch('user/login')
                        next()
                    }
                } else {
                    await store.dispatch('user/login')
                    next()
                }
            }
        },
        {
            path: '/profiles',
            name: 'Profiles',
            component: Profiles,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/thank-you',
            name: 'ThankYou',
            component: ThankYou,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/terminal/:id?', // '?' means the profile id is an optional parameter
            name: 'Terminal',
            component: Terminal,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/gui',
            name: 'GUI',
            component: GUI,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/welcome',
            name: 'Welcome',
            component: Welcome,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '*',
            name: 'CatchAll',
            redirect: { name: '404' }
        }
    ]
})

router.beforeEach((to, from, next) => {
    let requiresAuth = to.meta && to.meta.requiresAuth
    const userAuth = store.getters['user/getUserAuth']
    const enterprise = store.getters['user/getEnterprise']
    const browser = store.getters.getBrowser
    // console.log('from: ' + from.path, ' to: ' + to.path, ' hash: ' + to.hash)

    // if the browser is Internet Exploder
    if (browser.ie && to.name !== 'Error') {
        next({ name: 'Error' })
    // if the page requires auth and there's no user logged in and there's no enterprise
    } else if (requiresAuth && !userAuth && !enterprise) {
        next({ name: 'Login' })
    } else {
        next()
    }
})

export default router
