









































import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import { AccuTermStyle } from './../../types/store/terminal'

@Component({
    name: 'HeaderComponent'
})

export default class Header extends Vue {
/** ******************************** Vue Data! **********************************/

    private showTermNav: boolean = false;
    private showKeyboard: boolean = false;

    $refs!: {
        layoutHeader: HTMLElement
    }

/** ******************************** Vue Created! **********************************/

    created () {
        this.toggleTermNav(this.$route.name)
    }

/** ******************************** Vue Computed **********************************/

    get getUserAuth () { return this.$store.getters['user/getUserAuth'] }
    get getUserName () { return this.$store.getters['user/getUserName'] }

    get getConnectionStatus () { return this.$store.getters['terminal/getConnectionStatus'] }
    get accutermStyle () { return this.$store.getters['terminal/getAccuTermStyle'] }

    get hasProfile (): boolean { return this.$store.getters['profiles/getHasProfile'] }

    get getEnterprise () { return this.$store.getters['user/getEnterprise'] }

    get showHeader () {
        let header = this.$store.getters['settings/getShowHeader'] 

        let style: AccuTermStyle = { ...this.accutermStyle }
        // Set accuterm style if the keyboard is showing or not
        style.height = header ? '86vh' : '96vh'
        // update the store
        this.$store.dispatch('terminal/accutermStyle', style)

        return header
    }

    // return true if logoHeader is a full URL
    get logoUrl() { 
        let logos = this.$store.getters['settings/getLogoHeader'];
        return logos && logos.includes('http')
    }

    // return URL of logoHeader when logo is a static asset on enterprise server
    get logoSvr() {
        const svc = this.$store.getters['settings/getSettingsService'];
        if (svc) {
            const svr = svc.endpoint;
            const logos = this.logos;
            if (svr && logos) {
                return svr + '/' + logos;
            }
        }
        return null;
    }

    // return logoHeader from config settings
    get logos() { return this.$store.getters['settings/getLogoHeader'] }

    // return home route if no home link URL
    get toHome() { return this.linkHome ? '' : { name: 'Home' } }

    // return home link URL from config settings
    get linkHome() { return this.$store.getters['settings/getLinkHome'] }

    get hasGUI() { return this.$store.getters['guiGuis/hasGuis'] }

    get hasProfileParam() { return this.$store.getters['profiles/getHasProfileParam'] || false }

/** ******************************** Vue Methods **********************************/

    toggleTermKeyboard () {
        let style: AccuTermStyle = { ...this.accutermStyle }
        // Set accuterm style if the keyboard is showing or not
        style.height = !this.showKeyboard ? '54vh' : '86vh'
        // update the store
        this.$store.dispatch('terminal/accutermStyle', style)
        // update our data property
        this.showKeyboard = !this.showKeyboard
    }

    toggleTermNav (name: string | null | undefined) {
        this.showTermNav = (name === 'Terminal')
    }

    connectionStatusText () {
        return (this.getConnectionStatus) ? 'Disconnect' : 'Connect'
    }

    toggleConnection () {
        // If connected
        if (this.getConnectionStatus) {
            this.$store.dispatch('terminal/disconnect')
        } else {
            this.$store.dispatch('terminal/connect')
        }
    }

    reset () {
        this.$store.dispatch('terminal/reset')
    }

/** ******************************** Vue Mounted! **********************************/

    mounted () {
        // Refs
        const layoutHeader = this.$refs.layoutHeader
    }

/** ******************************** Vue Emit Events! **********************************/

    @Emit('clickLogIn') login () {}
    @Emit('clickLogOut') logout () {}

/** ******************************** Vue Watch **********************************/

    @Watch('$route.path') onPathChange () {
        this.toggleTermNav(this.$route.name)
    }
}
