/*
 * AccuTerm Mobile - terminal driver for basic TTY
 *
 * Copyright 2015 Zumasys, Inc.
 */

////////////////////////////////////////////////////////////
// Import Dependencies!
////////////////////////////////////////////////////////////

import { DEBUG } from './globals.js';

/* global DEBUG: false */

DEBUG&&console.log('top of tdtty.js');

function TTYEmulator(term) {
	this.term = term;
	this.state = this.term.NORMAL;
	this.init();
}

TTYEmulator.prototype.init = function() {
	// initialize the keyboard map
	var key, def, dfltkbd = [];
	dfltkbd[8] = '\x08';
	dfltkbd[9] = '\x09';
	dfltkbd[13] = '\x0d';
	dfltkbd[27] = '\x1b';
	dfltkbd[37] = '\x08';
	dfltkbd[40] = '\x0a';
	dfltkbd[46] = '\x7f';
	dfltkbd[253] = '\x0d';
	// add default key definitions to kbdmap
    for(key in dfltkbd) {
        if (dfltkbd.hasOwnProperty(key)) {
			def = this.term.kbdmap['key' + key] || {};
			def.dflt = dfltkbd[key];
			this.term.kbdmap['key' + key] = def;
        }
    }	
	this.reset();
};

TTYEmulator.prototype.reset = function() {
	
};

// returns number of characters remaining to be processed (in case of private sequence or changing termtype)
TTYEmulator.prototype.write = function(data) {	
	DEBUG&&console.log('TTY.write ' + data.length);
	var len = data.length
	  , i = 0
	  , ch;

	for (; i < len; i++) {
		ch = data[i];
		switch(this.state) {
			case this.term.NORMAL:
				switch (ch) {
					case '\x07':
						this.term.bell();
						break;
					case '\x08':
						if (this.term.x > 0) {
							this.term.x--;
						}
						break;
					case '\x0a':
						this.term.index();
						break;
					case '\x0d':
						this.term.x = 0;
						break;
					case '\x1b':
						this.state = this.term.ESCAPED;
						break;
					default:
						if (ch >= ' ') {
							this.term.writech(ch, this.term.curAttr);
						} else {
							if(this.term.ctlchr(ch)) { // is private control sequence?
								return (len - i - 1); // return number of characters remaining to process
							}
						}
						break;
				}
				break;
			case this.term.ESCAPED:
				if(this.term.escseq(ch)) { // is private escape sequence?
					return (len - i - 1); // return number of characters remaining to process
				}
				this.state = this.term.NORMAL;
				break;
			default:
				this.state = this.term.NORMAL;
				break;				
		}
	}
	return 0;
};


////////////////////////////////////////////////////////////
// Exports!
////////////////////////////////////////////////////////////

export { TTYEmulator };
