import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
// Types
import { RootState } from './../../types/store/'
import { NotificationsState } from './../../types/store/notifications'

import { simpleNotification, notification } from '../../types'
import { GUIErrorLevel, GUIErrorCode } from '../../types/enums'

// State
export const state: NotificationsState = {
    hasNotifications: false,
    notifications: [],
    logNotifications: true
}

// Namespace
const namespaced: boolean = true

// Getters
export const getters: GetterTree<NotificationsState, RootState> = {
    getNotifications (state) { return state.notifications },
    getHasNotifications (state) { return state.hasNotifications }
}

// Mutations
export const mutations: MutationTree<NotificationsState> = {
    ADD (state, notification) {
        let notifications = [...state.notifications]
        let newNotification = { ...notification }
        notifications.push(newNotification)
        state.notifications = notifications
    },
    DISMISS (state, key) {
        let notifications = [...state.notifications]
        let newNotification = { ...notifications[key] }
        newNotification.active = false
        notifications[key] = newNotification
        state.notifications = notifications
    },
    SET_HAS_NOTIFICATIONS (state) {
        let activeNotifications = state.notifications.filter((notification) => notification.active)
        state.hasNotifications = !!(activeNotifications && activeNotifications.length)
    },
    CLEAR (state) {
        state.notifications = []
    }
}

// Actions
export const actions: ActionTree<NotificationsState, RootState> = {
    addNotification ({ commit, dispatch, state }, notification: simpleNotification) {
        let newNotification: notification = {
            id: state.notifications.length,
            type: notification.type,
            active: true,
            show: notification.show,
            friendlyMessage: notification.friendlyMessage,
            message: notification.message,
            script: notification.script,
            error: (notification.error) ? { ...notification.error } : null
        }

        if (state.logNotifications) {
            console.log(notification.type + ' error - ' + notification.message + ' (' + notification.script + ')')
        }

        commit('ADD', newNotification)
        commit('SET_HAS_NOTIFICATIONS')
    },
    dismissNotification ({ commit }, { key }) {
        commit('DISMISS', key)
        commit('SET_HAS_NOTIFICATIONS')
    }, 
    clear({ commit }) {
        commit('CLEAR')
        commit('SET_HAS_NOTIFICATIONS')
    }

}

export const guiNotifications: Module<NotificationsState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}
