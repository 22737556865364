import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
// Types
import { RootState } from '@/types/store/'
import { SettingsState } from '@/types/store/config-settings'
import { actionErrorObject } from '@/types/store/errors'
import SettingsServiceType from '@/types/services/config-settings'
import SettingsService from '@/services/config-settings'
// State
export const state: SettingsState = {
    showHeader: true,
    logo: '',
    logo_header: '',
    settings: {
        show_header: true,
        link_home: '', // a URL to redirect to when user clicks the header icon
        logo_header: '', // a URL or image file name in server assets directory to display as header icon
        banner: ''
    },
    settingService: null,
    loading: {
        settings: false,
        service: false
    }
}

// Namespace
const namespaced: boolean = true

// Getters
export const getters: GetterTree<SettingsState, RootState> = {
    getLinkHome (state) { return 'link_home' in state.settings ? state.settings.link_home : '' },
    getLogoHeader (state) { return 'logo_header' in state.settings ? state.settings.logo_header : '' },
    getShowHeader (state) { return 'show_header' in state.settings ? state.settings.show_header : true },
    getBanner (state) { return 'banner' in state.settings ? state.settings.banner : null },
    getLoading (state) { return state.loading },
    getSettingsService (state) { return state.settingService }
}

// Mutations
export const mutations: MutationTree<SettingsState> = {
    SET_SETTINGS (state, settings) { state.settings = { ...settings } },
    SET_LOADING (state, { process, toggle }) {
        let loading = { ...state.loading }
        loading[process] = toggle
        state.loading = loading
    },
    SET_SETTINGS_SERVICE (state, ps) { state.settingService = ps }
}

// Actions
export const actions: ActionTree<SettingsState, RootState> = {

    setSettings ({ commit, dispatch, rootGetters, state }) {
        const authToken = rootGetters['user/getAuthToken'];
        let logError: actionErrorObject = { type: 'settings', show: true, message: '', friendly: 'We were not able to set your active AccuTerm settings', script: 'store/settings/index.ts' }
        if (state.settingService) {
            state.settingService.getSettings(authToken).then((data: any) => {
                commit('SET_SETTINGS', data)
                commit('SET_LOADING', { process: 'settings', toggle: true })
            }).catch((error: any) => {
                logError.message = 'Unable to fetch settings - ' + error.message
                dispatch('errors/addError', { ...logError }, { root: true })
            })
        } else {
            logError.message = 'Setting service is not connecting while retrieving settings'
            dispatch('errors/addError', { ...logError }, { root: true })
        }


    },
    setSettingsService ({ commit, dispatch }, endpoint: string) {
        let logError: actionErrorObject = { type: 'settings', show: true, message: '', friendly: 'We are currently unable to retrieve your AccuTerm settings', script: 'store/settings/index.ts' }
        const ep = endpoint

        try {
            let ps: SettingsServiceType = new SettingsService(ep)

            commit('SET_SETTINGS_SERVICE', ps)
            commit('SET_LOADING', { process: 'service', toggle: true })
        } catch (error) {
            logError.message = 'Unable to set settings service - ' + error
            dispatch('errors/addError', { ...logError }, { root: true })
        }
    }
}

export const settings: Module<SettingsState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}
