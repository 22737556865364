






import { Component, Prop, Vue } from 'vue-property-decorator'
// Types
import { UtilsType } from './../../types/utils'
// Utilities 
import Utils from './../../utils/index';

@Component({
    name: 'IconComponent'
})

export default class Icon extends Vue {
/** ******************************** Vue Props **********************************/

    @Prop() public iconName!: string;
    @Prop() public gpStyle!: number;

/** ******************************** Vue Data **********************************/

    public utils: UtilsType = new Utils();
    public imageFailedToLoad: boolean = false;

/** ******************************** Vue Computed **********************************/
    get showSmallIcon () { return (this.gpStyle == 2 || this.gpStyle == 3) }
    get showIcon () { return (this.gpStyle >= 2 && this.gpStyle <= 5) || this.iconName !== '' }

    get icon(): string { 
        let newIconName = this.utils.getIcon(this.iconName)
        if (newIconName === 'false') {
            newIconName = this.utils.getImage(this.iconName)
        }
        return newIconName
    }

    get iconIsImage() { return this.utils.getIcon(this.iconName) === 'false' }

    get iconStyle() {
        let style: Partial<CSSStyleDeclaration>[] = []

        return style
    }



/** ******************************** Vue Life Cycle **********************************/
    imageUrlFail() {
        this.imageFailedToLoad = true;
    }
}
