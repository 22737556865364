













import { Component, Prop, Vue } from 'vue-property-decorator';
// Types
import { ToolbarProps, GUIEventCode } from './../../types/enums'
import { IGUIEvent } from './../../types'
import { CharacterUnderlineProps } from './../../types/components'
import { UtilsType } from './../../types/utils'
// Utilities 
import Utils from './../../utils/index';
// Components
import CharacterUnderline from './../layout/CharacterUnderline.vue';
import Icon from './../layout/Icon.vue';


@Component({
  name: 'gxToolbarComponent',
  components: { CharacterUnderline, Icon}
})

export default class gxToolbar extends Vue {
  /** ******************************** Vue Props **********************************/

  @Prop({ default: () => (new ToolbarProps()) }) private props!: ToolbarProps;

  /** ******************************** Vue Data **********************************/

  public utils: UtilsType = new Utils();

  /** ******************************** Vue Computed **********************************/

  
  get style() {
    let style: Partial<CSSStyleDeclaration>[] = []
    const positioning = this.utils.controlPositionCSS(this.props.gpTop, this.props.gpLeft);
    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight)
    const font = this.utils.controlFont(this.props)
    const border = this.utils.controlBorder(this.props)
    const alignment = this.utils.controlAlign(this.props)
    const containerCSS: Partial<CSSStyleDeclaration> = { 
      backgroundColor: this.props.gpBackColor,
      color: this.props.gpForeColor
    }
    style.push(size, font, positioning, containerCSS, border, alignment)
    return style
  }

  get items () { return this.props.gpItems}

  get tabindex(): number { return this.props.tabindex }  

  get visible (): boolean { return this.props.gpVisible }

  get enabled (): boolean { return this.props.gpEnabled }  

  get isVertical () {
    if(this.props.gpAlign == 3 || this.props.gpAlign == 4) {
      return true;
    }

    return false;
  }

  get buttonStyle() {
    let style: Partial<CSSStyleDeclaration>[] = []
    const colors: Partial<CSSStyleDeclaration> = { 
      backgroundColor: this.utils.getHexColor('MENUBAR'),
      color: this.utils.getHexColor('MENUTEXT')
    }
    style.push(colors)
    return style
  }

  get gpStyle (): boolean { return this.props.gpStyle == 2 || this.props.gpStyle == 3; }

  get cuProps(): CharacterUnderlineProps {
    let form = this.props.form ? this.props.form.id : null;
    return { app: this.props.app!.id, form: form, control: this.props.id, type: this.props.type }
  }

  /** ******************************** Vue Methods **********************************/

  getIconName (value: string) { return this.utils.getIcon(value) };

  handleClick(e: any, item: any) {
    e.stopPropagation()
    if (this.props.gpEventMask & GUIEventCode.geClick) {
      // send this event to the server
      let args: Array<string> = []

      if (item.id) {
        args.push(item.id)
      }
      
      if (this.props.gpEventMask & GUIEventCode.geClick) {
        // send this event to the server
        const guiEvent: IGUIEvent = { event: GUIEventCode.geClick, id: this.props.id, args: args }
        this.$store.dispatch('guiGuis/addEvent', guiEvent);
      }
    }
  }

  
  checkDisabledState(item: any) {
    let style: any  = []
    if (item.enabled === true){
      style.push({color: this.utils.getHexColor('MENUTEXT')})
    } else {
      style.push({color:  this.utils.getHexColor('GRAYTEXT') })
    }
    return style;
  }

}
