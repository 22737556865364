import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
// Types
import { RootState } from '../../types/store/'
import { ResponseState } from '../../types/store/response'
import { GUIResponse } from '../../types'
// Services
import ResponseService from '../../services/response/'

// State
export const state: ResponseState = {
    responseService: new ResponseService(),
    response: [],
    responseLength: 0,
    respond: false
}

// Namespace
const namespaced: boolean = true

// Getters
export const getters: GetterTree<ResponseState, RootState> = {
    getResponse (state) { return state.response },
    getResponseLength (state) { return state.responseLength },
    getRespond (state) { return state.respond }
}

// Mutations
export const mutations: MutationTree<ResponseState> = {
    CLEAR_RESPONSE (state) { state.response = [] },
    CLEAR_RESPONSE_LENGTH (state) { state.responseLength = 0 },
    CLEAR_RESPOND (state) { state.respond = false },
    SET_RESPONSE (state, response) { state.response = [...response] },
    SET_RESPONSE_LENGTH (state, length) { state.responseLength = length },
    SET_RESPOND (state, respond) { state.respond = respond }
}

// Actions
export const actions: ActionTree<ResponseState, RootState> = {
    clear({ commit }) {
        commit('CLEAR_RESPONSE')
        commit('CLEAR_RESPONSE_LENGTH')
        commit('CLEAR_RESPOND')
    },
    addResponseCommand ({ commit, state }, command: GUIResponse) {

        let newResponse = state.response.map(response => ({ ...response }))
        
        newResponse.push({ ...command })

        commit('SET_RESPONSE', newResponse)
    },
    responseLength ({ commit }, length: number) {
        commit('SET_RESPONSE_LENGTH', length)
    },
    respond ({ commit }, respond: boolean) {
        commit('SET_RESPOND', respond)
    },
    setResponse({ commit, state }, response: GUIResponse) {

        return new Promise<void>((resolve, reject) => {
            state.responseService.responseHandler(response)
            resolve()
        })
    }
}

export const guiResponse: Module<ResponseState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}
