


































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// Types
import { IGUIEvent } from '../../../types';
import { EditProps, GUIObjectType, GUIEventCode } from '../../../types/enums';
import { UtilsType } from '../../../types/utils';
import { BFormInput } from 'bootstrap-vue';
// Utilities 
import Utils from '../../../utils/index';

@Component({
  name: 'EditComponent'
})

export default class Edit extends Vue {
  /** ******************************** Vue Props **********************************/

  @Prop({ default: () => (new EditProps()) }) public props!: EditProps;

  /** ******************************** Vue Data **********************************/
  public utils: UtilsType = new Utils();

  public id: string = this.props.id +'-input'

  public classes: Array<any> = ['control']
  // Single click and double click events
  public clickCount: number = 0
  public clickTimer: any = null

  public spinButtonValue: any = +this.props.gpValue

  /** ******************************** Vue Computed **********************************/

  get focused() { return this.$store.getters['guiGuis/getFocusedControl']; }
  get formProps() { return this.$store.getters['guiGuis/getProps'](this.props.form!.id); }

  get input (): boolean { return this.props.type === GUIObjectType.gxEdit && this.props.gpStyle != 4 }

  get spinbutton (): boolean { return this.props.type === GUIObjectType.gxEdit && this.props.gpStyle == 4 }

  get password (): boolean { return this.props.type === GUIObjectType.gxEditPassword }

  get textarea (): boolean { return this.props.type === GUIObjectType.gxEditMultiline }

  get value(): any { return Array.isArray(this.props.gpValue) ? this.props.gpValue.join('\r\n') : this.props.gpValue; }
  set value(val) { 

    let newValue: string | Array<string>;
    // if it's multiline and the value is an array, then account for line breaks
    if (this.textarea) {
      newValue = val.split(/\n\r?/g);
    } else {
      newValue = val;
    }

    // Check if the change event is included in the prop events
    if (this.props.gpEventMask & GUIEventCode.geChange) {
      // send this event to the server
      const args = Array.isArray(newValue) ? newValue : [newValue];
      const guiEvent: IGUIEvent = { event: GUIEventCode.geChange, id: this.props.id, args: args }
      this.$store.dispatch('guiGuis/addEvent', guiEvent);
    }
    
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'changed', value: true })
    // it's possible the user 'unchanged' the value; technically it's 'unchanged', but pratically who cares
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpChanged', value: true })
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpValue', value: newValue }) 
  }

  get position(): Array<Partial<CSSStyleDeclaration>> { return [this.utils.controlPositionCSS(this.props.gpTop, this.props.gpLeft)] }

  get inputStyle(): Array<Partial<CSSStyleDeclaration>> { 

    let style: Partial<CSSStyleDeclaration>[] = [];

    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight)
    const font = this.utils.controlFont(this.props)
    const border = this.utils.controlBorder(this.props)
    const colors: Partial<CSSStyleDeclaration> = {}
    
    if (this.enabled){
      colors.backgroundColor = this.props.gpBackColor
    } else {
      colors.backgroundColor = ''
    }

    colors.color = this.props.gpForeColor
    colors.padding = '0px'

    style.push(size, font, border, colors)
    
    return style
  }

  get textareaClass(): Partial<CSSStyleDeclaration> { 

    let classes = {
      'multiline_style_1': this.props.gpStyle !== 3,
      'multiline_style_3': this.props.gpStyle === 3
    }

    return classes
  }

  get readOnly (): boolean { return this.props.gpReadOnly }
  get required (): boolean { return this.props.gpRequired }
  get enabled (): boolean { return this.props.gpEnabled }
  get visible (): boolean { return this.props.gpVisible }

  get maxlength (): string { return (this.props.gpMaxLen !== 0) ? this.props.gpMaxLen.toString() : '' }
  get maxlines (): string { return (this.props.gpMaxLines !== 0) ? this.props.gpMaxLines.toString() : '' }

  get selectionStart(): number { return +this.props.gpSelStart }
  set selectionStart(selection: number) { 
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpSelStart', value: selection }) 
  }

  get selectionLength(): number { return +this.props.gpSelLength }
  set selectionLength(selection: number) {
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpSelLength', value: selection }) 
  }

  /** ******************************** Vue Method **********************************/

  selectionHandler (e: any) {
    e.stopPropagation()

    const ct = e.currentTarget

    this.selectionStart = ct.selectionStart
    this.selectionLength = ct.selectionEnd - ct.selectionStart
  }

  focus() {
    if (this.focused === this.props.id && this.enabled && this.visible) {
      // either there's no id (so the body) or the active element is not the current one
      try {
        const elem: any = this.$refs[this.props.id];
        (elem as BFormInput).focus();
        if(this.props.focusable) {
          (elem as HTMLInputElement).select();
        }
console.log('Edit Focus Me '+ this.props.id);
      } catch(e) {
console.log('Edit Focus Me error: ' + e);
      }
    }
  }

  activateHandler(e: any) {
    // if text should be selected
    if (this.selectionLength !== 0) {
      this.select();
    }
    let prevControlID = (e.relatedTarget && this.utils.getRelatedTargetID(e.relatedTarget, '', 'controlID')) || null;
    if (!prevControlID) {
      // handle the deferred deactivate event
      prevControlID = this.utils.handleDeferredCtlDeactivate(this.props.id);
    }
    if (prevControlID !== this.props.id) {
console.log('Edit activate ' + this.props.id + ' from ' + prevControlID);
      if (this.props.gpEventMask & GUIEventCode.geActivate) {
        const activateEvent: IGUIEvent = { event: GUIEventCode.geActivate, id: this.props.id, args: [prevControlID || ''] }
        this.$store.dispatch('guiGuis/addEvent', activateEvent);
      }
      // Clear the changed property
      this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'changed', value: false })
    } else console.log('   skipping Edit activate ' + this.props.id + ' - self')
    // Set focused in store and in parent form
    this.$store.dispatch('guiGuis/setFocused', { id: this.props.id })
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.form!.id, property: 'focused', value: this.props.id })
  }

  deactivateHandler(e: any) {
    const nextControlID = (e.relatedTarget && this.utils.getRelatedTargetID(e.relatedTarget, '', 'controlID')) || null;
    if (nextControlID) {
      this.handleDeactivateEvents(nextControlID);
    } else {
      this.$store.dispatch('guiGuis/setDeferredCtlDeactivate', this.handleDeactivateEvents.bind(this));
console.log('   deferring Edit deactivate ' + this.props.id + ' related = null');
    }
    // Native browser behavior deselects if the element loses focus
    this.selectionStart = 0 
    this.selectionLength = 0
  }

  enterHandler() {
    if (this.props.gpEventMask & GUIEventCode.geValidate && this.props.changed) {
        const validateEvent: IGUIEvent = { event: GUIEventCode.geValidate, id: this.props.id, value: this.value }
        this.$store.dispatch('guiGuis/addEvent', validateEvent);
    }
  }

  handleDeactivateEvents(nextControlID: string): string {
    if (nextControlID !== this.props.id) {
console.log('Edit deactivate ' + this.props.id + ' to ' + nextControlID);
      if (this.props.gpEventMask & GUIEventCode.geValidate && this.props.changed) {
        const validateEvent: IGUIEvent = { event: GUIEventCode.geValidate, id: this.props.id, args: [nextControlID], value: this.value }
        this.$store.dispatch('guiGuis/addEvent', validateEvent);
      }
      if (this.props.gpEventMask & GUIEventCode.geDeactivate) {
        const deactivateEvent: IGUIEvent = { event: GUIEventCode.geDeactivate, id: this.props.id, args: [nextControlID] }
        this.$store.dispatch('guiGuis/addEvent', deactivateEvent);
      }
    } else console.log('   skipping Edit deactivate ' + this.props.id + ' - self')
    return this.props.id;
  }

  leftClickHandler(e: MouseEvent) {
    e.stopPropagation();

    this.clickCount++

      if (this.clickCount === 1) {
        this.clickTimer = setTimeout(() => {
          this.clickCount = 0
          // Single Click
          if (this.props.gpEventMask & GUIEventCode.geClick) {
            // send this event to the server
            const guiEvent: IGUIEvent = { event: GUIEventCode.geClick, id: this.props.id }
            this.$store.dispatch('guiGuis/addEvent', guiEvent);
          }
        }, 250)
      } else if (this.clickCount === 2) {
        clearTimeout(this.clickTimer)
        this.clickCount = 0
        // Single Click and...
        if (this.props.gpEventMask & GUIEventCode.geClick) {
          // send this event to the server
          const clickEvent: IGUIEvent = { event: GUIEventCode.geClick, id: this.props.id }
          this.$store.dispatch('guiGuis/addEvent', clickEvent);
        }
        // Double Click
        if (this.props.gpEventMask & GUIEventCode.geDblClick) {
          // send this event to the server
          const dblClickEvent: IGUIEvent = { event: GUIEventCode.geDblClick, id: this.props.id }
          this.$store.dispatch('guiGuis/addEvent', dblClickEvent);
        }
      }
  }

  rightClickHandler(e: MouseEvent) {
    e.stopPropagation();

    if (this.props.gpEventMask & GUIEventCode.geContext) {
      // send this event to the server
      const contextEvent: IGUIEvent = { event: GUIEventCode.geContext, id: this.props.id }
      this.$store.dispatch('guiGuis/addEvent', contextEvent);
    }

    this.$store.dispatch('guiGuis/updateMousePosition', this.utils.getMousePositionRelativeToParent(e, this.props.form!.id))
  }

  spinButtonClickHandler(e: MouseEvent) {
    e.stopPropagation();

    // 0 = down, 1 = up
    let upOrDown = '0'

    if (this.value > this.spinButtonValue) {
        upOrDown = '1'
        this.spinButtonValue++
    }

    if (this.value < this.spinButtonValue) {
        this.spinButtonValue--
    }

    if (this.props.gpEventMask & GUIEventCode.geBtnClick) {
      // send this event to the server
      const btnClickEvent: IGUIEvent = { event: GUIEventCode.geBtnClick, id: this.props.id, value: upOrDown }
      this.$store.dispatch('guiGuis/addEvent', btnClickEvent);
    } 

  }

  select() {
    const start = this.selectionStart
    const end = this.selectionStart + this.selectionLength; // DO NOT REMOVE this semicolon the casting below depends on it
    //bootstrap-vue.org/docs/components/form-input#exposed-input-properties-and-methods
    if(start && end) {
      (this.$refs[this.id] as HTMLInputElement | HTMLTextAreaElement).setSelectionRange(start, end);
    }
  }

/** ******************************** Vue Life Cycle **********************************/

  created() {
    // differentiate the input id from the container id
    if (this.input) {
      this.id += '-text'
    } else if (this.password) {
      this.id += '-password'
    } else if (this.textarea) {
      this.id += '-textarea'
    } else if (this.spinbutton) {
      this.id += '-spinbutton'
    }
  }

  mounted() {
    this.$nextTick(() => { this.focus(); });
  }

/** ******************************** Vue Watch and Emit Events! **********************************/

  @Watch('focused') passRequest () { 
    this.focus()
  }


}
