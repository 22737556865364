





import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// Types
import { TimerProps, GUIEventCode } from './../../types/enums'
import { UtilsType } from './../../types/utils'
import { IGUIEvent } from './../../types'
// Utilities 
import Utils from './../../utils/index';


@Component({
  name: 'gxTimerComponent'
})

export default class gxTimer extends Vue {
  /** ******************************** Vue Props **********************************/

  @Prop({ default: () => (new TimerProps()) }) private props!: TimerProps;

  /** ******************************** Vue Data **********************************/
  public utils: UtilsType = new Utils();
  public timer: any = null;

  /** ******************************** Vue Computed **********************************/
  get style(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = []
    const positioning = this.utils.controlPositionCSS(this.props.gpTop, this.props.gpLeft);
    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight)
    const containerCSS: Partial<CSSStyleDeclaration> = { 
      backgroundColor: this.props.gpBackColor,
      color: this.props.gpForeColor
      }
    style.push(size)
    style.push(positioning)
    style.push(containerCSS)
    return style
  }

  get tabindex(): number { return this.props.tabindex }

  get enabled(): boolean { return this.props.gpEnabled }

  /** ******************************** Vue Methods **********************************/

  timerHandler () {
      if (this.props.gpStyle === 0) { // One-shot timer
        this.timer = setTimeout (() => {
        if (this.props.gpEventMask & GUIEventCode.geTimer) { 
            const guiEvent: IGUIEvent = { event: GUIEventCode.geTimer, id: this.props.id }
            this.$store.dispatch('guiGuis/addEvent', guiEvent);
        }
      }, this.props.gpTimeout);
    } else if (this.props.gpStyle === 1) { // repeating timer
          this.timer = setInterval (() => {
          if (this.props.gpEventMask & GUIEventCode.geTimer) { 
              const guiEvent: IGUIEvent = { event: GUIEventCode.geTimer, id: this.props.id }
              this.$store.dispatch('guiGuis/addEvent', guiEvent);
          }
        }, this.props.gpTimeout * 4);
    }
  }

  clear() {
    clearInterval(this.timer);
    this.timer = null;
  }

  /** ******************************** Vue Life Cycle Methods **********************************/

  created() {
    if(this.enabled) {
      this.timerHandler();
    }
  }   

  beforeDestroy() {
    this.clear();
  }

  /** ******************************** Vue Watch **********************************/
  
  @Watch('enabled') checkEnabled () {
    if (this.enabled) {
      this.timerHandler();
    } else {
      this.clear();
    }  
  }
  
}
