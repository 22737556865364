















import { Component, Prop, Vue } from 'vue-property-decorator';
// Types
import { GaugeProps } from './../../types/enums'
import { UtilsType } from './../../types/utils'
// Utilities 
import Utils from './../../utils/index';


@Component({
  name: 'gxGaugeComponent'
})

export default class gxGauge extends Vue {
  /** ******************************** Vue Props **********************************/

  @Prop({ default: () => (new GaugeProps()) }) private props!: GaugeProps;

  /** ******************************** Vue Data **********************************/

  public utils: UtilsType = new Utils();

  /** ******************************** Vue Computed **********************************/

  get value () { return this.props.gpValue }

  get style(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = []
    const positioning = this.utils.controlPositionCSS(this.props.gpTop, this.props.gpLeft);
    style.push(positioning)
    return style
  }

  get cssProperties(): Partial<CSSStyleDeclaration>[] { 
    let style: Partial<CSSStyleDeclaration>[] = []
    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight)
    const font = this.utils.controlFont(this.props)
    const border = this.utils.controlBorder(this.props)
    const alignment = this.utils.controlAlign(this.props)
    const containerCSS: Partial<CSSStyleDeclaration> = { 
      backgroundColor: this.props.gpBackColor,
      color: this.props.gpForeColor,
    }
    style.push(size)
    style.push(font)
    style.push(border)
    style.push(alignment)
    style.push(containerCSS)

    return style
  }

  get tabindex(): number { return this.props.tabindex }

  get enabled(): boolean { return this.props.gpEnabled }

  get segmented (): boolean { return this.props.gpStyle === 0 }

  get marquee (): boolean { return this.props.gpStyle === 2}

  get visible (): boolean { return this.props.gpVisible }

  /** ******************************** Vue Methods **********************************/

  created() {}
}
