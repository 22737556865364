















import { Component, Prop, Vue } from 'vue-property-decorator';
// Types
import { GridItem } from '../../../types/enums'

@Component({
  name: 'GridCheckboxComponent'
})

export default class GridCheckbox extends Vue {
  /** ******************************** Vue Props **********************************/

  @Prop({ default: () => (new GridItem()) }) public item!: GridItem;

  /** ******************************** Vue Computed **********************************/

  get col (): number { return this.item.col }
  get row (): number { return this.item.row }

  get value () { return this.item.value === '0' || this.item.value === 0 || this.item.value == "" ? "0" : "1"}
  set value (val) { this.$emit('updateValue', val, this.col, this.row) }

  get readonly (): boolean { return !this.item.editable }

  get id(): string { return this.item.id; }

  get style (): Partial<CSSStyleDeclaration> {
    let css: Partial<CSSStyleDeclaration> = {};
    let color: string;
    css.width = 'inherit'
    color = this.item.backColor;
    if (color) css.backgroundColor = color; 
    color = this.item.foreColor;
    if (color) css.color = color;
    return css;
  }

  get bgColor(): Partial<CSSStyleDeclaration> {
    let css: Partial<CSSStyleDeclaration> = {};
    let color: string;
  
    color = this.item.backColor;
    if (color) css.backgroundColor = color; 
    css.height = "inherit"
    css.display = "flex";
    css.alignItems = "center";
    return css
  }

  focusInner() {
    (this.$refs[this.id + "-inner"] as HTMLDivElement).focus();
  }

  keyHandler(e: KeyboardEvent) {
    switch(e.code) {
      case ("Space"):
      case ("Enter"): 
        this.item.value = this.item.value === "1" ? "0" : "1"
      break;
    }
  }
}
