








































































import { Component, Vue, Prop } from 'vue-property-decorator';
// Types
import { GUIObjectType, GUIComponent, AllProps, MDIProps, SDIProps, HotKey, GUIEventCode } from './../types/enums'
import { IGUIEvent } from './../types'
import { UtilsType } from './../types/utils'
// Utilities 
import Utils from './../utils/index'

@Component({
  name: 'GUIAppComponent'
})

export default class GUIApp extends Vue {
  
  
/** ******************************** Vue Props **********************************/

  @Prop({ default: () => ({}) }) private props!: MDIProps | SDIProps;
  @Prop({ default: () => ([]) }) private children!: Array<GUIComponent>

/** ******************************** Vue Data **********************************/

  // Array to store the hot keys pressed, which helps when a single key has multiple hot keys
  public hotKeysHistory: Array<string> = [];

  public utils: UtilsType = new Utils();

/** ******************************** Vue Methods **********************************/

  get focusedApp(): string { return this.$store.getters['guiGuis/getFocusedApp']; }
  get focusedForm(): string { return this.$store.getters['guiGuis/getFocusedForm']; }

  get hotKeys(): Array<HotKey> { 
    // If this is an MDI app and it has a menu or toolbar or statusbar
    if (this.isAnyFormMenus) {
      return this.form!.props.hotKeys.filter((hotKey: HotKey) => hotKey.typeFamily === 'menu');
    } else {
      return this.props.hotKeys; 
    }
  }

  get menuChildren (): Array<GUIComponent> { return this.isAnyFormMenus ? this.formMenuChildren : this.getChildren(this.children, GUIObjectType.gxMenu); }
  get formMenuChildren (): Array<GUIComponent> { return this.form ? this.getChildren(this.form.children, GUIObjectType.gxMenu) : []; }

  get toolbarChildren (): Array<GUIComponent>  { return this.isAnyFormMenus ? this.formToolbarChildren : this.getChildren(this.children, GUIObjectType.gxToolbar); }
  get isToolbar (): boolean { return this.toolbarChildren.length !== 0 }
  get formToolbarChildren (): Array<GUIComponent> { return this.form ? this.getChildren(this.form.children, GUIObjectType.gxToolbar) : []; }

  get statusbarChildren (): Array<GUIComponent>  { return this.isAnyFormMenus ? this.formStatusbarChildren : this.getChildren(this.children, GUIObjectType.gxStatusbar); }
  get isStatusbar (): boolean { return this.statusbarChildren.length !== 0 }
  get formStatusbarChildren (): Array<GUIComponent> { return this.form ? this.getChildren(this.form.children, GUIObjectType.gxStatusbar) : []; }

  get isAnyFormMenus (): boolean { return this.formMenuChildren.length !== 0 || this.formToolbarChildren.length !== 0 || this.formStatusbarChildren.length !== 0; }

  get popupChildren (): Array<GUIComponent>  { return this.getChildren(this.children, GUIObjectType.gxPopup); }

  get childrenNoMenus (): Array<GUIComponent>  { return this.children.filter(child => child.props.typeFamily !== 'menu') }

  get mdi(): boolean { return this.props.type === GUIObjectType.gxMDI; }

  get form(): GUIComponent | null { 
    // if this is an MDI app and there's a focused form
    if (this.mdi && this.focusedForm !== '') {
      return this.$store.getters['guiGuis/getComponent'](this.focusedForm);
    } else {
      return null;
    }
  }

  get sdi(): boolean { return this.props.type === GUIObjectType.gxSDI; }

  get style(): Partial<CSSStyleDeclaration>[] { 
    
    let style: Partial<CSSStyleDeclaration>[] = [];

    const colors = { backgroundColor: this.props.gpBackColor }
    const zIndex = { zIndex: this.props.zIndex.toString() }

    style.push(colors, zIndex)
    
    return style
  }

  get menuStyle(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = [];

    //style.push({ minHeight: '50px', position: 'relative', backgroundColor: this.utils.getHexColor('WINDOW'), color: this.utils.getHexColor('MENUTEXT') });
    style.push({maxHeight: "50px"})
    return style;
  }

  get toolbarStyle(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = [];

    style.push({paddingLeft: "0px"})

    return style;
  }

  get showImage() { return this.props.gpPicture !== '' }


  get bodyStyle(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = [];

    if(this.showImage) {
      const image = this.utils.getImage(this.props.gpPicture);
      const backgroundImage = {
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
      }
      style.push(backgroundImage)
    }

    return style;
  }

  get statusbarStyle(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = [];

    //style.push({ position: 'relative' });

    const height =  "80vh";

    style.push({height: height, backgroundColor: "gray"})

    return style;
  }

  get visible(): boolean { return this.props.gpVisible }

  get enabled(): boolean { return this.props.gpEnabled }

  get showGUI(): boolean { return (this.visible && this.enabled) || this.mdi }

/** ******************************** Vue Methods **********************************/

  getChildren (children: Array<GUIComponent>, type: GUIObjectType): Array<GUIComponent> { return children.filter(child => child.props.type == type); }

  altKeyHandler (e: any) {
    const key = e.key.toLowerCase()
    const keyCode = e.keyCode
    // not sure why hotKey.keyCode does not equal e.keyCode... need to Ask Pete
    const hotKeys: Array<HotKey> = this.hotKeys.filter(hotKey => hotKey.key === key);
    
    // Confirm the form has hot key(s)
    if (hotKeys.length > 0) {
      // Sets the local state hotKey history array and gets the current position
      // Solves for multiple instances of one hot key
      let historyPosition: number = this.hotKeyHistoryPosition(hotKeys);

      const triggerProps: AllProps = this.$store.getters['guiGuis/getProps'](hotKeys[historyPosition].trigger);

      switch (triggerProps.type) {
        case (GUIObjectType.gxMenu): 
          this.$store.dispatch('guiGuis/setMenuItemId', hotKeys[historyPosition].menuId);
        break;
        case (GUIObjectType.gxToolbar):
          // TODO Add toolbar hot key 
          console.log('alt + '+ key +' - Toolbar '+ triggerProps.id);
        break;
        case (GUIObjectType.gxPopup): 
        // TODO Add popup hot key
          console.log('alt + '+ key +' - Popup menu '+ triggerProps.id);
        break;
        case (GUIObjectType.gxStatusbar):
          // TODO Add status hot key 
          console.log('alt + '+ key +' - Status bar '+ triggerProps.id);
        break;
      }

    }
  }

  hotKeyHistoryPosition (hotKeys: Array<HotKey>) {
    const hotKeysLength: number = hotKeys.length;
    let history: number = this.hotKeysHistory.length;

    if (history < hotKeysLength) {
      this.hotKeysHistory.push(hotKeys[history].key);
      // Just confirm the first two aren't the same
      if (this.hotKeysHistory[0] !== hotKeys[0].key) {
        // reset to the new hotKey
        history = 0;
        this.hotKeysHistory = [hotKeys[0].key]
      }

    } else {
      // Reset to new hotKey or potentially the first of the same one
      history = 0;
      this.hotKeysHistory = [hotKeys[0].key]
    }

    return history;
  }

  closeHandler(e: any) {
    //e.stopPropagation();
    // Check that close event is included in the prop events
    if (this.props.gpEventMask & GUIEventCode.geClose) {
      // send this event to the server
      const guiEvent: IGUIEvent = { event: GUIEventCode.geClose, id: this.props.id }

      this.$store.dispatch('guiGuis/addEvent', guiEvent);
    }
  }

  /** ******************************** Vue Life Cycle **********************************/

  mounted() {
    const navbar = document.getElementById("sidebar-nav")
    navbar!.style.top = "10px"
    navbar!.style.right = "60px"
    navbar!.style.zIndex = "100"
  }
}
