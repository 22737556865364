// Store
import store from '../../store'
// Types
import { IGUICmdInit, IGUIRspError, IGUIRspInit } from '../../types'
import { GUIConstants, GUIErrorCode } from '../../types/enums'
import { GuisServiceInitializeType } from '../../types/services/guis'

export default class GuisServiceInitialize implements GuisServiceInitializeType {

    initiate(command: IGUICmdInit) {

        const guiVersion = GUIConstants.guiVersion

        const response: IGUIRspInit = { command: command.command, error: GUIErrorCode.grSuccess, guiVersion: guiVersion }

        store.dispatch('guiResponse/setResponse', response)
    }

}
