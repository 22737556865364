


















































































import { Component, Prop, Vue } from 'vue-property-decorator'
// Types
import { IGUIEvent } from './../../types'
import { GUIObjectType, GUIComponent, GUIEventCode, DialogProps, HotKey } from './../../types/enums'
import { GuisServiceType } from './../../types/services/guis'
import { UtilsType } from './../../types/utils'
// Utilities 
import Utils from './../../utils/index';

@Component({
  name: 'gxDialogComponent'
})

export default class gxDialog extends Vue {
  
/** ******************************** Vue Props **********************************/

  @Prop({ default: () => (new DialogProps()) }) public props!: DialogProps;
  @Prop({ default: () => ([]) }) public children!: Array<GUIComponent>

  /** ******************************** Vue Data **********************************/
  
  public utils: UtilsType = new Utils();

/** ******************************** Vue Computed **********************************/

  get guiService(): GuisServiceType { return this.$store.getters['guiGuis/getGuisService'] }
  get focused(): string { return this.$store.getters['guiGuis/getFocusedForm'] }
  get focusedApp(): string { return this.$store.getters['guiGuis/getFocusedApp'] }
  get hotKeys(): Array<HotKey> { return this.props.hotKeys; }

  get menuChildren (): Array<GUIComponent> { return this.children.filter(child => child.props.type == GUIObjectType.gxMenu) }
  get toolbarChildren (): Array<GUIComponent>  { return this.children.filter(child => child.props.type == GUIObjectType.gxToolbar) }
  get statusbarChildren (): Array<GUIComponent>  { return this.children.filter(child => child.props.type == GUIObjectType.gxStatusbar) }
  get popupChildren (): Array<GUIComponent>  { return this.children.filter(child => child.props.type == GUIObjectType.gxPopup) }

  get childrenNoMenus (): Array<GUIComponent>  { return this.children.filter(child => child.props.typeFamily !== 'menu') }

  get menuStyle() {
    let style: Partial<CSSStyleDeclaration>[] = [];

    style.push({ position: 'relative' });
    //style.push({ backgroundColor: this.utils.getHexColor('WINDOW'), color: this.utils.getHexColor('MENUTEXT') });

    return style;
  }

  get toolbarStyle() {
    let style: Partial<CSSStyleDeclaration>[] = [];

    style.push({ position: 'relative' });

    return style;
  }

  get bodyStyle () {

    let style: Partial<CSSStyleDeclaration>[] = [{ position: 'relative'}];

    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight)
    const font = this.utils.controlFont(this.props)

    style.push(size, font)
    
    return style

  }

  get statusbarStyle() {
    let style: Partial<CSSStyleDeclaration>[] = [];

    style.push({ position: 'relative' });

    return style;
  }

  get visibleAndEnabled(): boolean { return this.visible && this.enabled }

  get visible(): boolean { return this.props.gpVisible }

  get enabled(): boolean { return this.props.gpEnabled }

/** ******************************** Vue Methods **********************************/

  keyHandler (e: any) {

    const key = e.key.toLowerCase()
    const keyCode = e.keyCode
    // not sure why hotKey.keyCode does not equal e.keyCode... need to Ask Pete
    const hotKey: HotKey | undefined = this.hotKeys.find(hotKey => hotKey.key === key)
    
    if (hotKey) {
        console.log('Do something in form ' + hotKey.form + ' for ' + hotKey.control + ' on alt + ' + hotKey.key)
    }
  }

  clickHandler(e: any) {
    // Form should have been activated in focusHandler
    // Clear open popups
    if (this.popupChildren.length > 0) {
      this.popupChildren.forEach(popup => {
        const el = document.getElementById(popup.props.id)
        // confirm the popup is visible and is not contained in the popup element/div
        if (popup.props.gpVisible && !el!.contains(e.target)) {
          this.$store.dispatch('guiGuis/updateProperty', { id: popup.props.id, property: 'gpVisible', value: false }) 
        }
      })
    }
  }

  focusHandler(e: any) {
    // Set as focused
    let event: IGUIEvent;
    const curFormID = this.focused;
    if (this.props.id !== curFormID) {
      const curAppID = this.focusedApp;
      if (this.props.app!.id !== curAppID) {
        if (this.props.app!.gpEventMask & GUIEventCode.geActivate) {
          event = { event: GUIEventCode.geActivate, id: this.props.app!.id, args: [curAppID] }
          this.$store.dispatch('guiGuis/addEvent', event);
        }
      }
      if (this.props.gpEventMask & GUIEventCode.geActivate) {
        event = { event: GUIEventCode.geActivate, id: this.props.id, args: [curFormID] }
        this.$store.dispatch('guiGuis/addEvent', event);
      }
console.log('Dialog focus: ' + this.props.id + ' from ' + curFormID);
      // Set focused in store and in parent app
      this.$store.dispatch('guiGuis/setFocused', { id: this.props.id });
      this.$store.dispatch('guiGuis/updateProperty', {id: this.props.app!.id, property: 'focused', value: this.props.id });
    }
    // If setting focus to a form (not a control on the form), see if the form
    // has a focused control, and actvate it instead of the form (which does not
    // actually do anything with focus.)
    let nextControlID = (e.target && this.utils.getRelatedTargetID(e.target, this.props.parentID, 'controlID')) || null;
    if ((!nextControlID) && this.props.focused) {
console.log('Dialog ' + this.props.id + 'focus handler wants to focus ' + this.props.focused);
    }
  }

  blurHandler(e: any) {
    let formid: string | null = null;
    let event: IGUIEvent;
    // if the current target doesn't contain the related
    if (!e.currentTarget.contains(e.relatedTarget)) {
      const nextFormID = (e.relatedTarget && this.utils.getRelatedTargetID(e.relatedTarget, this.props.parentID, 'formID')) || null;
      if (nextFormID) {
        // don't want to fire Deactivate event if losing focus to another browser tab or other app
        if (this.props.gpEventMask & GUIEventCode.geDeactivate) {
          event = { event: GUIEventCode.geDeactivate, id: this.props.id, args: [nextFormID] }
          this.$store.dispatch('guiGuis/addEvent', event);
        }
        const nextForm: GUIComponent | undefined = this.$store.getters['guiGuis/getComponent'](nextFormID);
        if (nextForm) {
          if (nextForm.props.app!.id !== this.props.app!.id) {
            // if next form belongs to different gui app, fire app deativate event
            if (nextForm.props.app!.gpEventMask & GUIEventCode.geDeactivate) {
              event = { event: GUIEventCode.geDeactivate, id: nextForm.props.app!.id, args: [this.props.app!.id] }
              this.$store.dispatch('guiGuis/addEvent', event);
            }
          }
        }
console.log('Dialog blur: ' + this.props.id + ' to ' + nextFormID);
      }
    }
  }

  controlFocusHandler() {
    console.log('Dialog focused control ', this.props.focused)
    // Set focused in store
    //let el = document.querySelector('[controlid="CM*LOGIN*txUser"], [type="text"]');
    //el.focus()
    
    //this.$store.dispatch('guiGuis/setFocused', { id: 'CM*LOGIN*txUser', typeFamily: 'control' })
    //this.$store.dispatch('guiGuis/setFocused', { id: 'CM*LOGIN*txPW', typeFamily: 'control' })
    this.$store.dispatch('guiGuis/setFocused', { id: this.props.focused, typeFamily: 'control' })
  }

  closeHandler() {
    // Check that close event is included in the prop events
    if (this.props.gpEventMask & GUIEventCode.geClose) {
      // send this event to the server
      const guiEvent: IGUIEvent = { event: GUIEventCode.geClose, id: this.props.id }

      this.$store.dispatch('guiGuis/addEvent', guiEvent);
    }
  }

}
