// Store
import store from '../../store'
// Types
import { IGUICmdCreate, IGUIObject, IGUIRspError, IGUIRspSuccess, simpleNotification } from '../../types'
import { 
            GUICommandCode, GUIComponent, GUIErrorCode, GUIErrorLevel, GUIObjectType, 
            AllProps,
            GuisProps, MDIProps, SDIProps, 
            FormsProps, FormSizableProps, FormFixedProps, DialogProps, SDISizableProps, SDIFixedProps, 
            ControlsProps, LabelProps, EditProps, EditPasswordProps, EditMultilineProps, CommandProps, OptionProps, CheckProps, PictureProps, FrameProps, TabgrpProps, TabProps, GaugeProps, TreeProps, TimerProps, BrowserProps, ListProps, CheckedListProps, ListMultiselProps, DrpDwnCboProps, DrpDwnListProps, GridProps, GridEditableProps, 
            MenusProps, MenuProps, PopupProps, ToolbarProps, StatusbarProps,
            InternalsProps, BorderProps, SizerProps, ControlProps, GUIScaleMode
        } from '../../types/enums'
import { GuisServiceCreateType } from '../../types/services/guis'
// Classes/Arrays
import { GUIComponents } from '../../types/components'
// Utilities 
import Utils from '../../utils/index';


export default class GuisServiceCreate implements GuisServiceCreateType {

    public command: GUICommandCode | null = null;
    // Utilities
    public utils = new Utils();
    // for general notifications
    public notificationScript: string = 'services/guis/create.ts'

    create(command: IGUICmdCreate) {

        this.command = command.command;
        
        const type = command.guiobj.type
        const guiobj: IGUIObject = { ...command.guiobj }

        switch (type) {
            // Create a GUI
            case GUIObjectType.gxMDI: case GUIObjectType.gxSDI:
                this.createGUI(guiobj) 
            break;
            // Create a Form
            case GUIObjectType.gxFormSizable: case GUIObjectType.gxFormFixed: case GUIObjectType.gxDialog: case GUIObjectType.gxSDISizable: case GUIObjectType.gxSDIFixed:
                this.createForm(guiobj)
            break;
            // Create a Control
            case GUIObjectType.gxLabel: case GUIObjectType.gxEdit: case GUIObjectType.gxEditMultiline: case GUIObjectType.gxCommand: case GUIObjectType.gxOption: case GUIObjectType.gxCheck: case GUIObjectType.gxList: case GUIObjectType.gxListMultisel: case GUIObjectType.gxDrpDwnCbo: case GUIObjectType.gxDrpDwnList: case GUIObjectType.gxPicture: case GUIObjectType.gxFrame: case GUIObjectType.gxGrid: case GUIObjectType.gxGridEditable: case GUIObjectType.gxEditPassword: case GUIObjectType.gxTabgrp: case GUIObjectType.gxTab: case GUIObjectType.gxGauge: case GUIObjectType.gxTree: case GUIObjectType.gxCheckedList: case GUIObjectType.gxTimer: case GUIObjectType.gxBrowser:
                this.createControl(guiobj)
            break;
            // Create a Menu
            case GUIObjectType.gxMenu: case GUIObjectType.gxPopup: case GUIObjectType.gxToolbar: case GUIObjectType.gxStatusbar:
                this.createMenus(guiobj)
            break;
            // Handle an Internal
            case GUIObjectType.pxBorder: case GUIObjectType.pxSizer:
                this.createInternals(guiobj)
            break;
        }

    }
    /**
     * Create GUI objects 
     */
    createGUI(guiobj: IGUIObject) {        
        let response: boolean | IGUIRspError = true;
        
        // Confrim there is gui parent
        const root: GUIComponent | undefined = store.getters['guiGuis/getComponent']('*');
        
        if (root) {

            // retrieve from store as it's initially set by the context command 
            const scale = store.getters['guiGuis/getScale']; 
            const version = store.getters['guiGuis/getVersion'];

            let props = this.createGuiProps(guiobj.type)

            // Set standard properties
            props.type = guiobj.type
            props.id = guiobj.id

            props.zIndex = 1
            
            props.gpLeft = this.utils.pixelConversion(<number>guiobj.left, 'h', scale)
            props.gpTop = this.utils.pixelConversion(<number>guiobj.top, 'v', scale)
            props.gpWidth = this.utils.pixelConversion(guiobj.width, 'h', scale)
            props.gpHeight = this.utils.pixelConversion(guiobj.height, 'v', scale)
            props.gpEnabled = true
            props.gpEventMask = guiobj.events 

            props.gpScale = scale
            props.gpVersion = version

            if (props.type === GUIObjectType.gxMDI) {
                props.gpVisible = true // MDI app window is initially visible
            }

            let gui = this.createComponent(root, props)
            // Set the first gui to focused
            if (root.children.length === 0) {
                //root.props.focused = gui.props.id
                store.dispatch('guiGuis/setFocused', { id: gui.props.id })
            }

        } else {
            // error repsonse
            response = { command: this.command!, error: GUIErrorCode.grNoExist, level: GUIErrorLevel.glvlFail, message: 'Unable to create App (' + guiobj.id + ')', args: [guiobj.id, guiobj.type] };
            // Notify
            const notification: simpleNotification = { type: 'warning', show: false, message: 'Unable to create App (' + guiobj.id + ')', friendlyMessage: 'Unable to create App (' + guiobj.id + ')', script: 'services/guis/create.ts', error: { errorCode: GUIErrorCode.grNoExist, errorLevel: GUIErrorLevel.glvlFail } }
            store.dispatch('guiNotifications/addNotification', notification)
        }

        // response
        this.responseHandler(response)

    }

    createGuiProps (type: number) {

        let guiProps: GuisProps;

        switch (type) {
            case GUIObjectType.gxSDI:
                guiProps = new SDIProps()
                break;
            case GUIObjectType.gxMDI:
                guiProps = new MDIProps()
                break;
        }

        return guiProps!
    }
    /**
     * Create form objects 
     */
    createForm(guiobj: IGUIObject) {
        let response: boolean | IGUIRspError = true;

        // Confrim there is gui parent
        const root: GUIComponent | undefined = store.getters['guiGuis/getComponent']('*');
        const gui: GUIComponent | undefined = store.getters['guiGuis/getComponent'](guiobj.parent);

        if (root && gui) {

            const scale: GUIScaleMode = this.utils.getScale(gui.props)
            let props = this.createFormProps(guiobj.type)

            // Set standard properties
            props.type = guiobj.type
            props.id = guiobj.id
            props.parentID = guiobj.parent

            const left: string | number = (guiobj.left === 'auto') ? 'auto' : this.utils.pixelConversion(+guiobj.left, 'h', scale)
            const top: string | number = (guiobj.top === 'auto') ? 'auto' : this.utils.pixelConversion(+guiobj.top, 'v', scale)

            props.gpLeft = left
            props.gpTop = top
            props.gpWidth = this.utils.pixelConversion(+guiobj.width, 'h', scale)
            props.gpHeight = this.utils.pixelConversion(+guiobj.height, 'v', scale)
            props.gpEnabled = true
            props.gpVisible = false
            props.gpEventMask = guiobj.events 

            let form = this.createComponent(gui, props)

            if (form) {
                form.props.app = gui.props as GuisProps
                form.props.form = props
/* - when form is shown, we will call setFocused
                if (gui.props.focused === '' && form.props.focusable) {
//                    gui.props.focused = form.props.id
                    // If this forms's parent gui app is the focused gui app
                    if (root.props.focused === gui.props.id) {
                        // set the currently focused form
                        store.dispatch('guiGuis/setFocused', { id: form.props.id })
                    }
                }
*/                
            }

        } else {
            // error repsonse
            response = { command: this.command!, error: GUIErrorCode.grNoExist, level: GUIErrorLevel.glvlFail, message: 'Unable to create Form (' + guiobj.id + ')', args: [guiobj.id, guiobj.type] };
            // Notify
            const notification: simpleNotification = { type: 'warning', show: false, message: 'Unable to create Form (' + guiobj.id + ')', friendlyMessage: 'Unable to create Form (' + guiobj.id + ')', script: 'services/guis/create.ts', error: { errorCode: GUIErrorCode.grNoExist, errorLevel: GUIErrorLevel.glvlFail } }
            store.dispatch('guiNotifications/addNotification', notification)
        }

        this.responseHandler(response);

        //return guis;        
    }

    createFormProps (type: number) {

        let formProps: FormsProps;

        switch (type) {
            case GUIObjectType.gxFormSizable:
                formProps = new FormSizableProps()
                break;
            case GUIObjectType.gxFormFixed:
                formProps = new FormFixedProps()
                break;
            case GUIObjectType.gxDialog:
                formProps = new DialogProps()
                break;
            case GUIObjectType.gxSDISizable:
                formProps = new SDISizableProps()
                break;
            case GUIObjectType.gxSDIFixed:
                formProps = new SDIFixedProps()
                break;
        }

        return formProps!
    }
    /**
     * Create control objects 
     */
    createControl(guiobj: IGUIObject) {
        let response: boolean | IGUIRspError = true;
        
        // Confrim there is a parent form or control
        const parent: GUIComponent | undefined = store.getters['guiGuis/getComponent'](guiobj.parent);

        if (parent) {

            const formProps: FormsProps = (parent.props.typeFamily === 'form') ? parent.props as FormsProps : parent.props.form!;
            const parentProps: FormsProps | ControlsProps = parent.props as (FormsProps | ControlsProps);
            const scale: GUIScaleMode = this.utils.getScale(parentProps)
            
            let props: ControlsProps = this.createControlProps(guiobj.type, formProps, parentProps)

            // Set standard properties
            props.type = guiobj.type
            props.id = guiobj.id
            props.parentID = guiobj.parent
            props.gpLeft = this.utils.pixelConversion(+guiobj.left, 'h', scale)
            props.gpTop = this.utils.pixelConversion(+guiobj.top, 'v', scale)
            props.gpWidth = this.utils.pixelConversion(+guiobj.width, 'h', scale)
            props.gpHeight = this.utils.pixelConversion(+guiobj.height, 'v', scale)
            props.gpEnabled = true
            props.gpVisible = true
            props.gpEventMask = guiobj.events 
            
            let control = this.createComponent(parent, props)

            if (control) {
                control.props.app = parentProps.app;
                control.props.form = formProps;                
                control.props.tabindex = -1 // maybe one day we'll need specific tabindex numbering
/* when form is shown, we will call setFocused
                if (formProps.focused === '' && control.props.focusable) {
                    formProps.focused = control.props.id
                    // If this control's parent form is the focused form in the app
                    if (control.props.app?.focused === formProps.id) {
                        // then set the currently focused control
                        store.dispatch('guiGuis/setFocused', { id: control.props.id }) 
                    }
                }
*/                
            }
        } else {
            // error repsonse
            response = { command: this.command!, error: GUIErrorCode.grNoExist, level: GUIErrorLevel.glvlFail, message: 'Unable to create control (' + guiobj.id + ')', args: [guiobj.id, guiobj.type] };
            // Notify
            const notification: simpleNotification = { type: 'warning', show: false, message: 'Unable to create control (' + guiobj.id + ')', friendlyMessage: 'Unable to create control (' + guiobj.id + ')', script: 'services/guis/create.ts', error: { errorCode: GUIErrorCode.grNoExist, errorLevel: GUIErrorLevel.glvlFail } }
            store.dispatch('guiNotifications/addNotification', notification)
        }

        this.responseHandler(response);

        //return guis;
    }

    createControlProps (type: number, formProps: FormsProps, parentProps: FormsProps | ControlsProps) {
 
        let controlProps: ControlsProps;

        switch (type) {
            case GUIObjectType.gxLabel:
                controlProps = new LabelProps()
                // form
                controlProps.gpForeColor = formProps.gpForeColor
                controlProps.gpFontSize = formProps.gpFontSize
                controlProps.gpFontBold = formProps.gpFontBold
                controlProps.gpFontItalic = formProps.gpFontItalic
                controlProps.gpFontUnderline = formProps.gpFontUnderline
                controlProps.gpFontName = formProps.gpFontName
                // parent
                controlProps.gpBackColor = parentProps.gpBackColor

                break;
            case GUIObjectType.gxEdit:
            case GUIObjectType.gxEditPassword:
            case GUIObjectType.gxEditMultiline:
                
                switch (type) {
                    case GUIObjectType.gxEdit:
                        controlProps = new EditProps()
                        break;
                    case GUIObjectType.gxEditPassword:
                        controlProps = new EditPasswordProps()
                        break;
                    case GUIObjectType.gxEditMultiline:
                        controlProps = new EditMultilineProps()
                        break;
                }
                // form
                controlProps.gpForeColor = formProps.gpForeColor
                controlProps.gpFontSize = formProps.gpFontSize
                controlProps.gpFontBold = formProps.gpFontBold
                controlProps.gpFontItalic = formProps.gpFontItalic
                controlProps.gpFontUnderline = formProps.gpFontUnderline
                controlProps.gpFontName = formProps.gpFontName

                break;
            case GUIObjectType.gxCommand:
                controlProps = new CommandProps()
                // form
                controlProps.gpFontSize = formProps.gpFontSize
                controlProps.gpFontBold = formProps.gpFontBold
                controlProps.gpFontItalic = formProps.gpFontItalic
                controlProps.gpFontUnderline = formProps.gpFontUnderline
                controlProps.gpFontName = formProps.gpFontName

                break;
            case GUIObjectType.gxOption:
                controlProps = new OptionProps()
                // form
                controlProps.gpForeColor = formProps.gpForeColor
                controlProps.gpFontSize = formProps.gpFontSize
                controlProps.gpFontBold = formProps.gpFontBold
                controlProps.gpFontItalic = formProps.gpFontItalic
                controlProps.gpFontUnderline = formProps.gpFontUnderline
                controlProps.gpFontName = formProps.gpFontName
                // parent
                controlProps.gpBackColor = parentProps.gpBackColor

                break;         
            case GUIObjectType.gxCheck:
                controlProps = new CheckProps()
                // form
                controlProps.gpForeColor = formProps.gpForeColor
                controlProps.gpFontSize = formProps.gpFontSize
                controlProps.gpFontBold = formProps.gpFontBold
                controlProps.gpFontItalic = formProps.gpFontItalic
                controlProps.gpFontUnderline = formProps.gpFontUnderline
                controlProps.gpFontName = formProps.gpFontName
                // parent
                controlProps.gpBackColor = parentProps.gpBackColor

                break;
            case GUIObjectType.gxPicture:
                controlProps = new PictureProps()
                break;
            case GUIObjectType.gxFrame:
                controlProps = new FrameProps()                
                // form
                controlProps.gpForeColor = formProps.gpForeColor
                controlProps.gpFontSize = formProps.gpFontSize
                controlProps.gpFontBold = formProps.gpFontBold
                controlProps.gpFontItalic = formProps.gpFontItalic
                controlProps.gpFontUnderline = formProps.gpFontUnderline
                controlProps.gpFontName = formProps.gpFontName
                // parent
                controlProps.gpBackColor = parentProps.gpBackColor

                break;
            case GUIObjectType.gxTabgrp:
                controlProps = new TabgrpProps()                
                // form
                controlProps.gpBackColor = formProps.gpBackColor
                controlProps.gpForeColor = formProps.gpForeColor
                controlProps.gpFontSize = formProps.gpFontSize
                controlProps.gpFontBold = formProps.gpFontBold
                controlProps.gpFontItalic = formProps.gpFontItalic
                controlProps.gpFontUnderline = formProps.gpFontUnderline
                controlProps.gpFontName = formProps.gpFontName

                break;
            case GUIObjectType.gxTab:
                controlProps = new TabProps()
                // form
                controlProps.gpBackColor = formProps.gpBackColor
                controlProps.gpForeColor = formProps.gpForeColor
                controlProps.gpFontSize = formProps.gpFontSize
                controlProps.gpFontBold = formProps.gpFontBold
                controlProps.gpFontItalic = formProps.gpFontItalic
                controlProps.gpFontUnderline = formProps.gpFontUnderline
                controlProps.gpFontName = formProps.gpFontName

                break;
            case GUIObjectType.gxGauge:
                controlProps = new GaugeProps()                
                // form
                controlProps.gpFontSize = formProps.gpFontSize
                controlProps.gpFontBold = formProps.gpFontBold
                controlProps.gpFontItalic = formProps.gpFontItalic
                controlProps.gpFontUnderline = formProps.gpFontUnderline
                controlProps.gpFontName = formProps.gpFontName

                break;
            case GUIObjectType.gxTree:
                controlProps = new TreeProps()
                // form
                controlProps.gpForeColor = formProps.gpForeColor
                controlProps.gpFontSize = formProps.gpFontSize
                controlProps.gpFontBold = formProps.gpFontBold
                controlProps.gpFontItalic = formProps.gpFontItalic
                controlProps.gpFontUnderline = formProps.gpFontUnderline
                controlProps.gpFontName = formProps.gpFontName

                break;
            case GUIObjectType.gxTimer:
                controlProps = new TimerProps()
                break;
            case GUIObjectType.gxBrowser:
                controlProps = new BrowserProps()
                break;
            case GUIObjectType.gxList:
            case GUIObjectType.gxCheckedList:
            case GUIObjectType.gxListMultisel:
            case GUIObjectType.gxDrpDwnList:
            case GUIObjectType.gxDrpDwnCbo:

                switch (type) {
                    case GUIObjectType.gxList:
                        controlProps = new ListProps()
                        break;
                    case GUIObjectType.gxCheckedList:
                        controlProps = new CheckedListProps()
                        break;
                    case GUIObjectType.gxListMultisel:
                        controlProps = new ListMultiselProps()
                        break;
                    case GUIObjectType.gxDrpDwnList:
                        controlProps = new DrpDwnListProps()
                        break;
                    case GUIObjectType.gxDrpDwnCbo:
                        controlProps = new DrpDwnCboProps()
                        break;
                }
                
                // form
                controlProps.gpForeColor = formProps.gpForeColor
                controlProps.gpFontSize = formProps.gpFontSize
                controlProps.gpFontBold = formProps.gpFontBold
                controlProps.gpFontItalic = formProps.gpFontItalic
                controlProps.gpFontUnderline = formProps.gpFontUnderline
                controlProps.gpFontName = formProps.gpFontName

                break;
            case GUIObjectType.gxGrid:
            case GUIObjectType.gxGridEditable:
                
                switch (type) {
                    case GUIObjectType.gxGrid:
                        controlProps = new GridProps()
                        break;
                    case GUIObjectType.gxGridEditable:
                        controlProps = new GridEditableProps()
                        break;
                }
              
                // form
                controlProps.gpForeColor = formProps.gpForeColor
                controlProps.gpFontSize = formProps.gpFontSize
                controlProps.gpFontBold = formProps.gpFontBold
                controlProps.gpFontItalic = formProps.gpFontItalic
                controlProps.gpFontUnderline = formProps.gpFontUnderline
                controlProps.gpFontName = formProps.gpFontName

                break;  
        }

        return controlProps!
    }
    /**
     * Create menu objects 
     */
    createMenus(guiobj: IGUIObject) {   
        let response: boolean | IGUIRspError = true;

        // Confrim there is a parent form or gui
        const parent: GUIComponent | undefined = store.getters['guiGuis/getComponent'](guiobj.parent);

        // Parent can be a GUI, Form or Control
        if (parent) {
            let formProps: FormsProps | null, guiProps: GuisProps | null; 
            const parentTypeFamily = parent.props.typeFamily;

            if (parentTypeFamily === 'app') {
                guiProps = parent.props as GuisProps;
            } else {
                guiProps = parent.props.app || null;
            }

            if (parentTypeFamily === 'form') {
                formProps = parent.props as FormsProps;
            } else {
                formProps = parent.props.form || null;
            }

            const parentProps: GuisProps | FormsProps | ControlsProps = parent.props as (GuisProps | FormsProps | ControlsProps);
            const scale: GUIScaleMode = this.utils.getScale(parentProps)
            
            let props: MenusProps = this.createMenuProps(guiobj.type)

            // Set standard properties
            props.type = guiobj.type
            props.id = guiobj.id
            props.parentID = guiobj.parent
            props.gpLeft = this.utils.pixelConversion(+guiobj.left, 'h', scale)
            props.gpTop = this.utils.pixelConversion(+guiobj.top, 'v', scale)
            props.gpWidth = this.utils.pixelConversion(+guiobj.width, 'h', scale)
            props.gpHeight = this.utils.pixelConversion(+guiobj.height, 'v', scale)
            props.gpEventMask = guiobj.events 
            
            const menu = this.createComponent(parent, props)

            if (menu) {
                props.app = guiProps;
                props.form = formProps;                
                menu.props.tabindex = -1 // maybe one day we'll need specific tabindex numbering
            }
            
        } else {
            // error repsonse
            response = { command: this.command!, error: GUIErrorCode.grNoExist, level: GUIErrorLevel.glvlFail, message: 'Unable to create menu (' + guiobj.id + ')', args: [guiobj.id, guiobj.type] };
            // Notify
            const notification: simpleNotification = { type: 'warning', show: false, message: 'Unable to create menu (' + guiobj.id + ')', friendlyMessage: 'Unable to create menu (' + guiobj.id + ')', script: 'services/guis/create.ts', error: { errorCode: GUIErrorCode.grNoExist, errorLevel: GUIErrorLevel.glvlFail } }
            store.dispatch('guiNotifications/addNotification', notification)
        }

        this.responseHandler(response);

        //return guis;

    }

    createMenuProps (type: number) {

        let menuProps: MenusProps;

        switch (type) {
            case GUIObjectType.gxMenu:
                menuProps = new MenuProps()
                break;
            case GUIObjectType.gxPopup:
                menuProps = new PopupProps()
                break;
            case GUIObjectType.gxToolbar:
                menuProps = new ToolbarProps()
                break;
            case GUIObjectType.gxStatusbar:
                menuProps = new StatusbarProps()
                break;
        }

        return menuProps!
    }
    /**
     * Create internal objects 
     */
    createInternals(guiobj: IGUIObject) {   
        let response: boolean | IGUIRspError = true;
    
        // Confrim there is a parent
        const parent: GUIComponent | undefined = store.getters['guiGuis/getComponent'](guiobj.parent);
        if (parent) {

            let props = this.createInternalProps(guiobj.type)
            const obj = this.createComponent(parent, props)

        } else {
            // error repsonse
            response = { command: this.command!, error: GUIErrorCode.grNoExist, level: GUIErrorLevel.glvlFail, message: 'Unable to create object (' + guiobj.id + ')', args: [guiobj.id, guiobj.type] };
            // Notify
            const notification: simpleNotification = { type: 'warning', show: false, message: 'Unable to create object (' + guiobj.id + ')', friendlyMessage: 'Unable to create object (' + guiobj.id + ')', script: 'services/guis/create.ts', error: { errorCode: GUIErrorCode.grNoExist, errorLevel: GUIErrorLevel.glvlFail } }
            store.dispatch('guiNotifications/addNotification', notification)
        }

        this.responseHandler(response);

        //return guis;
    }

    createInternalProps (type: number) {

        let internalProps: InternalsProps;

        switch (type) {
            case GUIObjectType.pxBorder:
                internalProps = new BorderProps()
                break;
            case GUIObjectType.pxSizer:
                internalProps = new SizerProps()
                break;
        }

        return internalProps!
    }

    createComponent(parent: GUIComponent, props: AllProps): GUIComponent {
        const component = {
            props: props,
            component: GUIComponents[props.type],
            children: []
        }
        parent.children.push(component);
        props.parent = parent.props;
        
        store.dispatch('guiGuis/setComponent', { id: props.id.toUpperCase(), component: component })

        return component;
    }

    responseHandler(response: boolean | IGUIRspError) {

        if (typeof response === 'boolean') {
            store.dispatch('guiResponse/setResponse', { command: this.command!, error: GUIErrorCode.grSuccess })
        } else {
            store.dispatch('guiResponse/setResponse', response)
        }
    }

}

