







import { Component, Vue, Prop } from 'vue-property-decorator'
// Types
import { GUIDialogIcon } from '../../../types/enums'

@Component({
  name: 'MessageBoxComponent'
})

export default class MessageBox extends Vue {
  
/** ******************************** Vue Data **********************************/

  @Prop() public dialogBox: any;

/** ******************************** Vue Computed **********************************/

    get icon(): GUIDialogIcon { return this.dialogBox.icon }

    get iconName () {    
        let iconName: string
        switch (this.dialogBox.icon) {
            case(1) :
                iconName = 'x-circle-fill';
            break;
            case(2) :
                iconName = 'exclamation-triangle-fill';
            break;
            case(3) :
                iconName = 'question-circle-fill';
            break;
            case(4) :
                iconName = 'info-circle-fill';
            break;
            default :
                iconName = '' // no icon
            break;
        }
        return iconName;
    }

    get iconStyle () {
        let sizeColor: any = {}
        let style: Array<any> = []
        sizeColor.float = 'left';
        sizeColor.width = '32px';
        sizeColor.height = '32px';
        if (this.dialogBox.icon === 1){
            sizeColor.color = '#f03a17';
        } else if (this.dialogBox.icon === 2){
            sizeColor.color = '#fce100';
        } else if (this.dialogBox.icon === 3 || this.dialogBox.icon === 4) {
            sizeColor.color = '#006dca';
        } else {
            sizeColor.color = '#000000';
        }    
        style.push(sizeColor)
        return style
    }

/** ******************************** Vue Methods **********************************/

}
