






















import { Component, Vue } from 'vue-property-decorator'
// Types
import { notification } from './../../types'

@Component({
    name: 'NotificationsComponent'
})

export default class Notifications extends Vue {
/** ******************************** Vue Computed **********************************/
    get activeNotifications (): Array<notification> { return this.$store.getters['guiNotifications/getNotifications'].filter((notification: notification) => (notification.active && notification.show)) }

/** ******************************** Vue Method **********************************/

    closeHandler (id: number) {
        this.$store.dispatch('guiNotifications/dismissNotification', { key: id })
    }

}
