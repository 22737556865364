import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
// Types
import { RootState } from '@/types/store/'
import { TerminalState } from '@/types/store/terminal'
import { actionErrorObject } from '@/types/store/errors'
// Terminal Service
import { TerminalService } from '@/types/services/terminal'
import AccuTermService from '@/services/terminal'
import Profile from '@/types'

// State
export const state: TerminalState = {
    accutermService: null,
    accuTerm: null,
    viewport: null,
    openStatus: false,
    connectionStatus: false,
    focusStatus: false,
    messageBox: {
        title: '',
        message: '',
        button1: '',
        button2: '',
        button3: '',
        callback: (selectedButton) => selectedButton
    },
    status: {
        message: null,
        col: 0,
        row: 0,
        page: 0
    },
    clipboard: {
        term: ''
    },
    accutermStyle: {
        height: '86vh',
        maxHeight: 'auto', // '700px',
        width: '100%',
        maxWidth: 'auto' // '1024px'
    }
}

// Namespace
const namespaced: boolean = true

// Getters
export const getters: GetterTree<TerminalState, RootState> = {
    hasAccuTerm(state) { return !!(state.accuTerm) },
    getAccuTerm(state) { return state.accuTerm },
    getAccuTermStyle(state) { return state.accutermStyle },
    getClipboardTerm(state) { return state.clipboard.term },
    getConnectionStatus(state) { return state.connectionStatus },
    messageBox(state) { return state.messageBox },
    getStatusMessage(state) { return state.status.message },
    getStatusCol(state) { return state.status.col },
    getStatusRow(state) { return state.status.row },
    getStatusPage(state) { return state.status.page },
    getViewport(state) { return state.viewport }
}

// Mutations
export const mutations: MutationTree<TerminalState> = {
    ACCUTERM_STYLE(state, style) {
        state.accutermStyle = { ...style }
    },
    SET_TERM(state, accuTerm) {
        state.accuTerm = accuTerm
    },
    SET_VIEWPORT(state, viewport) {
        state.viewport = viewport
    },
    SET_OPEN_TERM(state, { status }) {
        state.openStatus = status
    },
    TOGGLE_CONNECTION(state, { status }) {
        state.connectionStatus = status
    },
    SET_FOCUS(state, { status }) {
        state.openStatus = status
    },
    SET_MESSAGE_BOX(state, options) { state.messageBox = { ...options } },
    SET_STATUS_MESSAGE(state, message) { state.status.message = message },
    SET_STATUS_COL(state, col) { state.status.col = col },
    SET_STATUS_ROW(state, row) { state.status.row = row },
    SET_STATUS_PAGE(state, page) { state.status.page = page },
    SET_CLIPBOARD_TERM(state, text) { state.clipboard.term = text },
    SET_ACCUTERM_SERVICE(state, ts) { state.accutermService = ts }
}

// Actions
export const actions: ActionTree<TerminalState, RootState> = {
    setTerminalService({ commit, dispatch }, ts: TerminalService) {
        let logError: actionErrorObject = { type: 'profile', show: true, message: '', friendly: 'We are currently unable to the Terminal Service', script: 'store/terminal/index.ts' }

        try {
            ts = ts || new AccuTermService()

            commit('SET_ACCUTERM_SERVICE', ts)
        } catch (error) {
            logError.message = 'Unable to start Terminal Service - ' + error
            dispatch('errors/addError', { ...logError }, { root: true })
        }
    },
    setClipboardTerm({ commit }, text) {
        commit('SET_CLIPBOARD_TERM', text)
    },
    setMessageBox({ commit }, options) {
        commit('SET_MESSAGE_BOX', options)
    },
    setStatus({ commit }, status) {
        commit('SET_STATUS_COL', status.col)
        commit('SET_STATUS_ROW', status.row)
        commit('SET_STATUS_PAGE', status.page)
    },
    setStatusMessage({ commit }, message) {
        commit('SET_STATUS_MESSAGE', message)
    },
    toggleConnection({ commit }, status) {
        commit('TOGGLE_CONNECTION', { status: status })
    },
    accutermStyle({ commit, dispatch }, style) {
        commit('ACCUTERM_STYLE', { ...style })
        // Set a timeout to wait for bootstrap's 'Collapse' keyboard toggle
        setTimeout(() => { dispatch('containerResize') }, 250)
    },
    // Terminal Service
    breakKey({ state }) {
        state.accutermService.breakKey(state.accuTerm)
    },
    connect({ commit, dispatch, state }) {
        state.accutermService.connect(state.accuTerm)
        commit('TOGGLE_CONNECTION', { status: true })
        dispatch('focus', state.accuTerm)
    },
    containerResize({ state }) {
        state.accutermService.containerResize(state.accuTerm)
    },
    disconnect({ commit, dispatch, state }) {
        state.accutermService.disconnect(state.accuTerm)
        commit('TOGGLE_CONNECTION', { status: false })
        dispatch('focus', state.accuTerm)
    },
    focus({ commit, state }) {
        state.accutermService.focus(state.accuTerm)
        commit('SET_FOCUS', { status: true })
    },
    initiateTerm(context, payload) {
        // Set a promise so we can dispatch other actions after the terminal loads
        // https://vuex.vuejs.org/guide/actions.html#composing-actions
        return new Promise<void>((resolve, reject) => {
            context.commit('SET_TERM', state.accutermService.initiateTerm(payload))
            resolve()
        })
    },
    initiateViewport({ commit, state }) {
        commit('SET_VIEWPORT', state.accutermService.getViewport(state.accuTerm))
    },
    openTerm({ commit, state }) {
        state.accutermService.openTerm(state.accuTerm!)
        commit('SET_OPEN_TERM', { status: true })
    },
    paste({ state }, payload: { text: string }) {
        state.accutermService.paste({ terminal: state.accuTerm, text: payload.text })
    },
    reset({ state }) {
        state.accutermService.reset(state.accuTerm)
    },
    sendClick({ state }, payload: { e: MouseEvent }) {
        state.accutermService.sendClick({ terminal: state.accuTerm, e: payload.e })
    },
    sendKeys({ state }, payload: { key: string }) {
        state.accutermService.sendKeys({ terminal: state.accuTerm, key: payload.key })
    },
    sendFunctionKey({ state }, payload: { key: number }) {
        state.accutermService.sendFunctionKey({ terminal: state.accuTerm, key: payload.key })
    },
    updateSettings({ state }, payload: { profile: Profile, options: { resetTerminal: boolean, resetConnection: boolean } }) {
        state.accutermService.updateSettings({ terminal: state.accuTerm, profile: payload.profile, options: payload.options })
    },
    // Listeners
    setListeners({ state }, payload: { term: HTMLElement, beep: HTMLMediaElement }) {
        state.accutermService.audioListener({ terminal: state.accuTerm, beep: payload.beep })
        state.accutermService.browserListener(state.accuTerm)
        state.accutermService.clipboardListener(state.accuTerm)
        state.accutermService.colorChangeListener({ terminal: state.accuTerm, term: payload.term })
        state.accutermService.cursorListener(state.accuTerm)
        state.accutermService.emailListener(state.accuTerm)
        state.accutermService.guiListener(state.accuTerm)
        state.accutermService.messageBoxListener(state.accuTerm)
        state.accutermService.phoneListener(state.accuTerm)
        state.accutermService.statusListener(state.accuTerm)
    }
}

export const terminal: Module<TerminalState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}
