// Store
import store from './../../store'
// Types
import { GUICommand } from '../../types'
import { RequestServiceType } from '../../types/services/request/'

export default class RequestService implements RequestServiceType {


  requestHandler (request: Array<GUICommand>) {
    
    store.dispatch('guiRequest/requestLength', request.length)

    request.forEach(requestCommand => {
      
      const command = { ...requestCommand };
 
      store.dispatch('guiGuis/commandHandler', command)

      store.dispatch('guiRequest/addRequestCommand', command)

    })
  }

}

