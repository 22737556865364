
































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// Types
import { TreeProps, GUIEventCode, GUIErrorCode, GUIErrorLevel, TreeItemProps } from '../../../types/enums'
import { IGUIEvent, simpleNotification, IGUIRspError } from '../../../types'
import { TreeItem } from '../../../types/components'
import { UtilsType } from '../../../types/utils'

// Utilities 
import Utils from '../../../utils/index';
// Components
import Branch from './Branch.vue'

@Component({
  name: 'gxTree',
  components: { Branch }
})

export default class gxTree extends Vue {
  /** ******************************** Vue Props **********************************/

    @Prop({ default: () => (new TreeProps()) }) private props!: TreeProps;

  /** ******************************** Vue Data **********************************/

  public utils: UtilsType = new Utils();
  
  public activated: boolean = false;

  // Notifications
  public script: string = 'components/controls/gxTree.vue'

  /** ******************************** Vue Computed **********************************/
  get focused() { return this.$store.getters['guiGuis/getFocusedControl']; }
  get visible() { return this.props.gpVisible }
  get enabled() { return this.props.gpEnabled }

  get value(): Array<string> { 
    // Value examples:
    // ''
    // 'root\parent\child\grandchild'
    // ['root\parent', 'root\parent\child\grandchild', 'root\parent-2']
    let selected: Array<string> = []

    // Built an array of all the selected ids
    if (Array.isArray(this.props.gpValue)) {
      selected = [ ...this.props.gpValue ]
    } else if (this.props.gpValue !== '') {
      selected.push(this.props.gpValue)
    } 
    
    return selected

  }
  set value(val: Array<string>) { 
    
    let newValue: string | Array<string> = [ ...val ];

    if (!this.isMultiSelect) {
      newValue = (newValue.length !== 0) ? newValue.pop()! : ''
    } 
    
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'changed', value: true })
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpChanged', value: true })
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpValue', value: newValue }) 
  }

  get firstTreeBranch () { return this.props.gpItems[0].id }

  // For tree data structure please see:
  // https://vuejs.org/v2/examples/tree-view.html
  get items() : Array<TreeItem> { 
    let items = { children: [] };
    let levels: Array<any> = [];
    let curLevel: number = 1;
    let topItem: any = items;
    levels[0] = items;
    
    this.props.gpItems.forEach((item: TreeItemProps) => {
      let level = item.level;

      if (level < 1 || level > curLevel + 1) { 
        // Error Notification
        const notification: simpleNotification = { type: 'danger', show: true, message: 'Tree level is out of order', friendlyMessage: 'We encountered a error while building the tree control (' + this.props.id + ')', script: this.script, error: { errorCode: GUIErrorCode.grFailure, errorLevel: GUIErrorLevel.glvlWarn } }
        this.$store.dispatch('guiNotifications/addNotification', notification)
      }

      if (curLevel === level) {
          // sibling at current level
      } else if (curLevel + 1 === level) {
          // child
          topItem = topItem.children[topItem.children.length - 1];
          levels[curLevel] = topItem;
      } else {
          // sibling at previous level
          topItem = levels[level - 1];
      }

      let indicies : Array<number> = []

      if (level > 1) {
          indicies = [...levels[level - 1].indicies]
      }

      indicies.push(topItem.children.length)
      
      let visualSelection: boolean = false

      if (this.value.length !== 0 && this.selectionType === 'single') {
        visualSelection = item.path === this.value[0]
      } else {
        visualSelection = item.id === this.firstTreeBranch
      }

      let newItem: TreeItem = { 
        ...item, 
        indicies: indicies, 
        visualSelection: visualSelection,
        selected: this.value.includes(item.path),
        selectionType: this.selectionType,
        style: this.decor,
        children: [] 
      };
    
      topItem.children.push(newItem);
      curLevel = level;
    });

    return items.children;
  }

  set items(items: Array<TreeItem>) {
    const newItems = this.convertItems(items)
    this.$store.dispatch('guiGuis/updateProperty', { id: this.props.id, property: 'gpItems', value: newItems }) 
  }

  get selectionType() {    
    let selection: string = 'single'
    if ((+this.props.gpStyle & 0x18) === 0x8) {
      selection = 'multi' 
    } else if ((+this.props.gpStyle & 0x18) === 0x10) {
      selection = 'hierarchical'
    } 
  
    return selection
  }
  
  get isMultiSelect() { return ((+this.props.gpStyle & 0x18) !== 0) }

  get decor() { 
    // 0 = text only 
    // 1 = text & sign 
    // 2 = text & small icon 
    // 3 = text & small icon & sign 
    // 4 = text & large icon 
    // 5 = text & large icon & sign
    return +this.props.gpStyle & 0x7;    
  }

  get treeStyle() {
    let style: Array<any> = []
    const border = this.utils.controlBorder(this.props)
    const positioning = this.utils.controlPositionCSS(this.props.gpTop, this.props.gpLeft);
    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight)
    const containerCSS = { 
      backgroundColor: this.props.gpBackColor, 
      color: this.props.gpForeColor,
      overflow: 'auto'
      }
    
    style.push(size)
    style.push(positioning)
    style.push(containerCSS)
    style.push(border)

    return style
  }    

  get itemStyle() { 
      let style: Array<any> = []
      const font = this.utils.controlFont(this.props)  
      const alignment = this.utils.controlAlign(this.props)
      const containerCSS = { 
        backgroundColor: this.props.gpBackColor,
        color: this.props.gpForeColor
      }

    style.push(font)
    style.push(alignment)
    style.push(containerCSS)

    return style
  }

  /** ******************************** Vue Methods **********************************/

  statusHandler(item: TreeItem, status: boolean) {

    let args: Array<any> = []
    // path
    args.push(item.path)
    // status
    args.push((status) ? 1 : 0)

    let guiEvent: IGUIEvent = { event: GUIEventCode.geStatus, id: this.props.id, args: args }

    if (this.props.gpEventMask & GUIEventCode.geStatus) {
      // send this event to the server
      this.$store.dispatch('guiGuis/addEvent', guiEvent)
    }
  }

  leftClickHandler(item: TreeItem, type: string) {
    let args: Array<any> = []
    // path
    args.push(item.path)

    let guiEvent: IGUIEvent = { event: GUIEventCode.geClick, id: this.props.id, args: args }
    // Single Click
    if (type === 'single') {

      let newValue = new Set([ ...this.value ]);

      // selected corresponds with the checkboxes in multi select and hierarchy
      // visual selection really only is used in single select at this point...
      // eventually we could use to show visually where they click in any
      if (item.selected || item.visualSelection) {
        newValue.add(item.path)
      } else if (newValue.has(item.path)) {
        newValue.delete(item.path)  
      }            

      if (this.selectionType === 'hierarchical') {
        
        let children = this.getChildren(item)
        let parents = this.getParents(item)


        if (item.selected) {
          // combine all the children and parent into one big set
          newValue = new Set([...newValue, ...parents, ...children])
        } else {
          // remove parents from the set
          parents.forEach(parent => {
            if (newValue.has(parent)) { newValue.delete(parent) }
          })          
          // remove children from the set
          children.forEach(child => {
            if (newValue.has(child)) { newValue.delete(child) }
          })
        }
      }

      this.value = [ ...newValue ]

      // Fire Event
      if (this.props.gpEventMask & GUIEventCode.geClick) {
          this.$store.dispatch('guiGuis/addEvent', guiEvent);
      }

    }
    // Double Click
    if (type === 'double') {
      
      if (!this.isMultiSelect) { 
        this.value = [item.path]
      }
      
      // Fire Event
      guiEvent.event = GUIEventCode.geDblClick

      if (this.props.gpEventMask & GUIEventCode.geDblClick) {
          this.$store.dispatch('guiGuis/addEvent', guiEvent);
      }
    }

  }

  rightClickHandler(e: MouseEvent, item?: TreeItem) {
    e.stopPropagation();

    let guiEvent: IGUIEvent = { event: GUIEventCode.geContext, id: this.props.id }

    if (item) {
      guiEvent.args = [item.path]
    } 
    
    if (this.props.gpEventMask & GUIEventCode.geContext) {
      // send this event to the server
      this.$store.dispatch('guiGuis/addEvent', guiEvent);
    }

    this.$store.dispatch('guiGuis/updateMousePosition', this.utils.getMousePositionRelativeToParent(e, this.props.form!.id))
  }

  focus() {
    if (this.focused === this.props.id && this.enabled && this.visible) {
      try {
        // get the tree container DIV element
        const elem: any = this.$refs[this.props.id];
        // set focus to the DIV - must have tabindex to accept focus!
        (elem as HTMLElement).focus();
console.log('Tree Focus Me '+ this.value +' in '+ this.props.id);
      } catch(e) {
console.log('Tree Focus Me error: ' + e);
      }
    }
  }

  activateHandler(e: any) {
    if (!this.activated) {
      this.activated = true;      
      let prevControlID = (e.relatedTarget && this.utils.getRelatedTargetID(e.relatedTarget, '', 'controlID')) || null;
      if (!prevControlID) {
        // handle the deferred deactivate event
        prevControlID = this.utils.handleDeferredCtlDeactivate(this.props.id);
      }
      if (prevControlID !== this.props.id) {
console.log('Tree activate ' + this.props.id + ' from ' + prevControlID);
        if (this.props.gpEventMask & GUIEventCode.geActivate) {
          const activateEvent: IGUIEvent = { event: GUIEventCode.geActivate, id: this.props.id, args: [prevControlID] }
          this.$store.dispatch('guiGuis/addEvent', activateEvent);
        }
        // Clear the changed property
        this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'changed', value: false })
      } else console.log('   skipping Tree activate ' + this.props.id + ' - self');
      // Set focused in store and in parent form
      this.$store.dispatch('guiGuis/setFocused', { id: this.props.id })
      this.$store.dispatch('guiGuis/updateProperty', {id: this.props.form!.id, property: 'focused', value: this.props.id })
    } 
  }

  deactivateHandler(e: any) {
    if (this.activated) {
      // if the current target doesn't contain the related
      if (!e.currentTarget.contains(e.relatedTarget)) {
        this.activated = false
        const nextControlID = (e.relatedTarget && this.utils.getRelatedTargetID(e.relatedTarget, '', 'controlID')) || null;
        if (nextControlID) {
          this.handleDeactivateEvents(nextControlID);
        } else {
          this.$store.dispatch('guiGuis/setDeferredCtlDeactivate', this.handleDeactivateEvents.bind(this));
console.log('   deferring Tree deactivate ' + this.props.id + ' related = null');
        }
      }
    }
  }

  handleDeactivateEvents(nextControlID: string): string {
    if (nextControlID !== this.props.id) {
console.log('Tree deactivate ' + this.props.id + ' to ' + nextControlID);
      if (this.props.gpEventMask & GUIEventCode.geValidate && this.props.changed) {
        const validateEvent: IGUIEvent = { event: GUIEventCode.geValidate, id: this.props.id, args: [nextControlID], value: this.value }
        this.$store.dispatch('guiGuis/addEvent', validateEvent);
      }
      if (this.props.gpEventMask & GUIEventCode.geDeactivate) {
        const deactivateEvent: IGUIEvent = { event: GUIEventCode.geDeactivate, id: this.props.id, args: [nextControlID] }
        this.$store.dispatch('guiGuis/addEvent', deactivateEvent);
      }
    } else console.log('   skipping Tree deactivate ' + this.props.id + ' - self')
    return this.props.id;
  }

  updateItem(item: TreeItem) { 
    const itemIndicies: Array<number> = [ ...item.indicies ] 
    let itemsReference: Array<TreeItem> = this.items;
    let parent = itemsReference;

    // Traverse the items tree to create path
    for (let i = 0; i < itemIndicies.length; i++) {
      // if it's the last index replace it
      if ((itemIndicies.length - 1) === i) {
        parent[itemIndicies[i]] = item
      } else {
        parent = parent[itemIndicies[i]].children 
      }
    }

    this.items = itemsReference
  }

  convertItems (items: Array<TreeItem>) : Array<TreeItemProps> {

    let returnArray: Array<TreeItemProps> = []

    items.forEach((item: TreeItem) => {

      returnArray.push({ id: item.id, path: item.path, level: item.level, caption: item.caption, icon: item.icon, state: item.state, tip: item.tip })

      if (item.children.length !== 0) {
        const children: Array<TreeItemProps> = this.convertItems(item.children)
        
        children.forEach((child: TreeItemProps) => {
          returnArray.push(child)
        })
                
      }      
    })

    return returnArray
  }

  get getBranchChildren() {
    return this.items;
  }

  getChildren(item: TreeItem): Array<string> {
    let children: Array<string> = []

    if (item.children.length !== 0) {

      item.children.forEach(child => {
        children.push(child.path)
        
        if (child.children.length !== 0) {
          let childsChildren = this.getChildren(child)
          
          children = children.concat(childsChildren)
        }        
      })
    }

    return children
  }

  getParents(item: TreeItem): Array<string> {
    let parents: Array<string> = []

    const pathParts = item.path.split('\\')
    // remove the choosen branch
    pathParts.pop()
    
    if (pathParts.length !== 0) {
      
      for (let i = 1; i <= pathParts.length; i++) {
        
        let part = pathParts.slice(0, i);
        
        if (part.length > 1) {
          parents.push(part.join('\\'))
        } else {
          parents.push(part[0])
        }

      }
    }
    
    return parents
  }

  mounted() {
    this.$nextTick(() => { this.focus(); });
  }

/** ******************************** Vue Watch and Emit Events! **********************************/

  @Watch('focused') passRequest () { 
    this.focus()
  }  

}
