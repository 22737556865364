// Vue
import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
// Types
import { RootState } from './../types/store/'
// Submodules
import { guiGeneral } from './../store/general/'
import { guiNotifications } from './../store/notifications/'
import { guiGuis } from './../store/guis/'
import { guiRequest } from './../store/request/'
import { guiResponse } from './../store/response/'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
    state: {
    },
    getters: {

    },
    mutations: {
    },
    actions: {
    },
    modules: {
        guiGeneral,
        guiNotifications,
        guiGuis,
        guiRequest,
        guiResponse
    }
}

export default new Vuex.Store<RootState>(store)
