// Vue
import Vue from 'vue'
// Store
import store from './store'
// Store Modules
import { guiGeneral } from './store/general/'
import { guiNotifications } from './store/notifications/'
import { guiGuis } from './store/guis/'
import { guiRequest } from './store/request/'
import { guiResponse } from './store/response/'
// Components
import AccuTermGUI from './App.vue'

const vm = new Vue({ store: store })

// Plugin Support
const install = (vm: any, options: any) => {
  // Confirm the store is passed
  if (!options || !options.store) {
    throw new Error('Please initialize plugin with a Vuex store.');
  }

  // https://forum.vuejs.org/t/problem-with-importing-store-in-a-custom-package-meant-for-npm/18380/3
  options.store.registerModule('guiGeneral', guiGeneral)
  options.store.registerModule('guiNotifications', guiNotifications)
  options.store.registerModule('guiGuis', guiGuis)
  options.store.registerModule('guiRequest', guiRequest)
  options.store.registerModule('guiResponse', guiResponse)

  // Register the components
  vm.component('accuterm-gui', AccuTermGUI)
  // ...
}
  
export default install
