// Store
import store from '../../store'
// Types
import { IGUICmdSetProp, IGUIRspError, IGUIRspSuccess, simpleNotification } from '../../types'
import { GUIObjectType, GUIComponent, GUIProperty, GUIScaleMode, GUIErrorCode, GUIErrorLevel, AllProps, GuisProps, FormsProps, ControlsProps, MenusProps, OptionProps, HotKeyBase, HotKey, GridEditableProps } from '../../types/enums'
import { ListValue, TableValue, PropsValue, OptionItemProps, ListColumnProps, GridProps, GridColumnProps, GridRowProps, GridValue, TreeProps, TreeItemProps, MenuItemProps, GUIRspErrorType, GUIError } from '../../types/enums'
import { GuisServiceType, GuisServiceSetPropType } from '../../types/services/guis'
// Services
import GuisServiceMethod from './method'
// Utilities
import Utils from '../../utils'

// generic type aliases for functor 
type Functor<T, U> = (arg: T) => U; 
type SetPropConv<T> = Functor<any, T>;

type PixelConverter = (amount: number, vh: string, scale: GUIScaleMode) => number;
class ScalingConverter {
  constructor(public pixelConverter: PixelConverter, public vh: string, public scale: GUIScaleMode) {}
  impl: SetPropConv<number> = (arg: any) => {
    return this.pixelConverter(arg, this.vh, this.scale);
  }
  static create(pixelConverter: PixelConverter, vh: string, scale: GUIScaleMode): SetPropConv<number> {
    return new ScalingConverter(pixelConverter, vh, scale).impl;
  }    
}

const SetPropConvNumber: SetPropConv<number> = function(arg: any) : number { return (+arg); }
const SetPropConvBoolean: SetPropConv<boolean> = function(arg: any) : boolean { return (!!+arg); }

const SetPropConvOptionItems: SetPropConv<OptionItemProps> = function(arg: any) : OptionItemProps {
  // convert caption string or array of option item props into OptionItemProps object
  let rtn = new OptionItemProps();
  if (Array.isArray(arg)) {
    rtn.caption = arg.length >= 1 ? arg[0] : '';
    rtn.enabled = arg.length >= 2 ? (!!+arg[1] || arg[1].length === 0 || false) : true; // if arg[1] is null use default of 'true'
    rtn.tip = arg.length >= 3 ? arg[2] : '';
  } else {
    rtn.caption = arg;
  }
  return rtn;
}

class SetPropConvMenuItem {
  constructor(private scalingConverter: SetPropConv<number>) {}

  impl: SetPropConv<MenuItemProps> = (arg: any) => {
    // convert array of menu item props into MenuItemProps object
    let rtn = new MenuItemProps();
    if (Array.isArray(arg)) {
      rtn.id = arg.length >= 1 ? arg[0] : '';
      rtn.level = arg.length >= 2 ? +arg[1] : 0;
      rtn.caption = arg.length >= 3 ? arg[2] : '';
      rtn.icon = arg.length >= 4 ? arg[3] : '';
      rtn.enabled = arg.length >= 5 ? !!+arg[4] : false;
      rtn.visible = arg.length >= 6 ? !!+arg[4] : false;
      rtn.shortcut = arg.length >= 7 ? arg[6] : '';
      rtn.causesValidation = arg.length >= 8 ? !!+arg[7] : false;
      rtn.tip = arg.length >= 9 ? arg[8] : '';
      rtn.panelWidth = arg.length >= 10 ? this.scaleWidth(+arg[9]) : 0;
      rtn.panelAlign = arg.length >= 11 ? +arg[10] : 0;
    } else {
      rtn.id = String(arg);
    }
    return rtn;
  }

  private scaleWidth(width: number) : number {
    if (width > 0) return this.scalingConverter(width);
    return width;
  }

  static create(scalingConverter: SetPropConv<number>): SetPropConv<MenuItemProps> {
    return new SetPropConvMenuItem(scalingConverter).impl;
  }    
}

class TreeItemConverter {
  constructor(public pathParts: string[]) {} // pathParts should be a reference so we can update it as nodes are added
  impl: SetPropConv<TreeItemProps> = (arg: any) => {  
    // convert array of tree item props into TreeItemProps object
    let rtn = new TreeItemProps();
    if (Array.isArray(arg)) {
      rtn.id = arg.length >= 1 ? arg[0] : '';
      rtn.level = arg.length >= 2 ? +arg[1] : 0;
      rtn.caption = arg.length >= 3 ? arg[2] : '';
      rtn.icon = arg.length >= 4 ? arg[3] : '';
      rtn.state = arg.length >= 5 ? !!+arg[4] : false;
      rtn.tip = arg.length >= 6 ? arg[5] : '';
    } else {
      rtn.id = String(arg);
    }
    this.pathParts[rtn.level] = rtn.id; // update path parts with this node ID
    rtn.path = this.pathParts.slice(1, rtn.level + 1).join('\\'); // build path from parent node IDs + this node ID
    return rtn;
  }
  static create(pathParts: string[]): SetPropConv<TreeItemProps> {
    return new TreeItemConverter(pathParts).impl;
  }    
}

export default class GuisServiceSetProp implements GuisServiceSetPropType {

  constructor(obj?: GuisServiceMethod) {
    obj ? this.methodService = obj : this.methodService = new GuisServiceMethod(this);
  }

  public utils = new Utils();
  //public guisService: GuisServiceType = store.getters['guiGuis/getGuisService'];
  public methodService: GuisServiceMethod;

  setProp (command: IGUICmdSetProp) {
    let response: IGUIRspSuccess | IGUIRspError;

    try {

      let props = store.getters['guiGuis/getProps'](command.id || '*')
      if (props) {

        this.setPropValue(command, props)
        
        response = { command: command.command, error: GUIErrorCode.grSuccess }
        store.dispatch('guiGuis/setProps', { id: command.id, props: props })
        
      } else {
        // error repsonse
        response = new GUIRspErrorType(command.command, GUIErrorCode.grNoExist, GUIErrorLevel.glvlFail, 'An object of the specified ID does not exist', [command.id, command.property]);
      }
    
    } catch (e: any) {
      if (e instanceof GUIRspErrorType) {
        response = e;
      } else if (e instanceof GUIError) {
        response = new GUIRspErrorType(command.command, e.errorCode, GUIErrorLevel.glvlFail, e.message, [command.id, command.property]);
      } else if (e instanceof RangeError) {
        response = new GUIRspErrorType(command.command, GUIErrorCode.grInvArg, GUIErrorLevel.glvlFail, e.message, [command.id, command.property]);
      } else {
        response = new GUIRspErrorType(command.command, GUIErrorCode.grFailure, GUIErrorLevel.glvlFail, (e & e.message ? e.message : 'An unknown error has occurred'), [command.id, command.property]);
      }
    }

    if (response.error !== GUIErrorCode.grSuccess) {
        // Notify
        const notification: simpleNotification = { type: 'warning', show: false, message: response.message || 'GUI error', friendlyMessage: 'Error setting property ' + command.property + ' on object ' + command.id, script: 'services/guis/setProp.ts', error: { errorCode: response.error, errorLevel: response.level } }
        store.dispatch('guiNotifications/addNotification', notification)
    }

    store.dispatch('guiResponse/setResponse', response)

  }

  setPropValue (command: IGUICmdSetProp, props: AllProps) {
 
    let propType: string;
    let propExists: boolean = true
    let value = command.value;

    // gpScale & pixelConversion are used via closure by SetPropConvScale
    const gpScale = this.utils.getScale(props)
    const SetPropConvScale = ScalingConverter.create(this.utils.pixelConversion, 'h', gpScale);

    switch (command.property) {

      case GUIProperty.gpDefVal:
        propType = 'gpDefVal'
        if ('gpDefVal' in props) {
          if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
            props.gpDefVal = this.SetTableProps(props.gpDefVal, value, '', 0, 0)
            this.UpdateGridRows(props as GridProps, props.gpDefVal.length);
          } else if (props.type === GUIObjectType.gxListMultisel || props.type === GUIObjectType.gxCheckedList) {
            props.gpDefVal = this.MakeListValue(value, 0, 0, SetPropConvNumber);
          } else if (props.type === GUIObjectType.gxEditMultiline) {
            props.gpDefVal = this.MakeListValue(value, '', 1);
          } else if (props.type === GUIObjectType.gxOption || props.type === GUIObjectType.gxCheck || props.type === GUIObjectType.gxList || 
            props.type === GUIObjectType.gxDrpDwnList || props.type === GUIObjectType.gxTabgrp || props.type === GUIObjectType.gxGauge) {
            props.gpDefVal = +value;
          } else {
            props.gpDefVal = value;
          } 
          if ('gpValue' in props) { 
            props.gpValue = props.gpDefVal;
            if (props.type === GUIObjectType.gxLabel || props.type === GUIObjectType.gxFrame) {
              props.gpCaption = props.gpValue; // for these controls, gpValue and gpCaption are synonyms            
              if (this.hasHotKey(value)) {
                this.addHotKeyBase(props, value);
                this.addHotKeytoParent(props);
              }
            } else if (props.type === GUIObjectType.gxPicture) {
              props.gpPicture = props.gpValue; // for picture control, gpValue & gpPicture are synonyms
            }
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpValue:
        propType = 'gpValue'
        if ('gpValue' in props) {             
          if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {       
            props.gpValue = this.SetTableProps(props.gpValue, value, '', command.col, command.row)
            this.UpdateGridRows(props as GridProps, props.gpValue.length);
            this.ResetGridChanged(props as GridProps, command.col, command.row);
            props.fields = this.utils.setGridFields(props as GridEditableProps);
            props.items = this.utils.setGridItems(props as GridEditableProps);
          } else if (props.type === GUIObjectType.gxListMultisel || props.type === GUIObjectType.gxCheckedList) {
            props.gpValue = this.MakeListValue(value, 0, 0, SetPropConvNumber);
            props.gpChanged = false;  
            props.changed = false;        
          } else if (props.type === GUIObjectType.gxEditMultiline) {
            props.gpValue = this.MakeListValue(value, '', 1);
            props.gpChanged = false;
            props.changed = false;  
          } else if (props.type === GUIObjectType.gxOption || props.type === GUIObjectType.gxCheck || props.type === GUIObjectType.gxList || 
            props.type === GUIObjectType.gxDrpDwnList || props.type === GUIObjectType.gxTabgrp || props.type === GUIObjectType.gxGauge) {
            props.gpValue = +value;
            props.gpChanged = false;
            props.changed = false;          
          } else {
            props.gpValue = value;
            props.gpChanged = false;
            props.changed = false;          
          }
          if (props.type === GUIObjectType.gxLabel || props.type === GUIObjectType.gxFrame) {
            props.gpCaption = props.gpValue // for these controls, gpValue and gpCaption are synonyms
            if (this.hasHotKey(value)) {
              this.addHotKeyBase(props, value);
              this.addHotKeytoParent(props);
            }
          } else if (props.type === GUIObjectType.gxPicture) {
            props.gpPicture = props.gpValue; // for picture control, gpValue & gpPicture are synonyms
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpAlign:
        propType = 'gpAlign'
        if ('gpAlign' in props) {
          if (props.type === GUIObjectType.gxStatusbar) {
            if (command.col === 0 && command.row === 0) {
              this.UpdateItemProp(props.gpItems as ListValue<MenuItemProps>, +value, command.item || '', 'id', 'panelAlign');
            } else {
              throw new RangeError('column or row argument is not valid')
            }
          } else {
            props.gpAlign = +value;
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpAltColor:
        propType = 'gpAltColor'
        if ('gpAltColor' in props) { props.gpAltColor = value } else { propExists = false }
      break;
      case GUIProperty.gpArrange:
        propType = 'gpArrange'
        if ('gpArrange' in props) {
          props.gpArrange = +value
        } else { propExists = false }
      break;
      case GUIProperty.gpAuthor:
        propType = 'gpAuthor'
        if ('gpAuthor' in props) { props.gpAuthor = value } else { propExists = false }
      break;
      case GUIProperty.gpAutoSelect:
        propType = 'gpAutoSelect'
        if ('gpAutoSelect' in props) {
          props.gpAutoSelect = !!+value
        } else { propExists = false }
      break;
      case GUIProperty.gpBackColor:
        propType = 'gpBackColor'
        if ('gpBackColor' in props) {
          if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
            let gridRow: GridRowProps;
            if (command.row === 0 && command.col === 0) {
              // control
              props.gpBackColor = value;
            } else if (command.row === 0 && command.col <= props.columnInfo.length) {
              // column
              props.columnInfo[command.col - 1].backColor = value;
            } else if (command.col === 0 && command.row <= props.gpValue.length) {
              // row
              gridRow = props.rowInfo[command.row - 1] || new GridRowProps();
              gridRow.rowBackColor = value;
              props.rowInfo[command.row - 1] = gridRow;
            } else if (command.col <= props.columnInfo.length && command.row <= props.rowInfo.length) {
              // cell
              gridRow = props.rowInfo[command.row - 1] || new GridRowProps();
              gridRow.cellBackColor[command.col - 1] = value;
              props.rowInfo[command.row - 1] = gridRow;
            }
          } else {
            props.gpBackColor = value;
          }  
        } else { propExists = false }
      break;
      case GUIProperty.gpBorder:
        propType = 'gpBorder'
        if ('gpBorder' in props) {
          props.gpBorder = +value
        } else { propExists = false }
      break;
      case GUIProperty.gpCaption:
        propType = 'gpCaption'
        if ('gpCaption' in props) {
          // caption may be string or Array<string>
          if (props.type === GUIObjectType.gxOption) {
            if (command.col === 0 && command.row === 0) {
              // control
              props.gpCaption = value
            } else if (command.col === 0 && command.row >= 1 && command.row <= props.gpItems.length) {
              // specifc option button
              (props as OptionProps).gpItems[command.row - 1].caption = value
            } else {
              throw new RangeError('column or row argument is not valid')
            }
          } else if (props.type === GUIObjectType.gxTree) {
            if (command.col === 0 && command.row === 0) {
              this.UpdateItemProp(props.gpItems as ListValue<TreeItemProps>, value, command.item || '', 'path', 'caption');
            } else {
              throw new RangeError('column or row argument is not valid')
            }
          } else if (props.type === GUIObjectType.gxMenu || props.type === GUIObjectType.gxPopup || props.type === GUIObjectType.gxToolbar || props.type === GUIObjectType.gxStatusbar) {
            if (command.col === 0 && command.row === 0) {
              this.UpdateItemProp(props.gpItems as ListValue<MenuItemProps>, value, command.item || '', 'id', 'caption');
            } else {
              throw new RangeError('column or row argument is not valid')
            }
          } else {
            props.gpCaption = value
          }

          if (props.type === GUIObjectType.gxLabel || props.type === GUIObjectType.gxFrame) {
            props.gpValue = props.gpCaption // for these controls, gpValue and gpCaption are synonyms
          }

          // Controls and Menus that can have keyboard accelerators (hot keys)
          const hotKeyControls: Array<GUIObjectType> = [GUIObjectType.gxLabel, GUIObjectType.gxFrame, GUIObjectType.gxCommand, GUIObjectType.gxTab, GUIObjectType.gxCheck, GUIObjectType.gxOption];
          const hotKeyMenus: Array<GUIObjectType> = [GUIObjectType.gxMenu, GUIObjectType.gxPopup, GUIObjectType.gxToolbar, GUIObjectType.gxStatusbar]

          if (hotKeyControls.includes(props.type) || hotKeyMenus.includes(props.type)) {
            if (this.hasHotKey(value)) {
              this.addHotKeyBase(props, value);
              this.addHotKeytoParent(props);
            }
          }

        } else { propExists = false }
      break;
      case GUIProperty.gpChanged:
        propType = 'gpChanged'        
        if ('gpChanged' in props) {
          if (+value) {
            props.gpChanged = true; // we do not propagate to ancestors since getting gpChanged from any ancestor will check its descendents
          } else {
            this.ResetChanged(props); // reset gpChanged property on this object and all descendents
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpColAlign:
        propType = 'gpColAlign'
        if ('gpColAlign' in props) {
          if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
            // Grid
            props.columnInfo = this.SetObjectListProps(GridColumnProps.create, props.columnInfo, value, 0, command.col, 'align', SetPropConvNumber)            
          } else if (props.type === GUIObjectType.gxList || props.type === GUIObjectType.gxListMultisel || props.type === GUIObjectType.gxCheckedList || props.type === GUIObjectType.gxDrpDwnCbo || props.type === GUIObjectType.gxDrpDwnList) {
            // List, Combo
            props.columnInfo = this.SetObjectListProps(ListColumnProps.create, props.columnInfo, value, 0, command.col, 'align', SetPropConvNumber)
          }          
        } else { propExists = false }
      break;
      case GUIProperty.gpColDataType:
        propType = 'gpColDataType'
        // Grid, List, Combo only
        if ('gpColDataType' in props) {
          if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
            // Grid
            props.columnInfo = this.SetObjectListProps(GridColumnProps.create, props.columnInfo, value, 0, command.col, 'dataType', SetPropConvNumber)            
          } else if (props.type === GUIObjectType.gxList || props.type === GUIObjectType.gxListMultisel || props.type === GUIObjectType.gxCheckedList || props.type === GUIObjectType.gxDrpDwnCbo || props.type === GUIObjectType.gxDrpDwnList) {
            // List, Combo
            props.columnInfo = this.SetObjectListProps(ListColumnProps.create, props.columnInfo, value, 0, command.col, 'dataType', SetPropConvNumber)
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpColFieldType:
        propType = 'gpColFieldType'
        if ('gpColFieldType' in props) {
          // Grids only
          if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
            props.columnInfo = this.SetObjectListProps(GridColumnProps.create, props.columnInfo, value, 0, command.col, 'fieldType', SetPropConvNumber)
          }
      } else { propExists = false }
      break;
      case GUIProperty.gpColHeading:
        propType = 'gpColHeading'
        if ('gpColHeading' in props) { 
          if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
            // Grid
            props.columnInfo = this.SetObjectListProps(GridColumnProps.create, props.columnInfo, value, '', command.col, 'heading')
          } else if (props.type === GUIObjectType.gxList || props.type === GUIObjectType.gxListMultisel || props.type === GUIObjectType.gxCheckedList || props.type === GUIObjectType.gxDrpDwnCbo || props.type === GUIObjectType.gxDrpDwnList) {
            // List, Combo
            props.columnInfo = this.SetObjectListProps(ListColumnProps.create, props.columnInfo, value, '', command.col, 'heading')
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpColHint:
        propType = 'gpColHint'
        if ('gpColHint' in props) { 
          if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
            // Grid only
            props.columnInfo = this.SetObjectListProps(GridColumnProps.create, props.columnInfo, value, '', command.col, 'tip')
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpColItems:
        propType = 'gpColItems'
        if ('gpColItems' in props) {
          if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
            // Grids only - if the dropdown list has multiple columns they are separated by '|' within each item
            // [ [ dropdown_row1_col1 | dropdown_row1_col2 | ... , dropdown_row2_col1 | dropdown_row2_col2 | ... ] ... ]
            props.columnInfo = this.SetObjectListProps(GridColumnProps.create, props.columnInfo, value, [], command.col, 'items')
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpColSizable:
        propType = 'gpColSizable'
        if ('gpColSizable' in props) { 
          if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
            // Grids only
            props.columnInfo = this.SetObjectListProps(GridColumnProps.create, props.columnInfo, value, false, command.col, 'sizable', SetPropConvBoolean)
          }
      } else { propExists = false }
      break;
      case GUIProperty.gpColTabStop:
        propType = 'gpColTabStop'
        if ('gpColTabStop' in props) { 
          if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
            // Grids only
            props.columnInfo = this.SetObjectListProps(GridColumnProps.create, props.columnInfo, value, true, command.col, 'tabStop', SetPropConvBoolean)
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpColumn:
        propType = 'gpColumn'
        if ('gpColumn' in props) {
          if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
            // Grids only
            props.gpColumn = +value
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpColumns:
        propType = 'gpColumns'        
        if ('gpColumns' in props) {
          let cols = +value
          if (cols >= 0) {
            if (props.type === GUIObjectType.gxList || props.type === GUIObjectType.gxListMultisel || props.type === GUIObjectType.gxCheckedList || props.type === GUIObjectType.gxDrpDwnCbo || props.type === GUIObjectType.gxDrpDwnList) {
              // List, Combo
              if (cols > 99) throw new RangeError('invalid property value')
              if (cols < props.gpColumns) {
                props.columnInfo.splice(cols)
              } else {
                for (let i = props.gpColumns; i < cols; i++) props.columnInfo.push(new ListColumnProps())
              }
              props.gpColumns = cols
            } else if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
              // Grids
              if (cols === 0 || cols > 255) throw new RangeError('invalid property value')
              // this may resize the grid
              if (cols < props.gpColumns) {
                props.columnInfo.splice(cols)
              } else {
                for (let i = props.gpColumns; i < cols; i++) props.columnInfo.push(new GridColumnProps())
              }
              props.gpColumns = cols
              if (props.gpColumn > props.gpColumns) props.gpColumn = props.gpColumns           
            } else if (props.type === GUIObjectType.gxOption) {
              // Option
              if (cols > 10) throw new RangeError('invalid property value')
              props.gpColumns = cols
            }
          } else {
            throw new RangeError('invalid property value')
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpColWidth:
        propType = 'gpColWidth'
        if ('gpColWidth' in props) {
          // Grid, List, Combo only
          if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
            // Grid
            props.columnInfo = this.SetObjectListProps(GridColumnProps.create, props.columnInfo, value, 120, command.col, 'width', SetPropConvScale)
          } else if (props.type === GUIObjectType.gxList || props.type === GUIObjectType.gxListMultisel || props.type === GUIObjectType.gxCheckedList || props.type === GUIObjectType.gxDrpDwnCbo || props.type === GUIObjectType.gxDrpDwnList) {
            // List, Combo
            props.columnInfo = this.SetObjectListProps(ListColumnProps.create, props.columnInfo, value, 120, command.col, 'width', SetPropConvScale)
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpContent:
        propType = 'gpContent'
        // Browser only
        if ('gpContent' in props) { 
          props.gpContent = value.map((v: Array<string>) => v.join('\r\n')).join('\r\n') 
        } else { propExists = false }
      break;
      case GUIProperty.gpCopyright:
        propType = 'gpCopyright'
        if ('gpCopyright' in props) { props.gpCopyright = value } else { propExists = false }
      break;
      case GUIProperty.gpCustom:
        propType = 'gpCustom'
        if ('gpCustom' in props) { props.gpCustom = value } else { propExists = false }
      break;
      case GUIProperty.gpDataCol:
        propType = 'gpDataCol'
        if ('gpDataCol' in props) { 
          if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
            // Grids
            props.columnInfo = this.SetObjectListProps(GridColumnProps.create, props.columnInfo, value, 0, command.col, 'dataCol', SetPropConvNumber)
          } else if (props.type === GUIObjectType.gxDrpDwnList || props.type === GUIObjectType.gxDrpDwnCbo) {
            // Combo
            props.gpDataCol = +value
          }           
        } else { propExists = false }
      break;
      case GUIProperty.gpDataType:
        propType = 'gpDataType'
        if ('gpDataType' in props) {
          props.gpDataType = +value
        } else { propExists = false }
      break;
      case GUIProperty.gpDescription:
        propType = 'gpDescription'
        if ('gpDescription' in props) { props.gpDescription = value } else { propExists = false }
      break;
      case GUIProperty.gpDragID:
        propType = 'gpDragID'
        if ('gpDragID' in props) { props.gpDragID = value } else { propExists = false }
      break;
      case GUIProperty.gpDragMode:
        propType = 'gpDragMode'
        if ('gpDragMode' in props) {
          props.gpDragMode = +value
        } else { propExists = false }
      break;
      case GUIProperty.gpDropIDs:
        propType = 'gpDropIDs'
        if ('gpDropIDs' in props) {
          props.gpDropIDs = this.MakeListValue(value, '', 0)
        } else { propExists = false }
      break;
      case GUIProperty.gpEnabled:
        propType = 'gpEnabled'
        if ('gpEnabled' in props) {
          if (props.type === GUIObjectType.gxOption) {
            if (command.col === 0 && command.row === 0) {
              // control
              this.UpdateEnabledProp(!!+value, props);
            } else if (command.col === 0 && command.row >= 1 && command.row <= props.gpItems.length) {
              // specifc option button
              (props as OptionProps).gpItems[command.row - 1].enabled = !!+value
            } else {
              throw new RangeError('column or row argument is not valid')
            }
          } else if (props.type === GUIObjectType.gxMenu || props.type === GUIObjectType.gxPopup || props.type === GUIObjectType.gxToolbar || props.type === GUIObjectType.gxStatusbar) {
            if (command.col === 0 && command.row === 0) {
              this.UpdateItemProp(props.gpItems as ListValue<MenuItemProps>, !!+value, command.item || '', 'id', 'enabled');
            } else {
              throw new RangeError('column or row argument is not valid')
            }
          } else {
            this.UpdateEnabledProp(!!+value, props);
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpEventMask:
        propType = 'gpEventMask'
        if ('gpEventMask' in props) {
          props.gpEventMask = +value
        } else { propExists = false }
      break;
      case GUIProperty.gpExtension:
        propType = 'gpExtension'
        if ('gpExtension' in props) { props.gpExtension = value } else { propExists = false }
      break;        
      case GUIProperty.gpFocusStyle:
        propType = 'gpFocusStyle'
        if ('gpFocusStyle' in props) {
          props.gpFocusStyle = +value
        } else { propExists = false }
      break;
      case GUIProperty.gpFixedCols:
        propType = 'gpFixedCols'
        if ('gpFixedCols' in props) {
          props.gpFixedCols = +value
        } else { propExists = false }
      break;
      case GUIProperty.gpFontBold:
        propType = 'gpFontBold'
        if ('gpFontBold' in props) {
          props.gpFontBold = !!+value
        } else { propExists = false }
      break;
      case GUIProperty.gpFontItalic:
        propType = 'gpFontItalic'
        if ('gpFontItalic' in props) {
          props.gpFontItalic = !!+value
        } else { propExists = false }
      break;
      case GUIProperty.gpFontName:
        propType = 'gpFontName'
        if ('gpFontName' in props) { props.gpFontName = value } else { propExists = false }
      break;
      case GUIProperty.gpFontSize:
        propType = 'gpFontSize'
        if ('gpFontSize' in props) {
          props.gpFontSize = +value
        } else { propExists = false }
      break;
      case GUIProperty.gpFontUnderline:
        propType = 'gpFontUnderline'
        if ('gpFontUnderline' in props) {
          props.gpFontUnderline = !!+value
        } else { propExists = false }
      break;
      case GUIProperty.gpForeColor:
        propType = 'gpForeColor'
        if ('gpForeColor' in props) {
          if (props.type === GUIObjectType.gxGrid || props.type == GUIObjectType.gxGridEditable) {
            let gridRow: GridRowProps;
            if (command.row === 0 && command.col === 0) {
              // control
              props.gpForeColor = value;
            } else if (command.row === 0 && command.col <= props.columnInfo.length) {
              // column
              props.columnInfo[command.col - 1].foreColor = value;
            } else if (command.col === 0 && command.row <= props.rowInfo.length) {
              // row              
              gridRow = props.rowInfo[command.row - 1] || new GridRowProps();
              gridRow.rowForeColor = value;
              props.rowInfo[command.row - 1] = gridRow;
            } else if (command.col <= props.columnInfo.length && command.row <= props.rowInfo.length) {
              // cell
              gridRow = props.rowInfo[command.row - 1] || new GridRowProps();
              gridRow.cellForeColor[command.col - 1] = value;
              props.rowInfo[command.row - 1] = gridRow;
            }
          } else {
            props.gpForeColor = value; 
          }
        } else { propExists = false }        
      break;
      case GUIProperty.gpGridLines:
        propType = 'gpGridLines'
        if ('gpGridLines' in props) {
          props.gpGridLines = +value
        } else { propExists = false }
      break;
      case GUIProperty.gpHeight:
        propType = 'gpHeight'
        if ('gpHeight' in props) {
           props.gpHeight = this.utils.pixelConversion(value, 'v', gpScale)
        } else { propExists = false }
      break;
      case GUIProperty.gpHelpFile:
        propType = 'gpHelpFile'
        if ('gpHelpFile' in props) { props.gpHelpFile = value } else { propExists = false }
      break;
      case GUIProperty.gpHelpID:
        propType = 'gpHelpID'
        if ('gpHelpID' in props) { props.gpHelpID = value } else { propExists = false }
      break;
      case GUIProperty.gpHelpType:
        propType = 'gpHelpType'
        if ('gpHelpType' in props) {
          props.gpHelpType = +value
        } else { propExists = false }
      break;
      case GUIProperty.gpHint:
        propType = 'gpHint'
        if ('gpHint' in props) { 
          // hint may be string or Array<string>
          if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
            let gridRow: GridRowProps;
            if (command.col === 0 && command.row === 0) {
              // control
              props.gpHint = value;
            } else if (command.row === 0 && command.col <= props.columnInfo.length) {
              // column
              props.columnInfo[command.col - 1].tip = value;
            } else if (command.col === 0 && command.row <= props.rowInfo.length) {
              // row              
              gridRow = props.rowInfo[command.row - 1] || new GridRowProps();
              gridRow.rowTip = value;
              props.rowInfo[command.row - 1] = gridRow;
            } else if (command.col <= props.columnInfo.length && command.row <= props.rowInfo.length) {
              // cell              
              gridRow = props.rowInfo[command.row - 1] || new GridRowProps();
              gridRow.cellTip[command.col - 1] = value;
              props.rowInfo[command.row - 1] = gridRow;
            }
          } else if (props.type === GUIObjectType.gxOption) {
            if (command.col === 0 && command.row === 0) {
              // control
              props.gpHint = value
            } else if (command.col === 0 && command.row >= 1 && command.row <= props.gpItems.length) {
              // specifc option button
              (props as OptionProps).gpItems[command.row - 1].tip = value
            } else {
              throw new RangeError('column or row argument is not valid')
            }
          } else if (props.type === GUIObjectType.gxTree) {
            if (command.col === 0 && command.row === 0) {
              this.UpdateItemProp(props.gpItems as ListValue<TreeItemProps>, value, command.item || '', 'path', 'tip');
            } else {
              throw new RangeError('column or row argument is not valid')
            }
          } else if (props.type === GUIObjectType.gxToolbar || props.type === GUIObjectType.gxStatusbar) {
            if (command.col === 0 && command.row === 0) {
              this.UpdateItemProp(props.gpItems as ListValue<MenuItemProps>, value, command.item || '', 'id', 'tip');
            } else {
              throw new RangeError('column or row argument is not valid')
            }
          } else {
            props.gpHint = value
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpIcon:
        propType = 'gpIcon'
        if ('gpIcon' in props) { 
          if (props.type === GUIObjectType.gxList || props.type === GUIObjectType.gxListMultisel || props.type === GUIObjectType.gxCheckedList || props.type === GUIObjectType.gxDrpDwnCbo || props.type === GUIObjectType.gxDrpDwnList) {
            if (command.col === 0 || command.col === 1) {
              props.gpIcon = this.SetListProps(props.gpIcon, value, '', command.row)
            } else {
              throw new RangeError('column or row argument is not valid')
            }
          } else if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
            let gridRow: GridRowProps;
            const tbl = this.MakeTableValue(value, '', 1, 1);
            if (command.row === 0 && command.col === 0) {
              // all
              tbl.forEach((v1, index1) => {
                if (index1 <= props.gpValue.length) {
                  gridRow = props.rowInfo[index1] || new GridRowProps();
                  v1.forEach((v2: any, index2: number) => {
                    if (index2 < props.columnInfo.length) {
                      gridRow.cellIcon[index2] = v2;
                    }
                  });
                  props.rowInfo[index1] = gridRow;
                }
              });
            } else if (command.row === 0 && command.col <= props.columnInfo.length) {
              // column
              tbl.forEach((v1, index) => {
                if (index < props.gpValue.length) {
                  gridRow = props.rowInfo[index] || new GridRowProps();
                  gridRow.cellIcon[command.col - 1] = v1[0];
                  props.rowInfo[index] = gridRow;
                }
              });
            } else if (command.col === 0 && command.row <= props.rowInfo.length) {
              // row
              gridRow = props.rowInfo[command.row - 1] || new GridRowProps();
              tbl[0].forEach((v1: any, index: number) => {
                if (index < props.columnInfo.length) {
                  gridRow.cellIcon[index] = v1;
                }
              });
              props.rowInfo[command.row - 1] = gridRow;
            } else if (command.col <= props.columnInfo.length && command.row <= props.rowInfo.length) {
              // cell              
              gridRow = props.rowInfo[command.row - 1] || new GridRowProps();
              gridRow.cellIcon[command.col - 1] = tbl[0][0];
              props.rowInfo[command.row - 1] = gridRow;
            }
          } else if (props.type === GUIObjectType.gxTree) {
            if (command.col === 0 && command.row === 0) {
              this.UpdateItemProp(props.gpItems as ListValue<TreeItemProps>, value, command.item || '', 'path', 'icon');
            } else {
              throw new RangeError('column or row argument is not valid')
            }
          } else if (props.type === GUIObjectType.gxMenu || props.type === GUIObjectType.gxPopup || props.type === GUIObjectType.gxToolbar || props.type === GUIObjectType.gxStatusbar) {
            if (command.col === 0 && command.row === 0) {
              this.UpdateItemProp(props.gpItems as ListValue<MenuItemProps>, value, command.item || '', 'id', 'icon');
            } else {
              throw new RangeError('column or row argument is not valid')
            }
          } else {
            props.gpIcon = value 
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpIconAlign:
        propType = 'gpIconAlign'
        if ('gpIconAlign' in props) {
          props.gpIconAlign = +value
        } else { propExists = false }
      break;
      case GUIProperty.gpIconSize:
        propType = 'gpIconSize'
        if ('gpIconSize' in props) {
          props.gpIconSize = +value
        } else { propExists = false }
      break;
      case GUIProperty.gpItems:
        propType = 'gpItems'
        if ('gpItems' in props) {
          if (props.type === GUIObjectType.gxList || props.type === GUIObjectType.gxListMultisel || props.type === GUIObjectType.gxCheckedList || props.type === GUIObjectType.gxDrpDwnCbo || props.type === GUIObjectType.gxDrpDwnList) {
            if (command.row === 0 && command.col === 0) {
              // all items
              props.gpItems = this.MakeTableValue(value, '', 0, 0);
            } else if (command.col === 0 && command.row <= props.gpItems.length) {
              // all columns in row
              props.gpItems[command.row - 1] = this.MakeListValue(Array.isArray(value) && Array.isArray(value[0]) ? value[0] : value, '', 0);
            } else if (command.col <= props.gpColumns && command.row <= props.gpItems.length) {
              // single cell
              props.gpItems[command.row - 1][command.col - 1] = Array.isArray(value) ? (Array.isArray(value[0] ? value[0][0] : value[0])) : value;
            }
          } else if (props.type === GUIObjectType.gxOption) {
            // With just captions
            // ['option 1 caption', 'option 2 caption', ...]
            // With more features
            // [ ['option 1 caption', (1 (or null) if option is enabled or 0 if disabled), 'help hint'], ...]
            props.gpItems = this.SetListProps(props.gpItems, value, OptionItemProps.create, command.row, SetPropConvOptionItems)
          } else if (props.type === GUIObjectType.gxTree) {
            let pathParts = [''];
            const convert = TreeItemConverter.create(pathParts);
            // pathParts needs to be initialized if row <> 0!
            if (command.row > 0) {
              (props.gpItems as Array<TreeItemProps>).forEach((v1) => pathParts[v1.level] = v1.id);
            }
            props.gpItems = this.SetListProps(props.gpItems, value, TreeItemProps.create, command.row, convert)
            if ((((<TreeProps>props).gpStyle & 0x18) === 0) && (<TreeProps>props).gpValue === "" && ((<TreeProps>props).gpItems.length > 0)) {
              (<TreeProps>props).gpValue = (<TreeProps>props).gpItems[0].id;
            }
          } else if (props.type === GUIObjectType.gxMenu || props.type === GUIObjectType.gxPopup || props.type === GUIObjectType.gxToolbar || props.type === GUIObjectType.gxStatusbar) {
            props.gpItems = this.SetListProps(props.gpItems, value, MenuItemProps.create, 0, SetPropConvMenuItem.create(SetPropConvScale));
          } else {
            props.gpItems = value 
          }

          // Menus that can have keyboard accelerators (hot keys)
          const hotKeyMenus: Array<GUIObjectType> = [GUIObjectType.gxMenu, GUIObjectType.gxToolbar] // GUIObjectType.gxPopup, GUIObjectType.gxStatusbar

          if (hotKeyMenus.includes(props.type)) {
            props.gpItems.forEach((item: MenuItemProps) => {
              if (item.level <= 1 && this.hasHotKey(item.caption)) {
                this.addHotKeyBase(props, item.caption, item.id);
                this.addHotKeytoParent(props);
              }
            });
          }

        } else { propExists = false }
      break;
      case GUIProperty.gpLeft:
        propType = 'gpLeft'
        if ('gpLeft' in props) {
          if (props.type === GUIObjectType.gxSDI || props.type === GUIObjectType.gxMDI || props.type === GUIObjectType.gxFormFixed || props.type === GUIObjectType.gxFormSizable || props.type === GUIObjectType.gxDialog || props.type === GUIObjectType.gxSDIFixed || props.type === GUIObjectType.gxSDISizable) {
            if (value === 'auto') {
              props.gpLeft = value
            } else {
              props.gpLeft = this.utils.pixelConversion(value, 'h', gpScale)
            }
          } else {
            props.gpLeft = this.utils.pixelConversion(value, 'h', gpScale)
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpLogo:
        propType = 'gpLogo'
        if ('gpLogo' in props) { props.gpLogo = value } else { propExists = false }
      break;
      case GUIProperty.gpMaxDrop:
        propType = 'gpMaxDrop'
        if ('gpMaxDrop' in props) {
          props.gpMaxDrop = +value
        } else { propExists = false }
      break;
      case GUIProperty.gpMaxLen:
        propType = 'gpMaxLen'
        if ('gpMaxLen' in props) {
          if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
            props.gpMaxLen = this.SetListProps(props.gpMaxLen, value, 0, command.col, SetPropConvNumber)
          } else {
            props.gpMaxLen = +value
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpMaxLines:
        propType = 'gpMaxLines'
        if ('gpMaxLines' in props) {
          props.gpMaxLines = +value
        } else { propExists = false }
      break;
      case GUIProperty.gpMsgText:
        propType = 'gpMsgText'
        if ('gpMsgText' in props) { props.gpMsgText = value } else { propExists = false }
      break;
      case GUIProperty.gpNoAutoTips:
        propType = 'gpNoAutoTips'
        if ('gpNoAutoTips' in props) {
          props.gpNoAutoTips = !!+value
        } else { propExists = false }
      break;
      case GUIProperty.gpPasteMode:
        propType = 'gpPasteMode'
        if ('gpPasteMode' in props) {
          props.gpPasteMode = +value
        } else { propExists = false }
      break;
      case GUIProperty.gpPicture:
        propType = 'gpPicture'
        if ('gpPicture' in props) {
          props.gpPicture = value 
          if (props.type === GUIObjectType.gxPicture) {
            props.gpValue = props.gpPicture // for picture control, gpValue and gpPicture are synonyms
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpReadOnly:
        propType = 'gpReadOnly'
        if ('gpReadOnly' in props) { 
          if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
            props.columnInfo = this.SetObjectListProps(GridColumnProps.create, props.columnInfo, value, false, command.col, 'readOnly', SetPropConvBoolean)
          } else {
            props.gpReadOnly = !!+value
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpRequired:
        propType = 'gpRequired'
        if ('gpRequired' in props) { 
          if (props.type === GUIObjectType.gxGrid) {
            props.columnInfo = this.SetObjectListProps(GridColumnProps.create, props.columnInfo, value, false, command.col, 'required', SetPropConvBoolean)
          } else {
            props.gpRequired = !!+value
          } 
        } else { propExists = false }
      break;
      case GUIProperty.gpRow:
        propType = 'gpRow'
        if ('gpRow' in props) {
          props.gpRow = +value
        } else { propExists = false }
      break;
      case GUIProperty.gpRows:
        propType = 'gpRows'
        if ('gpRows' in props) {
          let rows = +value
          if (rows >= 0) {
            if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
              this.UpdateGridRows(props as GridProps, rows);
            } else if (props.type === GUIObjectType.gxList || props.type === GUIObjectType.gxListMultisel || props.type === GUIObjectType.gxCheckedList || props.type === GUIObjectType.gxDrpDwnCbo || props.type === GUIObjectType.gxDrpDwnList) {
              // List, Combo getProp OK, but not setProp
              //props.gpRows = rows
            } else if (props.type === GUIObjectType.gxOption) {
              // Option
              if (rows > 50) throw new RangeError('invalid property value')          
              props.gpRows = rows
            }
          } else {
            throw new RangeError('invalid property value')          
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpRtnEqTab:
        propType = 'gpRtnEqTab'
        if ('gpRtnEqTab' in props) {
          props.gpRtnEqTab = +value
        } else { propExists = false }
      break;
      case GUIProperty.gpScale:
        propType = 'gpScale'
        if ('gpScale' in props) {
          props.gpScale = +value
        } else { propExists = false }
      break;
      case GUIProperty.gpSelLength:
        propType = 'gpSelLength'
        if ('gpSelLength' in props) {
          props.gpSelLength = +value
        } else { propExists = false }
      break;
      case GUIProperty.gpSelRange:
        propType = 'gpSelRange'
        if ('gpSelRange' in props && 'gpSelStart' in props && 'gpSelLength' in props) {
          if (Array.isArray(value)) {
            props.gpSelStart = (+value[0]) - 1
            props.gpSelLength = +value[1]
          } else {
            props.gpSelStart = (+value) - 1
            props.gpSelLength = 0
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpSelStart:
        propType = 'gpSelStart'
        if ('gpSelStart' in props) {
          props.gpSelStart = (+value) - 1
        } else { propExists = false }
      break;
      case GUIProperty.gpState:
        propType = 'gpState'
        if ('gpState' in props) {
          if (props.type === GUIObjectType.gxTree) {
            if (command.col === 0 && command.row === 0) {
              this.UpdateItemProp(props.gpItems as ListValue<TreeItemProps>, !!+value, command.item || '', 'path', 'state');
            } else {
              throw new RangeError('column or row argument is not valid')
            }            
          } else {
            props.gpState = +value
          }          
        } else { propExists = false }
      break;
      case GUIProperty.gpStatus:
        propType = 'gpStatus'
        if ('gpStatus' in props) {
          if (props.type === GUIObjectType.gxRoot) {
            if (command.col === -1) {
              // TODO: set status message displayed while GUI waiting for server
            } else {
              throw new RangeError('column or row argument is not valid')
            }
          }  else { propExists = false }
        } else { propExists = false }
      break;
      case GUIProperty.gpStyle:
        propType = 'gpStyle'
        if ('gpStyle' in props) {
          props.gpStyle = +value
        } else { propExists = false }
      break;
      case GUIProperty.gpTabStop:
        propType = 'gpTabStop'
        if ('gpTabStop' in props) {
          props.gpTabStop = !!+value
        } else { propExists = false }
      break;
      case GUIProperty.gpTimeout:
        propType = 'gpTimeout'
        if ('gpTimeout' in props) {
          props.gpTimeout = +value
        } else { propExists = false }
      break;
      case GUIProperty.gpTop:
        propType = 'gpTop'
        if ('gpTop' in props) {
          if (props.type === GUIObjectType.gxSDI || props.type === GUIObjectType.gxMDI || props.type === GUIObjectType.gxFormFixed || props.type === GUIObjectType.gxFormSizable || props.type === GUIObjectType.gxDialog || props.type === GUIObjectType.gxSDIFixed || props.type === GUIObjectType.gxSDISizable) {
            if (value === 'auto') {
              props.gpTop = value
            } else {
              props.gpTop = this.utils.pixelConversion(value, 'v', gpScale)
            }
          } else {
            props.gpTop = this.utils.pixelConversion(value, 'v', gpScale)
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpTransparent:
        propType = 'gpTransparent'
        if ('gpTransparent' in props) {
          props.gpTransparent = !!+value
        } else { propExists = false }
      break;
      case GUIProperty.gpVersion:
        propType = 'gpVersion'
        if ('gpVersion' in props) { props.gpVersion = value } else { propExists = false }
      break;
      case GUIProperty.gpVisible:
        propType = 'gpVisible'
        if ('gpVisible' in props) {
          if (command.item && // set menu item visible property
              (props.type === GUIObjectType.gxMenu || props.type === GUIObjectType.gxPopup || props.type === GUIObjectType.gxToolbar || props.type === GUIObjectType.gxStatusbar)) {
            if (command.col === 0 && command.row === 0) {
              // show/hide menu item
              this.UpdateItemProp(props.gpItems as ListValue<MenuItemProps>, value, command.item, 'id', 'visible'); // throws RangeError if item not found
            } else {
              throw new RangeError('column or row argument is not valid')
            }
          } else {
            this.UpdateVisibleProp(!!+value, props);
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpWidth:
        propType = 'gpWidth'
        if ('gpWidth' in props) {
          if (props.type === GUIObjectType.gxStatusbar) {
            if (command.col === 0 && command.row === 0) {
              this.UpdateItemProp(props.gpItems as ListValue<MenuItemProps>, this.utils.pixelConversion(value, 'h', gpScale), command.item || '', 'id', 'panelWidth');
            } else {
              throw new RangeError('column or row argument is not valid')
            }
          } else {
           props.gpWidth = this.utils.pixelConversion(value, 'h', gpScale);
          }
        } else { propExists = false }
      break;
      case GUIProperty.gpWindowState:
        propType = 'gpWindowState'
        if ('gpWindowState' in props) {
          props.gpWindowState = +value
        } else { propExists = false }
      break;
      case GUIProperty.gpWordWrap:
        propType = 'gpWordWrap'
        if ('gpWordWrap' in props) {
          props.gpWordWrap = !!+value
        } else { propExists = false }
      break;
    }

    if (!propExists) {
      throw new GUIRspErrorType(command.command, GUIErrorCode.grInvProp, GUIErrorLevel.glvlFail, 'invalid property code', [props.id, command.property])
    }
  }

  // Reset the Changed property for this object and all its descendents
  ResetChanged(props: AllProps) {
    const that = this;
    function resetRecurs(component: GUIComponent) {
      const props = component.props;
      if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
        that.ResetGridChanged(props as GridProps, 0, 0);
      } else {
        props.gpChanged = false;
        props.changed = false;
        // reset gpChanged for any child objects
        component.children.forEach(v1 => resetRecurs(v1));
      }
    }
    // check if any ancestors have gpChanged set, and if so, make sure its still needed
    function parentRecurs(component: GUIComponent) {
      const parent = store.getters['guiGuis/getComponent'](component.props.parentID);
      if (parent && parent.props.type != GUIObjectType.gxRoot && parent.props.gpChanged) {
        parent.props.gpChanged = childRecurs(parent.children);
      }
    }
    // check if any descendents have gpChanged set
    function childRecurs(children: Array<GUIComponent>): boolean {
      return children.some(v1 => {
        return v1.props.gpChanged || childRecurs(v1.children);
      });
    }
    const component = store.getters['guiGuis/getComponent'](props.id);
    if (component) {
      // recursively reset gpChanged for this object and its descendents
      resetRecurs(component);
      // This is a little strange - clearing gpChanged for this object may
      // require the parent gpChanged to be cleared, unless there are any
      // siblings that are also changed. So, after clearing gpChanged for
      // this object, recursively check each ancestor to see if its gpChanged
      // should also be cleared.
      parentRecurs(component);
    }
  }

  UpdateGridRows(props: GridProps, new_rows: number): void {
    const old_rows = props.gpRows;
    if (new_rows < old_rows) {
      props.gpValue.splice(new_rows);
      if (new_rows < props.rowInfo.length) {
        props.rowInfo.splice(new_rows);
      }
    }
    if (new_rows !== old_rows) {
      props.gpRows = new_rows;
    }
  }

  ResetGridChanged(props: GridProps, col: number, row: number) {
    if (row === 0 && col === 0) {
      // all
      props.gpChanged = false;
      props.changed = false;
      props.rowInfo.forEach(v1 => {
        if (v1 && v1.rowChanged) {
          v1.rowChanged = false;
          v1.cellChanged = [];
        }
      });
    } else if (col === 0) {
      // all columns in row
      if (props.rowInfo[row - 1] && props.rowInfo[row - 1].rowChanged) {
        props.rowInfo[row - 1].rowChanged = false;
        props.rowInfo[row - 1].cellChanged = [];
      }
      props.gpChanged = props.rowInfo.some(v1 => v1 && v1.rowChanged);
    } else if (row === 0) {
      // all rows in column
      let changed = false;
      props.rowInfo.forEach(v1 => {
        if (v1 && v1.cellChanged[col - 1]) {
          v1.cellChanged[col - 1] = false;
        }
        if ((v1.rowChanged = v1.cellChanged.some(v2 => v2))) {
          changed = true;
        }
      });
      props.gpChanged = changed;
  } else {
      // cell
      if (props.rowInfo[row - 1] && props.rowInfo[row - 1].cellChanged[col - 1]) {
        props.rowInfo[row - 1].cellChanged[col - 1] = false;
        props.rowInfo[row - 1].rowChanged = props.rowInfo[row - 1].cellChanged.some(v2 => v2);
        props.gpChanged = props.rowInfo.some(v1 => v1 && v1.rowChanged);
      }
    }
  }

  /*
   * Special handling for gpVisible property:
   *  When an control is hidden, if it is the focused control, or contains
   *  the focused control, then we need to find a new control to get focus.
   *  When showing a form, it becomes the active form.
   *  When hiding a form, the previous form in the zorder should be activated.
   */
  UpdateVisibleProp(value: boolean, props: AllProps, menuID?: string) {
  let activateID: string = '';
  if (props.typeFamily === 'menu' && menuID) {        
    // show/hide menu item
    this.UpdateItemProp(props.gpItems as ListValue<MenuItemProps>, value, menuID, 'id', 'visible'); // throws RangeError if item not found
  } else {
    if (!value) {
        activateID = this.preHideDelete(props);
    } else if (props.typeFamily === 'form') {
      activateID = props.id;
    }
    props.gpVisible = value;                    
    // Ensure focus is valid after showing or hiding
    if (activateID) {
      const activateComponent: GUIComponent | undefined = store.getters['guiGuis/getComponent'](activateID);
      if (activateComponent && this.methodService.activatable(activateComponent)) {
        this.methodService.activateComponentTree(activateComponent); // may throw GUIError
      }
    }
  }
}

  /*
   * Special handling for gpEnabled property:
   *  When an control is disabled, if it is the focused control, or contains
   *  the focused control, then we need to find a new control to get focus.
   */
  UpdateEnabledProp(value: boolean, props: AllProps) {
    let activateID: string = '';
    if (!value) {
      activateID = this.preHideDelete(props);
    }
    props.gpEnabled = value;                    
    // Ensure focus is valid after disabling
    if (activateID) {
      const activateComponent: GUIComponent | undefined = store.getters['guiGuis/getComponent'](activateID);
      if (activateComponent && this.methodService.activatable(activateComponent)) {
        this.methodService.activateComponentTree(activateComponent); // may throw GUIError
      }
    }
  }

  /*
   * If the focused control (or its ancestor) is being hidden, deleted or disabled,
   * set focus to the previous control (in tab order).
   */
  preHideDelete(props: AllProps): string {
  // First, check if the control being deleted or hidden is the focused control
  // on it's form, or an ancestor of the focused control. Forms (& apps) should
  // be automatic, since the form with the next highest zIndex should get focused.
  if (props.typeFamily === 'control') {
    const thisID = props.id;
    let focusedControlID = props.id;
    if (focusedControlID === thisID || this.utils.isChild(thisID, focusedControlID)) {
      // We are deleting/hiding the focused control on this form. Find the
      // previous control in tab order and set it as the focused control.
      const form: GUIComponent | undefined = store.getters['guiGuis/getComponent'](props.form!.id);
      if (form) {
        const controls = this.utils.flattenChildren(form, Infinity); // all controls on form in tab order
        const index = controls.findIndex((a: GUIComponent) => a.props.id === thisID); // find index of control being deleted / hidden
        if (index !== -1) {
          let reordered = controls.slice(index).concat(controls.slice(0, index)); // move deleted / hidden control to front of array
          reordered = reordered.filter((a: GUIComponent) => this.methodService.activatable(a)); // remove non-activatable controls (labels, frames, hidden controls, etc.)
          focusedControlID = (reordered.length && reordered[reordered.length - 1].props.id) || ''; // last element is previous control in tab order
          store.dispatch('guiGuis/updateProperty', {id: form.props.id, property: 'focused', value: focusedControlID }); // update the focused property for this form
          if (store.getters['guiGuis/getFocusedForm'] === form.props.id) {
            return focusedControlID; // activate this after delete / hide
          }
        }
      }
    }
  } else if (props.typeFamily === 'form' || props.typeFamily === 'app') {
    const root: GUIComponent | undefined = store.getters['guiGuis/getComponent']('*');
    const ztop: GUIComponent | undefined = this.utils.flattenChildren(root!, 1)
        .filter((a: GUIComponent) => a.props.id !== props.id && a.props.typeFamily === 'form' && this.methodService.activatable(a))
        .reduce((a: GUIComponent | undefined, b) => (a && a.props.zIndex > b.props.zIndex) ? a : b, undefined);
    if (ztop) {
      return ztop.props.id;
    }
  }
  return ''; // its a form or app or the control's form is not active, so nothing to do
}

  /*
   * Update an item property, searching array of items for matching key
   */
  UpdateItemProp<T, U, K extends keyof T>(props: ListValue<T>, value: U, key: string, keyname: K, propname: K) {
    const index = props.findIndex((item: T) => {
      return this.utils.compareIDs([key, item[keyname] as unknown as string])
    })
    if (index !== -1) {
      (props[index][propname] as unknown) = value;
    } else {
      throw RangeError('item not found')
    }
  }

  /*
   * Massage 'value' into a one-dimensional array (ListValue). 'value' can be a GenericValue, or an array of
   * GenericValues. If 'count' is specified, the result will contain at least 'count' elements. If 'convert'
   * is specified, each element in the original value will be converted by the 'convert' function. If 'value'
   * is an empty string, and 'count' is zero, an empty array is returned. When 'count' is not zero, missing
   * values are appended to the result using 'defval'. 'defval' can be a GenericValue, or a function to
   * create a new instance of T.
   */ 
  MakeListValue<T>(value: any, defval: (T|(()=>T)), count: number = 0, convert?: SetPropConv<T>) : ListValue<T> {
    let rtn: ListValue<T> = [];
    if (Array.isArray(value)) {
      if (convert) {
        value.forEach((v1) => { rtn.push(convert(v1)) })
      } else {
        rtn = value; // value is a reference & is an array (of strings?) & no conversion required, so just return itself
      }
    } else if (count === 0 && typeof value === 'string' && value.length === 0) {
      //rtn = []; // empty list
    } else {
      // convert simple value to list
      rtn = [(convert ? convert(value) : value)];
    }
    for (let i = rtn.length; i < count; i++) {
      rtn[i] = defval instanceof Function ? defval() : defval; // initialize missing items
    }
    return rtn;
  }

  MakeTableRowValue<T>(value: any, defval: (T|(()=>T)), count: number = 0, convert?: SetPropConv<T>) : ListValue<T> {
    let rtn: any = {};
    if (Array.isArray(value)) {
      if (convert) {
        value.forEach((v1, i) => { i: convert(v1) })
      } else {
        rtn = {...value}; // value is a reference & is an array (of strings?) & no conversion required, so just return itself
      }
    } else if (count === 0 && typeof value === 'string' && value.length === 0) {
      //rtn = []; // empty list
    } else {
      // convert simple value to list
      rtn = {...(convert ? convert(value) : value)};
    }
    for (let i = rtn.length; i < count; i++) {
      rtn[i] = defval instanceof Function ? defval() : defval; // initialize missing items
    }
    return rtn;
  }
  
  /*
   * Massage 'value' into a two-dimensional array (TableValue). 'value' can be a GenericValue, or an array of
   * GenericValues, or an array of arrays of GenericValues. If 'rows' is specified, the result will contain at
   * least 'rows' rows. If 'cols' is specified, each row in result will contain at least 'cols' columns. If
   * 'convert' is specified, each element in the original value will be converted by the 'convert' function.
   * When 'cols' or 'rows' are specified, missing values are appended to the result using 'defval'. 'defval'
   * can be a GenericValue, or a function to create a new instance of T.
   */ 
  MakeTableValue<T>(value: any, defval: (T|(()=>T)), rows: number = 0, cols: number = 0, convert?: SetPropConv<T>) {
    let rtn: ListValue<any>;
    let ra: Array<T>;
    if (Array.isArray(value)) {
      if (convert) {
        // need to massage it into a table, applying conversion on each element
        rtn = value.map(v1 => {
          if (Array.isArray(v1)) {
            ra = v1.map(v2 => convert(v2));
          } else {
            ra = [convert(v1)];
          }
          for (let i = ra.length; i < cols; i++) {
            ra[i] = defval instanceof Function ? defval() : defval; // initialize missing items
          }
          return ra;
        });
      } else {
        if (value.every(v1 => Array.isArray(v1) && v1.length >= cols)) {
          // value is a reference & every row is an object & conversion not required, so just return itself
          rtn = value
        } else {
          // need to massage it into a table
          rtn = value.map(v1 => {
            if (Array.isArray(v1)) {
              ra = [...v1];
            } else {
              ra = [v1];
            }
            for (let i = ra.length; i < cols; i++) {
              ra[i] = defval instanceof Function ? defval() : defval; // initialize missing items
            }
            return ra;
          });
        }
      }
    } else {
      // convert simple value to table
      ra = [(convert ? convert(value) : value)];
      for (let i = ra.length; i < cols; i++) {
        ra[i] = defval instanceof Function ? defval() : defval; // initialize missing items
      }
      rtn = [ra];
    }
    for (let row = rtn.length; row < rows; row++) {
      rtn[row] = this.MakeListValue<T>([], defval, cols, convert); // initialize missing items
    }
    return rtn;
  }

  /*
   * Update list property 'propsval' from 'value', a GenericValue, or array of GenericValues. When 'index'
   * is zero, the entire property is updated by 'value'. Otherwise only the element specified by 'index'
   * is updated. If 'convert' is specified, each element in 'value' will be converted by the 'convert'
   * function. Missing values are inserted using 'defval'. 'defval' can be a GenericValue, or a
   * function to create a new instance of T.
   */ 
  SetListProps<T>(propsval: PropsValue<T>, value: any, defval: (T|(()=>T)), index: number, convert?: SetPropConv<T>) : PropsValue<T> {
    if (index === 0) {
      // all
      propsval = this.MakeListValue<T>(value, defval, 0, convert);
    } else {
      // single element
      (propsval = this.MakeListValue<T>(propsval, defval, index, convert))[index - 1] = (convert ? convert(value) : value);
    }
    return propsval;
  }

  /*
   * Update table property 'propsval' from 'value', a GenericValue, or array of GenericValues or array of
   * array of GenericValues. When 'col' and 'row' are zero, the entire property is updated by 'value'. If
   * 'col' is zero but 'row' is not, 'value' is assumed to be a GenericValue or array of GenericValues, and
   * each element in the specified 'row' is updated. If 'row' is zero but 'col' is not, each element in the
   * specified 'col' is updated. If both 'col' and 'row' are non-zero, only one "cell" is updated. If
   * 'convert' is specified, each element in 'value' will be converted by the 'convert' function. Missing
   * values are inserted using 'defval'. 'defval' can be a GenericValue, or a function to create a new
   * instance of T.
   */ 
  SetTableProps<T>(propsval: PropsValue<T>, value: any, defval: (T|(()=>T)), col: number, row: number, convert?: SetPropConv<T>) : PropsValue<T> {
    if (row === 0 && col === 0) {
      // all
      propsval = this.MakeTableValue<T>(value, defval, 0, 0, convert);
    } else if (col === 0) {
      // all columns in row
      if (Array.isArray(value)) {
        (propsval = this.MakeTableValue<T>(propsval, defval, row, 0, convert))[row - 1] = this.MakeListValue<T>(value[0], defval, 0, convert);
      } else {
        (propsval = this.MakeTableValue<T>(propsval, defval, row, 0, convert))[row - 1] = this.MakeListValue<T>(value, defval, 0, convert);
      }
    } else if (row === 0) {
      // all rows in column
      if (Array.isArray(value)) {
        propsval = this.MakeTableValue<T>(propsval, defval, value.length, 0, convert);
        value.forEach((v1, index) => {
          (propsval as TableValue<T>)[index] = this.MakeListValue<T>((propsval as TableValue<T>)[index], defval, col, convert);
          (propsval as TableValue<T>)[index][col - 1] = (convert ? convert(v1) : v1);
        });
      } else {
        propsval = this.MakeTableValue<T>(propsval, defval, 1, 0, convert);
        (propsval as TableValue<T>)[0] = this.MakeListValue<T>((propsval as TableValue<T>)[0], defval, col, convert);
        (propsval as TableValue<T>)[0][col - 1] = (convert ? convert(value) : value);
      }
    } else {
      // cell
      (propsval = this.MakeTableValue<T>(propsval, defval, row, col, convert))[row - 1][col - 1] = (convert ? convert(value) : value);
    }
    return propsval;
  }

  /*
   * This function updates a property for one (index <> 0) or several (index = 0) items in an array.
   * Each item in 'propsval' is an object of type T, and the property 'name' in the item(s) is
   * updated. If 'index' = 0, 'value' should be an array, and 'propsval' elements from 0 to
   * 'value.length' are updated to ...value. If there are less items in the 'propsval' array
   * than 'value.length' then new items are added to 'propsval', using the 'factory' function.
   * If 'convert' is specified, each element in 'value' will be converted by the 'convert' function.
   */
  SetObjectListProps<T, K extends keyof T>(factory: ()=>T, propsval: ListValue<T>, value: any, defval: T[K], index: number, name: K, convert?: SetPropConv<T[K]>) : ListValue<T> {
    let i = 0;
    const propval = this.MakeListValue<T[K]>(value, defval, 0, convert);
    if (index === 0) {
      const n = propval.length;
      if (n > propsval.length) {
        // extend the propsval array so we have a slot for our property(s)
        for (i = propsval.length; i < n; i++) {
          propsval.push(factory());
        }
      }
      for (i = 0; i < n; i++) {
        propsval[i][name] = propval[i];
      }
    } else {
      if (index >= 1 && index <= propsval.length) {
        propsval[index - 1][name] = propval[0];
      } else {
        throw new RangeError('column or row argument is not valid');
      }
    }      
    return propsval;
  }

  // Check to see if the text has an ampersand
  hasHotKey(text: string): boolean {
    return text.search(/&[A-Za-z]/) !== -1; 
  }

  getHotKeyBase(text: string, trigger?: string, menuId?: string): HotKeyBase | undefined { 
    const i = text.search(/&[A-Za-z]/);

    if (i !== -1) {
      const key = text.substr(i + 1, 1).toLowerCase();
      const keyCode = key.charCodeAt(0);
      let hotKeyBase: HotKeyBase = { trigger: '', key: key, keyCode: keyCode }

      if (trigger) {
        hotKeyBase.trigger = trigger
      }

      if (menuId) {
        hotKeyBase.menuId = menuId
      }

      return hotKeyBase;
    }
  }

  // props really should be typed ControlsProps | MenusProps (but typescript gets all mad about it)
  addHotKeyBase(props: AllProps, value: string, menuId?: string) {
    let hotKeyBase: HotKeyBase | undefined;

    if (props.type === GUIObjectType.gxLabel || props.type === GUIObjectType.gxFrame) {
      hotKeyBase = this.getHotKeyBase(value);
    } else if (props.type === GUIObjectType.gxMenu || props.type === GUIObjectType.gxToolbar) {
      hotKeyBase = this.getHotKeyBase(value, props.id, menuId);
    } else {
      hotKeyBase = this.getHotKeyBase(value, props.id);
    }
    
    if (hotKeyBase) {
      // TODO some menus/controls can have multiple hotkeys (eg menu, maybe option?) Actually not sure if any controls can have more than one. Pete?
      // The statement below will only add a hotKey to the menu/control if it doesn't already have the same one
      // So we'll need to confirm which menus/controls need the ability to store multiple of the same hot key

      // if the key doesn't already exist
      if (!props.hotKeys.some((hotKey: HotKeyBase) => hotKey.key === hotKeyBase!.key)) {
        props.hotKeys.push(hotKeyBase)
      }
    }
  }

  getHotKey(hotKeyBase: HotKeyBase, typeFamily: string, app: string, form: string | null, control: string): HotKey { 
    return { ...hotKeyBase, typeFamily: typeFamily, app: app, form: form, control: control};
  }

  // props really should be typed ControlsProps | MenusProps (but typescript gets all mad about it)
  addHotKeytoParent(props: AllProps) {
    const hotKeyBase: HotKeyBase = props.hotKeys.slice(-1)[0];
    
    const form = props.form ? props.form.id : null; // if the form id is null it means we have gui app controls (e.g. menu, toolbar, etc)

    const hotKey: HotKey = this.getHotKey(hotKeyBase, props.typeFamily, props.app!.id, form, props.id);
    
    if (props.form) {
      
      if (hotKey.typeFamily === 'menu' || !props.form.hotKeys.some(k => k.key === hotKey.key && k.typeFamily === 'menu')) {
        const exists: boolean = props.form.hotKeys.some((hotKey: HotKey) => hotKey.control === props.id && hotKey.key === hotKeyBase.key);   
        if (!exists) {
          props.form.hotKeys.push(hotKey);
          // menu hotkey overrides any control with same hotkey
          if (props.typeFamily === 'menu') {
            props.form.hotKeys = props.form.hotKeys.filter(k => (k.key === hotKey.key) ? (hotKey.typeFamily === 'menu') : true);
          }
        }
      }

    } else {
      
      const exists: boolean = props.app!.hotKeys.some((hotKey: HotKey) => hotKey.control === props.id && hotKey.key === hotKeyBase.key);
      if (!exists) {
        props.app!.hotKeys.push(hotKey);
      }

    }
  }

}
