


















import { Component, Prop, Vue } from 'vue-property-decorator';
// Types
import { IGUIEvent } from '../../types'
import { LabelProps, GUIEventCode } from '../../types/enums';
import { CharacterUnderlineProps } from './../../types/components'
import { UtilsType } from './../../types/utils'
// Utilities 
import Utils from './../../utils/index';
// Components 
import CharacterUnderline from './../layout/CharacterUnderline.vue';

@Component({
  name: 'gxLabelComponent',
  components: { CharacterUnderline }
})

export default class gxLabel extends Vue {
  /** ******************************** Vue Props **********************************/
  @Prop({ default: () => (new LabelProps()) }) private props!: LabelProps;
  
  /** ******************************** Vue Data **********************************/
  public utils: UtilsType = new Utils();

  public clickCount: number = 0
  public clickTimer: any = null

  /** ******************************** Vue Computed **********************************/

  //get text() { return Array.isArray(this.props.gpValue) ? this.props.gpValue.join('<br/>') : this.props.gpValue; }
  get text() { return Array.isArray(this.props.gpValue) ? this.props.gpValue : new Array(this.props.gpValue) }

  get style(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = []
    const positioning = this.utils.controlPositionCSS(this.props.gpTop, this.props.gpLeft);
    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight)
    const font = this.utils.controlFont(this.props)
    const border = this.utils.controlBorder(this.props)
    const alignment = this.utils.controlAlign(this.props)
    const containerCSS: Partial<CSSStyleDeclaration> = { 
      backgroundColor: this.props.gpBackColor,
      color: this.props.gpForeColor
      }
    if (this.props.gpTransparent) {
        containerCSS.backgroundColor = 'transparent';
      }
    style.push(size)
    style.push(font)
    style.push(border)
    style.push(positioning)
    style.push(alignment)
    style.push(containerCSS)

    return style
  }

  get enabled(): boolean { return this.props.gpEnabled }

  get tabindex(): number { return this.props.tabindex }

  get cuProps(): CharacterUnderlineProps {
    return { app: this.props.app!.id, form: this.props.form!.id, control: this.props.id, type: this.props.type }
  }

  /** ******************************** Vue Methods **********************************/

  singleClickHandler(e: MouseEvent) {
      e.stopPropagation()

      this.clickCount++
      const guiEvent: IGUIEvent = { event: GUIEventCode.geClick, id: this.props.id }

      if (this.clickCount === 1) {
          this.clickTimer = setTimeout(() => { this.clickCount = 0 }, 250)  

          if (this.props.gpEventMask & GUIEventCode.geClick) {
            this.$store.dispatch('guiGuis/addEvent', guiEvent);
          }        
      } else {
          this.clickCount = 0
      }
  }

  doubleClickHandler(e: MouseEvent) {
      e.stopPropagation()

      this.clickCount = 0
      const guiEvent: IGUIEvent = { event: GUIEventCode.geDblClick, id: this.props.id }

      if (this.props.gpEventMask & GUIEventCode.geDblClick) {
        this.$store.dispatch('guiGuis/addEvent', guiEvent);
      }
  }
    
  rightClickHandler(e: MouseEvent) {
    const guiEvent: IGUIEvent = { event: GUIEventCode.geContext, id: this.props.id }

    if (this.props.gpEventMask & GUIEventCode.geContext) {
      this.$store.dispatch('guiGuis/addEvent', guiEvent);
    }

    this.$store.dispatch('guiGuis/updateMousePosition', this.utils.getMousePositionRelativeToParent(e, this.props.form!.id))
  }

}
