

















import { Component, Prop, Vue } from 'vue-property-decorator';
// Types
import { MenuItemProps, StatusbarProps } from './../../types/enums'
import { CharacterUnderlineProps } from './../../types/components'
import { UtilsType } from './../../types/utils'
// Utilities 
import Utils from './../../utils/index';
import CharacterUnderline from './../layout/CharacterUnderline.vue';
import Icon from './../layout/Icon.vue';

@Component({
  name: 'gxStatusbarComponent',
  components: { CharacterUnderline, Icon }
})

export default class gxStatusbar extends Vue {
  /** ******************************** Vue Props **********************************/

  @Prop({ default: () => (new StatusbarProps()) }) private props!: any;

  /** ******************************** Vue Data **********************************/
  public utils: UtilsType = new Utils();
  public numLock: boolean = false;
  public capsLock: boolean = false;
  public currentDate: any = '';

  /** ******************************** Vue Computed **********************************/

  get colorStyle() {
    let style: Partial<CSSStyleDeclaration>[] = []
    const colors: Partial<CSSStyleDeclaration> = { 
      backgroundColor: this.utils.getHexColor('MENUBAR'),
      color: this.utils.getHexColor('MENUTEXT')
    }
    style.push(colors)
    return style
  }

  get iconStyle(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = []
    let styleProps = {
      width: '',
      height: '',
      cursor: 'pointer'
    };
    //icon size is small
    if (this.props.gpIconSize === 0) {  
        styleProps.width = '16px';
        styleProps.height = '16px';
    }
    //icon size is large
    if (this.props.gpIconSize === 1) {
        styleProps.width = '32px';
        styleProps.height = '32px';
    }

    style.push(styleProps);
    return style;
  }

  get visible () { return this.props.gpVisible }

  get enabled () { return this.props.gpEnabled }

  get items () { 

    let items = this.props.gpItems.map((item: MenuItemProps) => {

      let newItem = { ...item };

      if (newItem.id === '*Caps') {
        newItem.caption = 'Caps'
      } else if (newItem.id === '*Num'){
        newItem.caption = 'Num'
      }  

      return newItem

    })
    
    return items
  }

  get cuProps(): CharacterUnderlineProps {
    let form = this.props.form ? this.props.form.id : null;
    return { app: this.props.app!.id, form: form, control: this.props.id, type: this.props.type }
  }

  /** ******************************** Vue Methods **********************************/

  getIconName (value: string) { return this.utils.getIcon(value) };

  getPanelWidth (value: any) {
    let returnValue: any = []
    let returnObject: any = {}
    if(value == 0){
      returnObject.width = 'auto'  + '!important';
    } else {
      returnObject.width = value +'px' + ' ' + '!important';
    }   
    returnValue.push(returnObject)
    return returnValue;
  }

  created () {
    window.addEventListener('keydown', this.keyHandler);
      setInterval(this.formatDate, 1000);
  }

  beforeDestroy() {
    window.removeEventListener('keydown', this.keyHandler);
  }

  keyHandler (e: any) {    
    this.capsLock = e.getModifierState("CapsLock")
    this.numLock = e.getModifierState("NumLock")
  }

  formatDate() {
    let date: any = new Date();
    let hour: any = date.getHours()
    let minutes: any = date.getMinutes()
    let stringDate = date.toDateString()

    let am_Pm = (hour >= 12)? 'PM' : 'AM';

    const [dayText, month, day ,year ] = stringDate.split(' ');

    let formatedDate =  `${day}-${month}-${year}` + ' ' + (hour <= 9 ? '0' + hour : hour) + ':' +  (minutes <= 9 ? '0' + minutes : minutes)  +''+ am_Pm;
    this.currentDate = formatedDate
  }

  checkDisabledState(item: any) {
    let style: any  = [{ marginLeft: '3px'}]
    if (item.enabled === true){
        if (item.id === '*Caps') {
          if (this.capsLock === true) {
            style.push({color: this.utils.getHexColor('MENUTEXT')})
          } else {
            style.push({color:  this.utils.getHexColor('GRAYTEXT') })
            }        
        } else if (item.id === '*Num') {
          if (this.numLock === true) {
            style.push({color: this.utils.getHexColor('MENUTEXT')})
          } else {
            style.push({color:  this.utils.getHexColor('GRAYTEXT') })
          }        
        } 
    } else {
      style.push({color:  this.utils.getHexColor('GRAYTEXT') })
    }
    return style;
  }

}
