




import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'LoginComponent'
})

export default class Login extends Vue {

}
