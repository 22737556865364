










































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
// Types
import { IGUIEvent } from './../../types'
import { FormSizableProps, FormFixedProps, SDISizableProps, SDIFixedProps, GUIComponent, GUIObjectType, GUIEventCode, HotKey, AllProps, FrameProps, GUIWindowState } from './../../types/enums'
import { UtilsType } from './../../types/utils'
// Utilities 
import Utils from './../../utils/index';

@Component({
  name: 'FormComponent'
})

export default class Form extends Vue {
  
/** ******************************** Vue Props **********************************/

  @Prop({ default: () => ({}) }) private props!: FormSizableProps | FormFixedProps | SDISizableProps | SDIFixedProps;
  @Prop({ default: () => ([]) }) private children!: Array<GUIComponent>

/** ******************************** Vue Data **********************************/

  public utils: UtilsType = new Utils();
  
  public cssclass: any = {
    form: true,
    position_center: false
  };

  public position: Partial<CSSStyleDeclaration> = {
    top: this.props.gpTop + 'px',
    left: this.props.gpLeft + 'px',
    position: 'absolute'
  };

  // Mouse Position
  public mp: any = {
    clientX: undefined,
    clientY: undefined,
    movementX: 0,
    movementY: 0
  }

  public formPosition: any = {
    top: this.props.gpTop,
    left: this.props.gpLeft
  }

  // Array to store the hot keys pressed, which helps when a single key has multiple hot keys
  public hotKeysHistory: Array<string> = [];

  public corners: Array<string> = ["upper-left", "upper-right", "lower-left", "lower-right"];
  public corner: string = "";
  public borders: Array<string> = ["border-l", "border-r", "border-t", "border-b"];
  public border: string = "";

  public isMaximized = false;

  public previousDim: any = {
    width: this.props.gpWidth,
    height: this.props.gpHeight,
    top: this.props.gpTop,
    left: this.props.gpLeft
  }

/** ******************************** Vue Computed **********************************/

  get focused(): string { return this.$store.getters['guiGuis/getFocusedForm'] }
  get focusedApp(): string { return this.$store.getters['guiGuis/getFocusedApp'] }
  get hotKeys(): Array<HotKey> { 
    if (this.isMDI) {
      return this.props.hotKeys.filter((hotKey: HotKey) => hotKey.typeFamily !== 'menu');
    } else {  
      return this.props.hotKeys; 
    }
  }

  get headerIcon (): string { return this.props.app? this.props.app.gpIcon : "" }
  get menuChildren (): Array<GUIComponent> { return this.children.filter(child => child.props.type == GUIObjectType.gxMenu) }
  get toolbarChildren (): Array<GUIComponent>  { return this.children.filter(child => child.props.type == GUIObjectType.gxToolbar) }
  get statusbarChildren (): Array<GUIComponent>  { return this.children.filter(child => child.props.type == GUIObjectType.gxStatusbar) }
  get popupChildren (): Array<GUIComponent>  { return this.children.filter(child => child.props.type == GUIObjectType.gxPopup) }

  get childrenNoMenus (): Array<GUIComponent>  { return this.children.filter(child => child.props.typeFamily !== 'menu') }

  get formFixed (): boolean { return this.props.type === GUIObjectType.gxFormFixed }

  get formSizable (): boolean { return this.props.type === GUIObjectType.gxFormSizable }

  get sdiFixed (): boolean { return this.props.type === GUIObjectType.gxSDIFixed }

  get sdiSizable (): boolean { return this.props.type === GUIObjectType.gxSDISizable }

  get isMinimized (): boolean { return this.props.gpWindowState === GUIWindowState.gwsMinimized}

  get isMDI() { return (this.formSizable || this.formFixed) && this.props.parent!.type === GUIObjectType.gxMDI }

  get captionStyle() : Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = []

    style.push(
      { position: "absolute"}, 
      { display: "inline"}, 
      { fontSize: "15px"}, 
      { whiteSpace:"nowrap"}, 
      { marginLeft:"2px"}, 
      { overflow: "hidden"},
      { width: (this.props.gpWidth - 90) + "px"},
      { textOverflow: "ellipsis"},
    );
    
    return style;
  }

  get style(): Partial<CSSStyleDeclaration>[] { 
    const left = this.props.gpLeft
    const top = this.props.gpTop
    let style: Partial<CSSStyleDeclaration>[] = []
/*
    // left and top are union types (number | string, where string is 'auto')
    let positioning: {[k: string]: any } = {
      top: 'auto',
      left: 'auto'
    }
    // if top and left are both 'auto' then add the center class
    if (typeof left === 'string' && typeof top === 'string') { 
      this.cssclass.position_center = true; 
    } else {
      positioning.position = 'absolute'
    };

    if (typeof top === 'number') { positioning.top = top + 'px'; };
    if (typeof left === 'number') { positioning.left = left + 'px'; };
*/
    const font = this.utils.controlFont(this.props)

    const border = this.utils.controlBorder(this.props)

    // Border should not be none on Form
    if (!border.hasOwnProperty('border') || border.border === 'none') {
      border.border = '1px solid #cccccc'
    }

    const colors = { 
      backgroundColor: this.props.gpBackColor,
      color: this.props.gpForeColor
    }

    const zIndex = { zIndex: this.props.zIndex.toString() }
/*
    const image = this.utils.getImage(this.props.gpPicture)

    const backgroundImage = {
      backgroundImage: `url(${image})`
    }
*/    
    style.push(this.width, font, border, colors, zIndex)
    
    return style
  }

  get width (): Partial<CSSStyleDeclaration> { return { width: this.props.gpWidth + this.addWidth +'px' } }
  get height (): Partial<CSSStyleDeclaration> { return { height: this.props.gpHeight + 'px' } } 

  get menuStyle(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = [];

    style.push({  position: 'relative', backgroundColor: this.utils.getHexColor('WINDOW'), color: this.utils.getHexColor('MENUTEXT'), padding: "0px"});
    let zIndex = (this.props.zIndex + 2).toString();

    style.push({zIndex: zIndex})
    return style;
  }

  get toolbarStyle(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = [];

    if(this.toolbarChildren.length == 0) {
      return style;
    }

    let align = this.toolbarChildren[0].props.gpAlign;
    let zIndex = (this.props.zIndex + 1).toString();

    style.push({zIndex: zIndex})

    if(align == 2) { // Bottom
      style.push({ position: 'absolute', bottom: "0px"});
    } else if(align == 3) { // Left
      style.push({ position: 'relative', float: "left"});
    } else if(align == 4) { // Right
      style.push({ position: 'relative', float: "right"});
    }

    return style;
  }
  
  get toolbarColStyle(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = [];

    if(this.toolbarChildren.length == 0) {
      return style;
    }

    let align = this.toolbarChildren[0].props.gpAlign;

    if(align == 1 || align == 0) { // Top & Floating (Default)
      style.push({ paddingLeft: "0px", borderBottom: "solid 1px grey"}, this.width);
    } else if(align == 2) { // Bottom
      style.push({ paddingLeft: "0px", borderTop: "solid 1px grey"}, this.width);
    } else if(align == 3) { // Left
      style.push({ paddingLeft: "0px", paddingRight: "1px", borderRight: "solid 1px grey"}, this.height);
    } else if(align == 4) { // Right
      style.push({ paddingRight: "0px", paddingLeft: "1px", borderLeft: "solid 1px grey"}, this.height);
    }

    return style;
  }

  // Add width to accomadate left and right toolbar
  get addWidth(): number {
    let width = 0;
    if(this.toolbarChildren.length == 0) {
      return width;
    }

    let align = this.toolbarChildren[0].props.gpAlign;

    if(align == 3) { // Left
      width = 16;
    } else if(align == 4) { // Right
      width = 40;
    }

    return width;
  }

  // Add padding for left and right toolbars
  get addPadding(): Partial<CSSStyleDeclaration> {
    let padding: Partial<CSSStyleDeclaration> = {}
    if(this.toolbarChildren.length == 0) {
      return padding
    }

    let align = this.toolbarChildren[0].props.gpAlign;

    if(align == 3) { // Left
      padding = { paddingLeft: "4px" }
    } else if(align == 4) { // Right
      padding = { paddingRight: "4px" }
    }

    return padding;
  }

  get showImage() { return this.props.gpPicture !== '' }

  get bodyStyle(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = [];

    style.push({ position: 'relative' }, this.addPadding, this.height);

    if(this.showImage) {
      const image = this.utils.getImage(this.props.gpPicture);
      const backgroundImage = {
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
      }
      style.push(backgroundImage)
    }

    return style;
  }

  get statusbarStyle(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = [];

    style.push({ position: 'relative' });

    return style;
  }

  get visible(): boolean { return this.props.gpVisible }

  get enabled(): boolean { return this.props.gpEnabled }

  get tabindex(): number { return this.props.tabindex }

  get hasNotifications(): boolean { return this.$store.getters['guiNotifications/getNotifications']? true : false }

  public divHeight: number = 0;

/** ******************************** Vue Methods **********************************/

  // divHeight(): number {
  //   let div = (this.$refs[this.props.id] as HTMLDivElement);
  //   let height = 0;
  //   this.$nextTick( () => {
  //     height = div.offsetHeight
  //   });
  //   return height;
  // }

  cornerStyle(corner: string) {
    let style: Partial<CSSStyleDeclaration>[] = []
    let height = this.divHeight + 10;
    let width = this.props.gpWidth - 25;
    style.push({"height": "20px"}, {"width": "20px"}, {"zIndex": "100"}, {"position": "absolute"})

    switch(corner) {
      case "upper-left":
        style.push({"marginTop": (-height) + "px"}, {"marginLeft": "-22px"});
      break;
      case "upper-right":
         style.push({"marginTop": (-height) + "px"}, {"marginLeft": width + "px"});
      break;
      case "lower-left":
         style.push({"marginTop": "-10px"}, {"marginLeft": "-22px"});
         break;
      case "lower-right":
         style.push({"marginTop": "-10px"}, {"marginLeft": width + "px"});
      break;
    }

    return style
  }

  borderStyle(border: string) {
    let style: Partial<CSSStyleDeclaration>[] = []
    let height = this.divHeight + 10;
    let width = this.props.gpWidth - 25;
    style.push({"position": "absolute"})

    switch(border) {
      case "border-l":
        style.push({"height": (height + 10) + "px"}, {"width": "20px"}, {"marginTop": (-height) + "px"}, {"marginLeft": "-22px"})
      break;
      case "border-r":
         style.push({"height": (height + 10) + "px"}, {"width": "20px"}, {"marginTop": (-height) + "px"}, {"marginLeft": width + "px"})
      break;
      case "border-t":
         style.push({"height": 20 + "px"}, {"width": width + 25 +"px"}, {"marginTop": (-height) + "px"}, {"marginLeft": "-22px"})
         break;
      case "border-b":
         style.push({"height": 20 + "px"}, {"width": width + 25 +"px"}, {"marginTop": "-10px"}, {"marginLeft": "-22px"})
      break;
    }

    return style
  }

  altKeyHandler (e: any) {

    const key = e.key.toLowerCase()
    const keyCode = e.keyCode
    // not sure why hotKey.keyCode does not equal e.keyCode... need to Ask Pete
    const hotKeys: Array<HotKey> = this.hotKeys.filter(hotKey => hotKey.key === key);
    
    // Confirm the form has hot key(s)
    if (hotKeys.length > 0) {
      // Sets the local state hotKey history array and gets the current position
      // Solves for multiple instances of one hot key
      let historyPosition: number = this.hotKeyHistoryPosition(hotKeys);
      
      const props: AllProps = this.$store.getters['guiGuis/getProps'](hotKeys[historyPosition].control);
      let triggerProps: AllProps = props; 

      // Label and Frame are the only controls that would have a trigger prop that aren't itself
      if (props.type === GUIObjectType.gxLabel || props.type === GUIObjectType.gxFrame) {
        triggerProps = this.$store.getters['guiGuis/getProps'](hotKeys[historyPosition].trigger);
      } 

      switch (props.type) {
        // Label - focus on adjacent control
        case (GUIObjectType.gxLabel):
          this.$store.dispatch('guiGuis/setFocused', { id: triggerProps.id, typeFamily: triggerProps.typeFamily });
        break;
        // Button - triggers
        case (GUIObjectType.gxCommand): 
          if (triggerProps.gpEventMask & GUIEventCode.geClick) {
            // send this event to the server
            const guiEvent: IGUIEvent = { event: GUIEventCode.geClick, id: triggerProps.id }
            this.$store.dispatch('guiGuis/addEvent', guiEvent);
          }
          this.$store.dispatch('guiGuis/setFocused', { id: triggerProps.id, typeFamily: triggerProps.typeFamily });
        break;
        case (GUIObjectType.gxCheck): 
          // TODO Add checkbox hot key
          console.log('alt + '+ key +' - Checkbox '+ triggerProps.id);
        break;
        case (GUIObjectType.gxOption): 
          // TODO Add option hot key
          console.log('alt + '+ key +' - Option '+ triggerProps.id);
        break;
        case (GUIObjectType.gxFrame): 
          // TODO Add frame hot key
          const components: Array<GUIComponent> = this.$store.getters['guiGuis/getComponent'](triggerProps.id).children;
          const {id, typeFamily} = components[0].props
          console.log("Frame", id, typeFamily)
          this.$store.dispatch('guiGuis/setFocused', { id: id, typeFamily: typeFamily });
        break;
        // Tab - open triggered tab
        case (GUIObjectType.gxTab): 
          const parentID: string = triggerProps.parent!.id
          const tabs: Array<GUIComponent> = this.$store.getters['guiGuis/getComponent'](parentID).children;
          const tabIndex: number = tabs.findIndex((tab: GUIComponent) => tab.props.id === triggerProps.id)

          this.$store.dispatch('guiGuis/updateProperty', {id: parentID, property: 'gpValue', value: tabIndex + 1 }) 
          console.log('alt + '+ key +' - Tab ' + triggerProps.id)
        break;
        case (GUIObjectType.gxMenu): 
          // TODO Add menu hot key
          console.log('alt + '+ key +' - Menu '+ triggerProps.id);
        break;
        case (GUIObjectType.gxToolbar):
          // TODO Add toolbar hot key 
          console.log('alt + '+ key +' - Toolbar '+ triggerProps.id);
        break;
        case (GUIObjectType.gxPopup): 
        // TODO Add popup hot key
          console.log('alt + '+ key +' - Popup menu '+ triggerProps.id);
        break;
        case (GUIObjectType.gxStatusbar):
          // TODO Add status hot key 
          console.log('alt + '+ key +' - Status bar '+ triggerProps.id);
        break;
      }

    }
  }

  hotKeyHistoryPosition (hotKeys: Array<HotKey>) {
    const hotKeysLength: number = hotKeys.length;
    let history: number = this.hotKeysHistory.length;

    if (history < hotKeysLength) {
      this.hotKeysHistory.push(hotKeys[history].key);
      // Just confirm the first two aren't the same
      if (this.hotKeysHistory[0] !== hotKeys[0].key) {
        // reset to the new hotKey
        history = 0;
        this.hotKeysHistory = [hotKeys[0].key]
      }

    } else {
      // Reset to new hotKey or potentially the first of the same one
      history = 0;
      this.hotKeysHistory = [hotKeys[0].key]
    }

    return history;
  }

  clickHandler(e: any) {
    console.log('Form Click');
    // Form should have been activated in focusHandler
    // Clear open popups
    if (this.popupChildren.length > 0) {
      this.popupChildren.forEach(popup => {
        const el = document.getElementById(popup.props.id)
        // confirm the popup is visible and is not contained in the popup element/div
        if (popup.props.gpVisible && !el!.contains(e.target)) {
          this.$store.dispatch('guiGuis/updateProperty', { id: popup.props.id, property: 'gpVisible', value: false }) 
        }
      })
    }
  }

  focus() {
    if (this.focused === this.props.id) {
      const zindex = this.$store.getters['guiGuis/getNextFormZIndex'];
      this.$store.dispatch('guiGuis/setNextFormZIndex', zindex + 1);
      this.$store.dispatch('guiGuis/updateProperty', { id: this.props.id, property: 'zIndex', value: zindex });
console.log('Form Focus Me ' + this.props.id + ' Z=' + zindex);      
    }
  }

  activateHandler(e: any) {
    // Set as focused
    let event: IGUIEvent;
const _related = (e.relatedTarget && (this.utils.getRelatedTargetID(e.relatedTarget, '', 'controlID') || this.utils.getRelatedTargetID(e.relatedTarget, '', 'formID'))) || null;
const _target = (e.target && (this.utils.getRelatedTargetID(e.target, '', 'controlID') || this.utils.getRelatedTargetID(e.target, '', 'formID'))) || null;
const _current = (e.currentTarget && (this.utils.getRelatedTargetID(e.currentTarget, '', 'controlID') || this.utils.getRelatedTargetID(e.currentTarget, '', 'formID'))) || null;
console.log('   Form focus targets: current=' + _current + ' related=' + _related + ' target=' + _target);
    let prevFormID = (e.relatedTarget && this.utils.getRelatedTargetID(e.relatedTarget, '', 'formID')) || null;
    if (!prevFormID) {
      // handle the deferred deactivate event
      prevFormID = this.utils.handleDeferredFrmDeactivate(this.props.id);
    }
    if (prevFormID !== this.props.id) {
      //const prevAppID = this.focusedApp;
      const prevAppID = (prevFormID && prevFormID.substr(0, prevFormID.indexOf('*'))) || null;
      if (this.props.app!.id !== prevAppID) {
console.log('App activate ' + this.props.app!.id + ' from ' + prevAppID);
        if (this.props.app!.gpEventMask & GUIEventCode.geActivate) {
          event = { event: GUIEventCode.geActivate, id: this.props.app!.id, args: [prevAppID] }
          this.$store.dispatch('guiGuis/addEvent', event);
        }
      }
console.log('Form activate ' + this.props.id + ' from ' + prevFormID);
      if (this.props.gpEventMask & GUIEventCode.geActivate) {
        event = { event: GUIEventCode.geActivate, id: this.props.id, args: [prevFormID] }
        this.$store.dispatch('guiGuis/addEvent', event);
      }
    } else console.log('   skipping Form activate ' + this.props.id + ' - self');

    // Set focused in store and in parent app
    
    // This is really odd. If we call 'setFocused' here, this will set the 'focused' property
    // in the store to this form's previously focused control. If the previously focused control
    // is the target of this 'focus' event (capturing phase), then that control will also set
    // the store 'focused' property to the same value. For some reason, some controls (Option)
    // receive a 'focusin' event with itself as the relatedTargetID, causing the Activate event
    // to be skipped. So here we test if there is a target controlID, and skip the 'setFocused'
    // in that case.
    const targetControlID = (e.target && this.utils.getRelatedTargetID(e.target, this.props.id, 'controlID')) || null;
    if (!targetControlID) {
      this.$store.dispatch('guiGuis/setFocused', { id: this.props.id });
    }
    
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.app!.id, property: 'focused', value: this.props.id });
  }

  deactivateHandler(e: any) {
const _related = (e.relatedTarget && (this.utils.getRelatedTargetID(e.relatedTarget, '', 'controlID') || this.utils.getRelatedTargetID(e.relatedTarget, '', 'formID'))) || null;
const _target = (e.target && (this.utils.getRelatedTargetID(e.target, '', 'controlID') || this.utils.getRelatedTargetID(e.target, '', 'formID'))) || null;
const _current = (e.currentTarget && (this.utils.getRelatedTargetID(e.currentTarget, '', 'controlID') || this.utils.getRelatedTargetID(e.currentTarget, '', 'formID'))) || null;
console.log('   Form blur targets: current=' + _current + ' related=' + _related + ' target=' + _target);
    // if the current target doesn't contain the related
    if (!e.currentTarget.contains(e.relatedTarget)) {
      const nextFormID = (e.relatedTarget && this.utils.getRelatedTargetID(e.relatedTarget, '', 'formID')) || null;
      if (nextFormID) {
        this.handleDeactivateEvents(nextFormID);
      } else {
        this.$store.dispatch('guiGuis/setDeferredFrmDeactivate', this.handleDeactivateEvents.bind(this));
console.log('   deferring Form deactivate ' + this.props.id + ' related = null');
      }
    } else console.log('   skipping Form deactivate ' + this.props.id + ' - target is child');
  }

  handleDeactivateEvents(nextFormID: string): string {
    let event: IGUIEvent;
    if (nextFormID !== this.props.id) {
console.log('Form deactivate ' + this.props.id + ' to ' + nextFormID);
      if (this.props.gpEventMask & GUIEventCode.geDeactivate) {
        event = { event: GUIEventCode.geDeactivate, id: this.props.id, args: [nextFormID] }
        this.$store.dispatch('guiGuis/addEvent', event);
      }
      const nextAppID = (nextFormID && nextFormID.substr(0, nextFormID.indexOf('*'))) || null;
      if (nextAppID !== this.props.app!.id) {
        // if next form belongs to different gui app, fire app deativate event
console.log('App deactivate ' + this.props.app!.id + ' to ' + nextAppID);
        const nextApp = this.$store.getters['guiGuis/getComponent'](nextAppID);
        if (nextApp && nextApp.props.gpEventMask & GUIEventCode.geDeactivate) {
          event = { event: GUIEventCode.geDeactivate, id: nextApp.props.id, args: [this.props.app!.id] }
          this.$store.dispatch('guiGuis/addEvent', event);
        }          
      }
    } else console.log('   skipping Form deactivate ' + this.props.id + ' - self')
    return this.props.id;
  }

  closeHandler(e: any) {
    //e.stopPropagation();
    // Check that close event is included in the prop events
    if (this.props.gpEventMask & GUIEventCode.geClose) {
      // send this event to the server
      const guiEvent: IGUIEvent = { event: GUIEventCode.geClose, id: this.props.id }

      this.$store.dispatch('guiGuis/addEvent', guiEvent);
    }
  }

  /*
    Found this elegant solution here: 
    https://dev.to/mandrewcito/vue-js-draggable-div-3mee
  */
  dragMouseDown(e: MouseEvent) {
    //e.preventDefault()

    // get the mouse cursor position at startup:
    this.mp.clientX = e.clientX
    this.mp.clientY = e.clientY

    document.onmousemove = this.elementDrag
    document.onmouseup = this.closeDragElement
  }

  elementDrag(e: MouseEvent) {
    e.preventDefault()

    this.mp.movementX = this.mp.clientX - e.clientX
    this.mp.movementY = this.mp.clientY - e.clientY
    this.mp.clientX = e.clientX
    this.mp.clientY = e.clientY

    let offsetTop = (this.$refs[this.props.id] as HTMLElement).offsetTop;
    let offsetLeft = (this.$refs[this.props.id] as HTMLElement).offsetLeft;

    // set the element's new position:
    this.formPosition.left = (offsetLeft - this.mp.movementX)
    this.formPosition.top = (offsetTop - this.mp.movementY)
    this.position.top = this.formPosition.top + 'px'
    this.position.left = this.formPosition.left + 'px'
  }

  closeDragElement () {
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpTop', value: this.formPosition.top }) 
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpLeft', value: this.formPosition.left }) 
    document.onmouseup = null
    document.onmousemove = null
  }

  mouseClickCorner(e:MouseEvent) {
    this.corner = (e.target as HTMLDivElement).className;

    this.mp.clientX = e.clientX
    this.mp.clientY = e.clientY

    document.onmouseup = this.closeElementExtend
    document.onmousemove = this.elementExtendCorner
  }

  mouseClickBorder(e:MouseEvent) {
    this.border = (e.target as HTMLDivElement).className;

    this.mp.clientX = e.clientX
    this.mp.clientY = e.clientY

    document.onmouseup = this.closeElementExtend
    document.onmousemove = this.elementExtendBorder
  }

  elementExtendCorner(e: MouseEvent) {
    e.preventDefault()
    this.mp.movementX = this.mp.clientX - e.clientX
    this.mp.movementY = this.mp.clientY - e.clientY
    this.mp.clientX = e.clientX
    this.mp.clientY = e.clientY

    // set the element's new position:
    let offsetTop = (this.$refs[this.props.id] as HTMLElement).offsetTop;
    let offsetLeft = (this.$refs[this.props.id] as HTMLElement).offsetLeft;

    let newHeight = this.props.gpHeight;
    let newWidth = this.props.gpWidth;

    switch(this.corner) {
      case "upper-left":
        newHeight = this.props.gpHeight + this.mp.movementY
        newWidth = this.props.gpWidth + this.mp.movementX
    
        this.position.top = (offsetTop - this.mp.movementY) + 'px'
        this.position.left = (offsetLeft - this.mp.movementX) + 'px'
      break;
      case "upper-right":
        newHeight = this.props.gpHeight + this.mp.movementY
        newWidth = this.props.gpWidth - this.mp.movementX
        this.position.top = (offsetTop - this.mp.movementY) + 'px'
      break;
      case "lower-left":
        newHeight = this.props.gpHeight - this.mp.movementY
        newWidth = this.props.gpWidth + this.mp.movementX

        this.position.left = (offsetLeft - this.mp.movementX) + 'px'
         break;
      case "lower-right":
        newHeight = this.props.gpHeight - this.mp.movementY
        newWidth = this.props.gpWidth - this.mp.movementX
      break;
    }
    this.props.gpHeight = newHeight;
    this.props.gpWidth = Math.max(newWidth, 150);
  }

  elementExtendBorder(e: MouseEvent) {
    e.preventDefault()
    this.mp.movementX = this.mp.clientX - e.clientX
    this.mp.movementY = this.mp.clientY - e.clientY
    this.mp.clientX = e.clientX
    this.mp.clientY = e.clientY

    // set the element's new position:
    let offsetTop = (this.$refs[this.props.id] as HTMLElement).offsetTop;
    let offsetLeft = (this.$refs[this.props.id] as HTMLElement).offsetLeft;
    let offsetWidth = (this.$refs[this.props.id] as HTMLElement).offsetWidth;

    let newHeight = this.props.gpHeight;
    let newWidth = this.props.gpWidth;

    switch(this.border) {
      case "border-l":
        newWidth = offsetWidth + this.mp.movementX
        this.position.left = (offsetLeft - this.mp.movementX) + 'px'
      break;
      case "border-r":
        newWidth = offsetWidth - this.mp.movementX
      break;
      case "border-t":
        this.position.top = (offsetTop - this.mp.movementY) + 'px'
        newHeight = this.props.gpHeight + this.mp.movementY
         break;
      case "border-b":
        newHeight = newHeight - this.mp.movementY
      break;
    }
    this.props.gpHeight = newHeight;
    this.props.gpWidth = Math.max(newWidth, 150);
  }

  minimize() {
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpWindowState', value: GUIWindowState.gwsMinimized }) 
  }


  maximize() {
    if(this.isMaximized) {
      this.props.gpHeight = this.previousDim.height;
      this.props.gpWidth = this.previousDim.width;
      this.position.left = this.previousDim.left!;
      this.position.top = this.previousDim.top!;
      this.props.zIndex = 1
      this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpWindowState', value: GUIWindowState.gwsNormal }) 
    } else {
      this.previousDim.height = this.props.gpHeight;
      this.previousDim.width = this.props.gpWidth;
      this.previousDim.top = this.position.top!;
      this.previousDim.left = this.position.left!;
      this.position.left = "0px";
      this.position.top = this.hasNotifications? "-20px" : "0px"; // Notifications are margin-top 20px
      this.position.position = "absolute"
      this.props.zIndex = 200;
      this.props.gpHeight = window.innerHeight - (this.divHeight - this.props.gpHeight);
      this.props.gpWidth = window.innerWidth - this.addWidth;
      this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpWindowState', value: GUIWindowState.gwsMaximized }) 

    }
    const args = [this.utils.scaleConversion(this.props.gpWidth, 'h', this.utils.getScale(this.props)), this.utils.scaleConversion(this.props.gpHeight, 'h',this.utils.getScale(this.props))];
    const resizeEvent: IGUIEvent = { event: GUIEventCode.geResize, id: this.props.id, args }
    this.$store.dispatch('guiGuis/addEvent', resizeEvent);

    this.isMaximized = !this.isMaximized;
  }

  closeElementExtend () {
    this.divHeight = (this.$refs[this.props.id] as HTMLDivElement).offsetHeight 
    
    // Resize event is causing infinite req/res loop when more than one form exists
    // const args = [this.utils.scaleConversion(this.props.gpWidth, 'h', this.utils.getScale(this.props)), this.utils.scaleConversion(this.props.gpHeight, 'h',this.utils.getScale(this.props))];
    // const resizeEvent: IGUIEvent = { event: GUIEventCode.geResize, id: this.props.id, args }
    // console.log(resizeEvent);
    // this.$store.dispatch('guiGuis/addEvent', resizeEvent);
    
    document.onmouseup = null
    document.onmousemove = null
  }

/** ******************************** Vue Life Cycle **********************************/

  mounted() {
    this.$nextTick(() => { 
      this.focus(); 
      let div = (this.$refs[this.props.id] as HTMLDivElement)
      this.divHeight = div? div.offsetHeight : 0; 
    });

    window.addEventListener("resize", () => {
      if(this.isMaximized) {
        this.props.gpHeight = window.innerHeight;
        this.props.gpWidth = window.innerWidth - this.addWidth;
      }
    })
  }

/** ******************************** Vue Watch and Emit Events! **********************************/

  @Watch('focused') passRequest () { 
    this.focus();
  }
}
