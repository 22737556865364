






























import { Component, Prop, Vue } from 'vue-property-decorator';
// Types
import { IGUIEvent } from './../../types'
import { GUIComponent, TabProps, GUIEventCode } from './../../types/enums'
import { CharacterUnderlineProps } from './../../types/components'
import { UtilsType } from './../../types/utils'
// Utilities 
import Utils from './../../utils/index';
// Components
import CharacterUnderline from './../layout/CharacterUnderline.vue';
import Icon from './../layout/Icon.vue';

@Component({
  name: 'gxTabComponent',
  components: { CharacterUnderline, Icon }
})

export default class gxTab extends Vue {
  /** ******************************** Vue Props **********************************/

  @Prop({ default: () => (new TabProps()) }) private props!: TabProps;
  @Prop({ default: () => ([]) }) private children!: Array<GUIComponent>
  
  @Prop({ default: false }) private selected!: boolean
  @Prop({ default: 0 }) private parentHeight!: number
  @Prop({ default: false }) private stackVertical!: boolean

  /** ******************************** Vue Data **********************************/
  public utils: UtilsType = new Utils();

  /** ******************************** Vue Computed **********************************/

  get iconStyle(): Partial<CSSStyleDeclaration>[] {
        let style: Partial<CSSStyleDeclaration>[] = []
        let styleProps = {
            width: '5px',
            height: '5px',
            cursor: 'pointer'
        };
        style.push(styleProps)
        return style;
    }

  get caption() { return Array.isArray(this.props.gpCaption) ? this.props.gpCaption.join('\r\n') : this.props.gpCaption; }

  get containerStyle(): Partial<CSSStyleDeclaration> {
    // 35 is just an estimate of the tabs menu height
    // eventually we'll need to use a ref (or something) to figure out the exact height and subtract it from the total
    let tabHeight: number = (this.stackVertical) ? this.parentHeight : this.parentHeight - 35 

    let style: Partial<CSSStyleDeclaration> = { 
      position: 'relative', 
      display: 'block', 
      width: '100%', 
      height: tabHeight +'px'
    };

    return style
  }

  get enabled(): boolean { return this.props.gpEnabled }

  get tabindex(): number { return this.props.tabindex }

  get cuProps(): CharacterUnderlineProps {
    return { app: this.props.app!.id, form: this.props.form!.id, control: this.props.id, type: this.props.type }
  }

  /** ******************************** Vue Methods **********************************/

  leftClickHandler(e: MouseEvent) {
    e.stopPropagation();
    
    if (!this.selected) {
      
      // Single Click
      if (this.props.gpEventMask & GUIEventCode.geClick) {
          // send this event to the server
          const guiEvent: IGUIEvent = { event: GUIEventCode.geClick, id: this.props.id }
          this.$store.dispatch('guiGuis/addEvent', guiEvent);
      }
    }

  }

  tabGroupRightClickHandler(e: MouseEvent) {
    e.stopPropagation();

    this.$emit('rightClick', e)    
    this.rightClickHandler(e)
  }

  rightClickHandler(e: MouseEvent) {
    if (this.props.gpEventMask & GUIEventCode.geContext) {
      // send this event to the server
      const contextEvent: IGUIEvent = { event: GUIEventCode.geContext, id: this.props.id }
      this.$store.dispatch('guiGuis/addEvent', contextEvent);
    }
  }

}
