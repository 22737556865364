






















import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'Error404Component'
})

export default class Error404 extends Vue {
/** ******************************** Vue Computed **********************************/
    get userAuth () { return this.$store.getters['user/getUserAuth'] }
}
