






















import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'AppErrorComponent'
})

export default class AppError extends Vue {
/** ******************************** Vue Data **********************************/

    private message: string = 'Unfortunately there has been an error. Please try again at a later time.';

/** ******************************** Vue Computed **********************************/

    get browser () { return this.$store.getters.getBrowser }

    get userAuth () { return this.$store.getters['user/getUserAuth'] }

    created () {
        if (this.browser.ie) {
            this.message = 'Unfortunately AccuTerm.io is not supported in Internet Explorer. Please log in with a different browser.'
        }
    }
}
