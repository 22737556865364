// Store
import store from '../../store'
// Types
import { IGUICmdDelete, IGUIEvent, IGUIRspError, IGUIRspSuccess, simpleNotification } from '../../types'
import { GUIComponent, GUIEventCode, GUIErrorCode, GUIErrorLevel, AllProps, HotKeyBase, HotKey } from '../../types/enums'
import { GuisServiceDeleteType } from '../../types/services/guis'
// Utilities 
import Utils from '../../utils';
// Services
import GuisServiceSetProp from './setProp'
import GuisServiceMethod from './method'

export default class GuisServiceDelete implements GuisServiceDeleteType {

    public utils = new Utils();
    public setPropService = new GuisServiceSetProp();
    public methodService = new GuisServiceMethod();

    delete(command: IGUICmdDelete) {

        let response: IGUIRspSuccess | IGUIRspError = { command: command.command, error: GUIErrorCode.grSuccess }

        if (command.id !== '*') { // NEVER DELETE THE ROOT!
            // Confrim there is a parent
            const component: GUIComponent | undefined = store.getters['guiGuis/getComponent'](command.id);
            if (component) {
                const activateID = this.setPropService.preHideDelete(component.props); // before deleting, get ID of control to activate
                const parent: GUIComponent = store.getters['guiGuis/getComponent'](component.props.parentID);
                if (parent) {
                    parent.children = parent.children.filter(child => child !== component);
                }
                this.deleteComponentFromMap(component);
                // Ensure focus is valid after deleting
                if (activateID) {
                    const activateComponent: GUIComponent | undefined = store.getters['guiGuis/getComponent'](activateID);
                    if (activateComponent && this.methodService.activatable(activateComponent)) {
                    this.methodService.activateComponentTree(activateComponent); // may throw GUIError
                    }
                }
              } else {
                // error repsonse
                response = { command: command.command, error: GUIErrorCode.grNoExist, level: GUIErrorLevel.glvlFail, message: 'Unable to delete component (' + command.id + ')', args: [command.id] };
                // Notify
                const notification: simpleNotification = { type: 'warning', show: false, message: 'Unable to delete component (' + command.id + ')', friendlyMessage: 'Unable to delete component (' + command.id + ')', script: 'services/guis/delete.ts', error: { errorCode: GUIErrorCode.grNoExist, errorLevel: GUIErrorLevel.glvlFail } }
                store.dispatch('guiNotifications/addNotification', notification)
            }
        }

        const root: GUIComponent = store.getters['guiGuis/getGuisRoot'];
        if (root.children.length === 0) {
            // Last GUI object deleted - add Quit event to event queue
            const guiEvent: IGUIEvent = { event: GUIEventCode.geQuit, id: '' }
            store.dispatch('guiGuis/addEvent', guiEvent);
        }

        store.dispatch('guiResponse/setResponse', response)
    }

    // Clean up the component map - remove self and all descendents
    deleteComponentFromMap(component: GUIComponent) {                    
        component.children.forEach(child => {
            this.deleteComponentFromMap(child);
        });
        this.removeHotKeysFromParent(component.props);
        store.dispatch('guiGuis/deleteComponent', component.props.id);
    }
    
    // If a deleted component has hotkeys, remove them from the form or app
    removeHotKeysFromParent(props: AllProps) {
        if (props.hotKeys && props.hotKeys.length > 0 && (props.typeFamily !== 'form' && props.typeFamily !== 'app')) {
            if (props.form) {            
                props.form.hotKeys = props.form.hotKeys.filter(k => k.control !== props.id);
            } else if (props.app) {
                props.app.hotKeys = props.app.hotKeys.filter(k => k.control !== props.id);
            }
        }        
    }
}
