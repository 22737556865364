// Store
import store from './../../store'
// Types
import { GUIResponse } from '../../types'
import { ResponseServiceType } from '../../types/services/response'

export default class ResponseService implements ResponseServiceType {
    
    responseHandler (response: GUIResponse) {
        
        const requestLength: number = store.getters['guiRequest/getRequestLength']
        let responseLength: number = store.getters['guiResponse/getResponseLength']

        responseLength = responseLength + 1
        
        if (responseLength === requestLength) {
            // set respond to true (to trigger the watcher in app.vue that passes the response to accuterm.io)
            store.dispatch('guiResponse/respond', true)
        }

        store.dispatch('guiResponse/responseLength', responseLength)
        store.dispatch('guiResponse/addResponseCommand', response)
    }

}
