




























































































































import { Component, Watch, Vue } from 'vue-property-decorator'
// Types
import { BModal } from 'bootstrap-vue'
// Components
import AccuTerm from '@/components/terminal/AccuTerm.vue'
import TermFooter from '@/components/terminal/Footer.vue'
import Keyboard from '@/components/terminal/Keyboard.vue'

@Component({
    name: 'TerminalComponent',
    components: {
       AccuTerm, TermFooter, Keyboard
    }
})

export default class Terminal extends Vue {
/** ******************************** Vue Data! **********************************/

    private isProfileID : boolean = false;
    private copyClipboardValue : string = '';
    private pasteClipboardValue : string = '';

    $refs!: {
        copyModal: BModal,
        copyClipboard: HTMLTextAreaElement,
        pasteModal: BModal,
        pasteClipboard: HTMLTextAreaElement,
        termMessageBoxModal: BModal,
        termCertFailModal: BModal
    }

/** ******************************** Vue Computed **********************************/

    get getClipboardTerm () { return this.$store.getters['terminal/getClipboardTerm'] }
    get messageBox () { return this.$store.getters['terminal/messageBox'] }

    get hasActiveProfile (): boolean { return this.$store.getters['profiles/getHasActiveProfile'] }
    get hasProfile (): boolean { return this.$store.getters['profiles/getHasProfile'] }

    get isNotConnected (): boolean { 
        let status = this.$store.getters['terminal/getStatusMessage'];
        return status === 'connection to host failed' || status === 'TLS handshake failure';
    }

    get getHostName (): string { 
        const { host, port } = this.$store.getters['profiles/getActiveProfile'];
        return `https://${host}:${port}`
    }

/** ******************************** Vue Methods! **********************************/

    showCopyModal () { this.$refs.copyModal.show() }
    showPasteModal () { this.$refs.pasteModal.show() }

    closeCopyModal () { this.$refs.copyModal.hide() }
    closePasteModal () { this.$refs.pasteModal.hide() }

    /** ******************************** Clipboard **********************************/

    focusCopyClipboard () { this.$refs.copyClipboard.focus() }
    focusPasteClipboard () { this.$refs.pasteClipboard.focus() }

    clearCopyClipboard () {
        this.copyClipboardValue = ''
        this.$store.dispatch('terminal/focus')
    }

    clearPasteClipboard () {
        this.pasteClipboardValue = ''
        this.$store.dispatch('terminal/focus')
    }

    /** ******************************** Paste **********************************/

    handlePaste () {
        // Use the store to paste into the terminal
        this.$store.dispatch('terminal/paste', { text: this.pasteClipboardValue })
        this.$store.dispatch('terminal/focus')
    }

    /** ******************************** Copy **********************************/

    confirmCopy (selectionString: string) {
        // Set the value
        this.copyClipboardValue = selectionString
        // If select method exists
        this.$refs.copyClipboard.select()

        this.showCopyModal()
    };

    handleCopy () {
        // Copy the selected text
        document.execCommand('copy')
        this.$store.dispatch('terminal/focus')
    }

    /** ******************************** Message Box **********************************/

    handleMessageBox (selectedButton: number) {
        this.messageBox.callback(selectedButton)
        this.$refs.termMessageBoxModal.hide()
    }

/** ******************************** Vue Created! **********************************/

    created () {
        // If there is a $route parameters id in the url
        this.isProfileID = !!(this.$route.params.id)

        if (this.isProfileID && this.hasProfile) {
            this.$store.dispatch('profiles/setActiveByID', { id: this.$route.params.id })
        }
    }

/** ******************************** Vue Watch **********************************/

    // Wait to get the list of profiles before setting a new one
    @Watch('hasProfile') setProfile () {
        // If there is a profile ID
        if (this.isProfileID) {
            this.$store.dispatch('profiles/setActiveByID', { id: this.$route.params.id })
        }
    }

    // Watch/Listen to see if the message box gets updated
    @Watch('messageBox') watchMessageBox () {
        this.$refs.termMessageBoxModal.show()
    }

    // Watch/Listen to see if the clipboard changes
    @Watch('getClipboardTerm') clipboardTermHandle (val: string) {
        this.confirmCopy(val)
    }
}
