/*
 * AccuTerm Mobile Character Set Class
 *
 * Copyright 2015 Zumasys, Inc.
 */

////////////////////////////////////////////////////////////
// Import Dependencies!
////////////////////////////////////////////////////////////

import { DEBUG } from './globals.js';

/* global escape: false, unescape: false */
/* global DEBUG: false */
/* jshint expr: true */

var CHARSETS = {

// Null character set
_NULL: [
'','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','',
[0,128] // natural position in 256 character set (C0 & C1 controls)
],

// C0 control character symbols
_CONTROLS: [
'\u2400',	// SYMBOL FOR NULL (NUL)
'\u2401',	// SYMBOL FOR START OF HEADING (SOH)
'\u2402',	// SYMBOL FOR START OF TEXT (STX)
'\u2403',	// SYMBOL FOR END OF TEXT (ETX)
'\u2404',	// SYMBOL FOR END OF TRANSMISSION (EOT)
'\u2405',	// SYMBOL FOR ENQUIRY (ENQ)
'\u2406',	// SYMBOL FOR ACKNOWLEDGE (ACK)
'\u2407',	// SYMBOL FOR BELL (BEL)
'\u2408',	// SYMBOL FOR BACKSPACE (BS)
'\u2409',	// SYMBOL FOR HORIZONTAL TABULATION (HT)
'\u240A',	// SYMBOL FOR LINE FEED (LF)
'\u240B',	// SYMBOL FOR VERTICAL TABULATION (VT)
'\u240C',	// SYMBOL FOR FORM FEED (FF)
'\u240D',	// SYMBOL FOR CARRIAGE RETURN (CR)
'\u240E',	// SYMBOL FOR SHIFT OUT (SO)
'\u240F',	// SYMBOL FOR SHIFT IN (SI)
'\u2410',	// SYMBOL FOR DATA LINK ESCAPE (DLE)
'\u2411',	// SYMBOL FOR DEVICE CONTROL ONE (DC1)
'\u2412',	// SYMBOL FOR DEVICE CONTROL TWO (DC2)
'\u2413',	// SYMBOL FOR DEVICE CONTROL THREE (DC3)
'\u2414',	// SYMBOL FOR DEVICE CONTROL FOUR (DC4)
'\u2415',	// SYMBOL FOR NEGATIVE ACKNOWLEDGE (NAK)
'\u2416',	// SYMBOL FOR SYNCHRONOUS IDLE (SYN)
'\u2417',	// SYMBOL FOR END OF TRANSMISSION BLOCK (ETB)
'\u2418',	// SYMBOL FOR CANCEL (CAN)
'\u2419',	// SYMBOL FOR END OF MEDIUM (EOM)
'\u241A',	// SYMBOL FOR SUBSTITUTE (SUB)
'\u241B',	// SYMBOL FOR ESCAPE (ESC)
'\u241C',	// SYMBOL FOR FILE SEPARATOR (FS)
'\u241D',	// SYMBOL FOR GROUP SEPARATOR (GS)
'\u241E',	// SYMBOL FOR RECORD SEPARATOR (RS)
'\u241F',	// SYMBOL FOR UNIT SEPARATOR (US)
[] // this set cannot be used for inverse map
],

// US ASCII Numerals
_ASCII_NUMS: [
'\u0020',	// SPACE
'\u0021',	// EXCLAMATION MARK
'\u0022',	// QUOTATION MARK
'\u0023',	// NUMBER SIGN
'\u0024',	// DOLLAR SIGN
'\u0025',	// PERCENT SIGN
'\u0026',	// AMPERSAND
'\u0027',	// APOSTROPHE
'\u0028',	// LEFT PARENTHESIS
'\u0029',	// RIGHT PARENTHESIS
'\u002A',	// ASTERISK
'\u002B',	// PLUS SIGN
'\u002C',	// COMMA
'\u002D',	// HYPHEN-MINUS
'\u002E',	// PERIOD
'\u002F',	// SLASH
'\u0030',	// DIGIT ZERO 
'\u0031',	// DIGIT ONE
'\u0032',	// DIGIT TWO
'\u0033',	// DIGIT THREE
'\u0034',	// DIGIT FOUR
'\u0035',	// DIGIT FIVE
'\u0036',	// DIGIT SIX
'\u0037',	// DIGIT SEVEN
'\u0038',	// DIGIT EIGHT
'\u0039',	// DIGIT NINE
'\u003A',	// COLON
'\u003B',	// SEMICOLON
'\u003C',	// LESS-THAN SIGN
'\u003D',	// EQUALS SIGN
'\u003E',	// GREATER-THAN SIGN
'\u003F',	// QUESTION MARK
[32,160] // natural position in 256 character set
],

// US ASCII Upper case
_ASCII_UPPER: [
'\u0040',	// COMMERCIAL AT
'\u0041',	// LATIN CAPITAL LETTER A
'\u0042',	// LATIN CAPITAL LETTER B
'\u0043',	// LATIN CAPITAL LETTER C
'\u0044',	// LATIN CAPITAL LETTER D
'\u0045',	// LATIN CAPITAL LETTER E
'\u0046',	// LATIN CAPITAL LETTER F
'\u0047',	// LATIN CAPITAL LETTER G
'\u0048',	// LATIN CAPITAL LETTER H
'\u0049',	// LATIN CAPITAL LETTER I
'\u004A',	// LATIN CAPITAL LETTER J
'\u004B',	// LATIN CAPITAL LETTER K
'\u004C',	// LATIN CAPITAL LETTER L
'\u004D',	// LATIN CAPITAL LETTER M
'\u004E',	// LATIN CAPITAL LETTER N
'\u004F',	// LATIN CAPITAL LETTER O
'\u0050',	// LATIN CAPITAL LETTER P
'\u0051',	// LATIN CAPITAL LETTER Q
'\u0052',	// LATIN CAPITAL LETTER R
'\u0053',	// LATIN CAPITAL LETTER S
'\u0054',	// LATIN CAPITAL LETTER T
'\u0055',	// LATIN CAPITAL LETTER U
'\u0056',	// LATIN CAPITAL LETTER V
'\u0057',	// LATIN CAPITAL LETTER W
'\u0058',	// LATIN CAPITAL LETTER X
'\u0059',	// LATIN CAPITAL LETTER Y
'\u005A',	// LATIN CAPITAL LETTER Z
'\u005B',	// LEFT SQUARE BRACKET
'\u005C',	// BACKSLASH
'\u005D',	// RIGHT SQUARE BRACKET
'\u005E',	// CIRCUMFLEX ACCENT
'\u005F',	// SPACING UNDERSCORE
[64,192] // natural position in 256 character set
],

// US ASCII Lower case
_ASCII_LOWER: [
'\u0060',	// GRAVE ACCENT
'\u0061',	// LATIN SMALL LETTER A
'\u0062',	// LATIN SMALL LETTER B
'\u0063',	// LATIN SMALL LETTER C
'\u0064',	// LATIN SMALL LETTER D
'\u0065',	// LATIN SMALL LETTER E
'\u0066',	// LATIN SMALL LETTER F
'\u0067',	// LATIN SMALL LETTER G
'\u0068',	// LATIN SMALL LETTER H
'\u0069',	// LATIN SMALL LETTER I
'\u006A',	// LATIN SMALL LETTER J
'\u006B',	// LATIN SMALL LETTER K
'\u006C',	// LATIN SMALL LETTER L
'\u006D',	// LATIN SMALL LETTER M
'\u006E',	// LATIN SMALL LETTER N
'\u006F',	// LATIN SMALL LETTER O
'\u0070',	// LATIN SMALL LETTER P
'\u0071',	// LATIN SMALL LETTER Q
'\u0072',	// LATIN SMALL LETTER R
'\u0073',	// LATIN SMALL LETTER S
'\u0074',	// LATIN SMALL LETTER T
'\u0075',	// LATIN SMALL LETTER U
'\u0076',	// LATIN SMALL LETTER V
'\u0077',	// LATIN SMALL LETTER W
'\u0078',	// LATIN SMALL LETTER X
'\u0079',	// LATIN SMALL LETTER Y
'\u007A',	// LATIN SMALL LETTER Z
'\u007B',	// LEFT CURLY BRACKET
'\u007C',	// VERTICAL LINE
'\u007D',	// RIGHT CURLY BRACKET
'\u007E',	// TILDE
'',			// DEL
[96,224] // natural position in 256 character set
],

// ISO Latin-1 symbols
_LATIN1_SYMS: [
'\u0020', 	// SPACE
'\u00A1', 	// INVERTED EXCLAMATION MARK
'\u00A2', 	// CENT SIGN
'\u00A3', 	// POUND SIGN
'\u00A4', 	// CURRENCY SIGN
'\u00A5', 	// YEN SIGN
'\u00A6', 	// BROKEN BAR
'\u00A7', 	// SECTION SIGN
'\u00A8', 	// DIAERESIS
'\u00A9', 	// COPYRIGHT SIGN
'\u00AA', 	// FEMININE ORDINAL INDICATOR
'\u00AB', 	// LEFT-POINTING DOUBLE ANGLE QUOTATION MARK
'\u00AC', 	// NOT SIGN
'\u00AD', 	// SOFT HYPHEN
'\u00AE', 	// REGISTERED SIGN
'\u00AF', 	// MACRON
'\u00B0', 	// DEGREE SIGN
'\u00B1', 	// PLUS-MINUS SIGN
'\u00B2', 	// SUPERSCRIPT TWO
'\u00B3', 	// SUPERSCRIPT THREE
'\u00B4', 	// ACUTE ACCENT
'\u00B5', 	// MICRO SIGN
'\u00B6', 	// PILCROW SIGN
'\u00B7', 	// MIDDLE DOT
'\u00B8', 	// CEDILLA
'\u00B9', 	// SUPERSCRIPT ONE 
'\u00BA', 	// MASCULINE ORDINAL INDICATOR
'\u00BB', 	// RIGHT-POINTING DOUBLE ANGLE QUOTATION MARK
'\u00BC', 	// VULGAR FRACTION ONE QUARTER
'\u00BD', 	// VULGAR FRACTION ONE HALF
'\u00BE', 	// VULGAR FRACTION THREE QUARTERS
'\u00BF', 	// INVERTED QUESTION MARK
[160] // natural position in 256 character set
],
  
// ISO Latin-1 upper case
_LATIN1_UPPER: [
'\u00C0', 	// LATIN CAPITAL LETTER A WITH GRAVE
'\u00C1', 	// LATIN CAPITAL LETTER A WITH ACUTE
'\u00C2', 	// LATIN CAPITAL LETTER A WITH CIRCUMFLEX
'\u00C3', 	// LATIN CAPITAL LETTER A WITH TILDE
'\u00C4', 	// LATIN CAPITAL LETTER A WITH DIAERESIS
'\u00C5', 	// LATIN CAPITAL LETTER A WITH RING ABOVE
'\u00C6', 	// LATIN CAPITAL LETTER AE
'\u00C7', 	// LATIN CAPITAL LETTER C WITH CEDILLA
'\u00C8', 	// LATIN CAPITAL LETTER E WITH GRAVE
'\u00C9', 	// LATIN CAPITAL LETTER E WITH ACUTE
'\u00CA', 	// LATIN CAPITAL LETTER E WITH CIRCUMFLEX
'\u00CB', 	// LATIN CAPITAL LETTER E WITH DIAERESIS
'\u00CC', 	// LATIN CAPITAL LETTER I WITH GRAVE
'\u00CD', 	// LATIN CAPITAL LETTER I WITH ACUTE
'\u00CE', 	// LATIN CAPITAL LETTER I WITH CIRCUMFLEX
'\u00CF', 	// LATIN CAPITAL LETTER I WITH DIAERESIS
'\u00D0', 	// LATIN CAPITAL LETTER ETH
'\u00D1', 	// LATIN CAPITAL LETTER N WITH TILDE
'\u00D2', 	// LATIN CAPITAL LETTER O WITH GRAVE
'\u00D3', 	// LATIN CAPITAL LETTER O WITH ACUTE
'\u00D4', 	// LATIN CAPITAL LETTER O WITH CIRCUMFLEX
'\u00D5', 	// LATIN CAPITAL LETTER O WITH TILDE
'\u00D6', 	// LATIN CAPITAL LETTER O WITH DIAERESIS
'\u00D7', 	// MULTIPLICATION SIGN
'\u00D8', 	// LATIN CAPITAL LETTER O WITH STROKE
'\u00D9', 	// LATIN CAPITAL LETTER U WITH GRAVE
'\u00DA', 	// LATIN CAPITAL LETTER U WITH ACUTE
'\u00DB', 	// LATIN CAPITAL LETTER U WITH CIRCUMFLEX
'\u00DC', 	// LATIN CAPITAL LETTER U WITH DIAERESIS
'\u00DD', 	// LATIN CAPITAL LETTER Y WITH ACUTE
'\u00DE', 	// LATIN CAPITAL LETTER THORN
'\u00DF', 	// LATIN SMALL LETTER SHARP S
[192] // natural position in 256 character set
],

// ISO Latin-1 lower case
_LATIN1_LOWER: [
'\u00E0', 	// LATIN SMALL LETTER A WITH GRAVE
'\u00E1', 	// LATIN SMALL LETTER A WITH ACUTE
'\u00E2', 	// LATIN SMALL LETTER A WITH CIRCUMFLEX
'\u00E3', 	// LATIN SMALL LETTER A WITH TILDE
'\u00E4', 	// LATIN SMALL LETTER A WITH DIAERESIS
'\u00E5', 	// LATIN SMALL LETTER A WITH RING ABOVE
'\u00E6', 	// LATIN SMALL LETTER AE
'\u00E7', 	// LATIN SMALL LETTER C WITH CEDILLA
'\u00E8', 	// LATIN SMALL LETTER E WITH GRAVE
'\u00E9', 	// LATIN SMALL LETTER E WITH ACUTE
'\u00EA', 	// LATIN SMALL LETTER E WITH CIRCUMFLEX
'\u00EB', 	// LATIN SMALL LETTER E WITH DIAERESIS
'\u00EC', 	// LATIN SMALL LETTER I WITH GRAVE
'\u00ED', 	// LATIN SMALL LETTER I WITH ACUTE
'\u00EE', 	// LATIN SMALL LETTER I WITH CIRCUMFLEX
'\u00EF', 	// LATIN SMALL LETTER I WITH DIAERESIS
'\u00F0', 	// LATIN SMALL LETTER ETH
'\u00F1', 	// LATIN SMALL LETTER N WITH TILDE
'\u00F2', 	// LATIN SMALL LETTER O WITH GRAVE
'\u00F3', 	// LATIN SMALL LETTER O WITH ACUTE
'\u00F4', 	// LATIN SMALL LETTER O WITH CIRCUMFLEX
'\u00F5', 	// LATIN SMALL LETTER O WITH TILDE
'\u00F6', 	// LATIN SMALL LETTER O WITH DIAERESIS
'\u00F7', 	// DIVISION SIGN
'\u00F8', 	// LATIN SMALL LETTER O WITH STROKE 
'\u00F9', 	// LATIN SMALL LETTER U WITH GRAVE
'\u00FA', 	// LATIN SMALL LETTER U WITH ACUTE
'\u00FB', 	// LATIN SMALL LETTER U WITH CIRCUMFLEX
'\u00FC', 	// LATIN SMALL LETTER U WITH DIAERESIS
'\u00FD', 	// LATIN SMALL LETTER Y WITH ACUTE
'\u00FE', 	// LATIN SMALL LETTER THORN
'\u00FF', 	// LATIN SMALL LETTER Y WITH DIAERESIS
[224] // natural position in 256 character set
],

// DEC Multinational symbols
_DEC_SYMS: [
'\u0020',	// SPACE
'\u00A1',	// INVERTED EXCLAMATION MARK
'\u00A2',	// CENT SIGN
'\u00A3',	// POUND SIGN
'\uFFFD',	// UNDEFINED
'\u00A5',	// YEN SIGN
'\uFFFD',	// UNDEFINED
'\u00A7',	// SECTION SIGN
'\u00A4',	// CURRENCY SIGN
'\u00A9',	// COPYRIGHT SIGN
'\u00AA',	// FEMININE ORDINAL INDICATOR
'\u00AB',	// LEFT-POINTING DOUBLE ANGLE QUOTATION MARK
'\uFFFD',	// UNDEFINED
'\uFFFD',	// UNDEFINED
'\uFFFD',	// UNDEFINED
'\uFFFD',	// UNDEFINED
'\u00B0',	// DEGREE SIGN
'\u00B1',	// PLUS-MINUS SIGN
'\u00B2',	// SUPERSCRIPT TWO
'\u00B3',	// SUPERSCRIPT THREE
'\uFFFD',	// UNDEFINED
'\u00B5',	// MICRO SIGN
'\u00B6',	// PILCROW SIGN
'\u00B7',	// MIDDLE DOT
'\uFFFD',	// UNDEFINED
'\u00B9',	// SUPERSCRIPT ONE 
'\u00BA',	// MASCULINE ORDINAL INDICATOR
'\u00BB',	// RIGHT-POINTING DOUBLE ANGLE QUOTATION MARK
'\u00BC',	// VULGAR FRACTION ONE QUARTER
'\u00BD',	// VULGAR FRACTION ONE HALF
'\uFFFD',	// UNDEFINED
'\u00BF',	// INVERTED QUESTION MARK
[160] // natural position in 256 character set
],
  
// DEC Multinational upper case
_DEC_UPPER: [
'\u00C0',	// LATIN CAPITAL LETTER A WITH GRAVE
'\u00C1',	// LATIN CAPITAL LETTER A WITH ACUTE
'\u00C2',	// LATIN CAPITAL LETTER A WITH CIRCUMFLEX
'\u00C3',	// LATIN CAPITAL LETTER A WITH TILDE
'\u00C4',	// LATIN CAPITAL LETTER A WITH DIAERESIS
'\u00C5',	// LATIN CAPITAL LETTER A WITH RING ABOVE
'\u00C6',	// LATIN CAPITAL LETTER AE
'\u00C7',	// LATIN CAPITAL LETTER C WITH CEDILLA
'\u00C8',	// LATIN CAPITAL LETTER E WITH GRAVE
'\u00C9',	// LATIN CAPITAL LETTER E WITH ACUTE
'\u00CA',	// LATIN CAPITAL LETTER E WITH CIRCUMFLEX
'\u00CB',	// LATIN CAPITAL LETTER E WITH DIAERESIS
'\u00CC',	// LATIN CAPITAL LETTER I WITH GRAVE
'\u00CD',	// LATIN CAPITAL LETTER I WITH ACUTE
'\u00CE',	// LATIN CAPITAL LETTER I WITH CIRCUMFLEX
'\u00CF',	// LATIN CAPITAL LETTER I WITH DIAERESIS
'\uFFFD',	// UNDEFINED
'\u00D1',	// LATIN CAPITAL LETTER N TILDE
'\u00D2',	// LATIN CAPITAL LETTER O WITH GRAVE
'\u00D3',	// LATIN CAPITAL LETTER O WITH ACUTE
'\u00D4',	// LATIN CAPITAL LETTER O WITH CIRCUMFLEX
'\u00D5',	// LATIN CAPITAL LETTER O WITH TILDE
'\u00D6',	// LATIN CAPITAL LETTER O WITH DIAERESIS
'\u0152',	// LATIN CAPITAL LIGATURE OE
'\u00D8',	// LATIN CAPITAL LETTER O WITH STROKE
'\u00D9',	// LATIN CAPITAL LETTER U WITH GRAVE
'\u00DA',	// LATIN CAPITAL LETTER U WITH ACUTE
'\u00DB',	// LATIN CAPITAL LETTER U WITH CIRCUMFLEX
'\u00DC',	// LATIN CAPITAL LETTER U WITH DIAERESIS
'\u0178',	// LATIN CAPITAL LETTER Y WITH DIAERESIS
'\uFFFD',	// UNDEFINED
'\u00DF',	// LATIN SMALL LETTER SHARP S
[192] // natural position in 256 character set
],

// DEC Multinational lower case
_DEC_LOWER: [
'\u00E0',	// LATIN SMALL LETTER A WITH GRAVE
'\u00E1',	// LATIN SMALL LETTER A WITH ACUTE
'\u00E2',	// LATIN SMALL LETTER A WITH CIRCUMFLEX
'\u00E3',	// LATIN SMALL LETTER A WITH TILDE
'\u00E4',	// LATIN SMALL LETTER A WITH DIAERESIS
'\u00E5',	// LATIN SMALL LETTER A WITH RING ABOVE
'\u00E6',	// LATIN SMALL LETTER AE
'\u00E7',	// LATIN SMALL LETTER C WITH CEDILLA
'\u00E8',	// LATIN SMALL LETTER E WITH GRAVE
'\u00E9',	// LATIN SMALL LETTER E WITH ACUTE
'\u00EA',	// LATIN SMALL LETTER E WITH CIRCUMFLEX
'\u00EB',	// LATIN SMALL LETTER E WITH DIAERESIS
'\u00EC',	// LATIN SMALL LETTER I WITH GRAVE
'\u00ED',	// LATIN SMALL LETTER I WITH ACUTE
'\u00EE',	// LATIN SMALL LETTER I WITH CIRCUMFLEX
'\u00EF',	// LATIN SMALL LETTER I WITH DIAERESIS
'\uFFFD',	// UNDEFINED
'\u00F1',	// LATIN SMALL LETTER N WITH TILDE
'\u00F2',	// LATIN SMALL LETTER O WITH GRAVE
'\u00F3',	// LATIN SMALL LETTER O WITH ACUTE
'\u00F4',	// LATIN SMALL LETTER O WITH CIRCUMFLEX
'\u00F5',	// LATIN SMALL LETTER O WITH TILDE
'\u00F6',	// LATIN SMALL LETTER O WITH DIAERESIS
'\u0153',	// LATIN SMALL LIGATURE OE
'\u00F8',	// LATIN SMALL LETTER O WITH STROKE 
'\u00F9',	// LATIN SMALL LETTER U WITH GRAVE
'\u00FA',	// LATIN SMALL LETTER U WITH ACUTE
'\u00FB',	// LATIN SMALL LETTER U WITH CIRCUMFLEX
'\u00FC',	// LATIN SMALL LETTER U WITH DIAERESIS
'\u00FF',	// LATIN SMALL LETTER Y WITH DIAERESIS
'\uFFFD',	// UNDEFINED
'',			// NULL
[224] // natural position in 256 character set
],

// PC Multinational 80 to 9F
_CP437_80_9F: [
'\u00C7',	// LATIN CAPITAL LETTER C WITH CEDILLA
'\u00FC',	// LATIN SMALL LETTER U WITH DIAERESIS
'\u00E9',	// LATIN SMALL LETTER E WITH ACUTE
'\u00E2',	// LATIN SMALL LETTER A WITH CIRCUMFLEX
'\u00E4',	// LATIN SMALL LETTER A WITH DIAERESIS
'\u00E0',	// LATIN SMALL LETTER A WITH GRAVE
'\u00E5',	// LATIN SMALL LETTER A WITH RING ABOVE
'\u00E7',	// LATIN SMALL LETTER C WITH CEDILLA
'\u00EA',	// LATIN SMALL LETTER E WITH CIRCUMFLEX
'\u00EB',	// LATIN SMALL LETTER E WITH DIAERESIS
'\u00E8',	// LATIN SMALL LETTER E WITH GRAVE
'\u00EF',	// LATIN SMALL LETTER I WITH DIAERESIS
'\u00EE',	// LATIN SMALL LETTER I WITH CIRCUMFLEX
'\u00EC',	// LATIN SMALL LETTER I WITH GRAVE
'\u00C4',	// LATIN CAPITAL LETTER A WITH DIAERESIS
'\u00C5',	// LATIN CAPITAL LETTER A WITH RING ABOVE
'\u00C9',	// LATIN CAPITAL LETTER E WITH ACUTE
'\u00E6',	// LATIN SMALL LETTER AE
'\u00C6',	// LATIN CAPITAL LETTER AE
'\u00F4',	// LATIN SMALL LETTER O WITH CIRCUMFLEX
'\u00F6',	// LATIN SMALL LETTER O WITH DIAERESIS
'\u00F2',	// LATIN SMALL LETTER O WITH GRAVE
'\u00FB',	// LATIN SMALL LETTER U WITH CIRCUMFLEX
'\u00F9',	// LATIN SMALL LETTER U WITH GRAVE
'\u00FF',	// LATIN SMALL LETTER Y WITH DIAERESIS
'\u00D6',	// LATIN CAPITAL LETTER O WITH DIAERESIS
'\u00DC',	// LATIN CAPITAL LETTER U WITH DIAERESIS
'\u00A2',	// CENT SIGN
'\u00A3',	// POUND SIGN
'\u00A5',	// YEN SIGN
'\u20A7',	// PESETA SIGN
'\u0192',	// LATIN SMALL LETTER F WITH HOOK
[128] // natural position in 256 character set
],
  
// PC Multinational A0 to BF
_CP437_A0_BF: [
'\u00E1',	// LATIN SMALL LETTER A WITH ACUTE
'\u00ED',	// LATIN SMALL LETTER I WITH ACUTE
'\u00F3',	// LATIN SMALL LETTER O WITH ACUTE
'\u00FA',	// LATIN SMALL LETTER U WITH ACUTE
'\u00F1',	// LATIN SMALL LETTER N WITH TILDE
'\u00D1',	// LATIN CAPITAL LETTER N TILDE
'\u00AA',	// FEMININE ORDINAL INDICATOR
'\u00BA',	// MASCULINE ORDINAL INDICATOR
'\u00BF',	// INVERTED QUESTION MARK
'\u0020',	// SPACE
'\u00AC',	// NOT SIGN
'\u00BD',	// VULGAR FRACTION ONE HALF
'\u00BC',	// VULGAR FRACTION ONE QUARTER
'\u00A1',	// INVERTED EXCLAMATION MARK
'\u00AB',	// LEFT-POINTING DOUBLE ANGLE QUOTATION MARK
'\u00BB',	// RIGHT-POINTING DOUBLE ANGLE QUOTATION MARK
'\u2591',	// LIGHT SHADE
'\u2592',	// MEDIUM SHADE
'\u2593',	// DARK SHADE
'\u2502',	// BOX DRAWINGS LIGHT VERTICAL
'\u2524',	// BOX DRAWINGS LIGHT VERTICAL AND LEFT
'\u2561',	// BOX DRAWINGS VERTICAL SINGLE AND LEFT DOUBLE
'\u2562',	// BOX DRAWINGS VERTICAL DOUBLE AND LEFT SINGLE
'\u2556',	// BOX DRAWINGS DOWN DOUBLE AND LEFT SINGLE
'\u2555',	// BOX DRAWINGS DOWN SINGLE AND LEFT DOUBLE
'\u2563',	// BOX DRAWINGS DOUBLE VERTICAL AND LEFT
'\u2551',	// BOX DRAWINGS DOUBLE VERTICAL
'\u2557',	// BOX DRAWINGS DOUBLE DOWN AND LEFT
'\u255D',	// BOX DRAWINGS DOUBLE UP AND LEFT
'\u255C',	// BOX DRAWINGS UP DOUBLE AND LEFT SINGLE
'\u255B',	// BOX DRAWINGS UP SINGLE AND LEFT DOUBLE
'\u2510',	// BOX DRAWINGS LIGHT DOWN AND LEFT
[160] // natural position in 256 character set
],

// PC Multinational C0 to DF
_CP437_C0_DF: [
'\u2514',	// BOX DRAWINGS LIGHT UP AND RIGHT
'\u2534',	// BOX DRAWINGS LIGHT UP AND HORIZONTAL
'\u252C',	// BOX DRAWINGS LIGHT DOWN AND HORIZONTAL
'\u251C',	// BOX DRAWINGS LIGHT VERTICAL AND RIGHT
'\u2500',	// BOX DRAWINGS LIGHT HORIZONTAL
'\u253C',	// BOX DRAWINGS LIGHT VERTICAL AND HORIZONTAL
'\u255E',	// BOX DRAWINGS VERTICAL SINGLE AND RIGHT DOUBLE
'\u255F',	// BOX DRAWINGS VERTICAL DOUBLE AND RIGHT SINGLE
'\u255A',	// BOX DRAWINGS DOUBLE UP AND RIGHT
'\u2554',	// BOX DRAWINGS DOUBLE DOWN AND RIGHT
'\u2569',	// BOX DRAWINGS DOUBLE UP AND HORIZONTAL
'\u2566',	// BOX DRAWINGS DOUBLE DOWN AND HORIZONTAL
'\u2560',	// BOX DRAWINGS DOUBLE VERTICAL AND RIGHT
'\u2550',	// BOX DRAWINGS DOUBLE HORIZONTAL
'\u256C',	// BOX DRAWINGS DOUBLE VERTICAL AND HORIZONTAL
'\u2567',	// BOX DRAWINGS UP SINGLE AND HORIZONTAL DOUBLE
'\u2568',	// BOX DRAWINGS UP DOUBLE AND HORIZONTAL SINGLE
'\u2564',	// BOX DRAWINGS DOWN SINGLE AND HORIZONTAL DOUBLE
'\u2565',	// BOX DRAWINGS DOWN DOUBLE AND HORIZONTAL SINGLE
'\u2559',	// BOX DRAWINGS UP DOUBLE AND RIGHT SINGLE
'\u2558',	// BOX DRAWINGS UP SINGLE AND RIGHT DOUBLE
'\u2552',	// BOX DRAWINGS DOWN SINGLE AND RIGHT DOUBLE
'\u2553',	// BOX DRAWINGS DOWN DOUBLE AND RIGHT SINGLE
'\u256B',	// BOX DRAWINGS VERTICAL DOUBLE AND HORIZONTAL SINGLE
'\u256A',	// BOX DRAWINGS VERTICAL SINGLE AND HORIZONTAL DOUBLE
'\u2518',	// BOX DRAWINGS LIGHT UP AND LEFT
'\u250C',	// BOX DRAWINGS LIGHT DOWN AND RIGHT
'\u2588',	// FULL BLOCK
'\u2584',	// LOWER HALF BLOCK
'\u258C',	// LEFT HALF BLOCK
'\u2590',	// RIGHT HALF BLOCK
'\u2580',	// UPPER HALF BLOCK
[192] // natural position in 256 character set
],

// PC Multinational E0 to FF
_CP437_E0_FF: [
'\u03B1',	// GREEK SMALL LETTER ALPHA
'\u00DF',	// LATIN SMALL LETTER SHARP S (or 0x03B2 #GREEK SMALL LETTER BETA)
'\u0393',	// GREEK CAPITAL LETTER GAMMA
'\u03C0',	// GREEK SMALL LETTER PI
'\u03A3',	// GREEK CAPITAL LETTER SIGMA
'\u03C3',	// GREEK SMALL LETTER SIGMA
'\u03BC',	// GREEK SMALL LETTER MU
'\u03C4',	// GREEK SMALL LETTER TAU
'\u03A6',	// GREEK CAPITAL LETTER PHI
'\u0398',	// GREEK CAPITAL LETTER THETA
'\u03A9',	// GREEK CAPITAL LETTER OMEGA
'\u03B4',	// GREEK SMALL LETTER DELTA
'\u221E',	// INFINITY
'\u03C6',	// GREEK SMALL LETTER PHI
'\u03B5',	// GREEK SMALL LETTER EPSILON
'\u2229',	// INTERSECTION
'\u2261',	// IDENTICAL TO
'\u00B1',	// PLUS-MINUS SIGN
'\u2265',	// GREATER-THAN OR EQUAL TO
'\u2264',	// LESS-THAN OR EQUAL TO
'\u2320',	// TOP HALF INTEGRAL
'\u2321',	// BOTTOM HALF INTEGRAL
'\u00F7',	// DIVISION SIGN
'\u2248',	// ALMOST EQUAL TO
'\u00B0',	// DEGREE SIGN
'\u2219',	// BULLET OPERATOR
'\u00B7',	// MIDDLE DOT
'\u221A',	// SQUARE ROOT
'\u207F',	// SUPERSCRIPT LATIN SMALL LETTER N
'\u00B2',	// SUPERSCRIPT TWO
'\u25A0',	// BLACK SQUARE
' ',			// NBSP
[224] // natural position in 256 character set
],

// Windows ANSI codepage 1252 80 to 9F
_CP1252_80_9F: [
'\u20AC',	// EURO SIGN
'\uFFFD',	// UNDEFINED
'\u201A',	// SINGLE LOW-9 QUOTATION MARK
'\u0192',	// LATIN SMALL LETTER F WITH HOOK
'\u201E',	// DOUBLE LOW-9 QUOTATION MARK
'\u2026',	// HORIZONTAL ELLIPSIS
'\u2020',	// DAGGER
'\u2021',	// DOUBLE DAGGER
'\u02C6',	// MODIFIER LETTER CIRCUMFLEX ACCENT
'\u2030',	// PER MILLE SIGN
'\u0160',	// LATIN CAPITAL LETTER S WITH CARON
'\u2039',	// SINGLE LEFT-POINTING ANGLE QUOTATION MARK
'\u0152',	// LATIN CAPITAL LIGATURE OE
'\uFFFF',	// UNDEFINED
'\u017D',	// LATIN CAPITAL LETTER Z WITH CARON
'\uFFFF',	// UNDEFINED
'\uFFFF',	// UNDEFINED
'\u2018',	// LEFT SINGLE QUOTATION MARK
'\u2019',	// RIGHT SINGLE QUOTATION MARK
'\u201C',	// LEFT DOUBLE QUOTATION MARK
'\u201D',	// RIGHT DOUBLE QUOTATION MARK
'\u2022',	// BULLET
'\u2013',	// EN DASH
'\u2014',	// EM DASH
'\u02DC',	// SMALL TILDE
'\u2122',	// TRADE MARK SIGN
'\u0161',	// LATIN SMALL LETTER S WITH CARON
'\u203A',	// SINGLE RIGHT-POINTING ANGLE QUOTATION MARK
'\u0153',	// LATIN SMALL LIGATURE OE
'\uFFFF',	// UNDEFINED
'\u017E',	// LATIN SMALL LETTER Z WITH CARON
'\u0178',	// LATIN CAPITAL LETTER Y WITH DIAERESIS
[128] // natural position in 256 character set
],

// ANSI special graphics
_DEC_GRAPH: [
'\u2666',	// BLACK DIAMOND SUIT
'\u2593',	// DARK SHADE
'\u2409',	// SYMBOL FOR HORIZONTAL TABULATION
'\u240C',	// SYMBOL FOR FORM FEED
'\u240D',	// SYMBOL FOR CARRIAGE RETURN
'\u240A',	// SYMBOL FOR LINE FEED
'\u00B0',	// DEGREE SIGN
'\u00B1',	// PLUS-MINUS SIGN
'\u2400',	// SYMBOL FOR NULL
'\u240B',	// SYMBOL FOR VERTICAL TABULATION
'\u2518',	// BOX DRAWINGS LIGHT UP AND LEFT
'\u2510',	// BOX DRAWINGS LIGHT DOWN AND LEFT
'\u250C',	// BOX DRAWINGS LIGHT DOWN AND RIGHT
'\u2514',	// BOX DRAWINGS LIGHT UP AND RIGHT
'\u253C',	// BOX DRAWINGS LIGHT VERTICAL AND HORIZONTAL
'\uF004',	// PRIVATE USE - HORIZONTAL LINE 4
'\uF003',	// PRIVATE USE - HORIZONTAL LINE 3
'\u2500',	// BOX DRAWINGS LIGHT HORIZONTAL
'\uF002',	// PRIVATE USE - HORIZONTAL LINE 2
'\uF001',	// PRIVATE USE - HORIZONTAL LINE 1
'\u251C',	// BOX DRAWINGS LIGHT VERTICAL AND RIGHT
'\u2524',	// BOX DRAWINGS LIGHT VERTICAL AND LEFT
'\u2534',	// BOX DRAWINGS LIGHT UP AND HORIZONTAL
'\u252C',	// BOX DRAWINGS LIGHT DOWN AND HORIZONTAL
'\u2502',	// BOX DRAWINGS LIGHT VERTICAL
'\u2264',	// LESS-THAN OR EQUAL TO
'\u2265',	// GREATER-THAN OR EQUAL TO
'\u03C0',	// GREEK SMALL LETTER PI
'\u2260',	// NOT EQUAL TO
'\u00A3',	// POUND SIGN
'\u00B7',	// MIDDLE DOT
'',			// NULL
[] // this set cannot be used for inverse map
],

// Wyse graphics
_WYSE_GRAPH: [
'\u0020',	// SPACE
'\u2401',	// SYMBOL FOR START OF HEADING
'\u2402',	// SYMBOL FOR START OF TEXT
'\u2403',	// SYMBOL FOR END OF TEXT
'\u2404',	// SYMBOL FOR END OF TRANSMISSION
'\u2405',	// SYMBOL FOR ENQUIRY
'\u2406',	// SYMBOL FOR ACKNOWLEDGE
'\u2407',	// SYMBOL FOR BELL
'\u2408',	// SYMBOL FOR BACKSPACE
'\u2409',	// SYMBOL FOR HORIZONTAL TABULATION
'\u240A',	// SYMBOL FOR LINE FEED
'\u240B',	// SYMBOL FOR VERTICAL TABULATION
'\u240C',	// SYMBOL FOR FORM FEED
'\u240D',	// SYMBOL FOR CARRIAGE RETURN
'\u240E',	// SYMBOL FOR SHIFT OUT
'\u240F',	// SYMBOL FOR SHIFT IN
'\u252C',	// BOX DRAWINGS LIGHT DOWN AND HORIZONTAL
'\u2514',	// BOX DRAWINGS LIGHT UP AND RIGHT
'\u250C',	// BOX DRAWINGS LIGHT DOWN AND RIGHT
'\u2510',	// BOX DRAWINGS LIGHT DOWN AND LEFT
'\u251C',	// BOX DRAWINGS LIGHT VERTICAL AND RIGHT
'\u2518',	// BOX DRAWINGS LIGHT UP AND LEFT
'\u2502',	// BOX DRAWINGS LIGHT VERTICAL
'\u2588',	// FULL BLOCK
'\u253C',	// BOX DRAWINGS LIGHT VERTICAL AND HORIZONTAL
'\u2524',	// BOX DRAWINGS LIGHT VERTICAL AND LEFT
'\u2500',	// BOX DRAWINGS LIGHT HORIZONTAL
'\u2593',	// DARK SHADE
'\u2550',	// BOX DRAWINGS DOUBLE HORIZONTAL
'\u2534',	// BOX DRAWINGS LIGHT UP AND HORIZONTAL
'\u2551',	// BOX DRAWINGS DOUBLE VERTICAL
'\u2592',	// MEDIUM SHADE
[] // this set cannot be used for inverse map
],

// Wyse Scientific character set
_WYSE_MATH: [
' ',		// SPACE
'\u03B1',	// GREEK SMALL LETTER ALPHA
'\u03B2',	// GREEK SMALL LETTER BETA
'\u0393',	// GREEK CAPITAL LETTER GAMMA
'\u03C0',	// GREEK SMALL LETTER PI
'\u03A3',	// GREEK CAPITAL LETTER SIGMA
'\u03C3',	// GREEK SMALL LETTER SIGMA
'\u03BC',	// GREEK SMALL LETTER MU
'\u03C4',	// GREEK SMALL LETTER TAU
'\u03A6',	// GREEK CAPITAL LETTER PHI
'\u0398',	// GREEK CAPITAL LETTER THETA
'\u03A9',	// GREEK CAPITAL LETTER OMEGA
'\u03B4',	// GREEK SMALL LETTER DELTA
'\u221E',	// INFINITY
'\u03C6',	// GREEK SMALL LETTER PHI
'\u03B5',	// GREEK SMALL LETTER EPSILON
'\u2229',	// INTERSECTION
'\u2261',	// IDENTICAL TO
'\u00B1',	// PLUS-MINUS SIGN
'\u2265',	// GREATER-THAN OR EQUAL TO
'\u2264',	// LESS-THAN OR EQUAL TO
'\u2320',	// TOP HALF INTEGRAL
'\u2321',	// BOTTOM HALF INTEGRAL
'\u00F7',	// DIVISION SIGN
'\u2248',	// ALMOST EQUAL TO
'\u00B0',	// DEGREE SIGN
'\u2219',	// BULLET OPERATOR
'\u00B7',	// MIDDLE DOT
'\u221A',	// SQUARE ROOT
'\u207F',	// SUPERSCRIPT LATIN SMALL LETTER N
'\u00B2',	// SUPERSCRIPT TWO
'\u25A0',	// BLACK SQUARE
[] // this set cannot be used for inverse map
],

// PC symbols
_WINGDINGS: [
' ',		// SPACE
'\u263A',	// WHITE SMILING FACE
'\u263B',	// BLACK SMILING FACE
'\u2665',	// BLACK HEART SUIT
'\u2666',	// BLACK DIAMOND SUIT
'\u2663',	// BLACK CLUB SUIT
'\u2660',	// BLACK SPADE SUIT
'\u25CF',	// BLACK CIRCLE
'\u25D8',	// INVERSE BULLET
'\u25CB',	// WHITE CIRCLE
'\u25D9',	// INVERSE WHITE CIRCLE 
'\u2642',	// MALE SIGN
'\u2640',	// FEMALE SIGN
'\u266A',	// EIGHTH NOTE
'\u266B',	// BEAMED EIGHTH NOTES
'\u263C',	// WHITE SUN WITH RAYS
'\u25BA',	// BLACK RIGHT-POINTING POINTER (or 0x25B6 # BLACK RIGHT-POINTING TRIANGLE)
'\u25C4',	// BLACK LEFT-POINTING POINTER (or 0x25C0 # BLACK LEFT-POINTING TRIANGLE)
'\u2195',	// UP DOWN ARROW
'\u203C',	// DOUBLE EXCLAMATION MARK
'\u00B6',	// PILCROW SIGN
'\u00A7',	// SECTION SIGN
'\u25AC',	// BLACK RECTANGLE
'\u21A8',	// UP DOWN ARROW WITH BASE
'\u2191',	// UPWARDS ARROW
'\u2193',	// DOWNWARDS ARROW
'\u2192',	// RIGHTWARDS ARROW
'\u2190',	// LEFTWARDS ARROW
'\u221F',	// RIGHT ANGLE
'\u2194',	// LEFT RIGHT ARROW
'\u25B2',	// BLACK UP-POINTING TRIANGLE
'\u25BC',	// BLACK DOWN-POINTING TRIANGLE
[] // this set cannot be used for inverse map
],

ASCII: ['_NULL', '_ASCII_NUMS', '_ASCII_UPPER', '_ASCII_LOWER'],
LATIN1: ['_NULL', '_LATIN1_SYMS', '_LATIN1_UPPER', '_LATIN1_LOWER'], // ISO 8859-1
IBMPC: ['_CP437_80_9F', '_CP437_A0_BF', '_CP437_C0_DF', '_CP437_E0_FF'], // OEM codepage 437
NATIVE: ['_CP437_80_9F', '_CP437_A0_BF', '_CP437_C0_DF', '_CP437_E0_FF'], // Wyse native (same as IBMPC)
DEC: ['_NULL', '_DEC_SYMS', '_DEC_UPPER', '_DEC_LOWER'], // DEC Multinational
DEC_GRAPH: ['_NULL', '_ASCII_NUMS', '_ASCII_UPPER', '_DEC_GRAPH'],
WYSE_GRAPH: ['_NULL', '_WYSE_GRAPH', '_WYSE_GRAPH', '_WYSE_GRAPH'],
WYSE_MATH: ['_NULL', '_WYSE_MATH', '_WYSE_MATH', '_WYSE_MATH'],
WYSE_DINGS: ['_NULL', '_WINGDINGS', '_WINGDINGS', '_WINGDINGS'],
WYSE_MULTI: ['_NULL', '_CP437_80_9F', '_CP437_80_9F', '_CP437_80_9F'],
WYSE_CTRL: ['_NULL', '_CONTROLS', '_CONTROLS', '_CONTROLS'],
WYSE_ASCII_GRAPH: ['_WYSE_GRAPH', '_ASCII_NUMS', '_ASCII_UPPER', '_ASCII_LOWER'],
WYSE_LATIN1_MATH: ['_WYSE_MATH', '_LATIN1_SYMS', '_LATIN1_UPPER', '_LATIN1_LOWER'],
ASCII_CONTROLS: ['_CONTROLS', '_ASCII_NUMS', '_ASCII_UPPER', '_ASCII_LOWER'],
ASCII_WINGDINGS: ['_WINGDINGS', '_ASCII_NUMS', '_ASCII_UPPER', '_ASCII_LOWER']

};


// constructor
function Charmap(charset, euro) {
	this._maps = [];
	if (charset === 'UTF8') {
		this.utf8 = true;
		charset = 'LATIN1';
	} else {
		this.utf8 = false;
	}
	euro = euro || 0;
	euro = euro > 0 && euro <= 255 ? euro : 0;
	this.euro = euro;
	this.updateCharset('ASCII', charset);
}

Charmap.prototype.updateCharset = function(left, right) {
/*jshint curly: false*/
	var i, j, k, key, prop, val, map, inmap, outmap = null, invertable;
	var parts, segment;
	this.left = left || 'ASCII';
	this.right = right || 'ASCII';
	key = this.left + ':' + this.right;
	if (this.euro) key += ':e' + this.euro;
    DEBUG&&console.log('updateCharset: ' + key);
	map = Charmap[key];
	if (!map) {
		// merge 4 left & 4 right 32-character segments into complete 256 character set
		parts = ([].concat(CHARSETS[this.left])).concat(CHARSETS[this.right]);
		if (parts.length !== 8) {
			DEBUG&&console.log('updateCharset: invalid number of segments ' + parts.length + ' (should be 8!)');
		} else {
			DEBUG&&console.log('updateCharset: using segments ' + parts);	
		}
		inmap = {}; // map host character set to Unicode
		invertable = true;
		for (j = k = 0; j < parts.length; j++) {
			segment = (parts[j] && CHARSETS[parts[j]]) || null;
			if (!segment) {
				DEBUG&&console.log('updateCharset: failed to get segment for ' + parts[j]);
			} else if (segment.length !== 33) {
				DEBUG&&console.log('updateCharset: segment size incorrect for ' + parts[j] + ' ' + segment.length);
			} else {
				for (i = 0; i < 32; i++) {
					inmap[String.fromCharCode(i + k)] = segment[i];
				}
				if (segment[32].indexOf(k) < 0) {
					invertable = false; // this segment is not in its expected position in the character map, so don't create an inverse map
				}
			}
			k += 32;
		}
		// initialize the C0 controls inmap
		for(i = 0; i < 32; i++) {
			inmap[String.fromCharCode(i)] = String.fromCharCode(i);
		}
		// map the euro symbol
		if (this.euro) {
			inmap[String.fromCharCode(this.euro)] = '\u20AC';
		}
		if (invertable) {
			// build the inverse map for outgoing characters
			outmap = {}; // map Unicode to host character set
			// initialize to null inverse map
			for (i = 32; i < 256; i++) {
				outmap[String.fromCharCode(i)] = '';
			}
			// initialize C0 controls map
			for (i = 0; i < 32; i++) {
				outmap[String.fromCharCode(i)] = String.fromCharCode(i);
			}
			if (parts[4] === '_NULL') {
				// initialize C1 controls map
				for (i = 128; i < 160; i++) {
					outmap[String.fromCharCode(i)] = String.fromCharCode(i);
				}
			}
			// build the inverse map
			for (i = 0; i < 256; i++) {				
				prop = String.fromCharCode(i);
				val = inmap[prop];
				if (val === '' || val === '\uFFFD') {
					// character not valid in this character set
				} else if (val <= '\xff') {
					if (prop === '\xA0' && val === ' ' && parts[4] === '_NULL')
						val = prop; // on output, allow xA0 without translation
					if (outmap[val] === '') {
						outmap[val] = prop;
					}
				}
			}
			// make sure DEL is mapped
			outmap['\x7f'] = '\x7f';
		}
		map = {in: inmap, out: outmap, invertable: invertable};
		Charmap[key] = map; // cache the merged character set
	}
	this._map = map;
	if (this._map.out) {
		this.outmap = this._map.out;
	}
	return (this.inmap = this._map.in);
};

Charmap.prototype.setGL = function(left) {
	return this.updateCharset(left, this.right);
};

Charmap.prototype.setGR = function(right) {
	return this.updateCharset(this.left, right);
};

// translate host character set to Unicode (no UTF-8 decoding)
Charmap.prototype.charIn = function(str) {
	var i, ch, xch, out = '';
	for (i = 0; i < str.length; i++) {
		xch = str.substr(i, 1);
		ch = this.inmap[xch];
		if (ch) {
			out += ch;
		} else if (ch === undefined) {
			out += xch;
		}
	}
	return out;
};

// translate Unicode to host character set with UTF-8 encoding if host is UTF-8
Charmap.prototype.charOut = function(str) {
	if (this._map.utf8) {
		return this.utf8Out(str);
	}
	var i, ch, xch, out = '', maxch = '\xff';
	var outmap = this.outmap;
	if (!outmap) {
		outmap = {};
		maxch = '\x7f'; // restrict output to ASCII character set if no output mapping is defined
	}
	for (i = 0; i < str.length; i++) {
		xch = str.substr(i, 1);
		ch = outmap[xch];
		if (ch) {
			out += ch;
		} else if (ch === undefined && xch <= maxch) {
			out += xch;
		}
	}
	return out;
};

Charmap.prototype.isUtf8 = function() {
	return this.utf8 || false;
};

Charmap.prototype.utf8In = function(str) {
/*jshint curly: false*/
	if (this.frag) {
		str = this.frag + str;
		this.frag = '';
	}
	try {
		return decodeURIComponent(escape(str));
	} catch(e) {}
	// if the input string does not contain valid UTF-8 data, or ends in a partial character
	var i, m, ch, out = ''; 
	for (i = 0; i < str.length; i += m) {
		ch = str.charCodeAt(i);
		if ((ch & 0x80) === 0x00) m = 1;
		else if ((ch & 0xe0) === 0xc0) m = 2;
		else if ((ch & 0xf0) === 0xe0) m = 3;
		else if ((ch & 0xf8) === 0xf0) m = 4;
		else m = 0; // UTF-8 encoding error!
		if (m + i > str.length) {
			// save fragment for next time
			this.frag = str.substr(i);
			return out;			
		}
		if (m > 0) {
			try {
				out += decodeURIComponent(escape(str.substr(i, m)));
			} catch(e) {
				out += '\uFFFD'; // encoding errors - use replacement character
				m = 1;
			}
		} else {
			out += '\uFFFD'; // encoding errors - use replacement character
			m = 1;
		}			
	}
	return out;
};

Charmap.prototype.utf8Out = function(str) {
	try {
		return unescape(encodeURIComponent(str));
	} catch(e) {}
	// if the input string does not contain valid UTF-16 data (unmatched surrogate pairs)
	var i, ch, out = '';
	for (i = 0; i < str.length; i++) {
		ch = str.charCodeAt(i);
		if (ch < 0x80) {
			out += String.fromCharCode(ch);			
		} else if (ch < 0x800) {
			out += String.fromCharCode(0xC0 | ((ch >>> 6) & 0x1F), 0x80 | (ch & 0x3F));
		} else {
			out += String.fromCharCode(0xE0 | (ch >>> 12) & 0x0F, 0x80 | ((ch >>> 6) & 0x3F), (ch & 0x3F));
		}
	}
	return out;
};


////////////////////////////////////////////////////////////
// Exports!
////////////////////////////////////////////////////////////

export { Charmap };