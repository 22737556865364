// Store
import store from '../../store'
// Types
import { IGUICmdShutdown, IGUIRspSuccess } from '../../types'
import { GUIErrorCode } from '../../types/enums'
import { GuisServiceShutdownType } from '../../types/services/guis'

export default class GuisServiceShutdown implements GuisServiceShutdownType {

    shutdown(command: IGUICmdShutdown) {

        const response: IGUIRspSuccess = { command: command.command, error: GUIErrorCode.grSuccess }

        store.dispatch('guiResponse/setResponse', response).then(() => {
            
            // Clear UX
            store.dispatch('guiGuis/clearDialogBox')
            store.dispatch('guiGuis/clearFocused')
            // Clear out Guis and Events store
            store.dispatch('guiGuis/clearGuis')
            store.dispatch('guiGuis/clearEvents')
            // Clear out request and response store
            store.dispatch('guiRequest/clear')
            store.dispatch('guiResponse/clear')
            // Clear notifications
            store.dispatch('guiNotifications/clear')

            // Set shutdown to true 
            // this will trigger the watcher in App.vue (that redirects to the terminal)
            store.dispatch('guiGuis/toggleShutdown', true)
        })
        
    }

}
