// Store
import store from '../../store'
// Types
import { IGUICmdGetUpds, IGUICmdGetProp, IGUIRspError, IGUIUpdObject, IGUIRspGetUpds, simpleNotification } from '../../types'
import { GUICommandCode, GUIErrorCode, GUIErrorLevel, GUIComponent, GUIProperty, GUIRspErrorType, AllProps } from '../../types/enums'
import { GuisServiceGetUpdsType } from '../../types/services/guis'
// Services
import GuisServiceGetProp from './getProp'

// from getProp:
type GetPropValueType = (command: IGUICmdGetProp, props: AllProps) => any;

export default class GuisServiceGetUpds implements GuisServiceGetUpdsType {
    
    public getPropService = new GuisServiceGetProp();

    getUpds(command: IGUICmdGetUpds) {

        let response: IGUIRspGetUpds | IGUIRspError;
        const getPropCommand: IGUICmdGetProp = {
            command: GUICommandCode.gcGetProp,
            id: '',
            property: GUIProperty.gpValue,
            col: 0,
            row: 0
        }

        try {
            // Confrim there is a component
            const component: GUIComponent | undefined = store.getters['guiGuis/getComponent'](command.id);
            if (component) {
                if (component.props.typeFamily === 'form') {
                    // gcGetUpds only supported for forms
                    const updates: Array<IGUIUpdObject> = [];
                    this.getUpdsRecurs(updates, component.children, getPropCommand);
                    // build response
                    response = { command: command.command, error: GUIErrorCode.grSuccess, updates: updates };
                } else {
                    // error repsonse
                    response = { command: command.command, error: GUIErrorCode.grNoForm, level: GUIErrorLevel.glvlFail, message: 'Must specify a form (' + command.command + ')', args: [command.id] };
                }
            } else {
                // error repsonse
                response = { command: command.command, error: GUIErrorCode.grNoExist, level: GUIErrorLevel.glvlFail, message: 'Object does not exist (' + command.command + ')', args: [command.id] };
            }        
        } catch (e) {
            if (e instanceof GUIRspErrorType) {
                response = e
            } else if (e instanceof RangeError) {
                response = new GUIRspErrorType(command.command, GUIErrorCode.grInvArg, GUIErrorLevel.glvlFail, e.message, [command.id]);
            } else {
                response = new GUIRspErrorType(command.command, GUIErrorCode.grFailure, GUIErrorLevel.glvlFail, (e && (e as any).description ? (e as any).description : 'An unknown error has occurred'), [command.id]);
            }
        }

        if (response.error !== GUIErrorCode.grSuccess) {
            // Notify
            const notification: simpleNotification = { type: 'warning', show: false, message: response.message || 'GUI error', friendlyMessage: 'Error getting updates from object ' + command.id, script: 'services/guis/getUpds.ts', error: { errorCode: response.error, errorLevel: response.level } }
            store.dispatch('guiNotifications/addNotification', notification);
        }
          
        store.dispatch('guiResponse/setResponse', response);
    }

    getUpdsRecurs (updates: Array<IGUIUpdObject>, ctrls: Array<GUIComponent>, getPropCommand: IGUICmdGetProp) {
        ctrls.forEach(ctrl => {
            if (ctrl.props.gpChanged && 'gpValue' in ctrl.props) {
                getPropCommand.id = ctrl.props.id;
                updates.push({ id: ctrl.props.id, value: this.getPropService.getPropValue(getPropCommand, ctrl.props) });
            }
            if (ctrl.children.length !== 0) {
                this.getUpdsRecurs(updates, ctrl.children, getPropCommand);
            }
        });
    }
}
