

















































import { Component, Prop, Vue } from 'vue-property-decorator';
// Types
import { GridItem } from '../../../types/enums'
import { UtilsType } from './../../../types/utils';

// Utilities 
import Utils from './../../../utils/index';

@Component({
  name: 'GridDropDownComponent'
})

export default class GridDropDown extends Vue {
  /** ******************************** Vue Props **********************************/

  @Prop({ default: () => (new GridItem()) }) public item!: GridItem;
  @Prop({ default: 3 }) public type!: number; // always 3 or 4
  @Prop({ default: false }) public dropDown!: boolean; // does this cell have a visible drop down?
  
  /** ******************************** Vue Data **********************************/ 
  public utils: UtilsType = new Utils();

  /** ******************************** Vue Computed **********************************/
  get isList() { return this.type === 3 }
  get isCombo() { return this.type === 4 }

  get col (): number { return this.item.col }
  get row (): number { return this.item.row }

  get value () { return this.item.value }
  set value (val)  { this.item.value = val }

  get maxLength(): string { return (this.item.columnInfo.maxLen !== 0) ? this.item.columnInfo.maxLen.toString() : ''; }

  get readonly (): boolean { return !this.item.editable }

  get id (): string { return this.item.id; }

  get enabled(): boolean { return this.item.enabled }

  get style (): Partial<CSSStyleDeclaration> {
    let css: Partial<CSSStyleDeclaration> = {
      //position: 'relative',
      width: 'inherit',
      height: 'inherit',
      backgroundColor: this.item.backColor,
      color: this.item.foreColor
    };

    return css;
  }

  get inputStyle (): Partial<CSSStyleDeclaration> { return { backgroundColor: this.item.backColor, color: this.item.foreColor} }
  
  get buttonStyle() {
    let css: { [k: string]: any } = {
      height: 'inherit',
      backgroundColor: this.item.backColor,
      color: this.item.foreColor,
      transparent: "100%",
    };

    return css;
  }

  get isVisible() {
    return (document.activeElement!.id === this.id) || 
           (document.activeElement!.id === this.id + "-inner") || 
           (document.activeElement!.id === this.id + "-input")
  }

  /** ******************************** Methods **********************************/ 

  handleInput() {
    if(this.dropDown){
      this.$emit("selectRowByValue", this.value);
    }
  }

  focusInner() {
    if(this.isCombo) {
      (this.$refs[this.id + "-input"] as HTMLDivElement).focus();
    } else {
      (this.$refs[this.id + "-inner"] as HTMLDivElement).focus();
    }
  }

  keyHandler(e: KeyboardEvent) {
    if(this.dropDown) {
      e.stopPropagation();
    }
    switch (e.key) {
      case ("ArrowUp"):
        if(this.dropDown) {
          // call function to get focus on drop down
          this.$emit("focusDropDown");
        }
      break;
      case ("ArrowDown"): 
        if(this.dropDown) {
          // call function to get focus on drop down
          this.$emit("focusDropDown");
        }
      break;    
      case ("Enter"):
        if (this.dropDown) {
          this.$emit("setValueFromDropDown");
          this.focusInner();
        } else {
          // This is getting fired on focus for some reason...
          this.$emit('toggleDropDown', this.col, this.row);
          this.focusInner();
        }
      break;
    }
  }

  //show and hide table function and row selection handling
  toggleDropDown (e: MouseEvent) {
    e.stopPropagation();
    if (this.dropDown) {
      this.$emit('toggleDropDown', null, null);
    } else {
      this.$emit('toggleDropDown', this.col, this.row); 
      this.focusInner();
    }
  }

  enterKeyHandler(e: KeyboardEvent) {
    e.stopPropagation()
    if (this.dropDown) {
      this.$emit("setValueFromDropDown");
      this.focusInner();
    } else {
      this.$emit('toggleDropDown', this.col, this.row);
    }
  }
}
