










import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
    name: 'GUIComponent'
})

export default class Profiles extends Vue {
/** ******************************** Vue Computed **********************************/

    get guiRequest () { return this.$store.getters['gui/getRequest'] }

    get profile () { return this.$store.getters['profiles/getActiveProfile'] }

/** ******************************** Vue Methods **********************************/

    handleResponse (response: any) {
        this.$store.dispatch('gui/callback', response)
    }

    backToTerminal () {
        // redirect back to the terminal
        if (this.$router.currentRoute.name !== 'Terminal') {
            this.$router.push({ name: 'Terminal' })
        }
    }
}
