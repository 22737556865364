






































import { Component, Prop, Vue } from 'vue-property-decorator';
// Types
import { GUIComponent, PictureProps, GUIEventCode } from './../../types/enums';
import { UtilsType } from './../../types/utils';
import { IGUIEvent } from './../../types'
// Utilities 
import Utils from './../../utils/index';


@Component({
  name: 'gxPictureComponent'
})

export default class gxPicture extends Vue {
  /** ******************************** Vue Props **********************************/

  @Prop({ default: () => (new PictureProps()) }) private props!: PictureProps;
  @Prop({ default: () => ([]) }) private children!: Array<GUIComponent>

  /** ******************************** Vue Data **********************************/

  public utils: UtilsType = new Utils();
  public clickCount: number = 0;
  public clickTimer: any = null;

  public pictureId: string = this.props.id + '-picture';

  /** ******************************** Vue Computed **********************************/

  get picture (): string { return this.utils.getImage(this.props.gpPicture) };

  get hint(): string { return Array.isArray(this.props.gpHint) ? this.props.gpHint.join('\r\n') : this.props.gpHint; }

  get isHint (): boolean { return this.props.gpHint !== '' || this.props.gpHint.length >= 0 };

  // div container styles
  get position(): Partial<CSSStyleDeclaration>[] {
    let imageProps: any = this.$refs[this.pictureId];
    let style: Partial<CSSStyleDeclaration>[] = []
    let size: Partial<CSSStyleDeclaration> = {}
    const positioning = this.utils.controlPositionCSS(this.props.gpTop, this.props.gpLeft);

     //scale control using prop height and width
    size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight)
      
    //resize control to fit image by using image width and height
    if (this.props.gpStyle === 3) {
      size = this.utils.controlSize(imageProps.naturalWidth, imageProps.naturalHeight)
    }
    const containerCSS = { 
      backgroundColor: this.props.gpBackColor,
      color: this.props.gpForeColor
      }
    const font = this.utils.controlFont(this.props)

    style.push(positioning, size, containerCSS, font)
    
    return style
    }

  // image styles
  get style(): Partial<CSSStyleDeclaration>[] {
    let size: Partial<CSSStyleDeclaration> = {}
    let style: Partial<CSSStyleDeclaration>[] = []
    const border = this.utils.controlBorder(this.props)
    const alignment = this.utils.controlAlign(this.props)
    // if this.props.gpStyle === 0 no scaling so use image 
    
    // scale image to fit control preserving image aspect ratio
    if (this.props.gpStyle === 1) {
      size.height = '100%';
      size.width = 'auto';
      size.objectFit = 'cover';
    }

    // scale image to fit control
    if (this.props.gpStyle === 2) {
      size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight)
    }
    style.push(alignment, size, border)
    return style
  }

  get visible(): boolean { return this.props.gpVisible }

  get enabled(): boolean { return this.props.gpEnabled }

  get tabindex(): number { return this.props.tabindex }

  /** ******************************** Vue Methods **********************************/

  leftClickHandler(e: MouseEvent) {
    e.stopPropagation();

    this.clickCount++

    if (this.clickCount === 1) {
      this.clickTimer = setTimeout(() => {
        this.clickCount = 0
          
        if (this.props.gpEventMask & GUIEventCode.geClick) {
          // send this event to the server
          const guiEvent: IGUIEvent = { event: GUIEventCode.geClick, id: this.props.id }
          this.$store.dispatch('guiGuis/addEvent', guiEvent);
        }

      }, 250)
    } else if (this.clickCount === 2) {
      clearTimeout(this.clickTimer)
      this.clickCount = 0
        
      if (this.props.gpEventMask & GUIEventCode.geDblClick) {
        // send this event to the server
        const guiEvent: IGUIEvent = { event: GUIEventCode.geDblClick, id: this.props.id }
        this.$store.dispatch('guiGuis/addEvent', guiEvent);
      }
    }
  }

  rightClickHandler(e: MouseEvent) {
    e.stopPropagation();

    if (this.props.gpEventMask & GUIEventCode.geContext) {
      // send this event to the server
      const guiEvent: IGUIEvent = { event: GUIEventCode.geContext, id: this.props.id }
      this.$store.dispatch('guiGuis/addEvent', guiEvent);
    }

    this.$store.dispatch('guiGuis/updateMousePosition', this.utils.getMousePositionRelativeToParent(e, this.props.form!.id))
  }

  created() {}
}
