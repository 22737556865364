// Store
import store from '../../store'
// Types
import { IGUICmdMethod, IGUICmdSetProp, IGUIRspError, IGUIRspMethod, simpleNotification } from '../../types'
import { GUIObjectType, GUIComponent, GUICommandCode, GUIEventCode, GUIErrorCode, GUIErrorLevel, GUIMethod, GUIProperty, AllProps, CommandProps, GUIRspErrorType, GUIError } from '../../types/enums'
import { ListValue, TableValue, GridProps, MenuItemProps } from '../../types/enums'
import { IGUIEvent } from './../../types'
import { GuisServiceMethodType } from '../../types/services/guis'
// Utilities 
import Utils from '../../utils';
// Services
import GuisServiceSetProp from './setProp'

export default class GuisServiceMethod implements GuisServiceMethodType {

    constructor(obj?: GuisServiceSetProp) {
        obj ? this.setPropService = obj : this.setPropService = new GuisServiceSetProp(this);
    }
    
    public utils = new Utils();
    public setPropService: GuisServiceSetProp;
    public GUIListType: Array<GUIObjectType> = [GUIObjectType.gxGrid, GUIObjectType.gxGridEditable, GUIObjectType.gxList, GUIObjectType.gxListMultisel, GUIObjectType.gxCheckedList, GUIObjectType.gxDrpDwnCbo, GUIObjectType.gxDrpDwnList]


    method(command: IGUICmdMethod) {

        let response: IGUIRspMethod | IGUIRspError = { command: command.command, error: GUIErrorCode.grSuccess, method: command.method };

        if (command.id) {

            try {

                switch(command.method) {
                    case GUIMethod.gmReset:
                        // Reset Method = 0
                        response = this.reset(command);
                        break;
                    case GUIMethod.gmClear:
                        // Clear Method = 1
                        response = this.clear(command);
                        break;
                    case GUIMethod.gmShow:
                        // Show Method = 2
                        response = this.showHide(command, true);
                        break;
                    case GUIMethod.gmHide:
                        // Hide Method = 3
                        response = this.showHide(command, false);
                        break;
                    case GUIMethod.gmActivate:
                        // Activate Method = 4
                        response = this.activate(command);
                        break;
                    case GUIMethod.gmInsert:
                        // Insert Method = 5
                        response = this.insert(command);
                        break;
                    case GUIMethod.gmRemove:
                        // Remove Method = 6
                        response = this.remove(command);
                        break;
                    case GUIMethod.gmEnable:
                        // Enable Method = 7
                        response = this.enableDisable(command, true);
                        break;
                    case GUIMethod.gmDisable:
                        // Disable Method = 8
                        response = this.enableDisable(command, false);
                        break;
                    case GUIMethod.gmMove:
                        // Move Method = 9
                        response = this.move(command);
                        break;
                    case GUIMethod.gmPrint:
                        // Print Method = 10
                        response = this.print(command);
                        break;
                    case GUIMethod.gmHelp:
                        // Help Method = 11
                        response = this.help(command);
                        break;
                    case GUIMethod.gmSort:
                        // Sort Method = 12
                        response = this.sort(command);
                        break;
                    case GUIMethod.gmCopy:
                        // Copy Method = 13
                        response = this.copy(command);
                        break;
                    case GUIMethod.gmCut:
                        // Cut Method = 14
                        response = this.cut(command);
                        break;
                    case GUIMethod.gmPaste:
                        // Paste Method = 15
                        response = this.paste(command);
                        break;
                    default:
                        // error repsonse
                        response = { command: command.command, error: GUIErrorCode.grInvMeth, level: GUIErrorLevel.glvlFail, message: 'Object does not support this method', args: [command.id, command.method] };
                }
            } catch (e: any) {
                if (e instanceof GUIRspErrorType) {
                  response = e
                } else if (e instanceof RangeError) {
                  response = new GUIRspErrorType(command.command, GUIErrorCode.grInvArg, GUIErrorLevel.glvlFail, e.message, [command.id, command.method]);
                } else {
                  response = new GUIRspErrorType(command.command, GUIErrorCode.grFailure, GUIErrorLevel.glvlFail, (e && e.message ? e.message : 'An unknown error has occurred'), [command.id, command.method]);
                }
            }
        } else {
            // error repsonse
            response = { command: command.command, error: GUIErrorCode.grNoID, level: GUIErrorLevel.glvlFail, message: 'Unable to call method (' + command.command + ')', args: [command.id, command.method] };
        }        

        if (response.error !== GUIErrorCode.grSuccess) {
            // Notify
            const notification: simpleNotification = { type: 'warning', show: false, message: response.message || 'GUI error', friendlyMessage: 'Error setting property ' + command.method + ' on object ' + command.id, script: 'services/guis/method.ts', error: { errorCode: response.error, errorLevel: response.level } }
            store.dispatch('guiNotifications/addNotification', notification)
        }
    
        store.dispatch('guiResponse/setResponse', response)
    }

    reset (method: IGUICmdMethod) {
        let methExists: boolean = true
        let response: IGUIRspMethod | IGUIRspError;
        const setPropCommand: IGUICmdSetProp = {
            command: GUICommandCode.gcSetProp,
            id: '',
            property: GUIProperty.gpValue,
            col: 0,
            row: 0,
            value: null           
        }

        // Confrim there is a component
        const component: GUIComponent | undefined = store.getters['guiGuis/getComponent'](method.id);
        if (component) {
            let forms: Array<GUIComponent> = [];
            if (component.props.typeFamily === 'app') {
                forms = component.children;
            } else if (component.props.typeFamily === 'form') {
                forms = [component];
            } else {
                methExists = false;
            }
            if (methExists) {
                // Recursively reset gpValue for all controls in form/app
                forms.forEach(form => this.resetRecurs(form.children, setPropCommand));
                response = { command: method.command, error: GUIErrorCode.grSuccess, method: method.method }
            } else {
                // error repsonse
                response = { command: method.command, error: GUIErrorCode.grInvMeth, level: GUIErrorLevel.glvlFail, message: 'Object does not support this method', args: [method.id, method.method] };
            }
        } else {
            // error repsonse
            response = { command: method.command, error: GUIErrorCode.grNoExist, level: GUIErrorLevel.glvlFail, message: 'An object of the specified ID does not exist', args: [method.id, method.method] };
        }        
        return response;
    }

    resetRecurs (ctrls: Array<GUIComponent>, setPropCommand: IGUICmdSetProp) {
        ctrls.forEach(ctrl => {
            if ('gpDefVal' in ctrl.props) {
                setPropCommand.id = ctrl.props.id;
                setPropCommand.value = ctrl.props.gpDefVal;
                this.setPropService.setPropValue(setPropCommand, ctrl.props);
            }
            this.resetRecurs(ctrl.children, setPropCommand);
        });
    }

    clear (method: IGUICmdMethod) {
        let methExists: boolean = true
        let response: IGUIRspMethod | IGUIRspError ;
        // Confrim there is a component
        const component: GUIComponent | undefined = store.getters['guiGuis/getComponent'](method.id);
        if (component) {
            const setPropCommand: IGUICmdSetProp = {
                command: GUICommandCode.gcSetProp,
                id: method.id,
                property: GUIProperty.gpValue,
                col: 0,
                row: 0,
                value: null           
            }
            const type = component.props.type;
            if (type === GUIObjectType.gxLabel || type === GUIObjectType.gxEdit || type === GUIObjectType.gxEditMultiline || type === GUIObjectType.gxEditPassword ||
                type === GUIObjectType.gxDrpDwnCbo || type === GUIObjectType.gxPicture) {
                setPropCommand.value = '';
            } else if (type === GUIObjectType.gxOption || type === GUIObjectType.gxCheck || type === GUIObjectType.gxList ||
                type === GUIObjectType.gxDrpDwnList || type === GUIObjectType.gxGauge) {
                setPropCommand.value = 0;                
            } else if (type === GUIObjectType.gxListMultisel || type === GUIObjectType.gxCheckedList || type === GUIObjectType.gxGrid || type === GUIObjectType.gxGridEditable) {
                setPropCommand.value = [];                
            } else {
                methExists = false;
            }
            if (methExists) {
                // clear gpValue of control
                try {
                    this.setPropService.setPropValue(setPropCommand, component.props);
                    response = { command: method.command, error: GUIErrorCode.grSuccess, method: method.method }
                } catch(e: any) {
                    if (e instanceof GUIRspErrorType) {
                        response = { ...e, command: method.command, args: [method.id, method.method] };                        
                    } else if (e instanceof GUIError) {
                        response = new GUIRspErrorType(method.command, e.errorCode, GUIErrorLevel.glvlFail, e.message, [method.id, method.method]);
                    } else if (e instanceof RangeError) {
                        response = new GUIRspErrorType(method.command, GUIErrorCode.grInvArg, GUIErrorLevel.glvlFail, e.message, [method.id, method.method]);
                    } else {
                        response = new GUIRspErrorType(method.command, GUIErrorCode.grFailure, GUIErrorLevel.glvlFail, (e && e.message ? e.message : 'An unknown error has occurred'), [method.id, method.method]);
                    }                    
                }
            } else {
                // error repsonse
                response = { command: method.command, error: GUIErrorCode.grInvMeth, level: GUIErrorLevel.glvlFail, message: 'Object does not support this method', args: [method.id, method.method] };
            }
        } else {
            // error repsonse
            response = { command: method.command, error: GUIErrorCode.grNoExist, level: GUIErrorLevel.glvlFail, message: 'An object of the specified ID does not exist', args: [method.id, method.method] };
        }        
        return response
    }

    showHide (method: IGUICmdMethod, toggle: boolean) {
        let response: IGUIRspMethod | IGUIRspError;
        // Confrim there is a component
        const component: GUIComponent | undefined = store.getters['guiGuis/getComponent'](method.id);
        if (component) {
            const setPropCommand: IGUICmdSetProp = {
                command: GUICommandCode.gcSetProp,
                id: method.id,
                property: GUIProperty.gpVisible,
                col: 0,
                row: 0,
                value: toggle,
                item: (method.args && method.args[0]) || undefined
            }
            // hide or show the component
            try {
                this.setPropService.setPropValue(setPropCommand, component.props);
                response = { command: method.command, error: GUIErrorCode.grSuccess, method: method.method }
            } catch(e: any) {
                if (e instanceof GUIRspErrorType) {
                    if (e.error === GUIErrorCode.grInvProp) {
                        response = { ...e, error: GUIErrorCode.grInvMeth, command: method.command, args: [method.id, method.method] };
                    } else {
                        response = { ...e, command: method.command, args: [method.id, method.method] };
                    }
                } else if (e instanceof GUIError) {
                    response = new GUIRspErrorType(method.command, e.errorCode, GUIErrorLevel.glvlFail, e.message, [method.id, method.method]);
                } else if (e instanceof RangeError) {
                    response = new GUIRspErrorType(method.command, GUIErrorCode.grInvArg, GUIErrorLevel.glvlFail, e.message, [method.id, method.method]);
                } else {
                    response = new GUIRspErrorType(method.command, GUIErrorCode.grFailure, GUIErrorLevel.glvlFail, (e && e.message ? e.message : 'An unknown error has occurred'), [method.id, method.method]);
                }                
            }
        } else {
            // error repsonse
            response = new GUIRspErrorType(method.command, GUIErrorCode.grNoExist, GUIErrorLevel.glvlFail, 'An object of the specified ID does not exist', [method.id, method.method]);
        }
        return response;
    }

    activate(method: IGUICmdMethod) {
        let response: IGUIRspMethod | IGUIRspError;
        const component: GUIComponent | undefined = store.getters['guiGuis/getComponent'](method.id);
        // Does the component exist?
        if (component) {
            // check if visible and enabled
            if (this.activatable(component, true)) {

                try {
                    this.activateComponentTree(component);
                    response = { command: method.command, error: GUIErrorCode.grSuccess, method: method.method }
                    const removeEvents: Array<GUIEventCode> = [GUIEventCode.geValidate, GUIEventCode.geDeactivate, GUIEventCode.geActivate, GUIEventCode.geValidateCell, GUIEventCode.geDeactivateCell, GUIEventCode.geActivateCell, GUIEventCode.geValidateRow, GUIEventCode.geDeactivateRow, GUIEventCode.geActivateRow]                    
                    removeEvents.forEach(event => {
                        store.dispatch('guiGuis/removeEventType', event);
                    })
                } catch(e: any) {
                    // error repsonse
                    if (e instanceof GUIError) {
                        response = new GUIRspErrorType(method.command, e.errorCode, GUIErrorLevel.glvlFail, e.message, [method.id, method.method]);
                    } else {
                        response = new GUIRspErrorType(method.command, GUIErrorCode.grFailure, GUIErrorLevel.glvlFail, (e && e.message ? e.message : 'An unknown error has occurred'), [method.id, method.method]);
                    }                    
                }
                
            } else {
                // error repsonse
                response = new GUIRspErrorType(method.command, GUIErrorCode.grCantActivate, GUIErrorLevel.glvlFail, 'Cannot activate hidden or disabled object', [method.id, method.method]);
            }
        } else {
            // error repsonse
            response = new GUIRspErrorType(method.command, GUIErrorCode.grNoExist, GUIErrorLevel.glvlFail, 'An object of the specified ID does not exist', [method.id, method.method]);
        }
        
        return response;
    }

    activateComponentTree(component: GUIComponent) {
        if (['app', 'form', 'control'].includes(component.props.typeFamily)) {
            if (component.props.typeFamily === 'form') {
                // When form is first shown, we need to activate the first control
                if (!component.props.focused) {
                    const ctls = this.utils.flattenChildren(component, Infinity).filter((ctl) => ctl.props.gpEnabled && ctl.props.gpVisible && ctl.props.focusable);
                    if (ctls.length > 0) {
                        component = ctls[0]; // activate first control on form
                    }
                }
            }
            store.dispatch('guiGuis/setFocused', { id: component.props.id })
        } else {
            throw new GUIError('Invalid object type', GUIErrorCode.grInvMeth);
        }
    }

    activatable(component: GUIComponent, forceTabs = false): boolean {
        let rtn = false;
        let comp: GUIComponent | undefined = component;
        if (component.props.type === GUIObjectType.gxSDI) {
            // For an SDI app to be activatable, it must have at least one form that is activatible
            return component.children.some((child) => child.props.typeFamily === 'form' && child.props.gpVisible && child.props.gpEnabled);
        }
        if (['app', 'form', 'control'].includes(component.props.typeFamily)) {
            if (comp.props.typeFamily !== 'control' || comp.props.focusable || comp.props.type === GUIObjectType.gxTab) {
                for (;comp;) {
                    if (!(comp.props.gpVisible && comp.props.gpEnabled)) {
                        break; // cannot activate component - it (or its ancestor) is not enabled and visible 
                    }
                    if (comp.props.type === GUIObjectType.gxTab) {
                        const tabgrp: GUIComponent | undefined = store.getters['guiGuis/getComponent'](comp.props.parentID);
                        if (!tabgrp) {
                            break;
                        }                        
                        const tabs = tabgrp.children.filter(child => child.props.type === GUIObjectType.gxTab);
                        let tabIsSelected = (tabs[tabgrp.props.gpValue].props.id !== comp.props.id);
                        if (!tabIsSelected && forceTabs) {
                            // Special case: activate a tab - make sure the tab is selected,
                            // and if any of its ancestors are also tabs, then make sure the
                            // ancestor tab is also selected.
                            const i = tabs.findIndex(child => child.props.id === comp!.props.id);
                            if (i >= 0) {
                                const setPropCommand: IGUICmdSetProp = {
                                    command: GUICommandCode.gcSetProp,
                                    id: comp.props.id,
                                    property: GUIProperty.gpValue,
                                    col: 0,
                                    row: 0,
                                    value: i + 1
                                }
                                // enable or disable the component
                                try {
                                    this.setPropService.setPropValue(setPropCommand, comp.props);
                                    tabIsSelected = true;
                                } catch(e) {}
                            }
                            if (!tabIsSelected) {
                                break; // tab must be selected
                            }
                        }
                    }
                    if (comp.props.typeFamily === 'app') {
                        rtn = true;
                        break;
                    }
                    comp = store.getters['guiGuis/getComponent'](comp.props.parentID); // ensure parent is visible & enabled
                }
            }
        }
        return rtn;
    }

    insert (method: IGUICmdMethod) {
        let response: IGUIRspMethod | IGUIRspError;
        // Confrim there is a component
        const component: GUIComponent | undefined = store.getters['guiGuis/getComponent'](method.id);
        if (component) {
            const props = component.props;
            if (this.GUIListType.includes(props.type)) {
                let length = (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) ? props.gpValue.length : props.gpItems.length;
                let index = (method.args && +method.args[0]) || 0;
                let items = (method.args && method.args.length > 1) ? method.args.slice(1) : [[]]; // insert at least 1 item
                if (index >= 0 && index <= length) {
                    if (index === 0) index = length; else index--;
                    if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
                        props.gpValue.splice(index, 0, ... items);
                        if (props.rowInfo.length > index) {
                            items.fill(undefined); // for rowInfo, insert 'undefined' for missing rows
                            props.rowInfo.splice(index, 0, ... items);
                        }
                    } else {
                        props.gpItems.splice(index, 0, ... items);
                    }
                    store.dispatch('guiGuis/setProps', { id: props.id, props: props })
                    response = { command: method.command, error: GUIErrorCode.grSuccess, method: method.method }                
                } else {
                    // error repsonse
                    response = { command: method.command, error: GUIErrorCode.grInvArg, level: GUIErrorLevel.glvlFail, message: 'Invalid item index (' + method.command + ')', args: [method.id, method.method] };
                }
            } else {
                // error repsonse
                response = { command: method.command, error: GUIErrorCode.grInvMeth, level: GUIErrorLevel.glvlFail, message: 'Object does not support this method', args: [method.id, method.method] };
            }
        } else {
            // error repsonse
            response = { command: method.command, error: GUIErrorCode.grNoExist, level: GUIErrorLevel.glvlFail, message: 'An object of the specified ID does not exist', args: [method.id, method.method] };
        }
        return response;
    }

    remove (method: IGUICmdMethod) {
        let response: IGUIRspMethod | IGUIRspError;
        // Confrim there is a component
        const component: GUIComponent | undefined = store.getters['guiGuis/getComponent'](method.id);
        if (component) {
            const props = component.props;
            if (this.GUIListType.includes(props.type)) {
                let length = (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) ? props.gpValue.length : props.gpItems.length;
                const index = (method.args && +method.args[0]) || 0;
                let count = (method.args && +method.args[1]) || 1;
                if (count <= 0) count = 1;
                if (index >= 1 && index <= length) {
                    if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
                        props.gpValue.splice(index - 1, count);
                        props.rowInfo.splice(index - 1, count);
                    } else {
                        props.gpItems.splice(index - 1, count);
                    }
                    store.dispatch('guiGuis/setProps', { id: props.id, props: props })
                    response = { command: method.command, error: GUIErrorCode.grSuccess, method: method.method }                
                } else {
                    // error repsonse
                    response = { command: method.command, error: GUIErrorCode.grInvArg, level: GUIErrorLevel.glvlFail, message: 'Invalid item index (' + method.command + ')', args: [method.id, method.method] };
                }
            } else {
                // error repsonse
                response = { command: method.command, error: GUIErrorCode.grInvMeth, level: GUIErrorLevel.glvlFail, message: 'Object does not support this method', args: [method.id, method.method] };
            }
        } else {
            // error repsonse
            response = { command: method.command, error: GUIErrorCode.grNoExist, level: GUIErrorLevel.glvlFail, message: 'An object of the specified ID does not exist', args: [method.id, method.method] };
        }

        return response;
    }

    enableDisable (method: IGUICmdMethod, toggle: boolean) {
        let response: IGUIRspMethod | IGUIRspError;
        // Confrim there is a component
        const component: GUIComponent | undefined = store.getters['guiGuis/getComponent'](method.id);
        if (component) {
            const setPropCommand: IGUICmdSetProp = {
                command: GUICommandCode.gcSetProp,
                id: method.id,
                property: GUIProperty.gpEnabled,
                col: 0,
                row: 0,
                value: toggle,
                item: (method.args && method.args[0]) || undefined
            }
            // enable or disable the component
            try {
                this.setPropService.setPropValue(setPropCommand, component.props);
                response = { command: method.command, error: GUIErrorCode.grSuccess, method: method.method }
            } catch(e: any) {
                if (e instanceof GUIRspErrorType) {
                    if (e.error === GUIErrorCode.grInvProp) {
                        response = { ...e, error: GUIErrorCode.grInvMeth, command: method.command, args: [method.id, method.method] };
                    } else {
                        response = { ...e, command: method.command, args: [method.id, method.method] };
                    }
                } else if (e instanceof RangeError) {
                    response = new GUIRspErrorType(method.command, GUIErrorCode.grInvArg, GUIErrorLevel.glvlFail, e.message, [method.id, method.method]);
                } else {
                    response = new GUIRspErrorType(method.command, GUIErrorCode.grFailure, GUIErrorLevel.glvlFail, (e && e.message ? e.message : 'An unknown error has occurred'), [method.id, method.method]);
                }                
            }
        } else {
            // error repsonse
            response = new GUIRspErrorType(method.command, GUIErrorCode.grNoExist, GUIErrorLevel.glvlFail, 'An object of the specified ID does not exist', [method.id, method.method]);
        }
        return response;
    }

    move (method: IGUICmdMethod) {
        let response: IGUIRspMethod | IGUIRspError;
        // Confrim there is a component
        const component: GUIComponent | undefined = store.getters['guiGuis/getComponent'](method.id);
        if (component) {
            const props = component.props;
            const gpScale = this.utils.getScale(props)
            if ('gpLeft' in props && method.args && method.args.length > 0) {
                props.gpLeft = this.utils.pixelConversion(method.args[0], 'h', gpScale);
            }
            if ('gpTop' in props && method.args && method.args.length > 1) {
                props.gpTop = this.utils.pixelConversion(method.args[1], 'v', gpScale);
            }
            if ('gpWidth' in props && method.args && method.args.length > 2 && +method.args[2]) {
                props.gpWidth = this.utils.pixelConversion(method.args[2], 'h', gpScale);
            }
            if ('gpHeight' in props && method.args && method.args.length > 3 && +method.args[2]) {
                props.gpHeight = this.utils.pixelConversion(method.args[3], 'v', gpScale);
            }
            store.dispatch('guiGuis/setProps', { id: props.id, props: props })
            response = { command: method.command, error: GUIErrorCode.grSuccess, method: method.method }
        } else {
            // error repsonse
            response = { command: method.command, error: GUIErrorCode.grNoExist, level: GUIErrorLevel.glvlFail, message: 'An object of the specified ID does not exist', args: [method.id, method.method] };
        }
        return response;
    }

    print (method: IGUICmdMethod) { // TODO
        let response: IGUIRspMethod | IGUIRspError;
        response = { command: method.command, error: GUIErrorCode.grUnimplemented, level: GUIErrorLevel.glvlFail, message: "The 'print' method has not been implemented", args: [method.id, method.method] }
        // Confrim there is a component
        const component: GUIComponent | undefined = store.getters['guiGuis/getComponent'](method.id);
        if (component) {
            window.print();
            // if(method.args) {
            //     const arg = "0"; // method.args[0] || "1";
            //     const props = component.props;
            //     console.log(props);
            //     if(arg === "0" || arg === "1" || true) {
            //         const ele = (document.getElementById(method.id) as HTMLElement);
            //         // Get all stylesheets HTML
            //         let stylesHtml = '';
            //         for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
            //             stylesHtml += node.outerHTML;
            //         }
            //         let myWindow = window.open('', '', `height=${ele.offsetHeight},width=${ele.offsetWidth}`);
            //         if(ele && myWindow) {
            //             myWindow.document.write('<html><head>' + stylesHtml + '</head><body>' + ele.outerHTML + '</body></html>');
            //             myWindow.print(); 
            //             myWindow.close();
            //             response = { command: method.command, error: GUIErrorCode.grSuccess, method: method.method }
            //         } else {
            //             // todo
            //         }
            //     } 
            // }
        } else {
            // error repsonse
            response = { command: method.command, error: GUIErrorCode.grNoExist, level: GUIErrorLevel.glvlFail, message: 'An object of the specified ID does not exist', args: [method.id, method.method] };
        }
        return response;
    }

    help (method: IGUICmdMethod) { // TODO
        let response: IGUIRspError = { command: method.command, error: GUIErrorCode.grUnimplemented, level: GUIErrorLevel.glvlFail, message: "The 'help' method has not been implemented", args: [method.id, method.method] }
        return response    
    }

    sort (method: IGUICmdMethod) {
        let response: IGUIRspMethod | IGUIRspError;
        // Confrim there is a component
        const component: GUIComponent | undefined = store.getters['guiGuis/getComponent'](method.id);
        if (component && method.args) {
            const props = component.props;
            if (this.GUIListType.includes(props.type)) {
                // Sort args (may not need if args are already sorted)
                method.args.sort( (a: any, b: any) => {
                    let x = parseInt(typeof(a) === 'string'? a : a[0])
                    let y = parseInt(typeof(b) === 'string'? b : b[0])
                    return x === y? 0 : x < y ? -1 : 1
                })
                
                if (props.type === GUIObjectType.gxGrid || props.type === GUIObjectType.gxGridEditable) {
                    props.gpValue.sort((a: any, b: any) => {
                        return this.utils.compareArguments(method.args as any, a, b)
                    })
                } else {
                    props.gpItems.sort((a: any, b: any) => {
                        return this.utils.compareArguments(method.args as any, a, b)
                    })
                }
                store.dispatch('guiGuis/setProps', { id: props.id, props: props })
                response = { command: method.command, error: GUIErrorCode.grSuccess, method: method.method }
            } else {
                response = { command: method.command, error: GUIErrorCode.grFailure, level: GUIErrorLevel.glvlFail, message: "Can't sort this GUI Component." }
            }
        } else {
            // error repsonse
            response = { command: method.command, error: GUIErrorCode.grNoExist, level: GUIErrorLevel.glvlFail, message: 'An object of the specified ID does not exist', args: [method.id, method.method] };
        }
        return response;  
    }

    copy (method: IGUICmdMethod) { // TODO
        let response: IGUIRspError = { command: method.command, error: GUIErrorCode.grUnimplemented, level: GUIErrorLevel.glvlFail, message: "The 'copy' method has not been implemented", args: [method.id, method.method] }
        return response    
    }

    cut (method: IGUICmdMethod) { // TODO
        let response: IGUIRspError = { command: method.command, error: GUIErrorCode.grUnimplemented, level: GUIErrorLevel.glvlFail, message: "The 'cut' method has not been implemented", args: [method.id, method.method] }
        return response    
    }

    paste (method: IGUICmdMethod) { // TODO
        let response: IGUIRspError = { command: method.command, error: GUIErrorCode.grUnimplemented, level: GUIErrorLevel.glvlFail, message: "The 'paste' method has not been implemented", args: [method.id, method.method] }
        return response     
    }

    updateItemProp<T, U, K extends keyof T>(method: IGUICmdMethod, props: AllProps, items: ListValue<T>, value: U, id: string, keyname: K, propname: K): IGUIRspMethod | IGUIRspError {
        let response: IGUIRspMethod | IGUIRspError;
        try {
            this.setPropService.UpdateItemProp(items, value, id, keyname, propname); // throws RangeError if ID not found
            store.dispatch('guiGuis/setProps', { id: props.id, props: props })
            response = { command: method.command, error: GUIErrorCode.grSuccess, method: method.method }                
        } catch(e) {
            // error repsonse
            response = { command: method.command, error: GUIErrorCode.grInvArg, level: GUIErrorLevel.glvlFail, message: 'Invalid item ID (' + (method.args && method.args[0] || '') + ')', args: [method.id, method.method] };
        }
        return response;
    }
}
