/*
 * AccuTerm Mobile - terminal driver for Wyse emulations
 *
 * Copyright 2015 Zumasys, Inc.
 */

////////////////////////////////////////////////////////////
// Import Dependencies!
////////////////////////////////////////////////////////////

import { TermColors } from './termcolors.js';
import { DEBUG } from './globals.js';

/* global TermColors: false, DEBUG: false */

function WyseEmulator(term, termtype) {
	this.term = term;
	this.init(termtype);
}

WyseEmulator.prototype = {
	// termtype mask bits
	WYSE50: 1,	// enable Wyse 50 functions (termtype is property name)
	WYSE60: 2,	// enable Wyse 60 functions (termtype is property name)
	WYSE: 3,	// enable Wyse 50 or Wyse 60 functions (test only)
	VPA2E: 4,	// enable Viewpoint Enhanced functions (termtype is property name)
	ADDSVP: 8,	// enable Viewpoint functions (termtype is property name)
	ADDS: 12,	// enable Viewpoint & Enhanced functions (test only)
	WYSEVP: 7,	// enable Wyse 50 or Wyse 60 or Viewpoint Enhanced functions (test only)
	// Wyse state contants
	ADDSVT: 100,
	ADDSDLE: 101,
	ADDSHT: 102,
	ESCBANG: 103,
	ADDSFKEY: 104,
	ADDSFKEY1: 105,
	ADDSFKEY2: 106,
	ESCLBRACKET: 107,
	ESCZERO: 108,
	PGMFKEY: 109,
	INPUTSTRING: 110,
	WYSEESCG: 111,
	ADDSESCG: 112,
	GETROWPOS: 113,
	GETCOLPOS: 114,
	GETROWDIGIT: 115,
	GETCOLDIGIT: 116,
	WYSEESCH: 117,
	TDPTR1: 118,
	TDPTR2: 119,
	TDPTR3: 120,
	SCRFEATURE: 121,
	ESCPERIOD: 122,
	SCRNATTR: 123,
	SCRNATTR1: 124,
	IGNORE: 125,
	ESCCARET: 126,
	ESCUNDERLINE: 127,
	ESCDASH: 128,
	ESCc: 129,
	ESCd: 130,
	ESCe: 131,
	ESCw: 132,
	ESCx: 133,
	ESCx1: 134,
	ESCz: 135,
	ESCZ: 136,
	ESCZDIR: 137,
	ESCZTILD: 138,
	CHANGETERMTYPE: 139,
	WYSEBANK: 140,
	WYSECHARSET: 141,
	ANSWERBACK: 142,
	GETPRIMARYFONT: 143,
	GETSECONDARYFONT: 144,
	GETBOXROW: 145,
	GETBOXCOL: 146,
	GETBOXCHAR: 147,
	GETFILLCHAR: 148,
	GETCPYMODE: 149,
	GETCPYPARM: 150
};

WyseEmulator.prototype.init = function(termtype) {
	this.termtype = termtype;
	this.tmask = this[this.termtype] || 0; // get mask for terminal type
	this.kbdinit();
	this.reset();
};

WyseEmulator.prototype.kbdinit = function() {

/*	
NORMAL              0
SHIFT               1000
CTRL                2000
CTRL+SHIFT          3000
ALT                 4000
CTRL+SHIFT          3000
ALT+SHIFT           5000
CTRL+ALT            6000
CTRL+ALT+SHIFT      7000

VK_BACK             8
VK_TAB              9
VK_INSERT           45
VK_DELETE           46
VK_HOME             36
VK_END              35
VK_PRIOR (PgUp)     33
VK_NEXT (PgDn)      34
VK_LEFT             37
VK_RIGHT            39
VK_UP               38
VK_DOWN             40

VK_ESCAPE           27
VK_RETURN           13
VK_KEYPAD_ENTER     253

VK_F1               112
VK_F2               113
VK_F3               114
VK_F4               115
VK_F5               116
VK_F6               117
VK_F7               118
VK_F8               119
VK_F9               120
VK_F10              121
VK_F11              122
VK_F12              123
*/	
	var key, def, dfltkbd = [];
	if (this.tmask & this.WYSE) {
		dfltkbd[8] = '\x08'; // VK_BACK
		dfltkbd[9] = '\x09'; // VK_TAB
		dfltkbd[13] = '\x0d'; // VK_RETURN
		dfltkbd[27] = '\x1b'; // VK_ESCAPE
		dfltkbd[33] = '\x1b\x4a'; // VK_PRIOR
		dfltkbd[34] = '\x1b\x4b'; // VK_NEXT
		dfltkbd[35] = '\x1b\x54'; // VK_END
		dfltkbd[36] = '\x1e'; // VK_HOME
		dfltkbd[37] = '\x08'; // VK_LEFT
		dfltkbd[38] = '\x0b'; // VK_UP
		dfltkbd[39] = '\x0c'; // VK_RIGHT
		dfltkbd[40] = '\x0a'; // VK_DOWN
		dfltkbd[45] = '\x1b\x71'; // VK_INSERT
		dfltkbd[46] = '\x1b\x57'; // VK_DELETE
		dfltkbd[253] = '\x0d'; // VK_KEYPAD_ENTER
		dfltkbd[1008] = '\x08'; // SHIFT+VK_BACK
		dfltkbd[1009] = '\x1b\x49'; // SHIFT+VK_TAB
		dfltkbd[1013] = '\x0d'; // SHIFT+VK_RETURN
		dfltkbd[1027] = '\x1b'; // SHIFT+VK_ESCAPE
		dfltkbd[1033] = '\x1b\x4a'; // SHIFT+VK_PRIOR
		dfltkbd[1034] = '\x1b\x4b'; // SHIFT+VK_NEXT
		dfltkbd[1035] = '\x1b\x59'; // SHIFT+VK_END
		dfltkbd[1036] = '\x1b\x7b'; // SHIFT+VK_HOME
		dfltkbd[1037] = '\x08'; // SHIFT+VK_LEFT
		dfltkbd[1038] = '\x0b'; // SHIFT+VK_UP
		dfltkbd[1039] = '\x0c'; // SHIFT+VK_RIGHT
		dfltkbd[1040] = '\x0a'; // SHIFT+VK_DOWN
		dfltkbd[1045] = '\x1b\x72'; // SHIFT+VK_INSERT
		dfltkbd[1046] = '\x1b\x52'; // SHIFT+VK_DELETE
		dfltkbd[1253] = '\x0d'; // SHIFT+VK_KEYPAD_ENTER
		dfltkbd[2046] = '\x7f'; // CTRL+VK_DELETE
		dfltkbd[112] ='\x01\x40\x0d'; // VK_F1
		dfltkbd[113] ='\x01\x41\x0d'; // VK_F2
		dfltkbd[114] ='\x01\x42\x0d'; // VK_F3
		dfltkbd[115] ='\x01\x43\x0d'; // VK_F4
		dfltkbd[116] ='\x01\x44\x0d'; // VK_F5
		dfltkbd[117] ='\x01\x45\x0d'; // VK_F6
		dfltkbd[118] ='\x01\x46\x0d'; // VK_F7
		dfltkbd[119] ='\x01\x47\x0d'; // VK_F8
		dfltkbd[120] ='\x01\x48\x0d'; // VK_F9
		dfltkbd[121] ='\x01\x49\x0d'; // VK_F10
		dfltkbd[122] ='\x01\x4a\x0d'; // VK_F11
		dfltkbd[123] ='\x01\x4b\x0d'; // VK_F12
		dfltkbd[1112] ='\x01\x60\x0d'; // SHIFT+VK_F1
		dfltkbd[1113] ='\x01\x61\x0d'; // SHIFT+VK_F2
		dfltkbd[1114] ='\x01\x62\x0d'; // SHIFT+VK_F3
		dfltkbd[1115] ='\x01\x63\x0d'; // SHIFT+VK_F4
		dfltkbd[1116] ='\x01\x64\x0d'; // SHIFT+VK_F5
		dfltkbd[1117] ='\x01\x65\x0d'; // SHIFT+VK_F6
		dfltkbd[1118] ='\x01\x66\x0d'; // SHIFT+VK_F7
		dfltkbd[1119] ='\x01\x67\x0d'; // SHIFT+VK_F8
		dfltkbd[1120] ='\x01\x68\x0d'; // SHIFT+VK_F9
		dfltkbd[1121] ='\x01\x69\x0d'; // SHIFT+VK_F10
		dfltkbd[1122] ='\x01\x6a\x0d'; // SHIFT+VK_F11
		dfltkbd[1123] ='\x01\x6b\x0d'; // SHIFT+VK_F12
		dfltkbd[2112] ='\x80'; // CTRL+VK_F1
		dfltkbd[2113] ='\x81'; // CTRL+VK_F2
		dfltkbd[2114] ='\x82'; // CTRL+VK_F3
		dfltkbd[2115] ='\x83'; // CTRL+VK_F4
		dfltkbd[2116] ='\x84'; // CTRL+VK_F5
		dfltkbd[2117] ='\x85'; // CTRL+VK_F6
		dfltkbd[2118] ='\x86'; // CTRL+VK_F7
		dfltkbd[2119] ='\x87'; // CTRL+VK_F8
		dfltkbd[2120] ='\x88'; // CTRL+VK_F9
		dfltkbd[2121] ='\x89'; // CTRL+VK_F10
		dfltkbd[2122] ='\x8a'; // CTRL+VK_F11
		dfltkbd[2123] ='\x8b'; // CTRL+VK_F12
		dfltkbd[3112] ='\x90'; // SHIFT+CTRL+VK_F1
		dfltkbd[3113] ='\x91'; // SHIFT+CTRL+VK_F2
		dfltkbd[3114] ='\x92'; // SHIFT+CTRL+VK_F3
		dfltkbd[3115] ='\x93'; // SHIFT+CTRL+VK_F4
		dfltkbd[3116] ='\x94'; // SHIFT+CTRL+VK_F5
		dfltkbd[3117] ='\x95'; // SHIFT+CTRL+VK_F6
		dfltkbd[3118] ='\x96'; // SHIFT+CTRL+VK_F7
		dfltkbd[3119] ='\x97'; // SHIFT+CTRL+VK_F8
		dfltkbd[3120] ='\x98'; // SHIFT+CTRL+VK_F9
		dfltkbd[3121] ='\x99'; // SHIFT+CTRL+VK_F10
		dfltkbd[3122] ='\x9a'; // SHIFT+CTRL+VK_F11
		dfltkbd[3123] ='\x9b'; // SHIFT+CTRL+VK_F12
	} else {
		dfltkbd[8] = '\x08'; // VK_BACK
		dfltkbd[9] = '\x09'; // VK_TAB
		dfltkbd[13] = '\x0d'; // VK_RETURN
		dfltkbd[27] = '\x1b'; // VK_ESCAPE
		dfltkbd[33] = '\x1b\x4a'; // VK_PRIOR
		dfltkbd[34] = '\x1b\x7c'; // VK_NEXT
		dfltkbd[35] = '\x1b\x4b'; // VK_END
		dfltkbd[36] = '\x01'; // VK_HOME
		dfltkbd[37] = '\x15'; // VK_LEFT
		dfltkbd[38] = '\x1a'; // VK_UP
		dfltkbd[39] = '\x06'; // VK_RIGHT
		dfltkbd[40] = '\x0a'; // VK_DOWN
		dfltkbd[45] = '\x1b\x71'; // VK_INSERT
		dfltkbd[46] = '\x1b\x57'; // VK_DELETE
		dfltkbd[253] = '\x0d'; // VK_KEYPAD_ENTER
		dfltkbd[1008] = '\x08'; // SHIFT+VK_BACK
		dfltkbd[1009] = '\x1b\x4f'; // SHIFT+VK_TAB
		dfltkbd[1013] = '\x0d'; // SHIFT+VK_RETURN
		dfltkbd[1027] = '\x1b'; // SHIFT+VK_ESCAPE
		dfltkbd[1033] = '\x1b\x4a'; // SHIFT+VK_PRIOR
		dfltkbd[1034] = '\x1b\x7c'; // SHIFT+VK_NEXT
		dfltkbd[1035] = '\x1b\x6b'; // SHIFT+VK_END
		dfltkbd[1036] = '\x01'; // SHIFT+VK_HOME
		dfltkbd[1037] = '\x15'; // SHIFT+VK_LEFT
		dfltkbd[1038] = '\x1a'; // SHIFT+VK_UP
		dfltkbd[1039] = '\x06'; // SHIFT+VK_RIGHT
		dfltkbd[1040] = '\x0a'; // SHIFT+VK_DOWN
		dfltkbd[1045] = '\x1b\x72'; // SHIFT+VK_INSERT
		dfltkbd[1046] = '\x1b\x6c'; // SHIFT+VK_DELETE
		dfltkbd[2046] = '\x7f'; // CTRL+VK_DELETE
		dfltkbd[1253] = '\x0d'; // SHIFT+VK_KEYPAD_ENTER
		dfltkbd[112] ='\x02\x31\x0d'; // VK_F1
		dfltkbd[113] ='\x02\x32\x0d'; // VK_F2
		dfltkbd[114] ='\x02\x33\x0d'; // VK_F3
		dfltkbd[115] ='\x02\x34\x0d'; // VK_F4
		dfltkbd[116] ='\x02\x35\x0d'; // VK_F5
		dfltkbd[117] ='\x02\x36\x0d'; // VK_F6
		dfltkbd[118] ='\x02\x37\x0d'; // VK_F7
		dfltkbd[119] ='\x02\x38\x0d'; // VK_F8
		dfltkbd[120] ='\x02\x39\x0d'; // VK_F9
		dfltkbd[121] ='\x02\x3a\x0d'; // VK_F10
		dfltkbd[122] ='\x02\x3b\x0d'; // VK_F11
		dfltkbd[123] ='\x02\x3c\x0d'; // VK_F12
		dfltkbd[1112] ='\x02\x21\x0d'; // SHIFT+VK_F1
		dfltkbd[1113] ='\x02\x22\x0d'; // SHIFT+VK_F2
		dfltkbd[1114] ='\x02\x23\x0d'; // SHIFT+VK_F3
		dfltkbd[1115] ='\x02\x24\x0d'; // SHIFT+VK_F4
		dfltkbd[1116] ='\x02\x25\x0d'; // SHIFT+VK_F5
		dfltkbd[1117] ='\x02\x26\x0d'; // SHIFT+VK_F6
		dfltkbd[1118] ='\x02\x27\x0d'; // SHIFT+VK_F7
		dfltkbd[1119] ='\x02\x28\x0d'; // SHIFT+VK_F8
		dfltkbd[1120] ='\x02\x29\x0d'; // SHIFT+VK_F9
		dfltkbd[1121] ='\x02\x2a\x0d'; // SHIFT+VK_F10
		dfltkbd[1122] ='\x02\x2b\x0d'; // SHIFT+VK_F11
		dfltkbd[1123] ='\x02\x2c\x0d'; // SHIFT+VK_F12
		dfltkbd[2112] ='\x80'; // CTRL+VK_F1
		dfltkbd[2113] ='\x81'; // CTRL+VK_F2
		dfltkbd[2114] ='\x82'; // CTRL+VK_F3
		dfltkbd[2115] ='\x83'; // CTRL+VK_F4
		dfltkbd[2116] ='\x84'; // CTRL+VK_F5
		dfltkbd[2117] ='\x85'; // CTRL+VK_F6
		dfltkbd[2118] ='\x86'; // CTRL+VK_F7
		dfltkbd[2119] ='\x87'; // CTRL+VK_F8
		dfltkbd[2120] ='\x88'; // CTRL+VK_F9
		dfltkbd[2121] ='\x89'; // CTRL+VK_F10
		dfltkbd[2122] ='\x8a'; // CTRL+VK_F11
		dfltkbd[2123] ='\x8b'; // CTRL+VK_F12
		dfltkbd[3112] ='\x90'; // SHIFT+CTRL+VK_F1
		dfltkbd[3113] ='\x91'; // SHIFT+CTRL+VK_F2
		dfltkbd[3114] ='\x92'; // SHIFT+CTRL+VK_F3
		dfltkbd[3115] ='\x93'; // SHIFT+CTRL+VK_F4
		dfltkbd[3116] ='\x94'; // SHIFT+CTRL+VK_F5
		dfltkbd[3117] ='\x95'; // SHIFT+CTRL+VK_F6
		dfltkbd[3118] ='\x96'; // SHIFT+CTRL+VK_F7
		dfltkbd[3119] ='\x97'; // SHIFT+CTRL+VK_F8
		dfltkbd[3120] ='\x98'; // SHIFT+CTRL+VK_F9
		dfltkbd[3121] ='\x99'; // SHIFT+CTRL+VK_F10
		dfltkbd[3122] ='\x9a'; // SHIFT+CTRL+VK_F11
		dfltkbd[3123] ='\x9b'; // SHIFT+CTRL+VK_F12
	}
	// add default key definitions to kbdmap
    for(key in dfltkbd) {
        if (dfltkbd.hasOwnProperty(key)) {
			def = this.term.kbdmap['key' + key] || {};
			def.dflt = dfltkbd[key];
			this.term.kbdmap['key' + key] = def;
        }
    }	

};

WyseEmulator.prototype.reset = function() {
	this.term.state = this.term.NORMAL;
	this.protMode = false; // set the protect bit when writing to screen
	this.protModeEnabled = false; // set to disable scrolling & test protect bit when writing to screen
	this.term.noScrollMode = false; // set to disable scrolling
	this.term.insertMode = false; // set to enable insert mode
	this.term.kbdLock = false; // set to lock keyboard
	this.term.wraparoundMode = !!this.term.settings.autoWrap; //set to auto wrap
	this.graphMode = false; // character line drawing mode
	this.monitorMode = 0; // display controls
	this.secondaryCharset = false; // use primary character set
	this.charbank = ['@', 'A', '@', '@']; // charbank(1) is OEM
	this.charsets = [0, 1]; // 0=primary, 1=secondary
	this.clearOnResize = true; // clear screen when resizing
	this.scrAttr = 0; // Wyse screen attribute
	this.dirAttr = 0; // Wyse 60 direct attribute (for protected characters, combines with curTag to form curAttr)
	this.curAttr = 0;
	this.curTag = this.term.settings.protectAttribute; // 0=normal, 2=underline, 32=dim
	this.curMode = this.tmask & this.WYSE60 ? 64 : 0; // Wyse 60 uses direct attributes; others use embedded attributes
	this.curBG = 256; 
	this.curFG = 257; 
	this.splitX = 0; // split screen save cursor column for inactive window
	this.splitY = 0; // split screen save cursor row for inactive window
	this.ignoreCount = 0; // max number of characters to ignore when in the IGNORE state
	this.ignoreExit = ''; // exit IGNORE state when this character found
	this.inputStr = '';
	this.inputLen = 0;
	this.inputTerm = '';
	this.inputFunc = 0;
	this.updateCharset();
};

WyseEmulator.prototype.write = function(data) {
	//DEBUG&&console.log('Wyse.write ' + data.length);
	var len = data.length
	  , i;

	for (i = 0; i < len; i++) {
		if (!this.wyse_engine(data[i])) {
			return (len - i - 1);
		}
	}
};
	
WyseEmulator.prototype.wyse_engine = function(ch) {	

	var curState
	  , x
	  , y
	  , n
	  , attr;

	curState = this.term.state;
	
	// special case for NUL
	if (ch === '\x00' && curState !== this.ADDSVT && curState !== this.ADDSDLE && curState !== this.ESCUNDERLINE && curState !== this.ADDSFKEY) {
		return true; // discard NUL & remain in current state
	}
	
	// monitor mode
	if (this.monitorMode && this.term.state === this.term.NORMAL) {
		if (ch <= '\x1f') {
			if (this.term.insertMode) {
				this.term.insertch(ch, this._get_attr(), this.curMode);							
			} else {
				this.term.writech(ch, this._get_attr(), this.curMode);
			}		
			if (ch === '\x1b') {
				this.monitorMode = 2; // watch for "cancel monitor mode" sequence
			}
			return true;
		}
		if (this.monitorMode === 2) {
			if (ch === 'X' || ch === 'u') {
				this.monitorMode = 0; // cancel monitor mode
			} else {
				this.monitorMode = 1; // don't watch for "cancel monitor mode" sequence
			}
		}
	}

	// normal Wyse state engine
	this.term.state = this.term.NORMAL;
	switch (curState) {
		case this.term.NORMAL:
			switch (ch) {
				case '\x01': // SOH
					if (this.tmask & this.ADDS) {
						// cursor home
						this._cursor_home();
						this._prot_chkfwd();
					}
					break;
				case '\x02': // STX
					if (this.tmask & this.VPA2E) {
						// ADDS unlock keyboard
						this.term.kbdLock = false; 
						//TODO: update status line to show keyboard lock
					}
					break;
				case '\x04': // EOT
					if (this.tmask & this.VPA2E) {
						// ADDS lock keyboard
						this.term.kbdLock = true; 
						//TODO: update status line to show keyboard lock
					}
					break;
				case '\x06': // ACK
					if (this.tmask & this.ADDS) {
						// cursor right
						this._cursor_right(1);
						this._prot_chkfwd();
					}
					break;
				case '\x07': // BEL
					this.term.bell();
					break;
				case '\x08': // BS
					// cursor left
					this._cursor_left(1);
					this._prot_chkbck();
					break;
				case '\x09': // HT
					// tab
					this.term.cursorForwardTab([1]);
					this._prot_chkfwd();
					break;						
				case '\x0a': // LF
					// line feed
					this._cursor_index(1);
					this._prot_chkfwd();
					break;
				case '\x0b': // VT
					if (this.tmask & this.ADDS) {
						this.term.state = this.ADDSVT;
					} else {
						// cursor up
						this._cursor_up(1);
						this._prot_chkbck();
					}
					break;
				case '\x0c': // FF
					if (this.tmask & this.ADDS) {
						// clear screen
						this._clear_screen(false, true, true);
					} else {
						// cursor right
						this._cursor_right(1);
						this._prot_chkfwd();
					}
					break;
				case '\x0d': // CR
					this.term.x = 0;
					this._prot_chkfwd();
					break;
				case '\x0e': // SO
					if (this.tmask & this.WYSE) {
						this.term.kbdLock = false;
						//TODO: update status line to show keyboard lock
					} else {
						this.protMode = true;
						this.curMode &= ~(2 + 8); // clear ATTR_COLORB & ATTR_COLORF bits
						this.curMode |= (1 + 16); // set ATTR_PROT & ATTR_TAG bits
					}
					break;
				case '\x0f': // SI
					if(this.tmask & this.WYSE) {
						this.term.kbdLock = true;
						//TODO: update status line to show keyboard lock
					} else {
						this.protMode = false;
						this.curMode &= ~(2 + 8 + 16); // clear ATTR_COLORB & ATTR_COLORF & ATTR_TAG bits
						if (!this.graphMode) {
							this.curMode &= 1; // clear ATTR_PROT bit unless in character graphics mode
						}
					}
					break;
				case '\x10': // DLE
					if (this.tmask & this.ADDS) {
						this.term.state = this.ADDSDLE;
					}
					break;
				case '\x12': // DC2
					// auto-print on
					this.term.printer_on(false); 
					break;						
				case '\x14': // DC4
					// slave printer off
					this.term.printer_off(false); 
					break;
				case '\x15': // NAK
					if (this.tmask & this.ADDS) {
						// cursor left
						this._cursor_left(1);
						this._prot_chkbck();
					}
					break;
				case '\x16': // SYN
					if (this.tmask & this.WYSEVP) {
						// cursor down
						this._cursor_down(1);
						this._prot_chkfwd();
					}
					break;
				case '\x17': // ETB
					if (this.tmask & this.ADDS) {
						// hide cursor
						this.term.cursorHidden = true;
					}
					break;
				case '\x18': // CAN
					if (this.tmask & this.WYSE) {
						// transparent print on
						this.term.printer_on(true); 
					} else {
						// show cursor
						this.term.cursorHidden = false;
					}
					break;
				case '\x1a': // SUB
					if (this.tmask & this.ADDS) {
						// cursor up
						this._cursor_up(1);
						this._prot_chkbck();
					} else {
						// clear screen
						this._clear_screen(true, false, true);
					}
					break;
				case '\x1b': // ESC
					this.term.state = this.term.ESCAPED;
					break;
				case '\x1e': // RS
					if (this.tmask & this.WYSEVP) {
						// cursor home
						this._cursor_home();
						this._prot_chkfwd();
					}
					break;
				case '\x1f': // US
					if (this.tmask & this.WYSE) {
						// newline (OK to leave cursor on protected character)
						this.term.x = 0;
						this._cursor_index(1);
					}
					break;
				case '\x7f': // DEL
					// ignore
					break;
				default:
					if (ch >= ' ') {
						this._prot_chkfwd();
						if (this.term.insertMode) {
							this.term.insertch(ch, this._get_attr(), this.curMode);							
						} else {
							this.term.writech(ch, this._get_attr(), this.curMode);
						}
					} else {
						if (this.term.ctlchr(ch)) { // is private control sequence?
							return false;
						}
						// Wyse ignores unrecognized control characters
					}
					break;
			}
			break;
		case this.term.ESCAPED:
			if (!this._escseq(ch)) {
				return false;
			}
			break;
		case this.ADDSVT:
			// ADDS cursor position vertical
			y = ch.charCodeAt(0) & 0x1f;
			n = this.term.scrollBottom - this.term.scrollTop + 1;
			if (y < n) {
				this.term.y = y + this.term.scrollTop;
			}
			break;
		case this.ADDSDLE:
			// ADDS cursor position horizontal
			x = ch.charCodeAt(0) & 0x7f;
			x = (((x & 0x70) >> 4) * 10) + (x & 0x0f);
			if (x < this.term.cols) {
				this.term.x = x;		
			}
			break;
		case this.ADDSHT:
			switch (ch) {
				case '0':
					this.term.lineAttributes(this.term.y, 0); // reset line attribute
					break;
				case '1': 
					this.term.lineAttributes(this.term.y, 1); // set double-wide line attribute
					break;
				case '2': 
					this.term.lineAttributes(this.term.y, 3); // set double-wide+double-high-top line attribute
					break;
				case '3': 
					this.term.lineAttributes(this.term.y, 5); // set double-wide+double-high-bottom line attribute
					break;
			} // ignore unexpected parameter
			break;
		case this.ESCBANG:
			// Fill unprotected character positions with protected attribute
			attr = this._decode_attr(ch, false);			
			if (attr >= 0) {
				this._fill_screen(' ', attr, (this.curMode & 64 ? 64 + 1 : 4 + 1), true); 
			}
			break;
		case this.ADDSFKEY:
			// VPA2E function key programming
			this.term.state = this.ADDSFKEY1;
			this._decode_adds_fkey(ch);			
			break;
		case this.ADDSFKEY1:
			// ADDS Fkey 'destination' parameter
			if (ch >= '0' && ch <= '9') {
				if (ch === '2' || ch === '4' || ch === '6' || ch === '8') {
					this.virtKey = 0; // invalid destination - ignore programming (only host destination is supported)
				}
				return (this.term.state = this.ADDSFKEY2);
			} else {
				return this._invalid_escseq(ch);
			}
			break;
		case this.ADDSFKEY2:
			// ADDS Fkey 'link' parameter (ignored)
			this.inputStr = '';
			this.inputTerm = '\x19';
			this.inputLen = 253;
			this.inputFunc = this.PGMFKEY;
			return (this.term.state = this.INPUTSTRING);
		case this.INPUTSTRING:
			if (ch !== this.inputTerm) {
				this.inputStr += ch;
				if(this.inputStr.length < this.inputLen) {
					this.term.state = this.INPUTSTRING;
					break;
				}
			}
			this.term.state = this.inputFunc;
			return this.wyse_engine(ch);
		case this.PGMFKEY:
            if (this.virtKey > 0) {
				x = 'key' + (this.virtKey + (this.shiftMask * 1000));
				y = this.term.kbdmap[x] || {};
				y.host = this.inputStr || null;
				this.term.kbdmap[x] = y;
			}
			this.inputStr = '';
			break;
		case this.ANSWERBACK:
			this.term.answerBack = this.term.charmap.charIn(this.inputStr); // save new answerback string
			this.inputStr = '';
			break;
		case this.WYSEESCG:
			return this._wyse_attr(ch, false);
		case this.ADDSESCG:
			return this._wyse_attr(ch, true);
		case this.ESCZERO:
			return this._adds_attr(ch);
		case this.GETROWPOS:
			// Set cursor row
			if (this._set_cursor_row(ch.charCodeAt(0) - 32)) { // pass character value after removing bias
				return (this.term.state = this.GETCOLPOS);
			}
			break;
		case this.GETCOLPOS:
			// Set cursor column
			this._set_cursor_col(ch.charCodeAt(0) - 32); // pass character value after removing bias
			return true;
		case this.GETROWDIGIT:
			if (ch === 'R') {
				if (this._set_cursor_row(this.term.params[0] - 1)) {
					return (this.term.state = this.GETCOLDIGIT);
				} else {
					return this.wyse_engine(ch); // display the final 'R' if invalid row
				}
			} else if(/^[0-9]$/.test(ch)) {
				if (this.term.params[0] < this.term.rows) {
					this.term.params[0] = (this.term.params[0] * 10) + ch.charCodeAt(0) - 48;
					return (this.term.state = this.GETROWDIGIT);
				} else {
					return this.wyse_engine(ch);
				}
			}
			break;
		case this.GETCOLDIGIT:
			if (ch === 'C') {
				if (this._set_cursor_col(this.term.params[1] - 1)) {
					return true;
				} else {
					return this.wyse_engine(ch); // display the final 'C' if invalid column
				}
			} else if(/^[0-9]$/.test(ch)) {
				this.term.params[1] = (this.term.params[1] * 10) + ch.charCodeAt(0) - 48;
				return (this.term.state = this.GETCOLDIGIT);
			}
			break;
		case this.WYSEESCH:
			// Wyse line graphics
			switch (ch) {
				case '\x02': // ESC H STX
					// Turn chargraph mode on
					this.curMode |= 0x01; // set protect mode bit
					this.graphMode = true;
					this.updateCharset();
					break;					
				case '\x03': // ESC H ETX
					// Turn chargraph mode off
					if (!this.protMode) {
						this.curMode &= ~0x01; // reset protect mode bit
					}               
					this.graphMode = false;
					this.updateCharset();
					break;
				default:
					if (this.graphMode) {
						if (this.term.insertMode) {
							this.term.insertch(ch, this._get_attr(), this.curMode);							
						} else {
							this.term.writech(ch, this._get_attr(), this.curMode);
						}								
					} else {
						this.graphMode = true;
						this.updateCharset();
						this.curMode |= 0x01; // set protect mode bit
						if (this.term.insertMode) {
							this.term.insertch(ch, this._get_attr(), this.curMode);							
						} else {
							this.term.writech(ch, this._get_attr(), this.curMode);
						}		
						if (!this.protMode) {
							this.curMode &= ~0x01; // reset protect mode bit
						}               
						this.graphMode = false;
						this.updateCharset();
					}
					break;
			}
			return true;
		case this.SCRFEATURE:
			this._scr_feature(ch);
			return true;
		case this.ESCLBRACKET:
			// Wyse row-only cursor position or ANSI slave printer
			if (ch === '?') {
				return (this.term.state = this.TDPTR1);
			} else if (this.tmask & this.WYSE) {
				this._set_cursor_row(ch.charCodeAt(0) - 32); // pass character value after removing bias
				return true;
			}
			return this._invalid_escseq(ch);
		case this.TDPTR1:
			if (ch === '5') {
				return (this.term.state = this.TDPTR2);
			} else if (ch === '4') {
				return (this.term.state = this.TDPTR3);
			}
			return this._invalid_escseq(ch);
		case this.TDPTR2:
			if (ch === 'i') {
				// transparent print on
				this.term.printer_on(true);
				return true;
			}
			return this._invalid_escseq(ch);
		case this.TDPTR3:
			if (ch === 'i') {
				// transparent print off
				this.term.printer_off(false);
				return true;
			}
			return this._invalid_escseq(ch);
		case this.ESCPERIOD:
			// Fill unprotected characrter positions with character
			this._fill_screen(ch, ((this.protMode && this.curMode & 64) ? this.curTag : 0), this.curMode, true);
			break;
		case this.ESCDASH:
			// Wyse set page & cursor position
			n = ch.charCodeAt(0) - 32;
			this._switch_page(n, 0);
			return (this.term.state = this.GETROWPOS);
		case this.SCRNATTR:
			if (ch >= '0' && ch <= '3') {
				this.term.params = [ch.charCodeAt(0) - 48];
				return (this.term.state = this.SCRNATTR1);
			}
			return this._invalid_escseq(ch);
		case this.SCRNATTR1:
			attr = this._decode_attr(ch, false);
			if (this.term.params[0] === 0) {
				this.scrAttr = attr;
				this._update_screen_attr();
			} else {
				//TODO: handle function key label attribute (1) and host message line attribute (3)
			}
			break;
		case this.ESCCARET:
			// Wyse reverse screen
			switch (ch) {
				case '0':
					this.term.reverseVideo = false;
					break;
				case '1':
					this.term.reverseVideo = true;
					break;
			}
			break;
		case this.ESCUNDERLINE:
			if (this.tmask & this.VPA2E) {
				//TODO: begin program status line
				this.ignoreCount = 48; // ignore up to 48 characters
				this.ignoreExit = '\x19'; // ignore until EM control character
				return (this.term.state = this.IGNORE);
			} else {
				// Wyse cursor column
				this._set_cursor_col(ch.charCodeAt(0) - 32); // remove bias & set column
			}
			break;
		case this.WYSEBANK:
			this.term.params = [ch.charCodeAt(0) & 3]; // save font bank in params[0]
			this.term.state = this.WYSECHARSET;
			break;
		case this.WYSECHARSET:
			// params[0] is the font bank
			if (this.charbank[this.term.params[0]] !== ch) {
				this.charbank[this.term.params[0]] = ch; // save character set for selected font bank
				this.updateCharset();
			}
			break;
		case this.GETPRIMARYFONT:
			n = ch.charCodeAt(0) & 3; // get font bank
			if (this.charsets[0] !== n) {
	            this.charsets[0] = n; // assign font bank to char set 0 (GL)
				this.updateCharset();
			}			
			break;
		case this.GETSECONDARYFONT:
			n = ch.charCodeAt(0) & 3; // get font bank
			if (this.charsets[1] !== n) {
	            this.charsets[1] = n; // assign font bank to char set 1 (GR)
				this.updateCharset();
			}			
			break;
		case this.GETBOXROW:
			this.term.params[1] = ch.charCodeAt(0) - 32; // remove bias & save row
			this.term.params[2] = 0; // init col
			this.term.state = this.GETBOXCOL;
			break;
		case this.GETBOXCOL:
			if (ch === '~') {
				this.term.params[2] = 80; // offset from column 80
				this.term.state = this.GETBOXCOL;
			} else {
				this.term.params[2] += ch.charCodeAt(0) - 32; // add to previous value, removing bias
				if (this.term.params[0] === 'G') {
					this._draw_box(this.term.params[2], this.term.params[1], false); // absolute corner specified
				} else if (this.term.params[0] === 'N') {
					this._draw_box(this.term.params[1], this.term.params[2], true); // corner is relative to cursor (note: params are reversed - width is params[1]!)
				} else {
					return (this.term.state = this.GETBOXCHAR);
				}
			}
			break;
		case this.GETBOXCHAR:
			if (this.term.params[0] === 'F') {
				this._clear_box(this.term.params[2], this.term.params[1], ch, true); // clear unprotected box
			} else {
				this._clear_box(this.term.params[2], this.term.params[1], ch, false); // clear entire box
			}
			break;
		case this.GETFILLCHAR:
			// fill unprotected column with character
			this._fill_screen(ch, ((this.protMode && this.curMode & 64) ? this.curTag : 0), this.curMode, true, this.term.x, this.term.x);
			break;
		case this.GETCPYMODE:
			if (ch >= '0' && ch <= '2') {
				this.term.params = [ch.charCodeAt(0) - 48, 0, 0, 0, 0, 0, 0, 0, 0]; // save copy mode & initialize coordinates
				this.term.currentParam = 1;
				this.inputTerm = 'P'; // termination character for first parameter
				this.term.state = this.GETCPYPARM;
			}
			break;
		case this.GETCPYPARM:
			if (ch >= '0' && ch <= '9') {		
				this.term.params[this.term.currentParam] = (this.term.params[this.term.currentParam] * 10) + ch.charCodeAt(0) - 48;
				this.term.state = this.GETCPYPARM;
			} else if(ch === this.inputTerm) {
				if (this.term.currentParam < 8) {
					this.inputTerm = "RCRCPRC".substr(this.term.currentParam - 1, 1); // termination character for next parameter
					this.term.currentParam++;
					this.term.state = this.GETCPYPARM;
				} else {
					this._copy_box(this.term.params[0], this.term.params[1], this.term.params[3], this.term.params[2], this.term.params[5], this.term.params[4], this.term.params[6], this.term.params[8], this.term.params[7]);
				}
			} else {
				return this._invalid_escseq(ch);
			}			
			break;
		case this.ESCZ:
			if (ch >= '0' && ch <= '2') {
				this.term.state = this.ESCZDIR;
			} else if (ch === '~') {
				this.term.state = this.ESCZTILD;
			} else {				
				return this._invalid_escseq(ch);
			}
			break;
		case this.ESCZDIR:
			n = this._decode_wyse_fkey(ch);
			if (n >= 1 && n <= 2) {
				this.inputStr = '';
				this.inputTerm = '\x7f';
				this.inputLen = 255;
				this.inputFunc = this.PGMFKEY;
				return (this.term.state = this.INPUTSTRING);
			}
			break;
		case this.ESCZTILD:
			// Wyse send key definition to host
			n = this._decode_wyse_fkey(ch);
			if (n >= 1 && n <= 2) {
				y = this.term.getKeyStr('key' + (this.virtKey + (this.shiftMask * 1000)), false);
				this.term.send('0' + y + '\x7f');
			}
			break;
		case this.ESCc:
			return this._escc(ch);
		case this.ESCd:
			switch (ch) {
				case '#': // ESC d #
					// start transparent print
					this.term.printer_on(true);
					break;
				case '.': // ESC d .
					// disable line wrap
					this.term.wraparoundMode = false;
					break;
				case '/': // ESC d /
					// enable line wrap
					this.term.wraparoundMode = true;
					break;
				case "'": case '&': // ESC d ' or ESC d &
					// begin print/send at top of screen/page (N/A)
					break;
				default:
					return this._invalid_escseq(ch);
			}
			break;
		case this.ESCe:
			switch (ch) {
				case '&': // ESC e &
					//TODO: caps lock on
					break;
				case "'": // ESC e '
					//TODO: caps lock off
					break;
				case '.': // ESC e .
					// Width change no clear screen
					this.clearOnResize = false;
					break;
				case '/': // ESC e /
					// Width change clear screen
					this.clearOnResize = true;
					break;
				case 'F': // ESC e F
					// Window down in page (N/A)
					break;
				default:
					// ignore undexpected characters
					break;
			}
			break;
		case this.ESCw:
			if (ch >= '0'&& ch <= '9') {
				this._switch_page(ch.charCodeAt(0) - 48, 0); // switch page absolute
			} else {
				switch (ch) {
					case 'B':
						this._switch_page(1, -1); // previous page
						break;
					case 'C':
						this._switch_page(1, 1); // next page
						break;
					case '@':
						// Set cursor position on specific page
						return (this.term.state = this.ESCDASH);
					case '`':
						// Send cursor position
						return this._escseq('/');
					case 'E':
						// Window up in page (N/A)
						break;
					case 'F':
						// Window down in page (N/A)
						break;
				}
			}			
			break;
		case this.ESCx:
			this.protMode = false;
			this.protModeEnabled = false;
			switch (ch) {
				case '0': case '@': // ESC x 0 or ESC x @
					// Redefine screen as single segment
					this.term.scrollTop = 0;
					this.term.scrollBottom = this.term.rows - 1;
					if (ch === '0') {
						this._clear_screen(true, false, true);
					}
					break;
				case '1': case 'A': // ESC x 1 or ESC x A
					// Split screen (fixed)
					this.inputFunc = ch;
					return (this.term.state = this.ESCx1);
				case '3': case 'C': // ESC x 3 or ESC x C
					// Split screen (adjustable)
					this.inputFunc = ch;
					return (this.term.state = this.ESCx1);
				case 'P': // ESC x P
					// Lower split (N/A)
					break;
				case 'R': // ESC x R
					// Raise split (N/A)
					break;
			}	
			break;
		case this.ESCx1:
			n = ch.charCodeAt(0) - 32;
			if (n >= 32) {
				n -= 32;
			}
			if (n > 0 && n < this.term.rows) {
				if (this.inputFunc === '1' || this.inputFunc === '3') {
					// clear screen before split (need to ensure single segment before clearing)
					this.term.scrollTop = 0;
					this.term.scrollBottom = this.term.rows - 1;
					this._clear_screen(true, false, true);					
				}
				this.term.scrollTop = 0;
				this.term.scrollBottom = n - 1;
				this.splitX = 0;
				this.splitY = n;
				this._cursor_home();
			}
			break;
		case this.ESCz:
			if (/[0-9\:\;<\=\>\?\(\)P-Z_]/.test(ch)) {
				//TODO: program status line
				this.ignoreExit = '\x0d';
				this.ignoreCount = 255;
				return (this.term.state = this.IGNORE);
			} else if (ch === '\x7f') {
				//TODO: hide shifted label line
			} else {
				if (this._decode_wyse_fkey(ch) === 1) {
					this.inputStr = '';
					this.inputTerm = '\x7f';
					this.inputLen = 255;
					this.inputFunc = this.PGMFKEY;
					return (this.term.state = this.INPUTSTRING);
				}
			}
			break;
		case this.CHANGETERMTYPE:
			switch (ch) {
				case ' ':
					if (this.termtype === 'VPA2E') {
						this.init('ADDSVP');
					}
					break;
				case '!':
					if (this.termtype === 'ADDSVP') {
						this.init('VPA2E');
					}
					break;
				case '"':
				case '@':
					this.init('WYSE50');
					break;
				case '%':
					this.init('VPA2E');
					break;
				case '4':
					this.init('WYSE60');
					break;
				case '6':
					// TODO: VT52 not supported
					break;
				case '8':
					// TODO: VP60 not supported
					break;
				case '>':
					// TODO: Tektronix not supported
					break;
				case ';':
				case '<':
				case '=':
				case 'A':
				case 'B':
				case 'C':
					// VTxxx
					switch (ch) {
						case ';':
							x = 'VT100';
							break;
						case '<':
						case '=':
						case 'A':
							x = 'VT220';
							break;
						case 'B':						
						case 'C':
							x = 'VT320';
					}
					this.term.options.eightBitControls = (ch === '=' || ch === 'A' || ch === 'C' );
					this.term.setTermtype(x);
					return false; // continue processing using new emulator object
				default:
					// unrecognized terminal type code - continue with current term type
			}
			break;
		case this.IGNORE:
			if (--this.ignoreCount > 0) {
				if (this.ignoreExit !== '' && ch !== this.ignoreExit) {
					this.term.state = this.IGNORE;
				}
			}
			break;
		default:
			if(this.term.state >= this.term.PRIVATE) {
				return false;
			}
			//TODO: log an error - unknown state
	}
	return true;
};

// process escape sequence
WyseEmulator.prototype._escseq = function(ch) {
	var i, j, k;
	switch (ch) {
		case '\x00': // ESC NUL
			return (this.term.state = this.term.ESCAPED); // ignore NULs in escape sequences			
		case '\x09': // ESC HT
			if (this.tmask & this.VPA2E) {
				return (this.term.state = this.ADDSHT); // old ADDS 4000 dblhigh/dblwide
			}
			break;
		case ' ': // ESC SP
			if (this.tmask & this.WYSE60) {
				this.term.send('60\r');
				return true;
			} else if (this.tmask & this.WYSEVP) {
				this.term.send('50\r');
				return true;
			}
			break;
		case '!': // ESC !
			if (this.tmask & this.WYSEVP) {
				return (this.term.state = this.ESCBANG);
			}
			break;
		case '"': // ESC "
			if (this.tmask & this.WYSEVP) {
				// unlock the keyboard
				this.term.kbdLock = false;
				//TODO: update status line to show keyboard lock
				return true;
			}
			break;
		case '#': // ESC #
			if (this.tmask & this.WYSEVP) {
				// lock the keyboard
				this.term.kbdLock = true;
				//TODO: update status line to show keyboard lock				
				return true;
			}
			break;			
		case '%': // ESC %
			if (this.tmask & this.VPA2E) {
				return (this.term.state = this.ADDSFKEY);
			}
			break;
		case '&': // ESC &
			// enable protect mode (disables scrolling)
			if (this.tmask & this.WYSEVP) {
				this.protModeEnabled = true;
				this._prot_chkfwd();
				return true;
			}
			break;
		case "'": // ESC '
			// disable protect mode (enables scrolling)
			if (this.tmask & this.WYSEVP) {
				this.protModeEnabled = false;
				return true;
			}
			break;
		case '(': // ESC (
			// turn off write protect submode (tag off)
			if (this.tmask & this.WYSEVP) {
				this.protMode = false;
				this.curMode &= ~(2 | 8 | 16); // clear direct color & tag bits
				if (!this.graphMode) {
					this.curMode &= ~0x01; // clear protect bit unless line graphics mode is set
				}
				if (this.tmask & this.WYSE60) {
					this.curAttr = this.dirAttr;
				}
				return true;
			}
			break;
		case ')': // ESC )
			// turn on write protect submode (tag on)
			if (this.tmask & this.WYSEVP) {
				this.protMode = true;
				this.curMode &= ~(2 | 8); // clear direct color bits
				if (this.tmask & this.WYSE60) {
					this.curMode |= 0x01; // set protect bit
					this.curAttr = this.dirAttr | this.curTag;
				} else {
					this.curMode |= (0x01 | 16); // set protect & tag bits
				}
				return true;
			}
			break;
		case '*': case '+': // ESC * or ESC +
			if (this.tmask & this.WYSEVP) {
				// clear screen & reset protect mode
				this._clear_screen(false, true, true);
				return true;
			}
			break;
		case ',': // ESC ,
			if (this.tmask & this.WYSEVP) {
				// clear screen to protected spaces
				this.protMode = false;
				this.protModeEnabled = false;
				this.curMode &= (1 + 2 + 8); // clear protect & direct color bits
				this.dirAttr = 0; // normal attribute
				this.curAttr = 0; // normal attribute
				this._fill_screen(' ', (this.curMode & 64 ? this.curTag : 0), (this.curMode & 64 ? 64 + 1 : 16 + 1), false);
				return true;
			}													
			break;
		case '-': // ESC -
			if (this.tmask & this.WYSEVP) {
				// Wyse set page & cursor position
				k = ch.charCodeAt(0) - 32;
				this._switch_page(k, 0); // set page to k
				return (this.term.state = this.GETROWPOS);
			}
			break;
		case '.': // ESC .
			if (this.tmask & this.WYSEVP) {
				// Fill unprotected character positions with character
				return (this.term.state = this.ESCPERIOD);
			}
			break;
		case '/': // ESC /
			if (this.tmask & this.WYSEVP) {
				// send page & cursor address in 80 column format
				i = this.term.x;
				j = this.term.y - this.term.scrollTop;
				if (i > 94) {
					i = 94; // max col for single-byte address
				}
				if (j > 94) {
					j = 94; // max row for single-byte address
				}
				if (this.term.hasScrollingRegion()) {
					k = (this.term.scrollTop > 0 ? 1 : 0); // window (top=0, bottom=1)
				} else {
					k = this.term.curPage; // page number
				}
				this.term.send(String.fromCharCode(k + 32, j + 32, i + 32, 0x0d));
				return true;
			}
			break;
		case '0': // ESC 0
			if (this.tmask & this.ADDS) {
				this.term.state = this.ESCZERO;
			} else {
				// clear all tab stops
				this.term.tabs = {};
			}
			return true;			
		case '1': // ESC 1
			if (this.tmask & this.WYSEVP) {
				// set tab at cursor
				this.term.tabs[this.term.x] = true;
				return true;
			}
			break;
		case '2': // ESC 2
			if (this.tmask & this.WYSEVP) {
				// clear tab at cursor
				delete this.term.tabs[this.term.x];
				return true;
			}
			break;
		case '3': // ESC 3
			if (this.tmask & this.ADDS) {
				// start transparent print
				this.term.printer_on(true);
				return true;
			}
			break;
		case '4': // ESC 4
			if (this.tmask & this.ADDS) {
				// stop transparent print
				this.term.printer_off(false);
			} else {			
				// Wyse send unprotected line (through cursor column)
				this._send_screen(true, 0);
			}
			return true;
		case '5': // ESC 5
			if (this.tmask & this.ADDS) {
				this.term.kbdLock = true;
				// TODO: update keyboard lock in status line
			} else {
				// Wyse send unprotected screen (through cursor column)
				this._send_screen(true, 1);
			}
			return true;
		case '6': // ESC 6
			if (this.tmask & this.ADDS) {
				this.term.kbdLock = false;
				// TODO: update keyboard lock in status line
			} else {
				// Wyse send line (through cursor column)
				this._send_screen(false, 2);
			}
			return true;
		case '7': // ESC 7
			if (this.tmask & this.WYSEVP) {
				// send page (through cursor position)
				this._send_screen(false, 3);
				return true;
			}
			break;			
		case '8': // ESC 8
			// define block begin (unsupported)
			break;
		case '9': // ESC 9
			// define block end (unsupported)
			break;
		case ':': case ';': // ESC : or ESC ;
			if (this.tmask & this.WYSEVP) {
				// clear unprotected screen
				this._clear_screen(true, false, true);
				return true;
			}
			break;
		/* - old DOS version used ESC <  /  ESC >  to execute DOS command -
		case '<': // ESC <
			break;
		case '>': // ESC >
			break; */
		case '=': // ESC =
			return (this.term.state = this.GETROWPOS);
		case '?': // ESC ?
			if (this.tmask & this.WYSEVP) {
				// Wyse report cursor position
				i = this.term.x;
				j = this.term.y - this.term.scrollTop;
				if (i > 94) {
					i = 94; // max col for single-byte address
				}
				if (j > 94) {
					j = 94; // max row for single-byte address
				}
				this.term.send(String.fromCharCode(j + 32, i + 32, 0x0d));
				return true;
			}
			break;
		case 'A': // ESC A
			if (this.tmask & this.WYSEVP) {
				return (this.term.state = this.SCRNATTR);
			}
			break;
		case 'B': // ESC B
			if (this.tmask & this.WYSEVP) {
				return true; // Block mode (unsupported)				
			}
			break;
		case 'C': // ESC C
			if (this.tmask & this.WYSEVP) {
				return true; // Conversation mode (unsupported)
			}
			break;
		case 'D': // ESC D
			if (this.tmask & this.WYSEVP) {
				this.ignoreCount = 1; // ignore next character
				this.ignoreExit = '';
				return (this.term.state = this.IGNORE); // Duplex (ignore)
			}
			break;
		case 'E': // ESC E
			if (this.tmask & this.WYSEVP) {
				// Wyse insert line
				if (!this.protModeEnabled) {
					this.term.insertLines([1]);
				}
				return true;
			}
			break;
		case 'F': // ESC F
			if (this.tmask & this.WYSEVP) {
				//TODO: Program the host message status line
				
		        // Note: different Wyse models display different number
				// of characters on host messgae line:
				//   Wy50: 46/98
				//   Wy60: 46/98
				//   Wy150: 48/100
				//   Wy150 in 50+ mode: 46/98
				//   Wy160: 39/91
				//   Wy160 in 50+ mode: 38/90
				//   Wy370 in Wy350 mode: 47/99
				this.ignoreCount = (this.term.cols <= 80 ? 46 : 98);
				if (this.tmask & this.WYSE60) {
					this.ignoreCount += 2;
				}
				this.ignoreExit = '\x0d'; // terminate on CR
				return (this.term.state = this.IGNORE);
			}
		    break;
		case 'G': // ESC G
			if (this.tmask & this.WYSEVP) {
				return (this.term.state = this.WYSEESCG);
			}
			break;
		case 'H': // ESC H
			if (this.tmask & this.WYSEVP) {
				return (this.term.state = this.WYSEESCH);
			}
			break;
		case 'I': // ESC I
			if (this.tmask & this.WYSEVP) {
				// backtab
				this.term.cursorBackwardTab([1]);
				this._prot_chkback();				
				return true;
			}
			break;
		case 'J': // ESC J
			if (this.tmask & this.WYSEVP) {
				// Wyse toggle window or switch page
				if (this.term.hasScrollingRegion()) {
	               this._toggle_window();
				} else {
					this._switch_page(1, -1); // switch to previous page
				}
				return true;
			}
			break;
		case 'K': // ESC K
			if (this.tmask & this.ADDS) {
				// ADDS clear to end of line
				this._clear_to_end_of_line(true);
			} else {
				// Wyse toggle window or switch page
				if (this.term.hasScrollingRegion()) {
	               this._toggle_window();
				} else {
					this._switch_page(1, 1); // switch to next page
				}
			}
			return true;			
		case 'L': case 'P': case 'p': // ESC L or ESC P or ESC p
			if (this.tmask & this.WYSEVP) {
				//TODO: print screen (live display - no message or status lines)
				return true;
			}
			break;
		case 'M': // ESC M
			if (this.tmask & this.VPA2E) {
				// ADDS insert line
				if (!this.protModeEnabled) {
					this.term.insertLines([1]);
				}
			} else {
				// Wyse send character at cursor
				ch = this.term.lines[this.term.y + this.term.ybase][this.term.x][1] || ' ';
				this.term.send(ch);
			}
			return true;
		case 'N': // ESC N
			if (this.tmask & this.WYSEVP) {
				// Turn off scroll mode
				this.term.noScrollMode = true;
				return true;
			}
			break;
		case 'O': // ESC O
			if (this.tmask & this.WYSEVP) {
				// Turn on scroll mode
				this.term.noScrollMode = false;
				return true;
			}
			break;
		case 'Q': // ESC Q
			if (this.tmask & this.WYSEVP) {
				// Insert character
				this._insert_char();
				return true;
			}
			break;
		case 'R': // ESC R
			if (this.tmask & this.WYSEVP) {
				// Delete line
				if (!this.protModeEnabled) {
					this.term.deleteLines([1]);
				}
				return true;
			}
			break;
		case 'T': case 't': // ESC T or ESC t
			if (this.tmask & this.WYSEVP) {
				this._clear_to_end_of_line(true);
				return true;
			}
			break;
		case 'U': // ESC U
			if (this.tmask & this.WYSEVP) {
				this.monitorMode = 1; // enable monitor mode
				this.updateCharset();
				return true;
			}
			break;
		case 'V': // ESC V
			if (this.tmask & this.WYSEVP) {				
				// Set column to protected spaces
				this._fill_screen(' ', (this.curMode & 64 ? this.curTag : 0), (this.curMode & 64 ? 64 + 1 : 16 + 1), false, this.term.x, this.term.x); 
				return true;
			}
			break;
		case 'W': // ESC W
			if (this.tmask & this.WYSEVP) {
				// delete character
				this._delete_char();
				return true;
			}
			break;
		case 'X': // ESC X
			if (this.tmask & this.WYSEVP) {
				this.monitorMode = 0; // cancel monitor mode
				this.updateCharset();
				return true;
			}
			break;
		case 'Y': // ESC Y
			if (this.tmask & this.ADDS) {
				// ADDS cursor position
				this.term.state = this.GETROWPOS;
			} else {
				// Wyse clear unprotected to end of scrolling region
				this._clear_to_end_of_region(true);
			}
			return true;
		case 'Z': // ESC Z
			if (this.tmask & this.WYSEVP) {
				return (this.term.state = this.ESCZ);
			}
			break;
		case '[': // ESC [
			return (this.term.state = this.ESCLBRACKET);
		case ']': // ESC ]
			if (this.tmask & this.WYSEVP) {
				if (this.term.hasScrollingRegion()) {
					if (this.term.scrollTop !== 0) {
						this._toggle_window(); // to upper segment
					}
				} else {
					this._switch_page(0, 0); // switch to page 0
				}
				return true;
			}
			break;
		case '^': // ESC ^
			if (this.tmask & this.WYSEVP) {
				return (this.term.state = this.ESCCARET);
			}
			break;
		case '_': // ESC _
			if (this.tmask & this.WYSEVP) {
				return (this.term.state = this.ESCUNDERLINE);
			}
			break;
		case '`': // ESC `
			if (this.tmask & this.WYSEVP) {
				// Screen feature
				return (this.term.state = this.SCRFEATURE);
			}
			break;
		case 'a': // ESC a
			if (this.tmask & this.WYSEVP) {
				this.term.params = [0, 0];
				return (this.term.state = this.GETROWDIGIT);
			}
			break;
		case 'b': // ESC b
			if (this.tmask & this.WYSEVP) {
				// send cursor position in 132 column format
				this.term.send((('000' + (this.term.y - this.term.scrollTop + 1).toString()).slice(-3)) + 'R' + (('000' + (this.term.x + 1).toString()).slice(-3)) + 'C');
				return true;
			}
			break;
		case 'c': // ESC c
			if (this.tmask & this.WYSEVP) {
				return (this.term.state = this.ESCc);
			}
			break;
		case 'd': // ESC d
			if (this.tmask & this.WYSEVP) {
				return (this.term.state = this.ESCd);
			}
			break;
		case 'e': // ESC e
			if (this.tmask & this.WYSEVP) {
				return (this.term.state = this.ESCe);
			}
			break;
		case 'f': // ESC f
			if (this.tmask & this.VPA2E) {
				//TODO: ADDS function key status line
				this.ignoreCount = this.term.cols; // ignore up to full line
				this.ignoreExit = '\x0d'; // terminate on CR
				return (this.term.state = this.IGNORE);
			}
			break;
		case 'g': // ESC g
			if (this.tmask & this.VPA2E) {
				// ADDS 4000 non-embedded attribute			
				return (this.term.state = this.ADDSESCG);
			}
			break;         
		case 'i': // ESC i
			if (this.tmask & this.WYSEVP) {
				// tab cursor
				this.term.cursorForwardTab([1]);
				this._prot_chkfwd();
				return true;
			}
			break;
		case 'j': // ESC j
			if (this.tmask & this.WYSEVP) {
				// move cursor up and scroll if at top
				this._cursor_reverse_index(1);
				return true;
			}
			break;
		case 'k': // ESC k
			if (this.tmask & this.ADDS) {
				// ADDS clear unprotected to end of screen
				this._clear_to_end_of_region(true);
				return true;
			}
			break;
		case 'l': // ESC l
			if (this.tmask & this.VPA2E) {
				if (!this.protModeEnabled) {
					this.term.deleteLines([1]);
				}
				return true;
			}
			break;
		case 'q': // ESC q
			if (this.tmask & this.WYSEVP) {
		        // Turn on insert mode
				this.term.insertMode = true;
				return true;
			}
			break;
		case 'r': // ESC r
			if (this.tmask & this.WYSEVP) {
		        // Turn off insert mode
				this.term.insertMode = false;
				return true;
			}
			break;
		case 's': // ESC s
			// Send block of data (N/A)
			return true;
		case 'u': // ESC u
			if (this.tmask & this.WYSEVP) {
				this.monitorMode = 0; // cancel monitor mode
				this.updateCharset();
				return true;
			}
			break;
		case 'w': // ESC w
			if (this.tmask & this.WYSEVP) {
				return (this.term.state = this.ESCw);
			}
			break;
		case 'x': // ESC x
			if (this.tmask & this.WYSEVP) {
				return (this.term.state = this.ESCx);
			}
			break;
		case 'y': // ESC y
			if (this.tmask & this.WYSEVP) {
				this._clear_to_end_of_region(true);
				return true;
			}
			break;
		case 'z': // ESC z			
			if (this.tmask & this.WYSEVP) {
				return (this.term.state = this.ESCz);
			}
			break;
		case '{': // ESC {
			if (this.tmask & this.WYSEVP) {
				this._cursor_home();
				return true;
			}
			break;
		case '}': // ESC }
			if (this.tmask & this.WYSEVP) {
				if (this.term.hasScrollingRegion()) {
					if (this.term.scrollTop === 0) {
						this._toggle_window(); // to lower segment
					}
				} else {
					this._switch_page(1, 0); // switch to page 1	
				}
				return true;
			}
			break;
		case '~': // ESC ~
			return (this.term.state = this.CHANGETERMTYPE);
		
	}
	if (this.term.escseq(ch)) { // is private escape sequence?
		return false;
	}
	
	return this._invalid_escseq(ch); // return true to continue processing write loop
	
};

// process ESC c sequence
WyseEmulator.prototype._escc = function(ch) {
	var i, j;
	if ((this.tmask & this.VPA2E) && ch >= '0' && ch <= '9') {
		return (this.term.state = this.ESCDASH);
	}
	switch (ch) {
        case '0': case '1': case '8':
			this.ignoreCount = 4; // ignore 4 characters
			this.ignoreExit = '';
			return (this.term.state = this.IGNORE);
        case '@':
			return (this.term.state = this.WYSEBANK);
        case '2': case '3': case '4': case '5': case '6': case '7': case '\\': case ']':
			this.ignoreCount = 1; // ignore 1 character
			this.ignoreExit = '';
			return (this.term.state = this.IGNORE);
		case ';':
			// program answerback string
			this.inputStr = '';
			this.inputTerm = '\x19';
			this.inputLen = 30;
			this.inputFunc = this.ANSWERBACK;
			return (this.term.state = this.INPUTSTRING);
        case '<':
			this.term.send(this.term.answerBack + String.fromCharCode(0x06)); // send answerback string to host
			break;
        case '=':
            this.term.answerBack = '';
			break;
        case 'B':
			return (this.term.state = this.GETPRIMARYFONT);
        case 'C':
			return (this.term.state = this.GETSECONDARYFONT);
        case 'D':
			if (this.secondaryCharset) {
				this.secondaryCharset = false;
				this.updateCharset();
			}            
			break;
        case 'E':
			if (!this.secondaryCharset) {
				this.secondaryCharset = true;
				this.updateCharset();
			}            
			break;
        case 'F': case 'G': case 'H': case 'N':
			this.term.params[0] = ch; // indicate which box function
			return (this.term.state = this.GETBOXROW);
        case 'I':
			// clear unprotected column to char
			return (this.term.state = this.GETFILLCHAR);
        case 'K':
			// clear unprotected column
			this._fill_screen(' ', ((this.protMode && this.curMode & 64) ? this.curTag : 0), this.curMode, true, this.term.x, this.term.x);
			break;
        case 'J':
			// delete column
			j = this.term.y; // save cursor position
			for (i = this.term.scrollTop; i <= this.term.scrollBottom; i++) {
				this.term.y = i;
				this._delete_char();
			}
			this.term.y = j; // restore cursor position
			this.term.updateRange(this.term.scrollTop);
			this.term.updateRange(this.term.scrollBottom);
			break;
        case 'M':
			// insert column
			j = this.term.y; // save cursor position
			for (i = this.term.scrollTop; i <= this.term.scrollBottom; i++) {
				this.term.y = i;
				this._insert_char();
			}
			this.term.y = j; // restore cursor position
			this.term.updateRange(this.term.scrollTop);
			this.term.updateRange(this.term.scrollBottom);
			break;
        case 'L': case 'O':
			// clear unprotected to end of line
			this._clear_to_end_of_line(true);
			break;
        case 'P': case 'Q':
            // clear unprotected foreground to end of page (clear characters, not attributes)
			this._fill_screen_data(' ', true, this.term.x, this.term.cols - 1, this.term.y, this.term.y);
			if (this.term.y < this.term.scrollBottom) {
				this._fill_screen_data(' ', true, 0, this.term.cols - 1, this.term.y + 1, this.term.scrollBottom);
			}
			break;
        case 'R': case 'S':
            // clear unprotected foreground to end of line (clear characters, not attributes)
			this._fill_screen_data(' ', true, this.term.x, this.term.cols - 1, this.term.y, this.term.y);
			break;
        case 'U':
			// clear all redefinable function keys
			this._clear_redefinable_keys();
			break;
        case 'V': case 'W':
            //TODO: save settings (W should save tabs, but AccuTerm never saves tabs)
			break;
        case 'X':
			// power-on reset
			this.term.reset();
			break;
		case '^':
			// copy rectangle
			return (this.term.state = this.GETCPYMODE);
		default:
			return this._invalid_escseq(ch); // return true to continue processing write loop
	}
	return true;
};

// handle invalid escape sequence
WyseEmulator.prototype._invalid_escseq = function(ch) {
	if (!(this.tmask & this.ADDSVP)) { // ADDS ignores invalid escqpe sequence
		this.term.writech('\u2593');
	}
	if (ch === '\x1b') {
		this.term.state = this.term.ESCAPED; // ESC ESC - discard first ESC, continue processing second ESC		
	}
	return true;
};

// return true if scrolling is disabled
WyseEmulator.prototype._noscroll = function() {
	return this.term.noScrollMode || this.protModeEnabled;
};

// calculate Wyse attribute
WyseEmulator.prototype._calc_attr = function(mode, attr, bg, fg, x, y) {
	switch (mode & (4 | 16 | 64)) {
		case 4: // ATTR_EMBED
			attr = this.scrAttr;
			break;
		case 16: // ATTR_TAG
			attr = this.curTag | this._get_embedded_attr(x, y);
			break;
		case 64: // ATTR_NONEMBED
			break;
		case 80: // ATTR_TAG | ATTR_NONEMBED
			attr |= this.curTag;
			break;
		default:
			attr = this._get_embedded_attr(x, y);
			break;
	}
	if (!(mode & 2)) { // ATTR_COLORB
		bg = attr ? this.term.attrColor[attr][0] : 256; // use the default background color if attr is zero
	}
	if (!(mode & 8)) { // ATTR_COLORF
		fg = attr ? this.term.attrColor[attr][1] : 257; // use the default foreground color if attr is zero
	}
	return ((attr << 18) | (fg << 9) | bg);
};

// get Wyse attribute
WyseEmulator.prototype._get_attr = function() {
	return this._calc_attr(this.curMode, this.curAttr, this.curBG, this.curFG);
};

// get Wyse embedded attribute
WyseEmulator.prototype._get_embedded_attr = function(x, y) {
	var line;
	var flags;
	x = (x === undefined ? this.term.x : x);
	y = (y === undefined ? this.term.y : y);
	x--;
	if (x < 0) {
		x = this.term.cols - 1;
		y--;
	}
	// scan screen buffer for embedded attribute
	for ( ; y >= 0; y--) {
		line = this.term.lines[this.term.ybase + y];
		for ( ; x >= 0; x--) {
			flags = line[x][2] || 0;
			if ((flags & 126) === 0) { // 126 = 2 | 4 | 8 | 16 | 32 | 64 (excluded ATTR_PROT)
				return ((line[x][0] >> 18) & 63); // found prior character without any special attribute flags - return its attribute
			} else if ((flags & 126) === 4) {
				return ((flags >> 8) & 63); // found prior embedded attribute character
			}
		}
		x = this.term.cols - 1;
	}
	return this.scrAttr;
};

// decode Wyse character attribute (returns term.js attribute)
WyseEmulator.prototype._decode_attr = function(ch, adds4000) {
	switch (ch) {
		case '0':
			return 0; // normal
		case '1':
			return 16; // invisible
		case '2':
			return 4; // blink
		case '3':
			return (16 + 4); // blink invisible
		case '4':
			return 8; // reverse
		case '5':
			return (16 + 8); // reverse invisible
		case '6':
			return (8 + 4); // reverse blink
		case '7':
			return (16 + 8 + 4); // reverse blink invisible
		case '8':
			return 2; // underline
		case '9':
			return (16 + 2); // invisible underline
		case ':':
			return (4 + 2); // blink underline
		case ';':
			return (16 + 4 + 2); // invisible blink underline
		case '<':
			return (8 + 2); // reverse underline
		case '=':
			return (16 + 8 + 2); // invisible reverse underline
		case '>':
			return (8 + 4 + 2); // reverse blink underline
		case '?':
			return (16 + 8 + 4 + 2); // invisible reverse blink underline
		case 'p': case 'P':
			return 32; // dim
		case 'q': case 'Q':
			return (32 + 16); // dim invisible
		case 'r': case 'R':
			return (32 + 4); // dim blink
		case 's': case 'S':
			return (32 + 16 + 4); // dim blink invisible
		case 't': case 'T':
			return (32 + 8); // dim reverse
		case 'u': case 'U':
			return (32 + 16 + 8); // dim reverse invisible
		case 'v': case 'V':
			return (32 + 8 + 4); // dim reverse blink
		case 'w': case 'W':
			return (32 + 16 + 8 + 4); // dim reverse blink invisible
		case 'x': case 'X':
			return (32 + 2); // dim underline
		case 'y': case 'Y':
			return (32 + 16 + 2); // dim invisible underline
		case 'z': case 'Z':
			return (32 + 4 + 2); // dim blink underline
		case '{': case '[':
			return (32 + 16 + 4 + 2); // dim invisible blink underline
		case '|': case '\\':
			return (32 + 8 + 2); // dim reverse underline
		case '}': case ']':
			return (32 + 16 + 8 + 2); // dim invisible reverse underline
		case '~': case '^':
			return (32 + 8 + 4 + 2); // dim reverse blink underline
		case '\x7f':
			return (32 + 16 + 8 + 4 + 2); // dim invisible reverse blink underline
	}
	if (adds4000) {
		switch (ch) {
			case '@':
				return 32; // dim
			case 'A':
				return (32 + 16); // dim invisible
			case 'B':
				return (32 + 4); // dim blink
			case 'C':
				return (32 + 16 + 4); // dim blink invisible
			case 'D':
				return (32 + 8); // dim reverse
			case 'E':
				return (32 + 16 + 8); // dim reverse invisible
			case 'F':
				return (32 + 8 + 4); // dim reverse blink
			case 'G':
				return (32 + 8 + 4 + 1); // dim reverse blink invisible
			case 'H':
				return (32 + 2); // dim underline
			case 'I':
				return (32 + 16 + 2); // dim invisible underline
			case 'J':
				return (32 + 4 + 2); // dim blink underline
			case 'K':
				return (32 + 16 + 4 + 2); // dim invisible blink underline
			case 'L':
				return (32 + 8 + 2); // dim reverse underline
			case 'M':
				return (32 + 16 + 8 + 2); // dim invisible reverse underline
			case 'N':
				return (32 + 8 + 4 + 2); // dim reverse blink underline
			case 'O':
				return (32 + 16 + 8 + 4 + 2); // dim invisible reverse blink underline
		}		
	}
	return -1; // character attribute not found
};

// handle Wyse ESC G & ADDS 4000 ESC g sequences
WyseEmulator.prototype._wyse_attr = function(ch, adds4000) {
    var attr = this._decode_attr(ch, adds4000);
    if (attr >= 0) {
		this.curMode &= ~(2 + 8); // turn off direct color bits
        if ((this.tmask & this.WYSE60) || adds4000) {
			// set the current & direct attribute
            this.curAttr = attr;
            if (this.tmask & this.WYSE60) {
                this.dirAttr = this.curAttr;
                if (this.protMode) {
					this.curAttr |= this.curTag;
				}
			}
            this.curMode |= 64; // set ATTR_NONEMBEDED bit
		} else {
			// update display with embedded attribute character
            this.curMode &= ~64; // clear ATTR_NONEMBEDED bit
			this.term.writech(' ', this.eraseAttr(), (attr << 8) | (4 + 0x01)); // mode is ATTR_EMBED | ATTR_PROT, save attribute in bits 8-15
			this._update_embedded_attr();
		}
	} else {
        // check for double wide / double high
        switch (ch) {
            case '@': // single high, single wide
				attr = 0; // reset line attribute
				break;
			case 'A': // single high, double wide
				attr = 1;
				break;
			case 'B': // double high top / single wide
				attr = 2;
				break;
            case 'C': // double high bottom / single wide
				attr = 4;
				break;
            case 'D': // double high top / double wide
				attr = 3;
				break;
            case 'E': // double high bottom / double wide
				attr = 5;
				break;
		}
		if (attr >= 0) {
			 this.term.lineAttributes(this.term.y, attr);
		}
	}
	return true;
};

// handle ADDS ESC 0 sequence
WyseEmulator.prototype._adds_attr = function(ch) {
	var attr = 0;
	switch (ch) {
		case '@': // ESC 0 @
	        attr = 0; // normal
			break;
		case 'A': // ESC 0 A         
			attr = 32; // dim
			break;
		case 'B': // ESC 0 B         
			attr = 4; // blink
			break;
		case 'C': // ESC 0 C         
			attr = (4 + 32); // blink dim
			break;
		case 'D': // ESC 0 D        
			attr = 16; // invisible
			break;
		case 'E': // ESC 0 E
			attr = (16 + 32); // invisible dim
			break;
		case 'F': // ESC 0 F
			attr = (16 + 4); // invisible blink
			break;
		case 'G': // ESC 0 G
			attr = (16 + 4 + 32); // invisible blink dim
			break;
		case 'P': // ESC 0 P         
			attr = 8; // reverse
			break;
		case 'Q': // ESC 0 Q         
			attr = (8 + 32); // reverse dim
			break;
		case 'R': // ESC 0 R        
			attr = (4 + 8); // blink reverse
			break;
		case 'S': // ESC 0 S         
	        attr = (4 + 8 + 32); // blink reverse dim
			break;
		case 'T': // ESC 0 T
			attr = (16 + 8); // invisible reverse
			break;
		case 'U': // ESC 0 U
			attr = (16 + 8 + 32); // invisible reverse dim
			break;
		case 'W': // ESC 0 W
			attr = (16 + 4 + 8 + 32); // invisible blink reverse dim
			break;
		case 'V': // ESC 0 V
			attr = (16 + 4 + 8); // invisible blink reverse
			break;
		case '`': // ESC 0 `         
			attr = 2; // underline
			break;
		case 'a': // ESC 0 a         
			attr = (2 + 32); // underline dim
			break;
		case 'b': // ESC 0 b         
			attr = (2 + 4); // underline blink
			break;
		case 'c': // ESC 0 c
			attr = (2 + 4 + 32); // underline blink dim
			break;
		case 'd': // ESC 0 d
			attr = (16 + 2); // invisible underline
			break;
		case 'e': // ESC 0 e
			attr = (16 + 2 + 32); // invisible underline dim
			break;
		case 'f': // ESC 0 f
			attr = (16 + 2 + 8); // invisible underline blink
			break;
		case 'g': // ESC 0 g
			attr = (16 + 2 + 4 + 32); // invisible underline blink dim
			break;
		case 'p': // ESC 0 p
			attr = (2 + 8); // underline reverse
			break;
		case 'q': // ESC 0 q
			attr = (2 + 8 + 32); // underline reverse dim
			break;
		case 'r': // ESC 0 r
			attr = (2 + 8 + 4); // underline reverse blink
			break;
		case 's': // ESC 0 s
			attr = (2 + 8 + 4 + 32); // underline reverse blink dim
			break;
		case 't': // ESC 0 t
			attr = (16 + 2 + 8); // invisible underline reverse
			break;
		case 'u': // ESC 0 u
			attr = (16 + 2 + 8 + 32); // invisible underline reverse dim
			break;
		case 'v': // ESC 0 v
			attr = (16 + 2 + 8 + 4); // invisible underline reverse blink
			break;
		case '\x7f': // ESC 0 DEL
			attr = (16 + 2 + 8 + 4 + 32); // invisible underline reverse blink dim
			break;
	}
	this.curMode &= ~(2 | 8); // reset ATTR_COLORB & ATTR_COLORF bits
    this.curTag = attr;
    this._update_tag_attr();
	return true;
};

// update display for changed embedded attribute character
WyseEmulator.prototype._update_embedded_attr = function(x, y) {
	var line;
	var flags;
	var old_attr, new_attr;
	var changed;
	x = (x === undefined ? this.term.x : x);
	y = (y === undefined ? this.term.y : y);
	// scan buffer forward until next embedded attribute character found
	for (; y < this.term.rows; y++) {
		changed = false;
		line = this.term.lines[this.term.ybase + y];
		for (; x < this.term.cols; x++) {
			flags = line[x][2] | 0;
			if (flags & 4) {
				return; // done when we encounter next embedded attribute character
			}
			old_attr = line[x][0];
			new_attr = this._calc_attr(flags, (old_attr >> 18) & 0x3f, old_attr & 0x1ff, (old_attr >> 9) & 0x1ff, x, y);
			if (old_attr !== new_attr) {
				if (line[x][2]) {
					line[x] = [new_attr, line[x][1], line[x][2]];					
				} else {
					line[x] = [new_attr, line[x][1]];
				}
				changed = true;
			}
		}
		x = 0;
		if (changed) {
			this.term.updateRange(y);
		}
	}
};

// update display for changed tag attribute
WyseEmulator.prototype._update_tag_attr = function() {
	var line;
	var flags;
	var old_attr, new_attr;
	var changed = false;
	var x;
	var y;
	// scan buffer forward until next embedded attribute character found
	if (!(this.curMode & 64)) {
		for (y = 0; y < this.term.rows; y++) {
			line = this.term.lines[this.term.ybase + y];
			for (x = 0; x < this.term.cols; x++) {
				flags = line[x][2] | 0;
				if ((flags & (16 | 64)) === 16) { // changed tag only affects characters that acquire embedded attributes
					old_attr = line[x][0];
					new_attr = this._calc_attr(flags, (old_attr >> 18) & 0x3f, old_attr & 0x1ff, (old_attr >> 9) & 0x1ff, x, y);
					if (old_attr !== new_attr) {
						line[x] = [new_attr, line[x][1], line[x][2]];							
						changed = true;
					}
				}
			}
			if (changed) {
				this.term.updateRange(y);
			}
		}	
	}
};

// update display for changed screen attribute
WyseEmulator.prototype._update_screen_attr = function() {
	var line;
	var flags;
	var old_attr, new_attr;
	var changed;
	var x, y;
	for (y = 0; y < this.term.rows; y++) {
		changed = false;
		line = this.term.lines[this.term.ybase + y];
		for (x = 0; x < this.term.cols; x++) {
			flags = line[x][2] | 0;
			old_attr = line[x][0];
			new_attr = this._calc_attr(flags, (old_attr >> 18) & 0x3f, old_attr & 0x1ff, (old_attr >> 9) & 0x1ff, x, y);
			if (old_attr !== new_attr) {
				if (line[x][2]) {
					line[x][0] = [new_attr, line[x][1], line[x][2]];
				} else {
					line[x][0] = [new_attr, line[x][1]];
				}
				changed = true;				
			}
		}
		if (changed) {
			this.term.updateRange(y);
		}
	}
};

// clear screen
WyseEmulator.prototype._clear_screen = function(unprot, resetProtectMode, homeCursor) {
	var j;
	if (resetProtectMode) {
		this.protModeEnabled = false;		
	}
	if (this.term.hasScrollingRegion()) {
		for (j = this.term.scrollTop; j <= this.term.scrollBottom; j++) {
			if (unprot && this.protModeEnabled) {
				this.term.eraseLineUnprotected(j);
			} else {
				this.term.eraseLine(j, true);
			}
		}
		if(!(unprot && this.prodModeEnabled))
			this.term.clearImages();
	} else {
		this.term.clearScreen(unprot && this.protModeEnabled);
	}
	if (homeCursor) {
		this._cursor_home(); // Wyse always homes the cursor after clear
	}
};

// clear unprotected to end of scrolling region
WyseEmulator.prototype._clear_to_end_of_region = function(unprot) {
	var j;
	if (this.term.x === 0 && this.term.y === this.term.scrollTop) {
		this._clear_screen(unprot, false, false); // erase the entire screen - copy to history before clearing
	} else {
		this._clear_to_end_of_line(unprot);
		for (j = this.term.y + 1; j <= this.term.scrollBottom; j++) {
			if (unprot && this.protModeEnabled) {
				this.term.eraseLineUnprotected(j);
			} else {
				this.term.eraseLine(j, true);
			}
		}
		this._update_embedded_attr();
	}
};

// clear unprotected to end of line
WyseEmulator.prototype._clear_to_end_of_line = function(unprot) {
	if (unprot && this.protModeEnabled) {
		this.term.eraseRightUnprotected(this.term.x, this.term.y);
	} else if (this.term.x === 0) {
		this.term.eraseLine(this.term.y, true);
	} else {
		this.term.eraseRight(this.term.x, this.term.y);
	}
	this._update_embedded_attr();
};
	
// fill screen
WyseEmulator.prototype._fill_screen = function(ch, attr, mode, unprot, left, right, top, bottom) {
	var line;
	var flags;
	var new_attr;
	var x, y;
	left = left || 0;
	right = right || this.term.cols - 1;
	top = top || this.term.scrollTop;
	bottom = bottom || this.term.scrollBottom;
	new_attr = this._calc_attr(mode, attr, this.curBG, this.curFG, left, top);
	for (y = top; y <= bottom; y++) {
		line = this.term.lines[this.term.ybase + y];
		if (line) {
			for (x = left; x <= right; x++) {
				if(unprot && this.protModeEnabled) {
					flags = line[x][2] || 0;
					if (!(flags & 1)) { // protected?
						line[x] = [new_attr, ch, mode];
					}
				} else {
					line[x] = [new_attr, ch, mode];
				}
			}
		}
	}
	this.term.updateRange(top);
	this.term.updateRange(bottom);
};

// fill screen data (attributes not affected)
WyseEmulator.prototype._fill_screen_data = function(ch, unprot, left, right, top, bottom) {
	var line;
	var flags;
	var x, y;
	left = left || 0;
	right = right || this.term.cols - 1;
	top = top || this.term.scrollTop;
	bottom = bottom || this.term.scrollBottom;
	for (y = top; y <= bottom; y++) {
		line = this.term.lines[this.term.ybase + y];
		if (line) {
			for (x = left; x <= right; x++) {
				if(unprot && this.protModeEnabled) {
					flags = line[x][2] || 0;
					if (!(flags & 1)) { // protected?
						if (line[x][2]) {
							line[x][1] = [line[x][0], ch, line[x][2]];						
						} else {
							line[x][1] = [line[x][0], ch];
						}
					}
				} else {
					if (line[x][2]) {
						line[x][1] = [line[x][0], ch, line[x][2]];
					} else {
						line[x][1] = [line[x][0], ch];
					}
				}
			}
		}
	}
	this.term.updateRange(top);
	this.term.updateRange(bottom);
};

// draw box
WyseEmulator.prototype._draw_box = function(x, y, relative) {
	// draw box
	var x1 = this.term.x,
		y1 = this.term.y,
		x2 = x,
		y2 = y,
		z;
	if (relative) {
		x2 += x1;
		y2 += y1;
	}
	if (x1 > x2) {z = x2; x2 = x1; x1 = z;}
	if (y1 > y2) {z = y2; y2 = y1; y1 = z;}
	if (x2 - x1 < 1 || y2 - y1 < 1) {
		return; // too small!
	}
	this._fill_screen('\u250c', this.curAttr, this.curMode + 0x01, false, x1, x1, y1, y1);	
	this._fill_screen('\u2510', this.curAttr, this.curMode + 0x01, false, x2, x2, y1, y1);	
	this._fill_screen('\u2518', this.curAttr, this.curMode + 0x01, false, x2, x2, y2, y2);	
	this._fill_screen('\u2514', this.curAttr, this.curMode + 0x01, false, x1, x1, y2, y2);
	if (x2 - x1 > 1) {
		this._fill_screen('\u2500', this.curAttr, this.curMode + 0x01, false, x1 + 1, x2 - 1, y1, y1);	
		this._fill_screen('\u2500', this.curAttr, this.curMode + 0x01, false, x1 + 1, x2 - 1, y2, y2);			
	}
	if (y2 - y1 > 1) {
		this._fill_screen('\u2502', this.curAttr, this.curMode + 0x01, false, x1, x1, y1 + 1, y2 - 1);	
		this._fill_screen('\u2502', this.curAttr, this.curMode + 0x01, false, x2, x2, y1 + 1, y2 - 1);			
	}
};

// clear box
WyseEmulator.prototype._clear_box = function(x, y, ch, unprot) {
	// clear box
	var x1 = this.term.x,
		y1 = this.term.y,
		z;
	if (x1 > x) {z = x; x = x1; x1 = z;}
	if (y1 > y) {z = y; y = y1; y1 = z;}
	this._fill_screen(ch, this.curAttr, this.curMode, unprot, x1, x, y1, y);	
};

// copy box
WyseEmulator.prototype._copy_box = function(m, p1, x1, y1, x2, y2, p3, x3, y3) {
	if (m === 0) {
		m = 3;
	}
	this.term.copyRectangle([y1, x1, y2, x2, p1 + 1, y3, x3, p3 + 1, m]);
};

// cursor home
WyseEmulator.prototype._cursor_home = function() {
	this.term.x = 0;
	this.term.y = this.term.scrollTop;
};

// cursor down
WyseEmulator.prototype._cursor_down = function(count) {
	count = count || 1;
	var y = this.term.y;
	y += count;
	if(y > this.term.scrollBottom) {
		y = this.term.scrollBottom;
	}
	this.term.y = y;
};

// cursor up
WyseEmulator.prototype._cursor_up = function(count) {
	count = count || 1;
	var y = this.term.y;
	var rows = this.term.scrollBottom - this.term.scrollTop + 1;
	// Wyse terminals wrap when moving cursor up
	y -= count;
	while(y < this.term.scrollTop) {
		y += rows;
	}
	this.term.y = y;
};

// cursor left
WyseEmulator.prototype._cursor_left = function(count) {
	count = count || 1;
	var x = this.term.x;
	var cols = this.term.cols;
	x -= count;
	while(x < 0) {
		this._cursor_up(1);
		x += cols;
	}
	this.term.x = x;
};

// cursor left
WyseEmulator.prototype._cursor_right = function(count) {
	count = count || 1;
	var x = this.term.x;
	var cols = this.term.cols;
	x += count;
	while(x >= cols) {
		this._cursor_down(1);
		x -= cols;
	}
	this.term.x = x;
};

// cursor index
WyseEmulator.prototype._cursor_index = function(count) {
	count = count || 1;
	if(this._noscroll()) {
		this._cursor_down(count);
	} else {
		// perform the index
		for(; count > 0; count--) {
			this.term.index();
		}
		// update embedded attributes
		this._update_embedded_attr(0, this.term.scrollTop);
	}
};

// cursor reverse index
WyseEmulator.prototype._cursor_reverse_index = function(count) {
	count = count || 1;
	if(this._noscroll()) {
		this._cursor_up(count);
	} else {
		for(; count > 0; count--) {
			this.term.reverseIndex();
		}
	}
};

// set cursor row
WyseEmulator.prototype._set_cursor_row = function(row) {
	/*jshint curly: false */
	var rows = this.term.scrollBottom - this.term.scrollTop + 1;
	if (row < 0) row = 0;
	if (row < rows) {
		this.term.y = row + this.term.scrollTop; // row is relative to top of scrolling region
		return true;
	}
	return false;
};

// set cursor column
WyseEmulator.prototype._set_cursor_col = function(col) {
	/*jshint curly: false */
	if (col < 0) col = 0;
	if (col >= 0 && col < this.term.cols) {
		this.term.x = col;
		return true;
	}
	return false;
};

// if protect mode, move cursor forward to next unprotected position
WyseEmulator.prototype._prot_chkfwd = function() {
	if(this.protModeEnabled) {
		var line,
			x = this.term.x,
			y = this.term.y,
			b,
			flags;
		if(y >= this.term.scrollTop && y <= this.term.scrollBottom) {
			b = this.term.scrollBottom;
		} else {
			b = this.term.rows - 1;			
		}
		for (; y <= b; y++) {
			line = this.term.lines[this.term.ybase + y];
			for (; x < this.term.cols; x++) {
				flags = line[x][2] || 0;
				if(!(flags & 0x1)) {
					this.term.x = x;
					this.term.y = y;
					return;
				}
			}
			x = 0;
		}
	}
};

// if protect mode, move cursor backward to previous unprotected position
WyseEmulator.prototype._prot_chkbck = function() {
	if(this.protModeEnabled) {
		var line,
			x = this.term.x,
			y = this.term.y,
			t,
			flags;
		if(y >= this.term.scrollTop && y <= this.term.scrollBottom) {
			t = this.term.scrollTop;
		} else {
			t = 0;			
		}
		for (; y >= t; y--) {
			line = this.term.lines[this.term.ybase + y];
			for (; x >= 0; x--) {
				flags = line[x][2] || 0;
				if(!(flags & 0x1)) {
					this.term.x = x;
					this.term.y = y;
					return;
				}
			}
			x = this.term.cols - 1;
		}
	}
};

// insert character
WyseEmulator.prototype._insert_char = function() {
	var x, save_x, line, flags;
	if (this.protModeEnabled) {
		// in protect mode, insert in field of unprotected characters
		line = this.term.lines[this.term.ybase + this.term.y];
		save_x = this.term.x;
		for (x = save_x; x < this.term.cols; x++) {
			flags = line[x][2] || 0;
			if (flags & 0x01) {
				break;
			}
		}
		this.term.x = x - 1; // temporarily adjust cursor
		this.term.deleteChars([1]); // delete last character of field
		this.term.x = save_x; // restore cursor
	}
	this.term.insertChars([1]);	
};

// delete character
WyseEmulator.prototype._delete_char = function() {
	var x, save_x, line, flags;
	if (this.protModeEnabled) {
		// in protect mode, delete from field of unprotected characters
		line = this.term.lines[this.term.ybase + this.term.y];
		save_x = this.term.x;
		for (x = save_x; x < this.term.cols; x++) {
			flags = line[x][2] || 0;
			if (flags & 0x01) {
				break;
			}
		}
		this.term.deleteChars([1]); // delete character in field
		if (x < this.term.cols) {
			save_x = this.term.x;
			this.term.x = x - 1; // temporarily adjust cursor
			this.term.insertChars([1]); // insert after last character in field
			this.term.x = save_x; // restore cursor
		}
	} else {
		this.term.deleteChars([1]);	
	}
};

// toggle Wyse window
WyseEmulator.prototype._toggle_window = function() {
	var x = this.splitX;
	var y = this.splitY;
	this.splitX = this.term.x;
	this.splitY = this.term.y;
	if (this.term.scrollTop > 0) {
		// activate the upper window
		this.term.scrollBottom = this.term.scrollTop - 1;
		this.term.scrollTop = 0;
		if (this.term.scrollBottom < this.term.scrollTop) {
			this.term.scrollBottom = this.term.scrollTop; // ensure region is at least one line
		}
	} else {
		// activate the lower window
		this.term.scrollTop = this.term.scrollBottom + 1;
		this.term.scrollBottom = this.term.rows - 1;
		if (this.term.scrollTop > this.term.scrollBottom) {
			this.term.scrollTop = this.term.scrollBottom; // ensure region is at least one line
		}
	}
	if (y < this.term.scrollTop) {
		y = this.term.scrollTop;
	} else if (y > this.term.scrollBottom) {
		y = this.term.scrollBottom;
	}
	this.term.x = x;
	this.term.y = y;
};

// switch page
WyseEmulator.prototype._switch_page = function(page, direction) {
	var scrollTop = this.term.scrollTop;
	var scrollBottom = this.term.scrollBottom;
	this.term.switchPage(page, direction, 1);
	this.term.scrollTop = scrollTop;
	this.term.scrollBottom = scrollBottom;
};

// Wyse send screen to host
WyseEmulator.prototype._send_screen = function(unprot, which) {
	
	switch (which) {
	
		case 0:
            // send unprotected line (through cursor column)
            //TODO: Wyse 50 terminal deletes protected characters instead of sending blanks!
            //TODO: Wyse 50 terminal only deletes protected characters when protect mode is enabled!
            //TODO: Wyse terminal inserts extra spaces where attributes change, also CHAR(28) after protected chars?
            // Y = TV.Screen.Row
            // TDSendInit TV, Y, Y, 6, AscCR, AscCR '5.3c: send unprotected characters in row up to cursor position, terminated by CR (any protected characters are converted to blanks)
		break;
		
		case 1:
	
    // TODO: Wyse 50 terminal deletes protected characters instead of sending blanks!
    // TODO: Wyse 50 terminal only deletes protected characters when protect mode is enabled!
    // TODO: Wyse terminal inserts extra spaces where attributes change, also CHAR(28) after protected chars?
	
	// send unprotected characters from (0,0) through cursor position, with US after each line except last, CR after last line (protected characters are converted to blanks)
    //  TDSendInit TV, TV.RowTop, TV.Screen.Row, 6, Asc31, AscCR 
		break;
		
		case 2:
	// send all characters in row up to cursor position
	// TODO: Wyse 50 terminal inserts protect on/protect off codes in stream!
    //  Y = TV.Screen.Row
    //  TDSendInit TV, Y, Y, 4, AscCR, AscCR '5.3c: send all characters in row up to cursor position, terminated by CR
		break;
		
		case 3:
	// send all characters from (0,0) through cursor position, with US after each line except last, CR after last line
    // TODO: Wyse 50 terminal inserts protect on/protect off codes in stream!
    //  TDSendInit TV, TV.RowTop, TV.Screen.Row, 4, Asc31, AscCR
		break;
		

	}			
};

// decode Wyse function key: return 0=undefined, 1=function key, 2=keypad key
WyseEmulator.prototype._decode_wyse_fkey = function(ch) {
	var rtn = 1;
	this.shiftMask = 0;
	this.virtKey = 0;
	if (ch >= '@' && ch <= 'I') {
		this.virtKey = ch.charCodeAt(0) - 64 + 112;  // F1-F10
	} else if (ch >= 'J' && ch <= 'O') {
		this.virtKey = ch.charCodeAt(0) - 74 + 122;  // F11-F16	
	} else if (ch >= '\x60' && ch <= 'i') {
		this.virtKey = ch.charCodeAt(0) - 96 + 112;  // shift F1-F10
		this.shiftMask = 1;
	} else if (ch >= 'j' && ch <= 'o') {
		this.virtKey = ch.charCodeAt(0) - 106 + 122;  // shift F11-F16
		this.shiftMask = 1;		
	} else {
		rtn = 2;
		switch(ch) {
			case ' ':
				this.virtKey = 27; // Escape
				break;
			case '%':
				this.virtKey = 27; // Escape
				this.shiftMask = 1;
				break;
			case '!':
				this.virtKey = 9; // Tab
				break;
			case '&':
				this.virtKey = 9; // Tab
				this.shiftMask = 1;
				break;
			case '"':
				this.virtKey = 8; // Backspace
				break;
			case "'":
				this.virtKey = 8; // Backspace
				this.shiftMask = 1;
				break;
			case '$':
				this.virtKey = 13; // Return
				break;
			case ')':
				this.virtKey = 13; // Return
				this.shiftMask = 1;
				break;
			case '*':
				this.virtKey = 36; // Home
				break;
			case '/':
				this.virtKey = 36; // Home
				this.shiftMask = 1;
				break;
			case '+':
				this.virtKey = 38; // Up
				break;
			case '0':
				this.virtKey = 38; // Up
				this.shiftMask = 1;
				break;
			case ',':
				this.virtKey = 40; // Down
				break;
			case '1':
				this.virtKey = 40; // Down
				this.shiftMask = 1;
				break;
			case '-':
				this.virtKey = 37; // Left
				break;
			case '2':
				this.virtKey = 37; // Left
				this.shiftMask = 1;
				break;
			case '.':
				this.virtKey = 39; // Right
				break;
			case '3':
				this.virtKey = 39; // Right
				this.shiftMask = 1;
				break;
			case 's':
				this.virtKey = 253; // Keypad Enter
				break;
			case '4':
				this.virtKey = 253; // Keypad Enter
				this.shiftMask = 1;
				break;
			case 'q':
				this.virtKey = 45; // Insert
				break;
			case 'p':
				this.virtKey = 45; // Insert
				this.shiftMask = 1;
				break;
			case 'r':
				this.virtKey = 34; // Page Down
				break;
			case 'w':
				this.virtKey = 34; // Page Down
				this.shiftMask = 1;
				break;
			case '5':
				this.virtKey = 46; // Delete
				break;
			case '6':
				this.virtKey = 46; // Delete
				this.shiftMask = 1;
				break;
			case 'R':
				this.virtKey = 0; /* TODO: what is vbKeyPrint (key code 42) ? */
				break;
			case 'X':
				this.virtKey = 0; /* TODO: what is vbKeyPrint (key code 42) ? */
				this.shiftMask = 1;
				break;
			case ':':
				this.virtKey = 33; // Page Up
				break;
			case ';':
				this.virtKey = 33; // Page Up
				this.shiftMask = 1;
				break;
			case '\\':
				this.virtKey = 35; // End
				break;
			case ']':
				this.virtKey = 35; // End
				this.shiftMask = 1;
				break;
			case '#': case '(': case 'u': case 't': case '}': case 'z': case '7': case '8': case 'Q': case 'W': case 'S': case 'Y': case 'T': case 'Z': case 'P': case 'V':
				this.virtKey = 0; // ignore these
				break;
			default:
				rtn = 0; // undefined key
				break;
		}
	}
	return rtn;
};

WyseEmulator.prototype._decode_adds_fkey = function(ch) {
	this.shiftMask = (ch >= '@' ? 1 : 0);
	this.virtKey = 0;
	if (ch <= '\x0f') {
		this.virtKey = ch.charCodeAt(0) + 112; // F1-F16
	} else if (ch <= '\x1f') {
		this.virtKey = ch.charCodeAt(0) + 112 - 16; // shift F1=F16
		this.shiftMask = 1;
	} else {
		switch(ch) {						
			case ' ': case '@':
				this.virtKey = 36; // Home
				break;
			case '!': case 'A':
				this.virtKey = 33; // Page Up
				break;
			case '"': case 'B':
				this.virtKey = 34; // Page Down
				break;
			case '#': case 'C':
				this.virtKey = 35; // End
				break;
			case '$': case 'D':
				this.virtKey = 40; // Down
				break;
			case '%': case 'E':
				this.virtKey = 37; // Left
				break;
			case '&': case 'F':
				this.virtKey = 39; // Right
				break;
			case "'": case 'G':
				this.virtKey = 38; // Up
				break;
			case '(': case 'H':
				this.virtKey = 103; // Numpad 7
				break;
			case ')': case 'I':
				this.virtKey = 104; // Numpad 8
				break;
			case '*': case 'J':
				this.virtKey = 105; // Numpad 9
				break;
			case '+': case 'K':
				this.virtKey = 109; // Subtract
				break;
			case ',': case 'L':								
				this.virtKey = 100; // Numpad 4
				break;
			case '-': case 'M':
				this.virtKey = 101; // Numpad 5
				break;
			case '.': case 'N':
				this.virtKey = 102; // Numpad 6
				break;
			case '/': case 'O':
				this.virtKey = 0; /* TODO: what is vbKeySeparator (key code 108) ? */
				break;
			case '0': case 'P':
				this.virtKey = 97; // Numpad 1
				break;
			case '1': case 'Q':
				this.virtKey = 98; // Numpad 2
				break;
			case '2': case 'R':
				this.virtKey = 99; // Numpad 3
				break;
			case '3': case 'S':
				this.virtKey = 96; // Numpad 0
				break;
			case '4': case 'T':
				this.virtKey = 110; // Decimal
				break;
			case '5': case 'U':
				this.virtKey = 253; // Keypad Enter
				break;
			case '6': case 'V':
				this.virtKey = 27; // Escape
				break;
			case '7': case 'W':
				this.virtKey = 8; // Backspace
				break;
			case '8': case 'X':
				this.virtKey = 9; // Tab
				break;
			case '9': case 'Y':
				this.virtKey = 13; // Return
				break;
			case ':': case 'Z':
				this.virtKey = 0; /* TODO: what is vbKeyPrint (key code 42) ? */
				break;
			case ';': case '[':
				this.virtKey = 45; // Insert
				break;
		}
	}
};

// Wyse screen features
WyseEmulator.prototype._scr_feature = function(ch) {
	switch (ch) {
		case '0':
			this.term.cursorHidden = true; // hide cursor
			break;
		case '1':
			this.term.cursorHidden = false; // show cursor
			break;
		case '2': 
			this.term.cursorStyle = 2; // steady block cursor
			this.term.cursorHidden = false; // show cursor
			break;
		case '3': 
			this.term.cursorStyle = 3; //TODO: blinking underline cursor
			this.term.cursorHidden = false; // show cursor
			break;
		case '4':
			this.term.cursorStyle = 4; //TODO: steady underline cursor
			this.term.cursorHidden = false; // show cursor
			break;
		case '5':
			this.term.cursorStyle = 1; // blinking block cursor
			this.term.cursorHidden = false; // show cursor
			break;
		case '8':
			// Turn screen off (N/A)
			break;
		case '9':
			// Turn Screen on (N/A)
			break;
		case ':':
	        if (this.term.cols !== this.term.settings.normCols || this.term.rows !== this.term.settings.normRows) {
	            if (this.clearOnResize) {
					this._clear_screen(false, true, true);
				}
				this.term.resize(this.term.settings.normCols, this.term.settings.normRows);
		        this.term.scrMode = 0;
			}
			break;
		case ';':
	        if (this.term.cols !== this.term.settings.extCols || this.term.rows !== this.term.settings.extRows) {
				if (this.clearOnResize) {
					this._clear_screen(false, true, true);
				}
				this.term.resize(this.term.settings.extCols, this.term.settings.extRows);
				this.term.scrMode = 1;
			}
			break;
		case '6':
			this._change_protect_attr(8); // Set protect to reverse
			break;
		case '7':
			this._change_protect_attr(32); // Set protect to dim
			break;
	    case 'A':
			this._change_protect_attr(0); // Set protect to normal
			break;
		case 'B':
			this._change_protect_attr(this.curTag | 4); // Set protect to blink
			break;
		case 'C':
			this._change_protect_attr(this.curTag | 16); // Set protect to invisible
			break;
		case 'E':
			this._change_protect_attr(this.curTag | 2); // Set protect to underline
			break;
		case 'F':
			this._change_protect_attr(this.curTag | 8); // Set protect to reverse
			break;
		case 'G':
			this._change_protect_attr(this.curTag | 32); // Set protect to dim
			break;
		case 'a': case 'b':
			//TODO: show the host message line
			break;
		case 'c':
			//TODO: hide the host message line
			break;
		default:
			// Smooth scroll stuff ignored
	}
};

// Change the protect (tag) attribute
WyseEmulator.prototype._change_protect_attr = function(attr) {
	this.curTag = attr;
	if (this.tmask & this.WYSE60) {
		if (this.protModeEnabled) {
			this.curAttr = this.dirAttr | attr;
		}
	} else {
		this._update_tag_attr();
	}	
};

// clear all redefinable function keys
WyseEmulator.prototype._clear_redefinable_keys = function() {
	var key, def;
    for(key in this.term.kbdmap) {
        if (this.term.kbdmap.hasOwnProperty(key)) {
			def = this.term.kbdmap[key] || {};
			if (def && def.dflt) {
				def.host = null; // define the key, but leave content null
				this.term.kbdmap[key] = def;
			}
        }
    }
};

// return screen erase attribute
WyseEmulator.prototype.eraseAttr = function() {
	var attr = this.scrAttr;
	//return ((attr << 18) | (this.term.attrColor[attr][1] << 9) | this.term.attrColor[attr][0]); 
	var bg = attr ? this.term.attrColor[attr][0] : 256; // use the default background color if attr is zero
	var fg = attr ? this.term.attrColor[attr][1] : 257; // use the default foreground color if attr is zero
	return ((attr << 18) | (fg << 9) | bg);
};

// set background color
WyseEmulator.prototype.setBackground = function(color) {
	this.curMode |= 2; // set the direct background color bit
	this.curBG = color;
};

// set foreground color
WyseEmulator.prototype.setForeground = function(color) {
	this.curMode |= 8; // set the direct foreground color bit
	this.curFG = color;
};

// update embedded attribute
WyseEmulator.prototype.updateEmbeddedAttr = function(x, y) {
	this._update_embedded_attr(x, y);
};

// update tag attribute
WyseEmulator.prototype.updateTagAttr = function(tag) {
	this._change_protect_attr(tag);
};

// update the host character set to Unicode map
WyseEmulator.prototype.updateCharset = function() {
	/* jshint curly: false */
	var i, left, right;	
	for (i = 0; i < 2; i++) {
		if (i === 0 && this.graphMode) {
			switch (this.charbank[this.secondaryCharset ? 1 : this.charsets[0]]) {
				case 'A':
				case 'a':
					// 80-9F of codepage 437
					left = 'WYSE_MULTI';
					break;
				case 'B':
				case 'G':
				case 'H':
				case 'c':
				case 'd':
				case 'g':
					//'control chars
					left = 'WYSE_CTRL';
					break;
				case 'D':
				case 'J':
				case 'b':
				   // 00-1F of codepage 437
				   left = 'WYSE_DINGS';
				   break;
				case 'I':
				case 'N':
				case 'e':
					// Wyse math
					left = 'WYSE_MATH';
					break;
				default: // '@', '`'
					// Wyse graphics
					left = 'WYSE_GRAPH';
			}
		} else {
			switch (this.charbank[this.secondaryCharset ? 1 : this.charsets[i]]) {
				case 'A':
				case 'a':
					// codepage 437 right
					if (i === 0) left = 'IBMPC'; else right = 'IBMPC';
					break;
				case '@':
				case '`':
					// Wyse graphics in Cx, ASCII in Gx
					if (i === 0) left = 'ASCII'; else right = 'WYSE_ASCII_GRAPH';
					break;
				case 'B':
				case 'c':
				case 'd':
				case 'G':
				case 'H':
				case 'g':
					// control chars in Cx, ASCII in Gx
					if (i === 0) left = 'ASCII'; else right = 'ASCII_CONTROLS';
					break;
				case 'J':
				case 'N':
				case 'j':
				case 'I':
				case 'e':
				   // Wyse math in Cx, ISO Latin-1 in Gx
					if (i === 0) left = 'LATIN1'; else right = 'WYSE_LATIN1_MATH';
					break;
			   default:  // 'D', 'b'
					// codepage 437 left (default)
					if (i === 0) left = 'ASCII'; else right = 'ASCII_WINGDINGS';
			}
		}
	}
	if (left === 'ASCII' && this.monitorMode) {
		left = 'ASCII_CONTROLS'; // display controls
	}
	this.term.charmap.updateCharset(left, right);
};

// save emulation-specific state variables
WyseEmulator.prototype.saveState = function(screen_state) {
	screen_state.scrAttr = this.scrAttr;
	screen_state.dirAttr = this.dirAttr;
	screen_state.curAttr = this.curAttr;
	screen_state.curMode = this.curMode;
	screen_state.curTag = this.curTag;
	screen_state.curFG = this.curFG;
	screen_state.curBG = this.curBG;
	screen_state.protMode = this.protMode;
	screen_state.protModeEnabled = this.protModeEnabled;
	screen_state.graphMode = this.graphMode;
	screen_state.secondaryCharset = this.secondaryCharset;
	screen_state.charbank = this.charbank.slice(0);
	screen_state.charsets = this.charsets.slice(0);
};

// restore emulation-specific state variables
WyseEmulator.prototype.restoreState = function(screen_state) {	
	this.scrAttr = screen_state.scrAttr !== undefined ? screen_state.scrAttr : this.scrAttr;
	this.dirAttr = screen_state.dirAttr !== undefined ? screen_state.dirAttr : this.dirAttr;
	this.curAttr = screen_state.curAttr !== undefined ? screen_state.curAttr : this.curAttr;
	this.curMode = screen_state.curMode !== undefined ? screen_state.curMode : this.curMode;
	this.curTag = screen_state.curTag !== undefined ? screen_state.curTag : this.curTag;
	this.curFG = screen_state.curFG !== undefined ? screen_state.curFG : this.curFG;
	this.curBG = screen_state.curBG !== undefined ? screen_state.curBG : this.curBG;
	this.protMode = screen_state.protMode !== undefined ? screen_state.protMode : this.protMode;
	this.protModeEnabled = screen_state.protModeEnabled !== undefined ? screen_state.protModeEnabled : this.protModeEnabled;
	this.graphMode = screen_state.graphMode !== undefined ? screen_state.graphMode : this.graphMode;
	this.secondaryCharset = screen_state.secondaryCharset !== undefined ? screen_state.secondaryCharset : this.secondaryCharset;
	this.charbank = screen_state.charbank || this.charbank;
	this.charsets = screen_state.charsets || this.charsets;
	this.updateCharset();
};

////////////////////////////////////////////////////////////
// Exports!
////////////////////////////////////////////////////////////

export { WyseEmulator };
