
































import { Component, Prop, Vue } from 'vue-property-decorator';
// Types
import { IGUIEvent, simpleNotification } from './../../../types'
import { GUIEventCode, MenuProps, MenuItemProps, GUIErrorCode, GUIErrorLevel } from './../../../types/enums'
import { MenuItem, CharacterUnderlineProps } from './../../../types/components'
import { UtilsType } from './../../../types/utils'
// Components
import MenuDropDown from './DropDown.vue'
import CharacterUnderline from './../../layout/CharacterUnderline.vue';
import Icon from './../../layout/Icon.vue';

@Component({
  name: 'gxMenuComponent',
  components: { CharacterUnderline, MenuDropDown, Icon }
})

export default class gxMenu extends Vue {
  /** ******************************** Vue Props **********************************/

  @Prop({ default: () => (new MenuProps()) }) public props!: MenuProps;

  /** ******************************** Vue Data **********************************/

  public script: string = 'components/menus/menu/Menu.vue';

  public bootstrapHandled: boolean = false;

  /** ******************************** Vue Computed **********************************/

  get utils(): UtilsType { return this.$store.getters['guiGuis/getUtils'] }

  get showingMenuId(): string { return this.$store.getters['guiGuis/getMenuItemId']; }

  get isShowingMenuId(): boolean { return this.showingMenuId !== ''; }

  get style() {
    let style: Partial<CSSStyleDeclaration>[] = []

    const containerCSS: Partial<CSSStyleDeclaration> = { 
      zIndex: '10',
      backgroundColor: this.props.gpBackColor,
      color: this.props.gpForeColor
    }
       
    style.push(containerCSS)

    return style
  }


  // For tree data structure please see:
  // https://vuejs.org/v2/examples/tree-view.html
  get items() : Array<MenuItem> { 
    let items = { children: [] };
    let levels: Array<any> = [];
    let curLevel: number = 1;
    let topItem: any = items;
    levels[0] = items;

    this.props.gpItems.forEach((item: MenuItemProps) => {
      let level = item.level;

      if (level < 1 || level > curLevel + 1) { 
        // Error Notification
        const notification: simpleNotification = { type: 'danger', show: true, message: 'Tree level is out of order', friendlyMessage: 'We encountered a error while building the tree control (' + this.props.id + ')', script: this.script, error: { errorCode: GUIErrorCode.grFailure, errorLevel: GUIErrorLevel.glvlWarn } }
        this.$store.dispatch('guiNotifications/addNotification', notification)
      }

      if (curLevel === level) {
          // sibling at current level
      } else if (curLevel + 1 === level) {
          // child
          topItem = topItem.children[topItem.children.length - 1];
          levels[curLevel] = topItem;
      } else {
          // sibling at previous level
          topItem = levels[level - 1];
      }

      let indicies : number[] = []

      if (level > 1) {
          indicies = [...levels[level - 1].indicies]
      }

      indicies.push(topItem.children.length)

      let newItem: MenuItem = { 
        ...item, 
        indicies: indicies,
        cuProps: this.cuProps,
        children: [] 
      };

      //newItem.caption = this.utils.underlineCharacter(newItem.caption);
    
      topItem.children.push(newItem);
      curLevel = level;
    });

    return items.children;
  }

  set items(items: Array<MenuItem>) {
    //const newItems = this.convertItems(items)
    //this.$store.dispatch('guiGuis/updateProperty', { id: this.props.id, property: 'gpItems', value: newItems }) 
  }

  get enabled() { return this.props.gpEnabled }

  get visible() { return this.props.gpVisible }

  get cuProps(): CharacterUnderlineProps {
    let form = this.props.form ? this.props.form.id : null;
    return { app: this.props.app!.id, form: form, control: this.props.id, type: this.props.type }
  }

/** ******************************** Vue Methods **********************************/

  clickHandler(e: MouseEvent, id: string, level: number) {
    e.stopPropagation();
    
    this.appClickEvent(id);
  }

  toggleHandler(e: PointerEvent, id: string, level: number, show: boolean) {
  
    if (level === 1 && show) {
      this.$store.dispatch('guiGuis/setMenuItemId', id);
    }

    this.appClickEvent(id);  
  }

  hideHandler(level: number) {
    if (level === 1) {
      this.$store.dispatch('guiGuis/setMenuItemId', '');
    }
  }

  appClickEvent(id: string) {
    let args: Array<string> = []

    if (id) {
      args.push(id)
    }
    
    if (this.props.gpEventMask & GUIEventCode.geClick) {
      // send this event to the server
      const guiEvent: IGUIEvent = { event: GUIEventCode.geClick, id: this.props.id, args: args }
      this.$store.dispatch('guiGuis/addEvent', guiEvent);
    }
  }

  getIconName (value: string) { return this.utils.getIcon(value) };

}
