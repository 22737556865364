






import { Component, Vue, Prop } from 'vue-property-decorator'
// Types

@Component({
  name: 'FontDialogComponent'
})

export default class FontDialog extends Vue {
  
/** ******************************** Vue Data **********************************/

  @Prop() public dialogBox: any;

/** ******************************** Vue Computed **********************************/

/** ******************************** Vue Methods **********************************/

}
