import { EnterpriseAuthServiceType } from '@/types/services/enterprise'

// payload is generally TermApp instance
export default class EnterpriseAuthService implements EnterpriseAuthServiceType {
    async auth (payload: string) {
        const response = await fetch(process.env.VUE_APP_ENTERPRISE_VALID_ENDPOINT, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'text/plain'
            },
            body: payload // body data type must match "Content-Type" header
        })

        return response.json() // parses JSON response into native JavaScript objects
    }
}
