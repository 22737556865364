import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
import Profile from '../../../../../types'
// Types
import { RootState } from './../../types/store/'
import { GeneralState } from './../../types/store/general'
// State
export const state: GeneralState = {
    environment: process.env.NODE_ENV,
    performanceCheck: process.env.NODE_ENV === "development",
    profile: new Profile()
}

// Namespace
const namespaced: boolean = true

// Getters
export const getters: GetterTree<GeneralState, RootState> = {
    getEnvironment(state) { return state.environment },
    performanceCheck(state) { return state.performanceCheck },
    getProfile(state) { return state.profile }
}

// Mutations
export const mutations: MutationTree<GeneralState> = {
    SET_PROFILE (state, profile) { state.profile = profile },
}

// Actions
export const actions: ActionTree<GeneralState, RootState> = {
    setProfile ({ commit }, profile) { commit('SET_PROFILE', profile) },
}

export const guiGeneral: Module<GeneralState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}
