










import { Component, Emit, Watch, Vue } from 'vue-property-decorator'
// Types
import Profile from '@/types'

@Component({
    name: 'AccuTermComponent'
})

export default class AccuTerm extends Vue {
/** ******************************** Vue Data! **********************************/
    private focusCommands: string = '';

    $refs!: {
        accuterm: HTMLElement,
        beep: HTMLElement
    }

/** ******************************** Vue Computed **********************************/

    get getViewport () { return this.$store.getters['terminal/getViewport'] }
    get style () { return this.$store.getters['terminal/getAccuTermStyle'] }
    get hasAccuTerm (): boolean { return this.$store.getters['terminal/hasAccuTerm'] }

    get activeProfile (): Profile { return this.$store.getters['profiles/getActiveProfile'] }

/** ******************************** Vue Methods **********************************/

    handleMouseClick (e: MouseEvent) {
        // If it's a left mouse click
        if (e.which === 1) {
            // So helpful: //stackoverflow.com/questions/5379120/get-the-highlighted-selected-text
            let selection = window.getSelection()
            let selectionString = (selection) ? selection.toString() : ''
            // Make sure there's something selected
            if (selectionString !== '') {
                this.$emit('handleCopy') // Just copy the selected text
                // this.$emit('confirmCopy', selectionString) // Use a confirm modal to copy
            // If there's nothing selected, then the user probably just clicked
            } else {
                this.$store.dispatch('terminal/sendClick', { e: e })
            }
        }
    }

/** ******************************** Vue Mounted! **********************************/

    mounted () {
        // Refs
        const term = this.$refs.accuterm
        const beep = this.$refs.beep

        // So helpful https://stackoverflow.com/questions/54759153/vuex-run-function-after-multiple-dispatches
        this.$store.dispatch('terminal/initiateTerm', { term, profile: this.activeProfile }).then(() => {
            // Set Listeners
            this.$store.dispatch('terminal/setListeners', { term, beep })

            this.$store.dispatch('terminal/openTerm')
            this.$store.dispatch('terminal/initiateViewport')
            this.$store.dispatch('terminal/connect')
            this.$store.dispatch('terminal/containerResize')
        })
    }

/** ******************************** Vue Watch **********************************/

    // Add mouse click listener after viewport is loaded
    @Watch('getViewport') setViewportListener () {
        // maybe replace the listener with the vue directive v-on:click.left (at some point)
        this.getViewport.addEventListener('mouseup', this.handleMouseClick)
    }

    @Watch('activeProfile') setActiveProfile () {
        let options = { resetTerminal: true, resetConnection: true }
        this.$store.dispatch('terminal/updateSettings', { profile: this.activeProfile, options: options })
    }

    @Watch('$route.name') scrollPastHistory () {
        // If we land on the terminal and AccuTerm is running
        if (this.$route.name === 'Terminal' && this.hasAccuTerm) {
            // scroll to the bottom of the terminal-viewport div
            this.getViewport.scrollTop = this.getViewport.scrollHeight
        }
    }
}
