/*
 * WebSocket transport for AccuTerm Web
 * 
 * Copyright 2018 Zumasys, Inc.
 */

////////////////////////////////////////////////////////////
// Import Dependencies!
////////////////////////////////////////////////////////////

import { DEBUG } from './globals.js';


var wsSocket = function(host, port, options)
{

	// options:
	//  options['raw'] = true -> raw mode (newline not appended to sent messages)
	//  options['charset'] = 'latin1' | 'utf8' | 'ascii' (this is ignored)
	//  options['secure'] = true (wss) | false (ws)
		
	// User callbacks
	this.onopen = null;
	this.onmessage = null;
//	this.onsent = null;
	this.onerror = null;
	this.onclose = null;
	
	// Properties
	this.host = host;
	this.port = port;
	this.options = options;
	this.readyState = wsSocket.INITIALIZED;
	this.lastErrorCode = 0;
	this.lastErrorMessage = "";

	// Methods
	this.connect = wsSocket.prototype._connect.bind(this);
	this.send = wsSocket.prototype._send.bind(this);
	this.close = wsSocket.prototype._close.bind(this);
	this.removeAllListeners = wsSocket.prototype._removeAllListeners.bind(this);
	
	// Private
	this._sock = null;
	this._timer = null;
		
};

// readyState enum
wsSocket.INITIALIZED = 0;
wsSocket.CONNECTING = 1;
wsSocket.CONNECTED = 2;
wsSocket.CLOSING = 3;
wsSocket.CLOSED	= 4;

// failure code enum
wsSocket.UNEXPECTED = 0;
wsSocket.HOST_NOT_FOUND = 1;
wsSocket.HOST_UNREACHABLE = 2;
wsSocket.CONNECTION_REFUSED = 3;
wsSocket.CONNECTION_TIMEOUT = 4;
wsSocket.CONNECTION_CLOSED = 4;
wsSocket.CONNECTION_BROKEN = 5;
wsSocket.SOCKET_NOT_OPEN = 7;
wsSocket.QUEUE_FULL = 8;

// application-defined websocket codes
// 4001 - timeout while connecting
// 4002 - failed to connect to host
// 4003 - invalid URL
// 4004 - security error
// 4011 - server closed connection due to heartbeat failure
// 4997 - unspecified error (occurs if socket emits error event without close event)
// 4998 - exception during send
// 4999 - exception while creating socket

wsSocket.prototype._connect = function(timeout)
{
	// timeout value is milliseconds, or zero for no timeout
	
	if(this.readyState !== wsSocket.INITIALIZED && this.readyState !== wsSocket.CLOSED) {
		console.log("wsSocket.connect: invalid readyState=" + this.readyState + "; ignoring!");
		return;
	}
	
	var sch = (this.options && this.options.secure) ? "wss" : "ws";
	var port = this.port || (sch === "wss" ? 443 : 80);
	var url = sch + "://" + this.host + ":" + port;

    console.log("wsSocket.connect: URL" + url + " timeout=" + timeout + " opts=" + this.options);
	if(this._timer) {
		clearTimeout(this._timer);
		this._timer = null;
	}
	
	timeout = ~~timeout;
	if(timeout > 0) {
		this._timer = setTimeout(this._onTimeout.bind(this), timeout);
	}
	
	this.lastErrorCode = 0;
	this.lastErrorMessage = "";
	this.readyState = wsSocket.CONNECTING;
	
	try {
		this._sock = new WebSocket(url);
		this._sock.binaryType = 'arraybuffer';
		this._sock.onopen = this._onConnect.bind(this);
		this._sock.onmessage = this._onMessage.bind(this);
		this._sock.onclose = this._onClose.bind(this);
		this._sock.onerror = this._onError.bind(this);
	} catch(e) {
		console.log('wsSocket.connect caught exception: ' + e);
		if(e && e.name && e.name === 'SyntaxError') {
			this._internalClose({code: 4003, reason: e.message});
		} else if(e && e.name && e.name === 'SecurityError') {
			this._internalClose({code: 4004, reason: e.message});			
		}
		this._internalClose({code: 4999, reason: e.message});
	}

};

wsSocket.prototype._send = function(data)
{
    //console.log("wsSocket.send: new data=" + data.length + " buffered data=" + this._out_length + " ack received=" + this._ack_received);
	try {
		if(this.readyState === wsSocket.CONNECTED) {
			if(data.length > 0) {
				this._sock.send(new Uint8Array([].map.call(data, function(x) {return x.charCodeAt(0)})).buffer);
			}
		}
	} catch(e) {
		console.log('wsSocket.send caught exception: ' + e);
		this._internalClose({code: 4998, reason: e.message});
	}
};

// app asked to close the connection
wsSocket.prototype._close = function()
{
    console.log("wsSocket.close: readyState=" + this.readyState);
	this._internalClose();
};

// if error occurs or socket closes, close the connection & report the error to client
wsSocket.prototype._internalClose = function(evt)
{
    console.log("wsSocket.internalClose: code=" + ((evt && evt.code) || 0) + " reason=" + ((evt && evt.reason) || "") + " readyState=" + this.readyState);
	var s = this._sock;
	this._sock = null; // bypass onClose handler
	if(this._timer) {
		clearTimeout(this._timer);
		this._timer = null;		
	}
	if(s) {
		this.readyState = wsSocket.CLOSING;
		try {
			s.close(); // close the socket
		} catch(e) {}
	}
	if(this.readyState !== wsSocket.CLOSED) {
		this.readyState = wsSocket.CLOSED;
		this.lastErrorCode = (evt && evt.code) || this.lastErrorCode;
		if(this.lastErrorCode === 1000) this.lastErrorCode = 0;
		if(this.lastErrorCode) {
			this.lastErrorMessage = (evt && evt.reason) || this._genericErrorMessage(this.lastErrorCode);
			this.onerror && this.onerror(this.lastErrorCode, this.lastErrorMessage);
		}
		this.onclose && this.onclose(this.lastErrorCode);		
	}
};

wsSocket.prototype._onConnect = function()
{
    console.log("wsSocket.onConnect");
	if(this._timer) {
		clearTimeout(this._timer);
		this._timer = null;		
	}
	this.readyState = wsSocket.CONNECTED;
	this.onopen && this.onopen();
};

wsSocket.prototype._onMessage = function(message)
{
	//console.log("wsSocket.onMessage: length=" + message.data.byteLength);
	if(this.onmessage) {	
		this.onmessage(String.fromCharCode.apply(null, new Uint8Array(message.data)));
	}
};

wsSocket.prototype._onTimeout = function()
{
    console.log("wsSocket.onTimeout: readyState=" + this.readyState);
	this._internalClose({code: 4001, reason: "timeout"});
};

wsSocket.prototype._onClose = function(evt)
{
    console.log("wsSocket.onClose: readyState=" + this.readyState + " code=" + ((evt && evt.code) || "") + " reason=" + ((evt && evt.reason) || "unknown"));
	this._sock = null; // indicate that socket is closed (we do not change readyState here - defer until internalClose)
	// while connecting, close code 1006 indicates a generic "failed to connect" error
	if (this.readyState === wsSocket.CONNECTING && evt && evt.code && evt.code === 1006) {
		this._internalClose({code: 4002, reason: "connection to host failed"});
	} else {
		this._internalClose(evt);
	}
};

wsSocket.prototype._onError = function()
{
    console.log("wsSocket.onError: readyState=" + this.readyState);
	this._sock = null; // indicate that socket is closed (we do not change readyState here - defer until internalClose)
	setTimeout((function() {this._internalClose({code: 4997, reason: "unspecified WebSocket error"});}).bind(this), 1); // report the error if onclose does not fire
};

wsSocket.prototype._removeAllListeners = function()
{
	this.onclose = null;
	this.onerror = null;
//	this.onsent = null;
	this.onmessage = null;
	this.onopen = null;
};

wsSocket.prototype._genericErrorMessage = function(code) {
	switch(code) {
		case 1000: return "";
		case 1001: return "going away";
		case 1002: return "protocol error";
		case 1003: return "unsupported data type";
		case 1005: return "no status code received";
		case 1006: return "abnormal connection closure";
		case 1007: return "invalid frame payload data";
		case 1008: return "policy violation";
		case 1009: return "message too big";
		case 1010: return "missing extension";
		case 1011: return "server internal error";
		case 1012: return "server is restarting";
		case 1013: return "try again later";
		case 1014: return "bad gateway";
		case 1015: return "TLS handshake failure";
	}
	return "unknown WebSocket error";	
};


////////////////////////////////////////////////////////////
// Exports!
////////////////////////////////////////////////////////////

export { wsSocket };
