/*
 * AccuTerm Mobile - terminal driver for Pick PC Console
 *
 * Copyright 2015 Zumasys, Inc.
 */

////////////////////////////////////////////////////////////
// Import Dependencies!
////////////////////////////////////////////////////////////

import { TermColors } from './termcolors.js';
import { DEBUG } from './globals.js';

/* global TermColors: false, DEBUG: false */

DEBUG&&console.log('top of tdpc.js');

function PCEmulator(term, termtype) {
	this.term = term;
	this.init(termtype);
}

PCEmulator.prototype = {
	// PC Console state contants
	ESCSTAR: 100,
	ESCEQUAL: 101,
	GETROWPOS: 102
};
	
PCEmulator.prototype.init = function(termtype) {
	this.termtype = termtype;
	this.kbdinit();
	this.reset();
};

PCEmulator.prototype.kbdinit = function() {

/*	
NORMAL              0
SHIFT               1000
CTRL                2000
CTRL+SHIFT          3000
ALT                 4000
CTRL+SHIFT          3000
ALT+SHIFT           5000
CTRL+ALT            6000
CTRL+ALT+SHIFT      7000

VK_BACK             8
VK_TAB              9
VK_INSERT           45
VK_DELETE           46
VK_HOME             36
VK_END              35
VK_PRIOR (PgUp)     33
VK_NEXT (PgDn)      34
VK_LEFT             37
VK_RIGHT            39
VK_UP               38
VK_DOWN             40

VK_ESCAPE           27
VK_RETURN           13
VK_KEYPAD_ENTER     253

VK_F1               112
VK_F2               113
VK_F3               114
VK_F4               115
VK_F5               116
VK_F6               117
VK_F7               118
VK_F8               119
VK_F9               120
VK_F10              121
VK_F11              122
VK_F12              123
*/	
	var key, def, dfltkbd = [];
	dfltkbd[8] = '\x08'; // VK_BACK
	dfltkbd[9] = '\x09'; // VK_TAB
	dfltkbd[13] = '\x0d'; // VK_RETURN
	dfltkbd[27] = '\x1b'; // VK_ESCAPE
	dfltkbd[37] = '\x15'; // VK_LEFT
	dfltkbd[38] = '\x1a'; // VK_UP
	dfltkbd[39] = '\x06'; // VK_RIGHT
	dfltkbd[40] = '\x0a'; // VK_DOWN
	dfltkbd[253] = '\x0d'; // VK_KEYPAD_ENTER
	dfltkbd[1008] = '\x08'; // SHIFT+VK_BACK
	dfltkbd[1009] = '\x09'; // SHIFT+VK_TAB
	dfltkbd[1013] = '\x0d'; // SHIFT+VK_RETURN
	dfltkbd[1027] = '\x1b'; // SHIFT+VK_ESCAPE
	dfltkbd[1037] = '\x15'; // SHIFT+VK_LEFT
	dfltkbd[1038] = '\x1a'; // SHIFT+VK_UP
	dfltkbd[1039] = '\x06'; // SHIFT+VK_RIGHT
	dfltkbd[1040] = '\x0a'; // SHIFT+VK_DOWN
	dfltkbd[1253] = '\x0d'; // SHIFT+VK_KEYPAD_ENTER
	dfltkbd[112] = '\x02\x40'; // VK_F1
	dfltkbd[113] = '\x02\x41'; // VK_F2
	dfltkbd[114] = '\x02\x42'; // VK_F3
	dfltkbd[115] = '\x02\x43'; // VK_F4
	dfltkbd[116] = '\x02\x44'; // VK_F5
	dfltkbd[117] = '\x02\x45'; // VK_F6
	dfltkbd[118] = '\x02\x46'; // VK_F7
	dfltkbd[119] = '\x02\x47'; // VK_F8
	dfltkbd[120] = '\x02\x48'; // VK_F9
	dfltkbd[121] = '\x02\x49'; // VK_F10
	dfltkbd[122] = '\x02\x4a'; // VK_F11
	dfltkbd[123] = '\x02\x4b'; // VK_F12
	dfltkbd[1112] = '\x02\x50'; // SHIFT+VK_F1
	dfltkbd[1113] = '\x02\x51'; // SHIFT+VK_F2
	dfltkbd[1114] = '\x02\x52'; // SHIFT+VK_F3
	dfltkbd[1115] = '\x02\x53'; // SHIFT+VK_F4
	dfltkbd[1116] = '\x02\x54'; // SHIFT+VK_F5
	dfltkbd[1117] = '\x02\x55'; // SHIFT+VK_F6
	dfltkbd[1118] = '\x02\x56'; // SHIFT+VK_F7
	dfltkbd[1119] = '\x02\x57'; // SHIFT+VK_F8
	dfltkbd[1120] = '\x02\x58'; // SHIFT+VK_F9
	dfltkbd[1121] = '\x02\x59'; // SHIFT+VK_F10
	dfltkbd[1122] = '\x02\x5a'; // SHIFT+VK_F11
	dfltkbd[1123] = '\x02\x5b'; // SHIFT+VK_F12
	dfltkbd[2112] = '\x02\x20'; // CTRL+VK_F1
	dfltkbd[2113] = '\x02\x21'; // CTRL+VK_F2
	dfltkbd[2114] = '\x02\x22'; // CTRL+VK_F3
	dfltkbd[2115] = '\x02\x23'; // CTRL+VK_F4
	dfltkbd[2116] = '\x02\x24'; // CTRL+VK_F5
	dfltkbd[2117] = '\x02\x25'; // CTRL+VK_F6
	dfltkbd[2118] = '\x02\x26'; // CTRL+VK_F7
	dfltkbd[2119] = '\x02\x27'; // CTRL+VK_F8
	dfltkbd[2120] = '\x02\x28'; // CTRL+VK_F9
	dfltkbd[2121] = '\x02\x29'; // CTRL+VK_F10
	dfltkbd[2122] = '\x02\x2a'; // CTRL+VK_F11
	dfltkbd[2123] = '\x02\x2b'; // CTRL+VK_F12
	dfltkbd[2112] = '\x02\x20'; // CTRL+VK_F1
	dfltkbd[2113] = '\x02\x21'; // CTRL+VK_F2
	dfltkbd[2114] = '\x02\x22'; // CTRL+VK_F3
	dfltkbd[2115] = '\x02\x23'; // CTRL+VK_F4
	dfltkbd[2116] = '\x02\x24'; // CTRL+VK_F5
	dfltkbd[2117] = '\x02\x25'; // CTRL+VK_F6
	dfltkbd[2118] = '\x02\x26'; // CTRL+VK_F7
	dfltkbd[2119] = '\x02\x27'; // CTRL+VK_F8
	dfltkbd[2120] = '\x02\x28'; // CTRL+VK_F9
	dfltkbd[2121] = '\x02\x29'; // CTRL+VK_F10
	dfltkbd[2122] = '\x02\x2a'; // CTRL+VK_F11
	dfltkbd[2123] = '\x02\x2b'; // CTRL+VK_F12
	dfltkbd[3112] = '\x02\x20'; // SHIFT+CTRL+VK_F1
	dfltkbd[3113] = '\x02\x21'; // SHIFT+CTRL+VK_F2
	dfltkbd[3114] = '\x02\x22'; // SHIFT+CTRL+VK_F3
	dfltkbd[3115] = '\x02\x23'; // SHIFT+CTRL+VK_F4
	dfltkbd[3116] = '\x02\x24'; // SHIFT+CTRL+VK_F5
	dfltkbd[3117] = '\x02\x25'; // SHIFT+CTRL+VK_F6
	dfltkbd[3118] = '\x02\x26'; // SHIFT+CTRL+VK_F7
	dfltkbd[3119] = '\x02\x27'; // SHIFT+CTRL+VK_F8
	dfltkbd[3120] = '\x02\x28'; // SHIFT+CTRL+VK_F9
	dfltkbd[3121] = '\x02\x29'; // SHIFT+CTRL+VK_F10
	dfltkbd[3122] = '\x02\x2a'; // SHIFT+CTRL+VK_F11
	dfltkbd[3123] = '\x02\x2b'; // SHIFT+CTRL+VK_F12
	// add default key definitions to kbdmap
    for (key in dfltkbd) {
        if (dfltkbd.hasOwnProperty(key)) {
			def = this.term.kbdmap['key' + key] || {};
			def.dflt = dfltkbd[key];
			this.term.kbdmap['key' + key] = def;
		}
	}

};

PCEmulator.prototype.reset = function() {
	this.term.state = this.term.NORMAL;	
	this.curAttr = this.scrAttr = 0;
	this.curMode = (2 | 8); // PC Console uses direct foreground & background colors
	this.curBG = this.scrBG = this.term.attrColor[0][0];
	this.curFG = this.scrFG = this.term.attrColor[0][1];
	this.protMode = (this.curFG <= 7); // dim foreground indicates "protect mode"
	this.protModeEnabled = false; // set to disable scrolling & test protect bit when writing to screen
	this.reverse = false;
};

// override default eraseAttr - PC Console fills any erased positions with current attribute, not screen attribute
PCEmulator.prototype.eraseAttr = function() {
	return this._get_attr();
};

// returns number of characters remaining to be processed (in case of private sequence or changing termtype)
PCEmulator.prototype.write = function(data) {
	//DEBUG&&console.log('PC.write ' + data.length);
	var len = data.length
	  , i;

	for (i = 0; i < len; i++) {
		if (!this.pc_engine(data[i])) {
			return (len - i - 1);
		}
	}
};
	
PCEmulator.prototype.pc_engine = function(ch) {	

	var curState
	  , n;

	// PC Console state engine
	curState = this.term.state;
	this.term.state = this.term.NORMAL;
	switch (curState) {
		case this.term.NORMAL:
			switch (ch) {
				case '\x00':
					break;
				case '\x07':
					this.term.bell();
					break;
				case '\x08':
					this._cursor_left();
					break;
				case '\x0a':
					this._cursor_down();
					break;
				case '\x0c':
					this._clear_screen();
					break;
				case '\x0d':
					this.term.x = 0;
					break;
				case '\x1b':
					this.term.state = this.term.ESCAPED;
					break;
				default:
					if (ch < ' ') {
						if(this.term.ctlchr(ch)) { // is private control sequence?
							return false;
						}
						// display unhandled control characters using wingdings character group
					}
					this._prot_chkfwd();
					this.term.writech(ch, this._get_attr());
					break;
			}
			break;
		case this.term.ESCAPED:
			switch (ch) {
				case '*':
					this.term.state = this.ESCSTAR;
					break;
				case '=':
					this.term.state = this.ESCEQUAL;
					break;
				default:
					if (this.term.escseq(ch)) { // is private escape sequence?
						return false;
					}
					this._prot_chkfwd();
					this.term.writech('\x1b', this._get_attr()); // display unhandled ESC character using wingdings character group
					return this.pc_engine(ch);
			}			
			break;
		case this.ESCEQUAL:
			this._set_cursor_col(ch.charCodeAt(0));
			return (this.term.state = this.GETROWPOS);
		case this.GETROWPOS:
			this._set_cursor_row(ch.charCodeAt(0));
			break;
		case this.ESCSTAR:
			n = ch.charCodeAt(0);
			if ((n >= 0 && n <= 19) || (n >= 104)) {
				this._at_minus(n);
			} else if (n >= 33 && n <= 40) {
				this._set_backcolor(40 - n);
			} else if (n >= 41 && n <= 48) {
				this._set_protect_mode(false);
				this._set_forecolor(48 - n);
			} else if (n >= 57 && n <= 64) {
				this._set_protect_mode(true);
				this._set_forecolor(64 - n);
			}
			break;
		default:
			break;				
	}
	return true;
};

// get PC attribute
PCEmulator.prototype._get_attr = function() {
	var attr = this.curAttr
	  , fg = this.curFG
	  , bg = this.curBG;
	if (!(this.curMode & 2)) { // ATTR_COLORB
		bg = attr ? this.term.attrColor[attr][0] : 256; // use the default background color if attr is zero
	}
	if (!(this.curMode & 8)) { // ATTR_COLORF
		fg = attr ? this.term.attrColor[attr][1] : 257; // use the default foreground color if attr is zero
	}
	return ((attr << 18) | (fg << 9) | bg);
};

// set cursor row
PCEmulator.prototype._set_cursor_row = function(row) {
	/*jshint curly: false */
	var rows = this.term.scrollBottom - this.term.scrollTop + 1;
	if (row < 0) row = 0;
	if (row < rows) {
		this.term.y = row + this.term.scrollTop; // row is relative to top of scrolling region
		return true;
	}
	return false;
};

// set cursor column
PCEmulator.prototype._set_cursor_col = function(col) {
	/*jshint curly: false */
	if (col < 0) col = 0;
	if (col >= 0 && col < this.term.cols) {
		this.term.x = col;
		return true;
	}
	return false;
};

// PC @(minus) functions
PCEmulator.prototype._at_minus = function(n) {
	var sc;
	switch (n) {
		case 1:
			// Clear screen
			this._clear_screen();
			break;
		case 2:
			// Cursor home
			this._cursor_home();
			break;
		case 3:
			// Clear end-of-screen
			this._clear_to_end_of_screen();
			break;
		case 4:
			// Clear end-of-line
			this._clear_to_end_of_line();
			break;
		case 5:
			// Blink on (high-intensity background on)
			this.curMode |= 64; // set the non-embedded attribute bit
			this.curAttr |= 4; // ATTR_BLINK
			break;
		case 6:
			// Blink off (high-intensity background off)
			this.curAttr &= ~4; // ~ATTR_BLINK
			break;
		case 7:
			// Protect on
			this._set_protect_mode(true);
			break;
		case 8:
			// Protect off
			this._set_protect_mode(false);
			break;
		case 9:
			// Cursor back
			this._cursor_left();
			break;         
		case 10:
			// Cursor up
			this._cursor_up();
			break;
		case 11:
			// Protect enabled
			this.protModeEnabled = true; // also sets "no scroll" mode
			break;
		case 12:
			// Protect disabled
			this.protectModeEnabled = false; // also sets "scroll" mode
			break;
		case 13:
			// Reverse on
			if (!this.reverse) {
				sc = this.curFG;
				this.curFG = (this.curFG & 0x8) | (this.curBG & 0x7);
				this.curBG = (this.curBG & 0x8) | (sc & 0x7);				
				this.reverse = true;
			}
			break;
		case 14:
			// Reverse off
			if (this.reverse) {
				sc = this.curFG;
				this.curFG = (this.curFG & 0x8) | (this.curBG & 0x7);
				this.curBG = (this.curBG & 0x8) | (sc & 0x7);				
				this.reverse = false;
			}
			break;
		case 15:
			// Underline on
			// todo - check this
			// only on monochrome monitor!
			break;
		case 16:
			// Underline off
			// todo - check this
			// only on monochrome monitor!
			break;
		case 17:
			// Printer on
			this.term.printer_on(true); 
			break;
		case 18:
			// Printer off
			this.term.printer_off(false);
			break;
		case 19:
			// Cursor forward
			this._cursor_right();
			break;			
		case 20:
			// Cursor down
			this._cursor_down();
			break;
		case 104:
			// Ins Char
			this._insert_line();
			break;
		case 105:
			//Del Char
			this._delete_char();
			break;
		case 106:
			// Ins Line
			this._insert_line();
			this.term.x = 0;
			break;
		case 107:
			// Del Line
			this._delete_line();
			this.term.x = 0;
			break;
	}

};

PCEmulator.prototype._set_protect_mode = function(protect) {
	if (protect) {
		this.protMode = true;
		this.curFG &= 0x7;
	} else {
		this.protMode = false;
		this.curFG |= 0x8;
	}
};
// cursor home
PCEmulator.prototype._cursor_home = function() {
	this.term.x = 0;
	this.term.y = this.term.scrollTop;
	this._prot_chkfwd();
};

// cursor down
PCEmulator.prototype._cursor_down = function() {
	if (this.protModeEnabled) {
		var y = this.term.y;
		y++;
		if(y > this.term.scrollBottom) {
			y = this.term.scrollBottom;
		}
		this.term.y = y;
	} else {
		this.term.index();
	}
	this._prot_chkfwd();
};

// cursor up
PCEmulator.prototype._cursor_up = function() {
	if (this.protectModeEnabled) {
		var y = this.term.y;
		y--;
		if(y < this.term.scrollTop) {
			y = this.term.scrollTop;
		}
		this.term.y = y;		
	} else {
		this.term.reverseIndex();
	}
	this._prot_chkbck();
};

// cursor left
PCEmulator.prototype._cursor_left = function() {
	var x = this.term.x;
	var cols = this.term.cols;
	x--;
	if (x < 0) {
		this._cursor_up();
		x += cols;
	}
	this.term.x = x;
	this._prot_chkbck();
};

// cursor left
PCEmulator.prototype._cursor_right = function() {
	var x = this.term.x;
	var cols = this.term.cols;
	x++;
	if (x >= cols) {
		this._cursor_down();
		x = 0;
	}
	this.term.x = x;
	this._prot_chkfwd();
};

// clear screen
PCEmulator.prototype._clear_screen = function() {
	this.term.clearScreen(this.protModeEnabled);
	this._cursor_home(); // home the cursor after clear
	if (this.scrAttr !== this.curAttr || this.scrBG !== this.curBG || this.scrFG !== this.curFG) {
		this.scrAttr = this.curAttr;
		this.scrBG = this.curBG;
		this.scrFG = this.curFG;
		this.term._handle_color_change(this.term.colors[this.scrBG], this.term.colors[this.scrFG]);
	}
};

// clear to end of screen
PCEmulator.prototype._clear_to_end_of_screen = function() {
	var j;
	if (this.term.x === 0 && this.term.y === this.term.scrollTop) {
		this._clear_screen(); // erase the entire screen - copy to history before clearing
	} else {
		this._clear_to_end_of_line();
		for (j = this.term.y + 1; j <= this.term.scrollBottom; j++) {
			if (this.protModeEnabled) {
				this.term.eraseLineUnprotected(j);		
			} else {
				this.term.eraseLine(j, true);
			}		
		}
	}
};

// clear to end of line
PCEmulator.prototype._clear_to_end_of_line = function() {
	if (this.protModeEnabled) {
		this.term.eraseRightUnprotected(this.term.x, this.term.y);
	} else if (this.term.x === 0) {
		this.term.eraseLine(this.term.y, true);
	} else {
		this.term.eraseRight(this.term.x, this.term.y);
	}
};
	
// insert character
PCEmulator.prototype._insert_char = function() {
	this.term.insertChars([1]);	
};

// delete character
PCEmulator.prototype._delete_char = function() {
	this.term.deleteChars([1]);	
};

// insert line
PCEmulator.prototype._insert_line = function() {
	this.term.insertLines([1]);
};
	
// delete line
PCEmulator.prototype._delete_line = function() {
	this.term.deleteLines([1]);	
};

// if protect mode, move cursor forward to next unprotected position
PCEmulator.prototype._prot_chkfwd = function() {
	if(this.protModeEnabled) {
		var line,
			x = this.term.x,
			y = this.term.y,
			b,
			flags;
		if(y >= this.term.scrollTop && y <= this.term.scrollBottom) {
			b = this.term.scrollBottom;
		} else {
			b = this.term.rows - 1;			
		}
		for (; y <= b; y++) {
			line = this.term.lines[this.term.ybase + y];
			for (; x < this.term.cols; x++) {
				flags = line[x][2] || 0;
				if(!(flags & 0x1)) {
					this.term.x = x;
					this.term.y = y;
					return;
				}
			}
			x = 0;
		}
	}
};

// if protect mode, move cursor backward to previous unprotected position
PCEmulator.prototype._prot_chkbck = function() {
	if(this.protModeEnabled) {
		var line,
			x = this.term.x,
			y = this.term.y,
			t,
			flags;
		if(y >= this.term.scrollTop && y <= this.term.scrollBottom) {
			t = this.term.scrollTop;
		} else {
			t = 0;			
		}
		for (; y >= t; y--) {
			line = this.term.lines[this.term.ybase + y];
			for (; x >= 0; x--) {
				flags = line[x][2] || 0;
				if(!(flags & 0x1)) {
					this.term.x = x;
					this.term.y = y;
					return;
				}
			}
			x = this.term.cols - 1;
		}
	}
};

PCEmulator.prototype._set_backcolor = function(color) {	
	color = (TermColors.XlateColor(color & 0x7)) | (this.curBG & 0x8); // keep current "bold" bit
	this.setBackground(color);
};

PCEmulator.prototype._set_forecolor = function(color) {
	color = (TermColors.XlateColor(color & 0x7)) | (this.curFG & 0x8); // keep current "bold" bit
	this.setForeground(color);
};

// set background color
PCEmulator.prototype.setBackground = function(color) {
	this.curMode |= 2; // set the direct background color bit
	this.curBG = color;
};

// set foreground color
PCEmulator.prototype.setForeground = function(color) {
	this.curMode |= 8; // set the direct foreground color bit
	this.curFG = color;
};

// update the host character set to Unicode map
PCEmulator.prototype.updateCharset = function() {
	this.term.charmap.setGL('ASCII_WINGDINGS'); // PC Console displays some characters in the C0 set (wingdings)!
};

// save emulation-specific state variables
PCEmulator.prototype.saveState = function(screen_state) {
	screen_state.curAttr = this.curAttr;
	screen_state.curMode = this.curMode;
	screen_state.curFG = this.curFG;
	screen_state.curBG = this.curBG;
	screen_state.protMode = this.protMode;
	screen_state.protModeEnabled = this.protModeEnabled;
	screen_state.reverse = this.reverse;
};

// restore emulation-specific state variables
PCEmulator.prototype.restoreState = function(screen_state) {	
	this.curAttr = screen_state.curAttr !== undefined ? screen_state.curAttr : this.curAttr;
	this.curMode = screen_state.curMode !== undefined ? screen_state.curMode : this.curMode;
	this.curFG = screen_state.curFG !== undefined ? screen_state.curFG : this.curFG;
	this.curBG = screen_state.curBG !== undefined ? screen_state.curBG : this.curBG;
	this.protMode = screen_state.protMode !== undefined ? screen_state.protMode : this.protMode;
	this.protModeEnabled = screen_state.protModeEnabled !== undefined ? screen_state.protModeEnabled : this.protModeEnabled;
	this.reverse = screen_state.reverse !== undefined ? screen_state.reverse : this.reverse;
	this.updateCharset();
};

////////////////////////////////////////////////////////////
// Exports!
////////////////////////////////////////////////////////////

export { PCEmulator };